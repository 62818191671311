<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Statystyki montaży</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Statystyki montaży</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight logs">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Filtry</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-md-3 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Miesiąc</label>
                  <div class="col-sm-8">
                    <VueDatePicker
                      class="stats-daterangepicker"
                      v-model="filtersMonth"
                      :format="format"
                      month-picker
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Statystyki</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :loading="loading"
                  alternating
                  multi-sort
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "MontagelogsList",
  mixins: [],
  components: {},
  props: {},

  setup() {
    const store = useStore();
    const montageStatsData = computed(() => store.getters.getMontageStatsData);
    let headers = [
      { text: "ID", value: "pk" },
      { text: "Użytkownik", value: "name", sortable: true, search: true },
      {
        text: "Zamkniętych",
        value: "closed",
        sortable: true,
      },
      { text: "Zmontowanych", value: "mounted" },
      { text: "Wydruk.", value: "printed" },
      { text: "Wydruk. (8)", value: "printed_8_plates" },
      { text: "Wydruk. (4)", value: "printed_4_plates" },
      { text: "Wydruk. (4 + odwrotka)", value: "printed_4_plates_flipped" },
      { text: "Nakład wydrukowanych [szt.]", value: "printed_quantity" },
      { text: "Waga wydrukowanych [kg.]", value: "printed_weight" },
      { text: "Naśw. płyt", value: "radiated" },
      { text: "Naśw. płyt (8)", value: "radiated_8_plates" },
      { text: "Naśw. płyt (4)", value: "radiated_4_plates" },
      { text: "Naśw. płyt (4 + odwrotka)", value: "radiated_4_plates_flipped" },
    ];
    const loading = ref(true);
    const searchValue = ref({});
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const filtersMonth = ref();
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
      sortBy: "name",
      sortType: "desc",
    });
    let items = ref([]);
    let itemsAll = ref(0);
    const getMontageStats = () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      store.dispatch("getMontageStats", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
        month: filtersMonth.value?.month + 1,
        year: filtersMonth.value?.year,
      });
    };

    const format = (date) => {
      let month, year;
      month = ("0" + (date.getMonth() + 1)).slice(-2);
      year = date.getFullYear();
      return `${month}.${year}`;
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getMontageStats();
        }, 1000);
      }
    };

    const updateSort = () => {
      search.value = true;
    };

    getMontageStats();

    watch(
      () => montageStatsData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        getMontageStats();
      }
    );
    watch(
      () => filtersMonth.value,
      function () {
        getMontageStats();
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getMontageStats();
          }, 1000);
        }
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getMontageStats();
          isSearch.value = false;
        }
      }
    );

    return {
      headers,
      serverOptions,
      items,
      itemsAll,
      loading,
      format,
      searchValue,
      search,
      updateSort,
      isSearch,
      rowsPerPage,
      filtersMonth,
    };
  },
  computed: {},
};
</script>
