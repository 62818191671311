<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Kompetencje</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/competencies">Kompetencje</router-link>
        </li>
        <li class="active">
          <strong>Nowa</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Kompetencje</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label for="username" class="control-label col-sm-2">
                  Nazwa
                </label>
                <div class="col-sm-10">
                  <input
                    id="username"
                    v-model="competenceObj.name_pl"
                    name="username"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name_pl" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="username" class="control-label col-sm-2">
                  Nazwa [EN]
                </label>
                <div class="col-sm-10">
                  <input
                    id="username"
                    v-model="competenceObj.name_en"
                    name="username"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa [EN]"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name_en" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="username" class="control-label col-sm-2">
                  Nazwa [DE]
                </label>
                <div class="col-sm-10">
                  <input
                    id="username"
                    v-model="competenceObj.name_de"
                    name="username"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa [DE]"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name_de" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="username" class="control-label col-sm-2">
                  Nazwa [DE_DE]
                </label>
                <div class="col-sm-10">
                  <input
                    id="username"
                    v-model="competenceObj.name_cpde"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa [DE_DE]"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.name_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.name_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="username" class="control-label col-sm-2">
                  Nazwa [DE_EN]
                </label>
                <div class="col-sm-10">
                  <input
                    id="username"
                    v-model="competenceObj.name_cpen"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa [DE_EN]"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.name_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.name_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Sklep</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="competenceObj.country"
                    mode="single"
                    placeholder="Kraj"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="countryList"
                  >
                  </Multiselect>
                  <div
                    v-if="!competenceObj.country.length && showErrors"
                    class="has-error"
                  >
                    <span class="help-block">To pole nie może być puste</span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="competenceObj.reclamation"
                        name="reclamation"
                        type="checkbox"
                      />
                      <span>Reklamacja</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="competenceObj.complaint_reason"
                        name="complaint_reason"
                        type="checkbox"
                      />
                      <span>Powód reklamacji</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        id="negotiation"
                        name="negotiation"
                        type="checkbox"
                      />
                      <span>Negocjacja</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <router-link class="btn btn-white" to="/competencies">
                    Anuluj
                  </router-link>
                  <button @click="create" class="btn btn-primary">Dodaj</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "CompetenciesCreate",
  mixins: [],
  components: {
    Multiselect,
  },
  props: {},

  setup() {
    const competenceObj = ref({
      name_pl: "",
      name_en: "",
      name_de: "",
      name_cpen: "",
      name_cpde: "",
      country: "",
      reclamation: false,
      complaint_reason: false,
      negotiations: false,
    });

    const countryList = ref([
      { name: "Polska", value: "PL" },
      { name: "Niemcy", value: "DE" },
    ]);

    const errorsAdminData = ref();
    const showErrors = ref(false);
    const store = useStore();
    const createCompetenceData = computed(
      () => store.getters.getCreateCompetenceData
    );

    const create = () => {
      showErrors.value = true;
      store.dispatch("createCompetence", competenceObj.value);
    };

    watch(
      () => createCompetenceData.value,
      function (newValue) {
        if (newValue.status === 201) {
          router
            .push({ name: "Competencies", path: "/competencies" })
            .then(() => {
              store.dispatch("showToaster", {
                type: "success",
                content: "Kompetencja została dodana",
              });
            });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    return {
      countryList,
      competenceObj,
      errorsAdminData,
      showErrors,
      create,
    };
  },
  computed: {},
};
</script>
