<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Statystyki</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Statystyki</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight logs">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Filtry</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-md-3 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Wybierz daty</label>
                  <div class="col-sm-8">
                    <VueDatePicker
                      class="stats-daterangepicker"
                      v-model="statsRangeDate"
                      multi-calendars
                      :format="format"
                      range
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Sklep</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="shop"
                      mode="single"
                      placeholder="Sklep"
                      track-by="name"
                      label="name"
                      :close-on-select="true"
                      :searchable="true"
                      :options="shopOptions"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="ibox-title">
        <h5>Statystyki</h5>
      </div>
      <div class="col-lg-12">
        <div v-if="packageStats" class="ibox float-e-margins">
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Ilość przesyłek - system</div>
            <div class="col-md-2 nopadding">
              {{ packageStats.no_system }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Ilość przesyłek - UPS</div>
            <div class="col-md-2 nopadding">
              {{ packageStats.no_system }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Waga przesyłek - system</div>
            <div class="col-md-2 nopadding">
              {{ packageStats.weight_system }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Waga przesyłek - pakowanie</div>
            <div class="col-md-2 nopadding">
              {{ packageStats.weight_packed }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Koszt wysyłki - system</div>
            <div class="col-md-2 nopadding">
              {{ packageStats.price_system }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Koszt wysyłki - UPS</div>
            <div class="col-md-2 nopadding">
              {{ packageStats.price_ups }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Przesyłek pobraniowych</div>
            <div class="col-md-2 nopadding">
              {{ packageStats.delivery_packages }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Koszt przesyłek pobraniowych</div>
            <div class="col-md-2 nopadding">
              {{ packageStats.price_delivery_packages }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Zamówienia zagraniczne z UE</div>
            <div class="col-md-2 nopadding">
              {{ packageStats.orders_eu }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">
              Zamówienia zagraniczne z poza UE
            </div>
            <div class="col-md-2 nopadding">
              {{ packageStats.orders_not_ue }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">
              Wartość przesyłek zagranicznych - system
            </div>
            <div class="col-md-2 nopadding">
              {{ packageStats.price_foreign_system }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">
              Wartość przesyłek zagranicznych - ups
            </div>
            <div class="col-md-2 nopadding">
              {{ packageStats.no_ups }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Zamówień wieloadresowych</div>
            <div class="col-md-2 nopadding">
              {{ packageStats.orders_multiaddress }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">
              Zamówień z podzielonym nakładem
            </div>
            <div class="col-md-2 nopadding">
              {{ packageStats.orders_cut }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">
              Wartość doliczonych kosztów związanych z
              {{ packageStats.price_orders_cut }} podziałem zamówienia
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="packageStats1" class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Filtry</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-md-3 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Wybierz daty</label>
                  <div class="col-sm-8">
                    <VueDatePicker
                      class="stats-daterangepicker"
                      v-model="statsRangeDate1"
                      multi-calendars
                      :format="format"
                      range
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Sklep</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="shop1"
                      mode="single"
                      placeholder="Sklep"
                      track-by="name"
                      label="name"
                      :close-on-select="true"
                      :searchable="true"
                      :options="shopOptions"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="ibox-title">
        <h5>Statystyki</h5>
      </div>
      <div class="col-lg-12">
        <div v-if="packageStats1" class="ibox float-e-margins">
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Ilość przesyłek - system</div>
            <div class="col-md-2 nopadding">
              {{ packageStats1.no_system }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Ilość przesyłek - UPS</div>
            <div class="col-md-2 nopadding">
              {{ packageStats1.no_system }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Waga przesyłek - system</div>
            <div class="col-md-2 nopadding">
              {{ packageStats1.weight_system }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Waga przesyłek - pakowanie</div>
            <div class="col-md-2 nopadding">
              {{ packageStats1.weight_packed }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Koszt wysyłki - system</div>
            <div class="col-md-2 nopadding">
              {{ packageStats1.price_system }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Koszt wysyłki - UPS</div>
            <div class="col-md-2 nopadding">
              {{ packageStats1.price_ups }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Przesyłek pobraniowych</div>
            <div class="col-md-2 nopadding">
              {{ packageStats1.delivery_packages }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Koszt przesyłek pobraniowych</div>
            <div class="col-md-2 nopadding">
              {{ packageStats1.price_delivery_packages }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Zamówienia zagraniczne z UE</div>
            <div class="col-md-2 nopadding">
              {{ packageStats1.orders_eu }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">
              Zamówienia zagraniczne z poza UE
            </div>
            <div class="col-md-2 nopadding">
              {{ packageStats1.orders_not_ue }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">
              Wartość przesyłek zagranicznych - system
            </div>
            <div class="col-md-2 nopadding">
              {{ packageStats1.price_foreign_system }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">
              Wartość przesyłek zagranicznych - ups
            </div>
            <div class="col-md-2 nopadding">
              {{ packageStats1.no_ups }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">Zamówień wieloadresowych</div>
            <div class="col-md-2 nopadding">
              {{ packageStats1.orders_multiaddress }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">
              Zamówień z podzielonym nakładem
            </div>
            <div class="col-md-2 nopadding">
              {{ packageStats1.orders_cut }}
            </div>
          </div>
          <div class="ibox-content row">
            <div class="col-md-2 nopadding">
              Wartość doliczonych kosztów związanych z
              {{ packageStats1.price_orders_cut }} podziałem zamówienia
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="statsRangeDate" class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title"><h5>Porównaj zakres</h5></div>
          <div class="ibox-content">
            <div class="form-inline">
              <div class="form-group">
                <label for="no-comparative" class="sr-only">Liczba</label>
                <input
                  v-model="comparative"
                  type="number"
                  placeholder="Podaj liczbę"
                  min="1"
                  id="no-comparative"
                  class="form-control"
                />
              </div>
              <button @click="comparativeFn('week')" class="btn btn-white">
                Tygodni wcześniej
              </button>
              <button @click="comparativeFn('month')" class="btn btn-white">
                Miesięcy wcześniej
              </button>
              <button @click="comparativeFn('year')" class="btn btn-white">
                Lat wcześniej
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "MontagelogsList",
  mixins: [],
  components: {
    Multiselect,
  },
  props: {},

  setup() {
    const store = useStore();
    const packageStatsData = computed(() => store.getters.getPackageStatsData);
    const shopOptions = ref([
      { name: "Polska", value: "PL" },
      { name: "Niemcy", value: "DE" },
      { name: "Zamówienia zewnętrzne", value: "ZZ" },
    ]);
    const loading = ref(true);
    const shop = ref();
    const shop1 = ref();
    const packageStats = ref();
    const packageStats1 = ref();
    const comparative = ref(0);
    const statsRangeDate = ref();
    const statsRangeDate1 = ref([]);

    const getPackageStats = (comp = false) => {
      let shopVal = shop.value;
      let date = statsRangeDate.value;
      if (comp) {
        date = statsRangeDate1.value;
        shopVal = shop1.value;
      }
      loading.value = true;
      store.dispatch("getPackageStats", {
        date: date,
        shop_version: shopVal,
        comp: comp,
      });
    };

    const format = (date) => {
      let day, month, year, dayEnd, monthEnd, yearEnd;
      if (date[0]) {
        day = date[0].getDate();
        month = date[0].getMonth() + 1;
        year = date[0].getFullYear();
      }

      if (date[1]) {
        dayEnd = date[1].getDate();
        monthEnd = date[1].getMonth() + 1;
        yearEnd = date[1].getFullYear();
      }

      if (date.length > 1) {
        return `${day}/${month}/${year} - ${dayEnd}/${monthEnd}/${yearEnd}`;
      }
    };

    const comparativeFn = (type) => {
      let dateStart = new Date(statsRangeDate.value[0]);
      let dateEnd = new Date(statsRangeDate.value[1]);
      dateStart.toLocaleDateString();
      dateEnd.toLocaleDateString();
      if (type === "week") {
        dateStart.setDate(dateStart.getDay() - comparative.value * 7);
        dateEnd.setDate(dateEnd.getDay() - comparative.value * 7);
      } else if (type === "month") {
        dateStart.setMonth(dateStart.getMonth() - comparative.value);
        dateEnd.setMonth(dateEnd.getMonth() - comparative.value);
      } else if (type === "year") {
        dateStart.setYear(dateStart.getFullYear() - comparative.value);
        dateEnd.setYear(dateEnd.getFullYear() - comparative.value);
      }
      statsRangeDate1.value[0] = dateStart;
      statsRangeDate1.value[1] = dateEnd;
      getPackageStats(true);
    };

    getPackageStats();

    watch(
      () => shop.value,
      function () {
        getPackageStats();
      }
    );
    watch(
      () => shop1.value,
      function () {
        getPackageStats(true);
      }
    );
    watch(
      () => statsRangeDate.value,
      function () {
        getPackageStats();
      }
    );
    watch(
      () => packageStatsData.value,
      function (newValue) {
        if (newValue.comp) {
          packageStats1.value = newValue.data;
        } else {
          packageStats.value = newValue.data;
        }
      }
    );

    return {
      loading,
      format,
      statsRangeDate,
      statsRangeDate1,
      shop,
      shop1,
      shopOptions,
      packageStatsData,
      comparativeFn,
      comparative,
      packageStats,
      packageStats1,
    };
  },
  computed: {},
};
</script>
