<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Montaże</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/montages/list">Montaże</router-link>
        </li>
        <li class="active">
          <strong>Nowy</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-8">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Nowy montaż</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-horizontal">
                  <div class="ibox-content">
                    <div class="form-group">
                      <label class="control-label col-sm-2">Nazwa</label>
                      <div class="col-sm-10">
                        <input
                          v-model="montageObj.name"
                          type="text"
                          class="form-control inputClass"
                          placeholder="Nazwa"
                          required="required"
                        />
                        <div
                          v-if="errorsAdminData?.data?.name"
                          class="has-error"
                        >
                          <span class="help-block">
                            {{ errorsAdminData.data.name[0] }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-sm-2">Nakład netto</label>
                      <div class="col-sm-10">
                        <input
                          v-model="montageObj.quantity_net"
                          type="number"
                          class="form-control inputClass"
                          placeholder="Nakład netto"
                          required="required"
                        />
                        <div
                          v-if="errorsAdminData?.data?.quantity_net"
                          class="has-error"
                        >
                          <span class="help-block">
                            {{ errorsAdminData.data.quantity_net[0] }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-sm-2">Naddatek</label>
                      <div class="col-sm-10">
                        <input
                          v-model="montageObj.quantity_surplus"
                          type="number"
                          class="form-control inputClass"
                          placeholder="Naddatek"
                          required="required"
                        />
                        <div
                          v-if="errorsAdminData?.data?.quantity_surplus"
                          class="has-error"
                        >
                          <span class="help-block">
                            {{ errorsAdminData.data.quantity_surplus[0] }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-sm-2">
                        Nakład brutto
                      </label>
                      <div class="col-sm-10">
                        <input
                          v-model="montageObj.quantity_surplus"
                          type="number"
                          class="form-control inputClass"
                          placeholder="Nakład brutto"
                          required="required"
                          readonly="readonly"
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-sm-2">Papier</label>
                      <div class="col-sm-10">
                        <Multiselect
                          v-model="montageObj.paper_option"
                          mode="single"
                          placeholder="Papier"
                          track-by="name"
                          label="name"
                          :close-on-select="true"
                          :searchable="true"
                          :options="papersOpt"
                        >
                        </Multiselect>
                        <div
                          v-if="sentForm && !montageObj.paper_option"
                          class="has-error"
                        >
                          <span class="help-block">
                            {{ montageObj.paper_option }}
                            To pole nie może być puste!
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-sm-2">
                        Format arkusza
                      </label>
                      <div class="col-sm-10">
                        <Multiselect
                          v-model="montageObj.sheet_format"
                          mode="single"
                          placeholder="Format arkusza"
                          track-by="name"
                          label="name"
                          :close-on-select="true"
                          :searchable="true"
                          :options="formatsOpt"
                        >
                        </Multiselect>
                        <div
                          v-if="sentForm && !montageObj.sheet_format"
                          class="has-error"
                        >
                          <span class="help-block">
                            To pole nie może być puste!
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-sm-2">Płyta</label>
                      <div class="col-sm-10">
                        <Multiselect
                          v-model="montageObj.plate"
                          mode="single"
                          placeholder="Płyta"
                          track-by="name"
                          label="name"
                          :close-on-select="true"
                          :searchable="true"
                          :options="platesOpt"
                        >
                        </Multiselect>
                        <div
                          v-if="errorsAdminData?.data?.plate"
                          class="has-error"
                        >
                          <span class="help-block">
                            {{ errorsAdminData.data.plate[0] }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-sm-2">Liczba płyt</label>
                      <div class="col-sm-10">
                        <input
                          v-model="montageObj.plate_quantity"
                          type="text"
                          class="form-control inputClass"
                          placeholder="Liczba płyt"
                          required="required"
                        />
                        <div
                          v-if="errorsAdminData?.data?.plate_quantity"
                          class="has-error"
                        >
                          <span class="help-block">
                            {{ errorsAdminData.data.plate_quantity[0] }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="col-sm-offset-2 col-sm-10">
                        <div class="checkbox checkbox-primary">
                          <label>
                            <input
                              v-model="montageObj.flipped"
                              name="flipped"
                              type="checkbox"
                            />
                            <span>Arkusz na odwrotkę</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-sm-2">
                        Wartość dodatkowa netto
                      </label>
                      <div class="col-sm-10">
                        <input
                          v-model="montageObj.extra_net"
                          type="number"
                          class="form-control inputClass"
                          placeholder="Wartość dodatkowa netto"
                          required="required"
                        />
                        <div
                          v-if="errorsAdminData?.data?.extra_net"
                          class="has-error"
                        >
                          <span class="help-block">
                            {{ errorsAdminData.data.extra_net[0] }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-sm-2">
                        Wymuszony termin realizacji
                      </label>
                      <div class="col-sm-10">
                        <VueDatePicker
                          :format="format"
                          v-model="montageObj.forced_deadline_date"
                        />
                        <div
                          v-if="errorsAdminData?.data?.forced_deadline_date"
                          class="has-error"
                        >
                          <span class="help-block">
                            {{ errorsAdminData.data.forced_deadline_date[0] }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-sm-2">Notatka</label>
                      <div class="col-sm-10 QuillEditor-box">
                        <QuillEditor
                          theme="snow"
                          contentType="html"
                          v-model:content="montageObj.note"
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-sm-2">Status RW</label>
                      <div class="col-sm-10">
                        <Multiselect
                          v-model="montageObj.rw_status"
                          mode="single"
                          placeholder="Status RW"
                          track-by="name"
                          label="name"
                          :close-on-select="true"
                          :searchable="true"
                          :options="filterRWOptions"
                        >
                        </Multiselect>
                        <div
                          v-if="errorsAdminData?.data?.rw_status"
                          class="has-error"
                        >
                          <span class="help-block">
                            {{ errorsAdminData.data.rw_status[0] }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-sm-2">Papier RW</label>
                      <div class="col-sm-10">
                        <Multiselect
                          v-model="montageObj.rw_paper"
                          mode="single"
                          placeholder="Papier RW"
                          track-by="name"
                          label="name"
                          :close-on-select="true"
                          :searchable="true"
                          :options="papersOpt"
                        >
                        </Multiselect>
                        <div
                          v-if="sentForm && !montageObj.rw_paper"
                          class="has-error"
                        >
                          <span class="help-block">
                            To pole nie może być puste!
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-sm-2">
                        Notatka do rw
                      </label>
                      <div class="col-sm-10 QuillEditor-box">
                        <QuillEditor
                          theme="snow"
                          contentType="html"
                          v-model:content="montageObj.rw_note"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Działania</h5>
          </div>
          <div class="ibox-content">
            <div class="col-sm-10 col-sm-offset-2 mtop-30">
              <router-link class="btn btn-white" to="/montages/list">
                Anuluj
              </router-link>
              <button @click="saveEdit" class="btn btn-primary">Zapisz</button>
            </div>
          </div>
        </div>
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Podsumowanie</h5>
          </div>
          <div class="ibox-content">
            <dl class="dl-horizontal">
              <dt>Termin</dt>
              <dd></dd>
              <dt>Liczba zleceń</dt>
              <dd>0</dd>
              <dt>Liczba zamówień</dt>
              <dd>0</dd>
              <dt>Wartość zleceń</dt>
              <dd>
                <span>0,00</span>
                <span>PLN</span>
              </dd>
              <dt>Koszt montażu</dt>
              <dd>
                <span>0,00</span>
                <span>PLN</span>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Zlecenia oczekujące</h5>
          </div>
          <div v-if="items.left" class="ibox-content">
            <button
              @click="moveItemsToRight"
              class="btn btn-sm btn-primary"
              type="button"
            >
              &gt;
            </button>
            <span class="records-count">
              Ilość rekordów: {{ itemsAll.left }}
            </span>
            <EasyDataTable
              v-model:server-options="serverOptions"
              :server-items-length="itemsAll.left"
              v-model:items-selected="itemsSelected"
              :headers="headers"
              :items="items.left"
              :loading="loading"
              alternating
              multi-sort
              border-cell
              @update-sort="updateSort"
              show-index
              class="table-search"
            >
              <template #header="header">
                <span v-if="header.text">{{ header.text }}</span>
                <input
                  class="table-search"
                  type="text"
                  v-if="header.search"
                  v-model="searchValue[header.value]"
                  @keyup="search"
                  @click="isSearch = true"
                />
              </template>
              <template #item-image="item">
                <img
                  :src="item?.product_file?.client_file_thumbnail"
                  width="40px"
                  @click="preview(item)"
                />
              </template>
              <template #item-actions="item">
                <button
                  @click="edit(item)"
                  class="btn btn-warning btn-xs"
                  title="Edytuj"
                >
                  <i class="fa fa-list-alt"></i>
                </button>
                <button
                  @click="getAdminCard(item.order_pk)"
                  class="btn btn-primary btn-xs"
                  title="Pobierz kartę zamówienia"
                >
                  <i class="fa fa-list-alt"></i>
                </button>
                <button
                  @click="
                    getOrderProductFiles(
                      item?.product_file?.preflight_status?.file_pk,
                      '/get_client_file/'
                    )
                  "
                  class="btn btn-xs"
                  title="Plik Klienta"
                >
                  <i class="fa fa-user"></i>
                </button>
                <button
                  @click="
                    getOrderProductFiles(
                      item?.product_file?.preflight_status?.file_pk,
                      '/get_result_file/'
                    )
                  "
                  class="btn btn-xs"
                  title="Plik wyjściowy"
                >
                  <i class="fa fa-search"></i>
                </button>
                <button
                  @click="
                    getOrderProductFiles(
                      item?.product_file?.preflight_status?.file_pk,
                      '/get_client_cover_file/'
                    )
                  "
                  class="btn btn-xs"
                  title="Plik okładki klienta"
                >
                  <i class="fa fa-user"></i>
                </button>
                <button
                  @click="
                    getOrderProductFiles(
                      item?.product_file?.preflight_status?.file_pk,
                      '/get_cover_result_file/'
                    )
                  "
                  class="btn btn-xs"
                  title="Plik okładki wyjściowy"
                >
                  <i class="fa fa-search"></i>
                </button>
              </template>
            </EasyDataTable>

            <div class="form-horizontal mtop-30">
              <div class="form-group">
                <label class="control-label col-sm-2">Domena</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="filterSite"
                    mode="single"
                    placeholder="Domena"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="sites"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Status zamówienia</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="filtersLeft.status"
                    mode="tags"
                    placeholder="Status zamówienia"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="filterStatusses"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Format</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="filtersLeft.format"
                    mode="tags"
                    placeholder="Format"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="formats.left"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Nakład</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="filtersLeft.quantities"
                    mode="tags"
                    placeholder="Nakład"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="quantities.left"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Papier</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="filtersLeft.paper"
                    mode="tags"
                    placeholder="Papier"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="papers.left"
                  >
                  </Multiselect>
                </div>
              </div>
              <div
                v-for="(filter, index) in montageFilters.left"
                :key="index"
                class="form-group"
              >
                <label class="control-label col-sm-2">
                  {{ filter.siteName }}
                  {{ filter.name }}
                </label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="filtersLeft[filter.filterId]"
                    mode="tags"
                    :placeholder="filter.name"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="filter.product_options"
                  >
                  </Multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Zlecenia dodane do montażu</h5>
          </div>
          <div class="ibox-content">
            <button @click="moveItemsToLeft" class="btn btn-sm btn-primary">
              &lt;
            </button>
            <span class="records-count">
              Ilość rekordów: {{ montageObj.montage_products.length }}
            </span>
            <EasyDataTable
              v-if="montageObj.montage_products.length > 0"
              v-model:server-options="serverOptions"
              :server-items-length="itemsAll.right"
              v-model:items-selected="itemsRightSelected"
              :headers="headers"
              :items="montageObj.montage_products"
              :loading="loading"
              alternating
              multi-sort
              border-cell
              @update-sort="updateSort"
              show-index
              class="table-search"
            >
              <template #header="header">
                <span v-if="header.text">{{ header.text }}</span>
                <input
                  class="table-search"
                  type="text"
                  v-if="header.search"
                  v-model="searchValue[header.value]"
                  @keyup="search"
                  @click="isSearch = true"
                />
              </template>
              <template #item-image="item">
                <img
                  :src="item?.product_file?.client_file_thumbnail"
                  width="40px"
                />
              </template>
              <template #item-actions="item">
                <button
                  @click="edit(item)"
                  class="btn btn-warning btn-xs"
                  title="Edytuj"
                >
                  <i class="fa fa-list-alt"></i>
                </button>
                <button
                  @click="getAdminCard(item.order_pk)"
                  class="btn btn-primary btn-xs"
                  title="Pobierz kartę zamówienia"
                >
                  <i class="fa fa-list-alt"></i>
                </button>
                <button
                  @click="
                    getOrderProductFiles(
                      item?.product_file?.preflight_status?.file_pk,
                      '/get_client_file/'
                    )
                  "
                  class="btn btn-xs"
                  title="Plik Klienta"
                >
                  <i class="fa fa-user"></i>
                </button>
                <button
                  @click="
                    getOrderProductFiles(
                      item?.product_file?.preflight_status?.file_pk,
                      '/get_result_file/'
                    )
                  "
                  class="btn btn-xs"
                  title="Plik wyjściowy"
                >
                  <i class="fa fa-search"></i>
                </button>
                <button
                  @click="
                    getOrderProductFiles(
                      item?.product_file?.preflight_status?.file_pk,
                      '/get_client_cover_file/'
                    )
                  "
                  class="btn btn-xs"
                  title="Plik okładki klienta"
                >
                  <i class="fa fa-user"></i>
                </button>
                <button
                  @click="
                    getOrderProductFiles(
                      item?.product_file?.preflight_status?.file_pk,
                      '/get_cover_result_file/'
                    )
                  "
                  class="btn btn-xs"
                  title="Plik okładki wyjściowy"
                >
                  <i class="fa fa-search"></i>
                </button>
              </template>
            </EasyDataTable>
            <div class="form-horizontal mtop-30">
              <div class="form-group">
                <label class="control-label col-sm-2">Domena</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="filtersRight.site"
                    mode="single"
                    placeholder="Domena"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="sites"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Status zamówienia</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="filtersRight.status"
                    mode="tags"
                    placeholder="Status zamówienia"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="filterStatusses"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Format</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="filtersRight.format"
                    mode="tags"
                    placeholder="Format"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="formats.right"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Nakład</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="filtersRight.quantities"
                    mode="tags"
                    placeholder="Nakład"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="quantities.right"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Papier</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="filtersRight.paper"
                    mode="tags"
                    placeholder="Papier"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="papers.right"
                  >
                  </Multiselect>
                </div>
              </div>
              <div
                v-for="(filter, index) in montageFilters.right"
                :key="index"
                class="form-group"
              >
                <label class="control-label col-sm-2">
                  {{ filter.site_name }}
                  {{ filter.name }}
                </label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="filtersRight.paper"
                    mode="tags"
                    :placeholder="filter.name"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="filter.product_options"
                  >
                  </Multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mActions" class="modal-dialog realization">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header">
          <h4 class="modal-title">Wymuszenie produkcji</h4>
        </div>
        <div class="modal-body">
          <div class="form-horizontal">
            <div class="col-lg-12">
              <div class="row">
                <div class="form-group">
                  <div class="col-sm-offset-2 col-sm-10">
                    <div class="checkbox checkbox-primary">
                      <label>
                        <input v-model="editObj.is_locked" type="checkbox" />
                        <span>Wymuś status w produkcji</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-sm-2">Notatka</label>
                  <div class="col-sm-10 QuillEditor-box">
                    <QuillEditor
                      theme="snow"
                      contentType="text"
                      v-model:content="editObj.locked_info"
                    />
                    <div
                      v-if="errorsAdminData?.data?.locked_info"
                      class="has-error"
                    >
                      <span class="help-block">
                        {{ errorsAdminData.data.locked_info[0] }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-sm-4 col-sm-offset-2">
                    <button @click="mActions = false" class="btn btn-white">
                      Anuluj
                    </button>
                    <button
                      @click="updateMontageProduct"
                      class="btn btn-primary"
                    >
                      Zapisz
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mActions" @click="mActions = false" class="modal-backdrop"></div>
  <div v-if="mPreview" class="modal-dialog preflight">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header">
          <h4 class="modal-title">Podgląd pliku</h4>
        </div>
        <div v-if="previewData.preview.product_file" class="modal-body">
          <pdfViewer
            :order-product-file="previewData.preview.product_file"
            :gross-size="previewData.preview.product_gross_size"
            :bleed="previewData.product_bleed"
            :text-margin="previewData.product_text_margin"
            :product-kind="previewData.product_kind"
            :tech-lines="previewData.preview.tech_lines"
            :booklet-uv="previewData.preview.is_booklet_uv"
            :pages-loaded="0"
            :loading="true"
            loading-text="Ładowanie stron"
          />
        </div>
        <div class="modal-footer">
          <button @click="mPreview = false" class="btn btn-white">
            Zamknij
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mPreview" @click="mPreview = false" class="modal-backdrop"></div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";
import { useRoute } from "vue-router";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import pdfViewer from "../pdfViewer/pdfViewer.vue";

export default {
  name: "MontagesEdit",
  mixins: [],
  components: {
    Multiselect,
    pdfViewer,
  },
  props: {},

  setup() {
    const store = useStore();
    const montageProductsData = computed(
      () => store.getters.getMontageProductsData
    );
    const montageFilterGroupData = computed(
      () => store.getters.getMontageFilterGroupData
    );
    const sitesData = computed(() => store.getters.getSitesData);
    const formatData = computed(() => store.getters.getMontageFormatData);
    const quantityData = computed(() => store.getters.getMontageQuantitiesData);
    const paperOptionsData = computed(
      () => store.getters.getMontagePaperOptionsData
    );
    const papersData = computed(() => store.getters.getMontagePapersData);
    const formatsData = computed(() => store.getters.getMontageFormatsData);
    const platesData = computed(() => store.getters.getMontagePlatesData);
    const montageData = computed(() => store.getters.getMontageData);
    const orderProductsFileData = computed(
      () => store.getters.getOrderProductsFileData
    );
    const updateMontageData = computed(
      () => store.getters.getUpdateMontageData
    );
    const updateMontageProductData = computed(
      () => store.getters.getUpdateMontageProductData
    );
    const route = useRoute();
    const errorsAdminData = ref();
    const montageObj = ref({
      montage_products: [],
      paper_option: null,
      sheet_format: null,
      rw_paper: null,
    });
    const editObj = ref();
    const searchValue = ref({});
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });
    const loading = ref(true);
    const isSearch = ref(false);
    const mActions = ref(false);
    const mPreview = ref(false);
    const sentForm = ref(false);
    const itemsSelected = ref([]);
    const itemsRightSelected = ref([]);
    const filtersLeft = ref({});
    const filtersRight = ref([]);
    const sites = ref([]);
    const papersOpt = ref([]);
    const formatsOpt = ref([]);
    const platesOpt = ref([]);
    const filterSite = ref();
    const previewData = ref();
    const montageFilters = ref({
      left: [],
      right: [],
    });
    const formats = ref({
      left: [],
      right: [],
    });
    const quantities = ref({
      left: [],
      right: [],
    });
    const papers = ref({
      left: [],
      right: [],
    });
    let items = ref({
      left: [],
      right: [],
    });
    let itemsAll = ref({
      left: 0,
      right: 0,
    });

    let headers = [
      { text: "Podgląd", value: "image", sortable: true, search: true },
      {
        text: "ID zlecenia",
        value: "pk_with_count",
        sortable: true,
        search: true,
      },
      {
        text: "Numer Zamówienia",
        value: "order_pk",
        sortable: true,
        search: true,
      },
      { text: "ID produktu", value: "product_pk", search: true },
      {
        text: "Produkt",
        value: "product_name",
        search: true,
      },
      { text: "Nakład", value: "selected_quantity", search: true },
      { text: "Format", value: "product_format", search: true },
      { text: "Netto", value: "net_price" },
      { text: "Waluta", value: "currency" },
      { text: "Waga", value: "weight" },
      { text: "Termin realizacji", value: "order_realization_date" },
      { text: "Akcje", value: "actions" },
    ];
    const filterRWOptions = ref([
      { name: "Oczekuje na wystawienie RW", value: "awaiting" },
      { name: "RW wystawione automatycznie", value: "auto_issued" },
      { name: "RW wystawione ręcznie", value: "manual_issued" },
      { name: "RW niewystawione automatycznie", value: "auto_not_issued" },
    ]);
    const filterStatusses = ref([
      { name: "Oczekuje na płatność", value: "waiting_for_payment" },
      { name: "W realizacji", value: "in_realization" },
      { name: "W produkcji", value: "in_production" },
    ]);

    const getMontageProducts = () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      store.dispatch("getMontageProducts", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
        site_pk: filterSite.value,
        filters: filtersLeft.value,
        exclude: montageObj.value.montage_products,
      });
    };

    const format = (date) => {
      let day, month, year, hours, minutes, seconds;
      day = date.getDate();
      month = ("0" + (date.getMonth() + 1)).slice(-2);
      year = date.getFullYear();
      minutes = ("0" + date.getMinutes()).slice(-2);
      seconds = ("0" + date.getSeconds()).slice(-2);
      hours = date.getHours();

      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    };

    const updateSort = () => {
      search.value = true;
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getMontageProducts();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getMontageProducts();
    };

    const moveItemsToRight = () => {
      itemsSelected.value.forEach((element) => {
        montageObj.value.montage_products.push(element.pk);
      });
      itemsSelected.value = [];
      getMontageProducts();
    };

    const moveItemsToLeft = () => {
      let index;
      itemsRightSelected.value.forEach((element) => {
        index = montageObj.value.montage_products.indexOf(element.pk);
        if (index !== -1) {
          montageObj.value.montage_products.splice(index, 1);
        }
        montageObj.value.montage_products.splice(
          montageObj.value.montage_products.indexOf(element),
          1
        );
      });
      itemsRightSelected.value = [];
      getMontageProducts();
    };

    const saveEdit = () => {
      sentForm.value = true;
      if (
        montageObj.value.paper_option &&
        montageObj.value.sheet_format &&
        montageObj.value.rw_paper
      ) {
        store.dispatch("updateMontage", montageObj.value);
      }
    };

    const getFilters = (val) => {
      store.dispatch("getMontageFilterGroup", { type: val });
      store.dispatch("getMontageFormatsOptions", { type: val });
      store.dispatch("getMontageQuantities", { type: val });
      store.dispatch("getMontagePaperOptions", { type: val });
    };

    const getOrderProductFiles = (pk, type) => {
      if (pk) {
        store.dispatch("getOrderProductFiles", {
          pk: pk,
          type: type,
        });
      }
    };

    const getAdminCard = (pk) => {
      if (pk) {
        store.dispatch("getOrderAdminCard", pk);
      }
    };

    const edit = (item) => {
      mActions.value = true;
      editObj.value = item;
    };

    const updateMontageProduct = () => {
      store.dispatch("updateMontageProduct", editObj.value);
    };

    const preview = (item) => {
      previewData.value = item;
      store.dispatch("getOrderProductsFile", { pk: item.pk });
    };

    getMontageProducts();
    getFilters("left");
    getFilters("right");
    store.dispatch("getSites");
    store.dispatch("getMontagePapers");
    store.dispatch("getMontageFormats");
    store.dispatch("getMontagePlates");

    if (route.params?.pk) {
      store.dispatch("getMontage", { pk: route.params?.pk });
    }

    watch(
      () => orderProductsFileData.value,
      function (newValue) {
        if (newValue.data.product_file) {
          previewData.value.preview = newValue.data;
          mPreview.value = true;
        }
      }
    );

    watch(
      () => montageData.value,
      function (newValue) {
        if (newValue.data) {
          montageObj.value = newValue.data;
        }
      }
    );

    watch(
      () => updateMontageProductData.value,
      function (newValue) {
        if (newValue.status === 200) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Dane zlecenia zostały zaktualizowane!",
          });
          mActions.value = false;
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    watch(
      () => updateMontageData.value,
      function (newValue) {
        if (newValue.status === 200) {
          router
            .push({
              name: "MontagesList",
              path: "/montages/list",
            })
            .then(() => {
              store.dispatch("showToaster", {
                type: "success",
                content: "Dane montażu zostały zaktualizowane",
              });
            });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    watch(
      () => montageProductsData.value,
      function (newValue) {
        itemsAll.value.left = newValue.data.count;
        items.value.left = [];
        newValue.data.results?.forEach((element) => {
          items.value.left.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => sitesData.value,
      function (newValue) {
        if (newValue.data) {
          newValue.data?.results.forEach((element) => {
            sites.value.push({
              name: element.name,
              value: element.pk,
            });
          });
        }
      }
    );

    watch(
      () => platesData.value,
      function (newValue) {
        if (newValue.data) {
          newValue.data?.forEach((element, pk) => {
            platesOpt.value.push({
              name: element.readable_plate,
              value: pk,
            });
          });
        }
      }
    );

    watch(
      () => papersData.value,
      function (newValue) {
        if (newValue.data) {
          newValue.data?.forEach((element) => {
            papersOpt.value.push({
              name:
                "[" +
                element.group_pk +
                "] " +
                element.group_name +
                " [" +
                element.pk +
                "] " +
                element.label,
              value: element.pk,
            });
          });
        }
      }
    );

    watch(
      () => formatsData.value,
      function (newValue) {
        if (newValue.data) {
          let group;
          newValue.data.forEach((element) => {
            if (element.group_pk && element.group_name) {
              group = "[" + element.group_pk + "] " + element.group_name;
            } else {
              group = "";
            }

            formatsOpt.value.push({
              name: group + " [" + element.pk + "] " + element.readable_format,
              value: element.pk,
            });
          });
        }
      }
    );

    watch(
      () => filtersLeft.value,
      function () {
        loading.value = true;
        getMontageProducts();
      },
      { deep: true }
    );

    watch(
      () => filterSite.value,
      function () {
        loading.value = true;
        getMontageProducts();
      }
    );

    watch(
      () => paperOptionsData.value,
      function (newValue) {
        if (newValue.data) {
          newValue.data.forEach((element) => {
            papers.value[newValue.type].push({
              name:
                "[" +
                element.site_name +
                "] " +
                "[" +
                element.group_pk +
                "] " +
                element.group_name +
                " [" +
                element.pk +
                "] " +
                element.label,
              value: element.pk,
            });
          });
        }
      }
    );

    watch(
      () => quantityData.value,
      function (newValue) {
        if (newValue.data) {
          newValue.data.forEach((element) => {
            quantities.value[newValue.type].push({
              name:
                "[" +
                element.site_name +
                "] " +
                "[" +
                element.pk +
                "] " +
                element.display_quantity,
              value: element.pk,
            });
          });
        }
      }
    );

    watch(
      () => formatData.value,
      function (newValue) {
        if (newValue.data) {
          let group;
          newValue.data.forEach((element) => {
            if (element.group_pk && element.group_name) {
              group = "[" + element.group_pk + "] " + element.group_name;
            } else {
              group = "";
            }

            formats.value[newValue.type].push({
              name:
                "[" +
                element.site_name +
                "] " +
                group +
                " [" +
                element.pk +
                "] " +
                element.readable_format,
              value: element.pk,
            });
          });
        }
      }
    );

    watch(
      () => montageFilterGroupData.value,
      function (newValue) {
        if (newValue.data) {
          let productOptions = [];
          newValue.data.forEach((element) => {
            productOptions = [];
            element.product_options.forEach((option) => {
              productOptions.push({
                name:
                  "[" +
                  option.group_pk +
                  "] " +
                  option.group_name +
                  " [" +
                  option.pk +
                  "] " +
                  option.label,
                value: option.pk,
              });
            });
            montageFilters.value[newValue.type].push({
              name: element.name,
              siteName: element.site_name,
              filterId: "filter" + element.pk,
              product_options: productOptions,
            });
          });
        }
      }
    );

    return {
      errorsAdminData,
      montageObj,
      format,
      headers,
      itemsAll,
      items,
      loading,
      isSearch,
      resetFilters,
      search,
      searchValue,
      serverOptions,
      updateSort,
      itemsSelected,
      itemsRightSelected,
      filtersLeft,
      filtersRight,
      montageFilters,
      sites,
      filterSite,
      formats,
      quantities,
      papers,
      papersOpt,
      formatsOpt,
      platesOpt,
      moveItemsToRight,
      moveItemsToLeft,
      filterRWOptions,
      filterStatusses,
      saveEdit,
      getOrderProductFiles,
      getAdminCard,
      mActions,
      mPreview,
      edit,
      editObj,
      updateMontageProduct,
      sentForm,
      preview,
      previewData,
    };
  },
  computed: {},
};
</script>
