<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Klienci</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Klienci</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Klienci</h5>
          </div>
          <div class="ibox-content">
            <router-link to="/clients/create">
              <button class="btn btn-sm btn-primary">Dodaj</button>
            </router-link>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <button @click="resetFilters" class="btn btn-primary btn-xs">
                  <i class="fa fa-refresh"></i>
                </button>
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :clickRowToExpand="true"
                  :loading="loading"
                  @click-row="editClient"
                  alternating
                  multi-sort
                  show-index
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                  <template #expand="item">
                    <div
                      class="wrapper wrapper-content animated fadeInRight"
                      v-if="clientObj.pk"
                    >
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="ibox float-e-margins">
                            <div class="ibox-title">
                              <h5>
                                Edycja klienta
                                {{ item.user.first_name }}
                                {{ item.user.last_name }}
                              </h5>
                            </div>

                            <div class="form-horizontal">
                              <div class="ibox-content">
                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Adres e-mail
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.user.email"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Adres e-mail"
                                      required="required"
                                    />
                                    <div
                                      v-if="errorsAdminData?.data?.user?.email"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{ errorsAdminData.data.user.email[0] }}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Hasło
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.user.password"
                                      type="password"
                                      class="form-control inputClass"
                                      placeholder="Hasło"
                                      required="required"
                                    />
                                    <div
                                      v-if="
                                        errorsAdminData?.data?.user?.password
                                      "
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{
                                          errorsAdminData.data.user.password[0]
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Powtórz hasło
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.user.password2"
                                      type="password"
                                      class="form-control inputClass"
                                      placeholder="Powtórz hasło"
                                      required="required"
                                    />
                                    <div
                                      v-if="
                                        errorsAdminData?.data?.user?.password2
                                      "
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{
                                          errorsAdminData.data.user.password2[0]
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Główne konto
                                  </label>
                                  <div class="col-sm-10">
                                    <Multiselect
                                      v-model="clientObj.main_account"
                                      mode="single"
                                      placeholder="Główne konto"
                                      track-by="name"
                                      label="name"
                                      :close-on-select="true"
                                      :searchable="true"
                                      :options="clientsOptions"
                                    >
                                    </Multiselect>
                                    <div
                                      v-if="errorsAdminData?.data?.main_account"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{
                                          errorsAdminData.data.main_account[0]
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="hr-line-dashed"></div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Sklep
                                  </label>
                                  <div class="col-sm-10">
                                    <Multiselect
                                      v-model="clientObj.company_localization"
                                      mode="single"
                                      placeholder="Sklep"
                                      track-by="name"
                                      label="name"
                                      :close-on-select="true"
                                      :searchable="true"
                                      :options="countryList"
                                    >
                                    </Multiselect>
                                    <div
                                      v-if="errorsAdminData?.data?.main_account"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{
                                          errorsAdminData.data.main_account[0]
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Imię
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.user.first_name"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Imię"
                                      required="required"
                                    />
                                    <div
                                      v-if="
                                        errorsAdminData?.data?.user?.first_name
                                      "
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{
                                          errorsAdminData.data.user
                                            .first_name[0]
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Nazwisko
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.user.last_name"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Nazwisko"
                                      required="required"
                                    />
                                    <div
                                      v-if="
                                        errorsAdminData?.data?.user?.last_name
                                      "
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{
                                          errorsAdminData.data.user.last_name[0]
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="hr-line-dashed"></div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Nazwa firmy
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.company_name"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Nazwa firmy"
                                      required="required"
                                    />
                                    <div
                                      v-if="errorsAdminData?.data?.company_name"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{
                                          errorsAdminData.data.company_name[0]
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    NIP
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.tax_id"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="NIP"
                                      required="required"
                                    />
                                    <div
                                      v-if="errorsAdminData?.data?.tax_id"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{ errorsAdminData.data.tax_id[0] }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="hr-line-dashed"></div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Numer telefonu
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.telephone"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Numer telefonu"
                                      required="required"
                                    />
                                    <div
                                      v-if="errorsAdminData?.data?.telephone"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{ errorsAdminData.data.telephone[0] }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Adres
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.address"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Adres"
                                      required="required"
                                    />
                                    <div
                                      v-if="errorsAdminData?.data?.address"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{ errorsAdminData.data.address[0] }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Numer budynku
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.building_number"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Numer budynku"
                                      required="required"
                                    />
                                    <div
                                      v-if="
                                        errorsAdminData?.data?.building_number
                                      "
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{
                                          errorsAdminData.data
                                            .building_number[0]
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Numer mieszkania
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.suite_number"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Numer mieszkania"
                                      required="required"
                                    />
                                    <div
                                      v-if="errorsAdminData?.data?.suite_number"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{
                                          errorsAdminData.data.suite_number[0]
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Miasto
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.city"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Miasto"
                                      required="required"
                                    />
                                    <div
                                      v-if="errorsAdminData?.data?.city"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{ errorsAdminData.data.city[0] }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Kod pocztowy
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.zip_code"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Kod pocztowy"
                                      required="required"
                                    />
                                    <div
                                      v-if="errorsAdminData?.data?.zip_code"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{ errorsAdminData.data.zip_code[0] }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Kraj
                                  </label>
                                  <div class="col-sm-10">
                                    <Multiselect
                                      v-model="clientObj.country"
                                      mode="single"
                                      placeholder="Kraj"
                                      track-by="name"
                                      label="name"
                                      :close-on-select="true"
                                      :searchable="true"
                                      :options="countriesOptions"
                                    >
                                    </Multiselect>
                                    <div
                                      v-if="errorsAdminData?.data?.country"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{ errorsAdminData.data.country[0] }}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="ibox float-e-margins">
                            <div class="ibox-title">
                              <h5>Dodatkowy adres dostawy</h5>
                            </div>

                            <div class="form-horizontal">
                              <div class="ibox-content">
                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Nazwa firmy
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.company_name_2"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Nazwa firmy"
                                      required="required"
                                    />
                                    <div
                                      v-if="
                                        errorsAdminData?.data?.company_name_2
                                      "
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{
                                          errorsAdminData.data.company_name_2[0]
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="hr-line-dashed"></div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Numer telefonu
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.telephone_2"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Numer telefonu"
                                      required="required"
                                    />
                                    <div
                                      v-if="errorsAdminData?.data?.telephone_2"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{
                                          errorsAdminData.data.telephone_2[0]
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Adres
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.address_2"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Adres"
                                      required="required"
                                    />
                                    <div
                                      v-if="errorsAdminData?.data?.address_2"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{ errorsAdminData.data.address_2[0] }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Numer budynku
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.building_number_2"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Numer budynku"
                                      required="required"
                                    />
                                    <div
                                      v-if="
                                        errorsAdminData?.data?.building_number_2
                                      "
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{
                                          errorsAdminData.data
                                            .building_number_2[0]
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Numer mieszkania
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.suite_number_2"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Numer mieszkania"
                                      required="required"
                                    />
                                    <div
                                      v-if="
                                        errorsAdminData?.data?.suite_number_2
                                      "
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{
                                          errorsAdminData.data.suite_number_2[0]
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Miasto
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.city_2"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Miasto"
                                      required="required"
                                    />
                                    <div
                                      v-if="errorsAdminData?.data?.city_2"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{ errorsAdminData.data.city_2[0] }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Kod pocztowy
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.zip_code_2"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Kod pocztowy"
                                      required="required"
                                    />
                                    <div
                                      v-if="errorsAdminData?.data?.zip_code_2"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{ errorsAdminData.data.zip_code_2[0] }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Kraj
                                  </label>
                                  <div class="col-sm-10">
                                    <Multiselect
                                      v-model="clientObj.country_2"
                                      mode="single"
                                      placeholder="Kraj"
                                      track-by="name"
                                      label="name"
                                      :close-on-select="true"
                                      :searchable="true"
                                      :options="countriesOptions"
                                    >
                                    </Multiselect>
                                    <div
                                      v-if="errorsAdminData?.data?.country_2"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{ errorsAdminData.data.country_2[0] }}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="ibox float-e-margins">
                            <div class="ibox-title">
                              <h5>Limity użytkownika</h5>
                            </div>

                            <div class="form-horizontal">
                              <div class="ibox-content">
                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Ilość zamówień pobraniowych
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="
                                        clientObj.limitation.cod_quantity
                                      "
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Ilość zamówień pobraniowych"
                                      required="required"
                                    />
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Kwota netto zamówienia pobraniowego
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="clientObj.limitation.cod_amount"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Kwota netto zamówienia pobraniowego"
                                      required="required"
                                    />
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="col-sm-offset-2 col-sm-10">
                                    <div class="checkbox checkbox-primary">
                                      <label>
                                        <input
                                          v-model="
                                            clientObj.limitation
                                              .is_many_addresses_available
                                          "
                                          type="checkbox"
                                        />
                                        <span>
                                          Możliwość ustawienia dowolnego adresu
                                          przesyłki dla płatności za pobraniem
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div class="hr-line-dashed"></div>

                                <div class="form-group">
                                  <div class="col-sm-offset-2 col-sm-10">
                                    <div class="checkbox checkbox-primary">
                                      <label>
                                        <input
                                          v-model="
                                            clientObj.limitation
                                              .is_provide_personal_parcel_pickup
                                          "
                                          type="checkbox"
                                        />
                                        <span>Odbiór osobisty przesyłek</span>
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div class="hr-line-dashed"></div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Kwota limitu płatności z terminem
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="
                                        clientObj.limitation
                                          .payment_deadline_amount
                                      "
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Kwota limitu płatności z terminem"
                                      required="required"
                                    />
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label class="control-label col-sm-2">
                                    Ilość dni dla płatności z terminem
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      v-model="
                                        clientObj.limitation
                                          .payment_deadline_days_num
                                      "
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Ilość dni dla płatności z terminem"
                                      required="required"
                                    />
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="col-sm-4 col-sm-offset-2">
                                    <button
                                      @click="cancel"
                                      class="btn btn-white"
                                    >
                                      Anuluj
                                    </button>
                                    <button
                                      @click="saveEdit"
                                      class="btn btn-primary"
                                    >
                                      Zapisz
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #item-actions="item">
                    <button
                      @click.stop="
                        showModal = item.pk;
                        deleteName = item.user.email;
                      "
                      class="btn btn-danger btn-xs"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showModal"
    :title="'Potwierdzenie usunięcia'"
    :content="'Czy na pewno chcesz usunąć ' + deleteName + ' ?'"
    @callbackOk="deleteUser(showModal)"
    @callbackClose="showModal = false"
  />
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import "@vueform/multiselect/themes/default.css";
import Modal from "../caffeprint/Modal.vue";
import { useRoute } from "vue-router";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "ClientsList",
  mixins: [],
  components: {
    Modal,
    Multiselect,
  },
  props: {},

  setup() {
    const store = useStore();
    const clientsData = computed(() => store.getters.getClientsData);
    const clientData = computed(() => store.getters.getClientData);
    const updateClientData = computed(() => store.getters.getUpdateClientData);
    const clientDeleteData = computed(() => store.getters.getDeleteClientData);
    const adminClientsData = computed(() => store.getters.getAdminClientsData);
    const countriesData = computed(() => store.getters.getCountriesData);
    const clientsOptions = ref([]);
    const countriesOptions = ref([]);

    const route = useRoute();

    const clientObj = ref({
      user: {
        email: "",
        password: "",
        password2: "",
      },
    });

    const countryList = ref([
      { name: "Polska", value: "PL" },
      { name: "Niemcy", value: "DE" },
    ]);
    let headers = [
      { text: "ID", value: "pk" },
      {
        text: "Imię",
        value: "user.first_name",
        search: true,
      },
      {
        text: "Nazwisko",
        value: "user.last_name",
        sortable: true,
        search: true,
      },
      {
        text: "Adres e-mail",
        value: "user.email",
        sortable: true,
        search: true,
      },
      {
        text: "Główne konto",
        value: "main_account",
        sortable: true,
        search: true,
      },
      {
        text: "Sklep",
        value: "company_localization_display",
        sortable: true,
        search: true,
      },
      {
        text: "Grupa rabatowa",
        value: "discount_group",
        sortable: true,
        search: true,
      },
      {
        text: "Konto aktywne",
        value: "user.is_active",
        sortable: true,
        search: true,
      },
      {
        text: "Data dodania",
        value: "user.date_added",
        sortable: true,
        search: true,
      },
      {
        text: "Data modyfikacji",
        value: "user.date_modified",
        sortable: true,
        search: true,
      },
      {
        text: "Edytowano przez",
        value: "user.last_modified_by",
        sortable: true,
        search: true,
      },
      { text: "Akcje", value: "actions" },
    ];

    let items = ref([]);
    let itemsAll = ref(0);
    const showModal = ref(false);
    const deleteName = ref("");
    const loading = ref(true);
    const searchValue = ref({});
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 5,
    });

    const getClients = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-user__" + serverOptions.value.sortBy;
      } else {
        sort = "user__" + serverOptions.value.sortBy;
      }
      await store.dispatch("getClients", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
        userPk: route.params?.pk,
      });
    };

    const cancel = () => {
      document.querySelector(".expanding")?.click();
    };

    const saveEdit = () => {
      store.dispatch("updateClient", clientObj.value);
    };

    const editClient = (item) => {
      cancel();
      store.dispatch("getClient", { pk: item.pk });
    };

    const deleteUser = (pk) => {
      showModal.value = false;
      store.dispatch("deleteClient", {
        pk: pk,
        user: {
          is_active: false,
        },
      });
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getClients();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getClients();
    };

    const updateSort = () => {
      search.value = true;
    };

    getClients();
    store.dispatch("getAdminClients");
    store.dispatch("getCountriesList");

    watch(
      () => adminClientsData.value,
      function (newValue) {
        newValue.data.results?.forEach((element) => {
          clientsOptions.value.push({
            name: "[" + element.pk + "] " + element.full_name,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => countriesData.value,
      function (newValue) {
        newValue.data.data?.forEach((element) => {
          countriesOptions.value.push({
            name: element.name_pl,
            value: element.code,
          });
        });
      }
    );

    watch(
      () => clientsData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getClients();
          isSearch.value = false;
        }
      }
    );

    watch(
      () => clientData.value,
      function (newValue) {
        clientObj.value = newValue.data;
      }
    );

    watch(
      () => updateClientData.value,
      function (newValue) {
        if (newValue.status === 200) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Dane zostały zaktualizowane",
          });
        }
        cancel();
      }
    );

    watch(
      () => clientDeleteData.value,
      function (newValue) {
        if (newValue.status === 200) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Klient zostal usunięty",
          });
        }
        getClients();
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getClients();
          }, 1000);
        }
      }
    );

    return {
      headers,
      clientsData,
      items,
      itemsAll,
      loading,
      serverOptions,
      showModal,
      clientObj,
      editClient,
      cancel,
      saveEdit,
      deleteUser,
      deleteName,
      searchValue,
      search,
      resetFilters,
      updateSort,
      isSearch,
      rowsPerPage,
      countryList,
      clientsOptions,
      countriesOptions,
    };
  },
  created() {
    const route = useRoute();
    if (route.params?.pk) {
      setTimeout(() => {
        document
          .querySelector(".can-expand.direction-left")
          .nextSibling.click();
      }, 500);
    }
  },
};
</script>
