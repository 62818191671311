<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Rabaty</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Rabaty</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox-title">
          <h5>Wartość</h5>
        </div>
        <div class="ibox-content">
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group flex">
                    <label for="file" class="col-sm-3 control-label">
                      Plik rabatów asystenta cen
                    </label>
                    <div class="col-sm-6">
                      <div class="fileinput fileinput-new input-group">
                        <span
                          for="file"
                          class="input-group-addon btn btn-default btn-file"
                        >
                          <input
                            type="file"
                            name="file"
                            id="file"
                            @change="changeFile"
                            multiple="multiple"
                          />
                        </span>
                      </div>
                      <div class="has-error"></div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <button
                    @click="importFile"
                    class="btn btn-primary"
                    :disabled="!discountFile"
                  >
                    <span>Importuj</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectedNode">
            <hr />
            <dl class="dl-horizontal">
              <dt>Wybrany rabat:</dt>
              <dd>
                <span v-html="selectedNode.text"></span>
              </dd>
              <dt>Wartość:</dt>
              <dd>
                <input
                  v-model="selectedNode.value"
                  type="number"
                  class="form-control"
                />
              </dd>
            </dl>

            <dl class="dl-horizontal">
              <dt>Wartość dodatkowa:</dt>
              <dd>
                <input
                  v-model="selectedNode.secondary_value"
                  type="number"
                  class="form-control"
                />
              </dd>
            </dl>
            <button @click="updateDiscount" class="btn btn-primary pull-right">
              Zapisz
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div v-if="data" class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Drzewo rabatów</h5>
          </div>
          <div class="ibox-content">
            <div v-if="loading" class="loading-entity">
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <tree v-else :nodes="data.nodes" :config="data.config">
              <template #before-input="props">
                <div
                  :data-id="props.node.pk"
                  @click="onNodeClick(props.node, $event)"
                  v-html="props.node.text"
                ></div>
              </template>
            </tree>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="mActions" class="modal-dialog abs">
    <div v-if="discountData.data" class="modal-content">
      <div class="inmodal">
        <div class="modal-header">
          <h4 class="modal-title">
            Edycja rabatów dla nakładów:
            {{ discountData.data.title }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="form-horizontal discounts">
            <div class="row m-b-sm">
              <div class="col-lg-1">
                <input @change="selectAll" v-model="sAll" type="checkbox" />
              </div>
              <div class="col-lg-11">
                <div class="row m-b-sm">
                  <div class="col-lg-12">
                    <button
                      @click="calc(2, 0.1)"
                      type="button"
                      class="btn btn-success btn-one-down"
                      title="Na doł o 0.1%"
                    >
                      <span class="fa fa-angle-right"></span>
                    </button>
                    <button
                      @click="calc(1, 0.1)"
                      type="button"
                      class="btn btn-success btn-one-up"
                      title="Do góry o 0.1%"
                    >
                      <span class="fa fa-angle-right"></span>
                    </button>
                    |
                    <button
                      @click="calc(2, 1)"
                      type="button"
                      class="btn btn-success btn-double-down"
                      title="Na doł o 1%"
                    >
                      <span class="fa fa-angle-double-right"></span>
                    </button>
                    <button
                      @click="calc(1, 1)"
                      type="button"
                      class="btn btn-success btn-double-up"
                      title="Do góry o 1%"
                    >
                      <span class="fa fa-angle-double-right"> </span>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-7">
                    <div class="form-group">
                      <label for="value" class="control-label col-sm-3 no-padd">
                        Wartość
                      </label>
                      <div class="col-sm-9">
                        <input
                          id="value"
                          type="text"
                          class="form-control"
                          placeholder="Wartość"
                          v-model="calcValue"
                        />
                        <div class="has-error"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <button @click="calc(1, calcValue)" class="btn btn-success">
                      Przelicz wybrane
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-lg-12">
                <div class="ibox">
                  <div
                    v-for="(child, index) in discountData.data.children"
                    :key="index"
                    class="row"
                  >
                    <div class="col-lg-1">
                      <input
                        v-model="child.checked"
                        type="checkbox"
                        class="discounts select-row"
                      />
                    </div>
                    <div class="col-lg-11">
                      <div class="has-error"></div>
                      <div class="form-group">
                        <label for="value" class="control-label col-sm-2">
                          {{ child.title }}
                        </label>
                        <div class="col-sm-9">
                          <input
                            id="value"
                            name="value"
                            type="text"
                            v-model="child.value"
                            class="form-control discount-input-0"
                            :placeholder="child.title"
                          />
                          <div class="has-error"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="mActions = false" class="btn btn-white">
            Anuluj
          </button>
          <button @click="saveDiscount" class="btn btn-primary">Zapisz</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mActions" @click="mActions = false" class="modal-backdrop"></div>
</template>

<script>
import { computed, ref, watch } from "vue";
import treeview from "vue3-treeview";
import { useStore } from "vuex";
import "vue3-treeview/dist/style.css";
export default {
  name: "DiscountList",
  mixins: [],
  components: {
    tree: treeview,
  },
  props: {},

  setup() {
    const store = useStore();
    const discountsData = computed(() => store.getters.getDiscountsData);
    const discountData = computed(() => store.getters.getDiscountData);
    const updateDiscountsData = computed(
      () => store.getters.getUpdateDiscountsData
    );
    const createDiscountsForQData = computed(
      () => store.getters.getCreateDiscountsForQData
    );
    const selectedNode = ref();
    const discountFile = ref();
    const mActions = ref(false);
    const loading = ref(true);
    const sAll = ref(false);
    const calcValue = ref(0);
    const data = ref({
      config: {
        roots: [],
      },
      nodes: {},
    });
    const onNodeClick = (node, e) => {
      selectedNode.value = node;
      if (e.target.classList.contains("add-discount-quantity")) {
        store.dispatch("createDiscountForQuantitity", {
          id: node.pk,
        });
      } else if (e.target.classList.contains("update-discount-quantity")) {
        store.dispatch("getDiscount", {
          id: node.pk,
        });
        mActions.value = true;
      } else if (e.target.classList.contains("delete-discount-quantity")) {
        store.dispatch("deleteDiscountForQuantitity", {
          id: node.pk,
        });
      }
    };
    const getByParent = (parent) => {
      var res = discountsData.value.data.filter((obj) => {
        return obj.parent === parent;
      });

      return res;
    };

    const updateDiscount = () => {
      store.dispatch("updateDiscount", {
        secondary_value: selectedNode.value.secondary_value,
        value: selectedNode.value.value,
        pk: selectedNode.value.pk,
      });
    };

    const selectAll = () => {
      discountData.value.data.children.forEach((child) => {
        if (sAll.value) {
          child.checked = true;
        } else {
          child.checked = false;
        }
      });
    };

    const calc = (type, val) => {
      discountData.value.data.children.forEach((child) => {
        if (child.checked) {
          if (type === 1) {
            child.value = parseFloat(child.value) + parseFloat(val);
          } else if (type === 2) {
            child.value = parseFloat(child.value) - parseFloat(val);
          }
        }
      });
    };
    const saveDiscount = () => {
      store.dispatch("updateDiscountForQ", discountData.value.data.children);
    };

    const changeFile = (file) => {
      discountFile.value = file.target.files[0];
    };

    const importFile = () => {
      store.dispatch("importDiscountForQ", {
        file: discountFile.value,
      });
      mActions.value = true;
    };

    store.dispatch("getDiscounts");

    watch(
      () => discountsData.value,
      function (newValue) {
        let roots = getByParent("#");

        newValue.data.forEach((element) => {
          let childrens = getByParent(element.id);
          let childArr = [];
          element.state = {};
          if (element.parent === "#") {
            element.state.opened = true;
          }
          element.pk = element.id;
          data.value.nodes["id" + element.id] = element;
          childrens.forEach((child) => {
            childArr.push("id" + child.id);
          });
          data.value.nodes["id" + element.id].children = childArr;
        });

        roots.forEach((element) => {
          data.value.config.roots.push("id" + element.id);
        });
        loading.value = false;
      }
    );

    watch(
      () => [updateDiscountsData.value, createDiscountsForQData.value],
      function (newValue) {
        if (newValue[1].closeModal) {
          mActions.value = false;
        }
        if (newValue[0].status === 200 || newValue[1].status === 200) {
          loading.value = true;
          store.dispatch("getDiscounts");
          store.dispatch("showToaster", {
            type: "success",
            content: "Rabat został zaktualizowany.",
          });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
        }
      }
    );

    return {
      data,
      onNodeClick,
      getByParent,
      selectedNode,
      updateDiscount,
      mActions,
      discountData,
      selectAll,
      sAll,
      calc,
      calcValue,
      saveDiscount,
      discountFile,
      changeFile,
      importFile,
      loading,
    };
  },
};
</script>
