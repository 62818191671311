<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Użytkownicy</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/admins">Użytkownicy</router-link>
        </li>
        <li class="active">
          <strong>Nowy</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Dodanie nowego użytkownika</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label for="username" class="control-label col-sm-2">
                  Nazwa użytkownika
                </label>
                <div class="col-sm-10">
                  <input
                    id="username"
                    v-model="adminObj.user.username"
                    name="username"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa użytkownika"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.user?.username"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.user.username[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="password" class="control-label col-sm-2">
                  Hasło
                </label>
                <div class="col-sm-10">
                  <input
                    id="password"
                    v-model="adminObj.user.password"
                    type="password"
                    class="form-control inputClass"
                    placeholder="Haslo"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.user?.password"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.user.password[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group">
                <label for="email" class="control-label col-sm-2">
                  Adres e-mail
                </label>
                <div class="col-sm-10">
                  <input
                    id="email"
                    v-model="adminObj.user.email"
                    type="email"
                    class="form-control inputClass"
                    placeholder="Adres e-mail"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.user?.email"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.user.email[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="name" class="control-label col-sm-2">Imię</label>
                <div class="col-sm-10">
                  <input
                    id="name"
                    v-model="adminObj.user.first_name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Imię"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.user?.first_name"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.user.first_name[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="lastname" class="control-label col-sm-2">
                  Nazwisko
                </label>
                <div class="col-sm-10">
                  <input
                    id="lastname"
                    v-model="adminObj.user.last_name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwisko"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.user?.last_name"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData?.data.user.last_name[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Zdjęcie</label>
                <div class="col-sm-10">
                  <picture-input
                    ref="pictureInput"
                    width="300"
                    height="300"
                    margin="16"
                    accept="image/jpeg,image/png"
                    size="10"
                    button-class="btn"
                    :custom-strings="{
                      upload: '<h1>Uploading!</h1>',
                      drag: 'Drag & Drop',
                    }"
                    @change="changePhoto"
                  >
                  </picture-input>
                </div>
              </div>
              <div class="hr-line-dashed"></div>
              <div class="form-group">
                <label class="control-label col-sm-2">Czynności maszyn</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="adminObj.machine_actions"
                    mode="tags"
                    placeholder="Czynności maszyn"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="machinesList"
                  >
                  </Multiselect>
                  <div
                    v-if="!adminObj.machine_actions.length && showErrors"
                    class="has-error"
                  >
                    <span class="help-block">To pole nie może być puste</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Kompetencje</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="adminObj.competencies"
                    mode="tags"
                    placeholder="Kompetencje"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="competenciesList"
                  >
                  </Multiselect>
                  <div
                    v-if="!adminObj.competencies.length && showErrors"
                    class="has-error"
                  >
                    <span class="help-block">To pole nie może być puste</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Grupy uprawnień</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="adminObj.user.groups"
                    mode="tags"
                    placeholder="Grupy uprawnień"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="permissionsList"
                  >
                  </Multiselect>
                  <!-- <span class="help-block">To pole nie może być puste</span> -->
                </div>
              </div>
              <div class="hr-line-dashed"></div>

              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <router-link class="btn btn-white" to="/admins">
                    Anuluj
                  </router-link>
                  <button @click="create" class="btn btn-primary">Dodaj</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import PictureInput from "vue-picture-input";

export default {
  name: "AdminsCreate",
  mixins: [],
  components: {
    Multiselect,
    PictureInput,
  },
  props: {},

  setup() {
    const adminObj = ref({
      user: {
        username: "",
        password: "",
        email: "",
        first_name: "",
        last_name: "",
        groups: [],
      },
      competencies: [],
      machine_actions: [],
      image: [],
    });
    const machinesList = ref([]);
    const competenciesList = ref([]);
    const permissionsList = ref([]);
    const errorsAdminData = ref();
    const showErrors = ref(false);
    const store = useStore();
    store.dispatch("getMachines");
    store.dispatch("getCompetencies");
    store.dispatch("getPermissionsGroups");
    const createAdminData = computed(() => store.getters.getCreateAdminData);
    const machinesData = computed(() => store.getters.getMachinesData);
    const competenciesData = computed(() => store.getters.getCompetenciesData);
    const permissionsGroupData = computed(
      () => store.getters.getPermissionsGroupsData
    );

    const create = () => {
      showErrors.value = true;
      if (
        adminObj.value.competencies.length &&
        adminObj.value.machine_actions
      ) {
        store.dispatch("createAdmin", adminObj);
      }
    };

    const changePhoto = (image) => {
      if (image.srcElement?.files[0]) {
        adminObj.value.image = image.srcElement?.files[0];
      }
    };

    watch(
      () => machinesData.value,
      function (newValue) {
        newValue.data.results?.forEach((element) => {
          machinesList.value.push({
            name: "[" + element.pk + "] " + element.name,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => competenciesData.value,
      function (newValue) {
        newValue.data.results?.forEach((element) => {
          competenciesList.value.push({
            name: "[" + element.pk + "] " + element.name,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => permissionsGroupData.value,
      function (newValue) {
        newValue.data.results?.forEach((element) => {
          permissionsList.value.push({
            name: "[" + element.pk + "] " + element.name,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => createAdminData.value,
      function (newValue) {
        if (newValue.status === 201) {
          router.push({ name: "Admins", path: "/admins" }).then(() => {
            store.dispatch("showToaster", {
              type: "success",
              content: "Użytkownik zostal dodany",
            });
          });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data.user)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    return {
      machinesList,
      competenciesList,
      permissionsList,
      adminObj,
      create,
      errorsAdminData,
      showErrors,
      changePhoto,
    };
  },
  computed: {},
};
</script>
