<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Kategorie</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/categories/list">Kategorie</router-link>
        </li>
        <li class="active">
          <strong>Nowa</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis kategorii [pl]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa</label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.title_pl"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.title_pl" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.title_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Tytuł</label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.title_seo_pl"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Tytuł"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.title_seo_pl"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.title_seo_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  [SEO] Słowa kluczowe
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.keywords_seo_pl"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Słowa kluczowe"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.keywords_seo_pl"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.keywords_seo_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Opis</label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.description_seo_pl"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Opis"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_seo_pl"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_seo_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="categoryObj.description_pl"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_pl"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ibox collapsed float-e-margins">
          <div class="ibox-title">
            <h5>Opis kategorii [en]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa</label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.title_en"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.title_en" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.title_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Tytuł</label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.title_seo_en"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Tytuł"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.title_seo_en"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.title_seo_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  [SEO] Słowa kluczowe
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.keywords_seo_en"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Słowa kluczowe"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.keywords_seo_en"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.keywords_seo_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Opis</label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.description_seo_en"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Opis"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_seo_en"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_seo_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="categoryObj.description_en"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_en"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ibox collapsed float-e-margins">
          <div class="ibox-title">
            <h5>Opis kategorii [de]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa</label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.title_de"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.title_de" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.title_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Tytuł</label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.title_seo_de"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Tytuł"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.title_seo_de"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.title_seo_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  [SEO] Słowa kluczowe
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.keywords_seo_de"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Słowa kluczowe"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.keywords_seo_de"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.keywords_seo_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Opis</label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.description_seo_de"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Opis"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_seo_de"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_seo_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="categoryObj.description_de"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_de"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ibox collapsed float-e-margins">
          <div class="ibox-title">
            <h5>Opis kategorii [de_de]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa</label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.title_cpde"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.title_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.title_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Tytuł</label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.title_seo_cpde"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Tytuł"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.title_seo_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.title_seo_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  [SEO] Słowa kluczowe
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.keywords_seo_cpde"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Słowa kluczowe"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.keywords_seo_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.keywords_seo_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Opis</label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.description_seo_cpde"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Opis"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_seo_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_seo_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="categoryObj.description_cpde"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ibox collapsed float-e-margins">
          <div class="ibox-title">
            <h5>Opis kategorii [de_en]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa</label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.title_cpen"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.title_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.title_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Tytuł</label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.title_seo_cpen"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Tytuł"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.title_seo_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.title_seo_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  [SEO] Słowa kluczowe
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.keywords_seo_cpen"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Słowa kluczowe"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.keywords_seo_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.keywords_seo_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Opis</label>
                <div class="col-sm-10">
                  <input
                    v-model="categoryObj.description_seo_cpen"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Opis"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_seo_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_seo_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="categoryObj.description_cpen"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Konfiguracja Pliku</h5>
          </div>
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">
                  Kategoria nadrzędna
                </label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="categoryObj.parent"
                    mode="single"
                    placeholder="Kategoria nadrzędna"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :searchable="true"
                    :options="parents"
                  >
                  </Multiselect>
                  <div
                    v-if="categoryObj.parent && showErrors"
                    class="has-error"
                  >
                    <span class="help-block">To pole nie może być puste</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  Dostępność w sklepach
                </label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="categoryObj.available_in_countries"
                    mode="tags"
                    placeholder="Dostępność  w sklepach"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="countryList"
                  >
                  </Multiselect>
                  <div
                    v-if="
                      !categoryObj.available_in_countries.length && showErrors
                    "
                    class="has-error"
                  >
                    <span class="help-block">To pole nie może być puste</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Obrazek</label>
                <div class="col-sm-2">
                  <picture-input
                    ref="pictureInput"
                    width="300"
                    height="300"
                    margin="16"
                    accept="image/jpeg,image/png"
                    size="10"
                    button-class="btn"
                    :custom-strings="{
                      upload: '<h1>Uploading!</h1>',
                      drag: 'Drag & Drop',
                    }"
                    @change="onFileChange"
                  >
                  </picture-input>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="categoryObj.is_published"
                        id="active"
                        name="active"
                        type="checkbox"
                      />
                      <span>Aktywna</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <router-link class="btn btn-white" to="/categories/list">
                    Anuluj
                  </router-link>
                  <button @click="create" class="btn btn-primary">Dodaj</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import PictureInput from "vue-picture-input";

export default {
  name: "categoriesCreate",
  mixins: [],
  components: {
    Multiselect,
    PictureInput,
  },
  props: {},

  setup() {
    const categoryObj = ref({
      title_pl: "",
      description_pl: "",
      title_en: "",
      description_en: "",
      title_de: "",
      description_de: "",
      title_cpde: "",
      description_cpde: "",
      title_cpen: "",
      description_cpen: "",
      title_seo_pl: "",
      keywords_seo_pl: "",
      description_seo_pl: "",
      title_seo_en: "",
      keywords_seo_en: "",
      description_seo_en: "",
      title_seo_de: "",
      keywords_seo_de: "",
      description_seo_de: "",
      title_seo_cpde: "",
      keywords_seo_cpde: "",
      description_seo_cpde: "",
      title_seo_cpen: "",
      keywords_seo_cpen: "",
      description_seo_cpen: "",
      image: "",
      parent: [],
      is_published: false,
      available_in_countries: [],
    });
    const errorsAdminData = ref();
    const parents = ref([]);
    const showErrors = ref(false);
    const store = useStore();
    const createCategoriesData = computed(
      () => store.getters.getCreateCategoriesData
    );
    const categoriesList = computed(() => store.getters.getCategoryParentsData);
    const countryList = ref([
      { name: "Polska", value: "PL" },
      { name: "Niemcy", value: "DE" },
    ]);
    const create = () => {
      showErrors.value = true;
      store.dispatch("createCategories", categoryObj.value);
    };
    store.dispatch("getCategoryParents");
    const onFileChange = (image) => {
      if (image.srcElement?.files[0]) {
        categoryObj.value.image = image.srcElement?.files[0];
      }
    };

    watch(
      () => categoriesList.value,
      function (newValue) {
        newValue.data?.forEach((element) => {
          parents.value.push({
            name: "[" + element.pk + "] " + element.title,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => createCategoriesData.value,
      function (newValue) {
        if (newValue.status === 201) {
          router
            .push({
              name: "CategoriesList",
              path: "/categories/list",
            })
            .then(() => {
              store.dispatch("showToaster", {
                type: "success",
                content: "Nowa kategoria została dodana",
              });
            });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    return {
      categoryObj,
      errorsAdminData,
      showErrors,
      create,
      onFileChange,
      parents,
      countryList,
    };
  },
  computed: {},
};
</script>
