<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Grupy uprawnień</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/groups">Grupy uprawnień</router-link>
        </li>
        <li class="active">
          <strong>Nowy</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Dodanie nowej grupy uprawnień</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label for="username" class="control-label col-sm-2">
                  Nazwa
                </label>
                <div class="col-sm-10">
                  <input
                    id="username"
                    v-model="groupObj.name"
                    name="username"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.user?.username"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.user.username[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Uprawnienia</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="groupObj.permissions"
                    mode="tags"
                    placeholder="Uprawnienia"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="permissionsList"
                  >
                  </Multiselect>
                  <div
                    v-if="!groupObj.permissions.length && showErrors"
                    class="has-error"
                  >
                    <span class="help-block">To pole nie może być puste</span>
                  </div>
                </div>
              </div>
              <div class="hr-line-dashed"></div>

              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <router-link class="btn btn-white" to="/groups">
                    Anuluj
                  </router-link>
                  <button @click="create" class="btn btn-primary">Dodaj</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "AdminsCreate",
  mixins: [],
  components: {
    Multiselect,
  },
  props: {},

  setup() {
    const groupObj = ref({
      name: "",
      permissions: [],
    });
    const permissionsList = ref([]);
    const errorsAdminData = ref();
    const showErrors = ref(false);
    const store = useStore();
    const permissionsData = computed(() => store.getters.getPermissionsData);
    const createPermissionsGroupData = computed(
      () => store.getters.getCreatePermissionsGroupData
    );

    store.dispatch("getPermissions");
    const create = () => {
      showErrors.value = true;
      if (groupObj.value.permissions.length) {
        store.dispatch("createPermissionsGroup", groupObj.value);
      }
    };

    watch(
      () => permissionsData.value,
      function (newValue) {
        newValue.data?.forEach((element) => {
          permissionsList.value.push({
            name: "[" + element.pk + "] " + element.name,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => createPermissionsGroupData.value,
      function (newValue) {
        if (newValue.status === 201) {
          router.push({ name: "Groups", path: "/groups" }).then(() => {
            store.dispatch("showToaster", {
              type: "success",
              content: "Grupa uprawnień została dodana",
            });
          });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    return {
      permissionsList,
      groupObj,
      errorsAdminData,
      showErrors,
      create,
    };
  },
  computed: {},
};
</script>
