<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Sprawy</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Sprawy</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Sprawy</h5>
          </div>
          <div class="ibox-content">
            <router-link to="/messages/create">
              <button class="btn btn-sm btn-primary">Dodaj</button>
            </router-link>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <button @click="resetFilters" class="btn btn-primary btn-xs">
                  <i class="fa fa-refresh"></i>
                </button>
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :body-row-class-name="bodyRowClassNameFunction"
                  :headers="headers"
                  :items="items"
                  :clickRowToExpand="true"
                  :loading="loading"
                  @click-row="messageDetails"
                  alternating
                  multi-sort
                  show-index
                  border-cell
                  @update-sort="updateSort"
                  class="messages table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showModal"
    :title="'Potwierdzenie usunięcia'"
    :content="'Czy na pewno chcesz usunąć <b>' + deleteName + '</b> ?'"
    @callbackOk="deleteMessage(showModal)"
    @callbackClose="showModal = false"
  />
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../caffeprint/Modal.vue";
import router from "../../router/router";

export default {
  name: "MessagesList",
  mixins: [],
  components: {
    Modal,
  },
  props: {},

  setup() {
    const store = useStore();
    const deleteMessageData = computed(
      () => store.getters.getDeleteMessageData
    );
    const messagesData = computed(() => store.getters.getMessagesData);
    let headers = [
      { text: "Numer sprawy", value: "pk", search: true },
      {
        text: "E-mail",
        value: "client_email",
        sortable: true,
        search: true,
      },
      {
        text: "Klient",
        value: "client_name",
        sortable: true,
        search: true,
      },
      {
        text: "Rodzaj sprawy",
        value: "competency",
        sortable: true,
        search: true,
      },
      { text: "Nr zamówienia", value: "order", sortable: true, search: true },
      {
        text: "Osoba odpowiedzialna",
        value: "responsible_person_name",
        sortable: true,
        search: true,
      },
      { text: "Status", value: "status_display", sortable: true, search: true },
      { text: "Data dodania", value: "date_added", sortable: true },
      { text: "Data modyfikacji", value: "date_modified", sortable: true },
    ];

    let items = ref([]);
    let itemsAll = ref(0);
    const showModal = ref(false);
    const deleteName = ref("");
    const permissionsList = ref([]);
    const loading = ref(true);
    const searchValue = ref({});
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });
    const getMessages = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      await store.dispatch("getMessages", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
      });
    };

    const deleteMessage = (pk) => {
      showModal.value = false;
      store.dispatch("deleteMessage", {
        pk: pk,
      });
    };
    const cancel = () => {
      document.querySelector(".expanding")?.click();
    };

    const editMessage = (item) => {
      cancel();
      store.dispatch("getMessage", { pk: item.pk });
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getMessages();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getMessages();
    };

    const updateSort = () => {
      search.value = true;
    };

    const bodyRowClassNameFunction = (item) => {
      if (item.is_last_message_from_client) {
        return "yellow-row";
      }

      return false;
    };

    const messageDetails = (item) => {
      router.push({
        name: "MessagesDetails",
        path: "/messages/details/",
        params: { pk: item.pk },
      });
    };

    getMessages();

    watch(
      () => messagesData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => deleteMessageData.value,
      function (newValue) {
        if (newValue.status === 204) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Grupa rabatów została usunięta",
          });
        }
        getMessages();
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getMessages();
          }, 1000);
        }
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getMessages();
          isSearch.value = false;
        }
      }
    );

    return {
      headers,
      items,
      itemsAll,
      loading,
      serverOptions,
      permissionsList,
      showModal,
      deleteMessage,
      deleteName,
      cancel,
      editMessage,
      searchValue,
      isSearch,
      search,
      rowsPerPage,
      resetFilters,
      updateSort,
      bodyRowClassNameFunction,
      messageDetails,
    };
  },
  computed: {},
};
</script>
