<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Faq</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Faq</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Faq</h5>
          </div>
          <div class="ibox-content">
            <router-link to="/faq/create">
              <button class="btn btn-sm btn-primary">Dodaj</button>
            </router-link>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <button @click="resetFilters" class="btn btn-primary btn-xs">
                  <i class="fa fa-refresh"></i>
                </button>
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :clickRowToExpand="true"
                  :loading="loading"
                  @click-row="editFaq"
                  alternating
                  multi-sort
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                  <template #expand>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Opis faq [pl]</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Tytuł
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    id="username"
                                    v-model="faqObj.title_pl"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Tytuł"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_pl"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.title_pl[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Opis
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="faqObj.description_pl"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.description_pl"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.description_pl[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Opis faq [en]</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Tytuł
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="faqObj.title_en"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Tytuł"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_en"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.title_en[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Opis
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="faqObj.description_pl"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.description_pl"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.description_pl[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Opis faq [de]</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Tytuł
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="faqObj.title_de"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Tytuł"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_de"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.title_de[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Opis
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="faqObj.description_de"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.description_de"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.description_de[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Opis faq [de_de]</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Tytuł
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="faqObj.title_cpde"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Tytuł"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_cpde"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.title_cpde[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Opis
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="faqObj.description_cpde"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data?.description_cpde
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.description_cpde[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Opis faq [de_en]</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Tytuł
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="faqObj.title_cpen"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Tytuł"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_cpen"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.title_cpen[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Opis
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="faqObj.description_cpen"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data?.description_cpen
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.description_cpen[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Dane faq</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Sekcja
                                </label>
                                <div class="col-sm-10">
                                  <Multiselect
                                    v-model="faqObj.section"
                                    mode="single"
                                    placeholder="Sekcja"
                                    track-by="name"
                                    label="name"
                                    :close-on-select="false"
                                    :searchable="true"
                                    :options="faqSections"
                                  >
                                  </Multiselect>
                                  <div
                                    v-if="errorsAdminData?.data?.section"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.section[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="hr-line-dashed"></div>

                              <div class="form-group">
                                <div class="col-sm-4 col-sm-offset-2">
                                  <button class="btn btn-white" @click="cancel">
                                    Anuluj
                                  </button>
                                  <button
                                    @click="saveEdit"
                                    class="btn btn-primary"
                                  >
                                    Zapisz
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #item-section="item">{{ item.section }}</template>
                  <template #item-actions="item">
                    <button
                      @click.stop="
                        showModal = item.pk;
                        deleteName = item.title;
                      "
                      class="btn btn-danger btn-xs"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showModal"
    :title="'Potwierdzenie usunięcia'"
    :content="'Czy na pewno chcesz usunąć <b>' + deleteName + '</b> ?'"
    @callbackOk="deleteFaq(showModal)"
    @callbackClose="showModal = false"
  />
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../caffeprint/Modal.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "FaqList",
  mixins: [],
  components: {
    Modal,
    Multiselect,
  },
  props: {},

  setup() {
    const store = useStore();
    const deleteFaqData = computed(() => store.getters.getDeleteFaqData);
    const faqData = computed(() => store.getters.getFaqData);
    const updateFaqData = computed(() => store.getters.getUpdateFaqData);
    const faqsData = computed(() => store.getters.getFaqsData);
    const faqObj = ref({
      title: "",
      section: "",
    });
    let headers = [
      { text: "Nr", value: "pk" },
      { text: "Nazwa", value: "title", sortable: true, search: true },
      { text: "Sekcja", value: "section", sortable: true, search: true },
      { text: "Akcje", value: "actions" },
    ];

    let items = ref([]);
    let statsItems = ref([]);
    let itemsAll = ref(0);
    const showModal = ref(false);
    const deleteName = ref("");
    const loading = ref(true);
    const searchValue = ref({});
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });

    const getFaqs = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      await store.dispatch("getFaqs", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
      });
    };

    const deleteFaq = (pk) => {
      showModal.value = false;
      store.dispatch("deleteFaq", {
        pk: pk,
      });
    };
    const saveEdit = () => {
      store.dispatch("updateFaq", faqObj.value);
    };

    const cancel = () => {
      document.querySelector(".expanding")?.click();
    };

    const editFaq = (item) => {
      cancel();
      store.dispatch("getFaq", { pk: item.pk });
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getFaqs();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getFaqs();
    };

    const updateSort = () => {
      search.value = true;
    };

    const faqSections = ref([
      {
        name: "1",
        value: "SECTION_ORDER",
      },
      {
        name: "2",
        value: "SECTION_PAYMENT",
      },
      {
        name: "3",
        value: "SECTION_DELIVERY",
      },
    ]);

    getFaqs();

    watch(
      () => faqsData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => faqData.value,
      function (newValue) {
        faqObj.value = newValue.data;
      }
    );

    watch(
      () => deleteFaqData.value,
      function (newValue) {
        if (newValue.status === 204) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Faq został usunięty",
          });
        }
        getFaqs();
      }
    );

    watch(
      () => updateFaqData.value,
      function (newValue) {
        if (newValue.status === 200) {
          getFaqs();
          store.dispatch("showToaster", {
            type: "success",
            content: "Dane zostały zaktualizowane",
          });
        }

        cancel();
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getFaqs();
          }, 1000);
        }
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getFaqs();
          isSearch.value = false;
        }
      }
    );

    return {
      headers,
      items,
      itemsAll,
      loading,
      serverOptions,
      showModal,
      deleteFaq,
      deleteName,
      statsItems,
      faqObj,
      cancel,
      editFaq,
      saveEdit,
      searchValue,
      search,
      resetFilters,
      updateSort,
      isSearch,
      rowsPerPage,
      faqSections,
    };
  },
  computed: {},
};
</script>
