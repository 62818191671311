<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Statystyki</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Statystyki Sprzedaży</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight logs">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Filtry</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-md-3 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Wybierz daty</label>
                  <div class="col-sm-8">
                    <VueDatePicker
                      class="stats-daterangepicker"
                      v-model="statsRangeDate"
                      multi-calendars
                      :format="format"
                      range
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Sklep</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="shop"
                      mode="single"
                      placeholder="Sklep"
                      track-by="name"
                      label="name"
                      :close-on-select="true"
                      :searchable="true"
                      :options="shopOptions"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
              <div class="col-md-3 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Grupowanie</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="group"
                      mode="single"
                      placeholder="Grupowanie"
                      track-by="name"
                      label="name"
                      :close-on-select="true"
                      :searchable="true"
                      :options="groups"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Statystyki</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :loading="loading"
                  alternating
                  multi-sort
                  show-index
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Podsumowanie</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div v-if="summary !== 0" class="col-sm-12 m-b-xs">
                <EasyDataTable
                  :headers="headers"
                  :items="summary"
                  border-cell
                  hide-footer="true"
                >
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="statsRangeDate1[0]" class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Filtry</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-md-3 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Wybierz daty</label>
                  <div class="col-sm-8">
                    <VueDatePicker
                      class="stats-daterangepicker"
                      v-model="statsRangeDate1"
                      multi-calendars
                      :format="format"
                      range
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Sklep</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="shop1"
                      mode="single"
                      placeholder="Sklep"
                      track-by="name"
                      label="name"
                      :close-on-select="true"
                      :searchable="true"
                      :options="shopOptions"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
              <div class="col-md-3 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Grupowanie</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="group1"
                      mode="single"
                      placeholder="Grupowanie"
                      track-by="name"
                      label="name"
                      :close-on-select="true"
                      :searchable="true"
                      :options="groups"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Statystyki</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items1">
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll1"
                  :headers="headers"
                  :items="items1"
                  :loading="loading"
                  alternating
                  multi-sort
                  show-index
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Podsumowanie</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div v-if="summary1 !== 0" class="col-sm-12 m-b-xs">
                <EasyDataTable
                  :headers="headers"
                  :items="summary1"
                  border-cell
                  hide-footer="true"
                >
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="statsRangeDate" class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title"><h5>Porównaj zakres</h5></div>
          <div class="ibox-content">
            <div class="form-inline">
              <div class="form-group">
                <label for="no-comparative" class="sr-only">Liczba</label>
                <input
                  v-model="comparative"
                  type="number"
                  placeholder="Podaj liczbę"
                  min="1"
                  id="no-comparative"
                  class="form-control"
                />
              </div>
              <button @click="comparativeFn('week')" class="btn btn-white">
                Tygodni wcześniej
              </button>
              <button @click="comparativeFn('month')" class="btn btn-white">
                Miesięcy wcześniej
              </button>
              <button @click="comparativeFn('year')" class="btn btn-white">
                Lat wcześniej
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading" class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title"><h5>Pokaż wykresy</h5></div>
          <div class="ibox-content">
            <div class="row form-inline">
              <div
                v-for="(chart, key) in data"
                class="form-group col-lg-4 checkbox"
                :key="key"
              >
                <div class="checkbox checkbox-primary">
                  <label>
                    <input v-model="chart.show" type="checkbox" />
                    <span>{{ chart.title }}</span>
                  </label>
                </div>
              </div>
            </div>
            <div v-for="(chart, key) in data" :key="key">
              <h4 v-if="chart.show" class="text-center">
                {{ chart.title }}
              </h4>
              <Bar v-if="chart.show" :data="data[key]" :options="options" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Bar } from "vue-chartjs";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default {
  name: "SalelogsList",
  mixins: [],
  components: {
    Multiselect,
    Bar,
  },
  props: {},

  setup() {
    const store = useStore();
    const salesStatsData = computed(() => store.getters.getSalesStatsData);
    const salesGroupsData = computed(() => store.getters.getSalesGroupsData);
    let headers = [
      { text: "Data", value: "date_to_display", sortable: true },
      {
        text: "Wartość zamówień netto bez kosztów przesyłki i podziału zamówienia",
        value: "net_sum_without_delivery_costs",
        sortable: true,
      },
      {
        text: "Ilość zamówień",
        value: "num_of_orders",
        sortable: true,
      },
      {
        text: "Ilość zamówień pobraniowych",
        value: "num_of_on_cash_delivery_orders",
        sortable: true,
      },
      {
        text: "Ilość zamówionych produktów",
        value: "num_of_order_products",
        sortable: true,
      },
      {
        text: "Ilość zamówień wysłanych w dniu wyznaczonym przez system",
        value: "num_of_orders_sent_on_day_set_by_system",
        sortable: true,
      },
      {
        text: "Ilość zamówień opóźnionych",
        value: "num_of_delayed_orders",
        sortable: true,
      },
      {
        text: "Max ilość dni opóźnienia",
        value: "num_of_delayed_days_max",
        sortable: true,
      },
      {
        text: "Min ilość dni opóźnienia",
        value: "num_of_delayed_days_min",
        sortable: true,
      },
    ];
    const shopOptions = ref([
      { name: "Polska", value: "PL" },
      { name: "Niemcy", value: "DE" },
      { name: "Zamówienia zewnętrzne", value: "ZZ" },
    ]);

    const data = ref({
      net_sum_without_delivery_costs: {
        show: false,
        title:
          "Wartość zamówień netto bez kosztów przesyłki i podziału zamówienia",
        labels: [],
        datasets: [
          {
            label:
              "Wartość zamówień netto bez kosztów przesyłki i podziału zamówienia",
            data: [],
          },
        ],
      },
      num_of_orders: {
        show: false,
        title: "Ilość zamówień",
        labels: [],
        datasets: [{ label: "Ilość zamówień", data: [] }],
      },
      num_of_on_cash_delivery_orders: {
        show: false,
        title: "Ilość zamówień pobraniowych",
        labels: [],
        datasets: [{ label: "Ilość zamówień pobraniowych", data: [] }],
      },
      num_of_order_products: {
        show: false,
        title: "Ilość zamówionych produktów",
        labels: [],
        datasets: [{ label: "Ilość zamówionych produktów", data: [] }],
      },
      num_of_orders_sent_on_day_set_by_system: {
        show: false,
        title: "Ilość zamówień wysłanych w dniu wyznaczonym przez system",
        labels: [],
        datasets: [
          {
            label: "Ilość zamówień wysłanych w dniu wyznaczonym przez system",
            data: [],
          },
        ],
      },
      num_of_delayed_orders: {
        show: false,
        title: "Ilość zamówień opóźnionych",
        labels: [],
        datasets: [{ label: "Ilość zamówień opóźnionych", data: [] }],
      },
      num_of_delayed_days_max: {
        show: false,
        title: "Max ilość dni opóźnienia",
        labels: [],
        datasets: [{ label: "Max ilość dni opóźnienia", data: [] }],
      },
      num_of_delayed_days_min: {
        show: false,
        title: "Min ilość dni opóźnienia",
        labels: [],
        datasets: [{ label: "Min ilość dni opóźnienia", data: [] }],
      },
    });

    const options = ref({
      responsive: true,
    });
    const summary = ref([]);
    const summary1 = ref([]);
    const loading = ref(true);
    const groups = ref([]);
    const group = ref("months");
    const group1 = ref("months");
    const searchValue = ref({});
    const isSearch = ref(false);
    const shop = ref();
    const shop1 = ref();
    const rowsPerPage = ref();
    const statsRangeDate = ref();
    const statsRangeDate1 = ref([]);
    const comparative = ref(0);
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });
    let items = ref([]);
    let items1 = ref([]);
    let itemsAll = ref(0);
    let itemsAll1 = ref(0);
    const getSalesStats = (comp = false) => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      let shopVal = shop.value;
      let groupVal = group.value;
      let date = statsRangeDate.value;
      if (comp) {
        date = statsRangeDate1.value;
        shopVal = shop1.value;
        groupVal = group1.value;
      }
      store.dispatch("getSalesStats", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        date: date,
        search: searchValue.value,
        shop_version: shopVal,
        group_by_date: groupVal,
        comp: comp,
      });
    };

    const format = (date) => {
      let day, month, year, dayEnd, monthEnd, yearEnd;
      if (date[0]) {
        day = date[0].getDate();
        month = date[0].getMonth() + 1;
        year = date[0].getFullYear();
      }

      if (date[1]) {
        dayEnd = date[1].getDate();
        monthEnd = date[1].getMonth() + 1;
        yearEnd = date[1].getFullYear();
      }

      if (date.length > 1) {
        return `${day}/${month}/${year} - ${dayEnd}/${monthEnd}/${yearEnd}`;
      }
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getSalesStats();
        }, 1000);
      }
    };

    const comparativeFn = (type) => {
      let dateStart = new Date(statsRangeDate.value[0]);
      let dateEnd = new Date(statsRangeDate.value[1]);
      dateStart.toLocaleDateString();
      dateEnd.toLocaleDateString();
      if (type === "week") {
        dateStart.setDate(dateStart.getDay() - comparative.value * 7);
        dateEnd.setDate(dateEnd.getDay() - comparative.value * 7);
      } else if (type === "month") {
        dateStart.setMonth(dateStart.getMonth() - comparative.value);
        dateEnd.setMonth(dateEnd.getMonth() - comparative.value);
      } else if (type === "year") {
        dateStart.setYear(dateStart.getFullYear() - comparative.value);
        dateEnd.setYear(dateEnd.getFullYear() - comparative.value);
      }
      statsRangeDate1.value[0] = dateStart;
      statsRangeDate1.value[1] = dateEnd;
      getSalesStats(true);
    };

    const updateSort = () => {
      search.value = true;
    };

    const tableSummary = (comp = false) => {
      let tempSummary = {
        net_sum_without_delivery_costs: 0,
        num_of_orders: 0,
        num_of_on_cash_delivery_orders: 0,
        num_of_order_products: 0,
        num_of_orders_sent_on_day_set_by_system: 0,
        num_of_delayed_orders: 0,
      };
      if (comp) {
        summary1.value = [];
        items1.value?.forEach((element) => {
          tempSummary.net_sum_without_delivery_costs +=
            element.net_sum_without_delivery_costs;
          tempSummary.num_of_orders += element.num_of_orders;
          tempSummary.num_of_on_cash_delivery_orders +=
            element.num_of_on_cash_delivery_orders;
          tempSummary.num_of_order_products += element.num_of_order_products;
          tempSummary.num_of_orders_sent_on_day_set_by_system +=
            element.num_of_orders_sent_on_day_set_by_system;
          tempSummary.num_of_delayed_orders += element.num_of_delayed_orders;
        });
        summary1.value.push(tempSummary);
      } else {
        summary.value = [];
        items.value?.forEach((element) => {
          tempSummary.net_sum_without_delivery_costs +=
            element.net_sum_without_delivery_costs;
          tempSummary.num_of_orders += element.num_of_orders;
          tempSummary.num_of_on_cash_delivery_orders +=
            element.num_of_on_cash_delivery_orders;
          tempSummary.num_of_order_products += element.num_of_order_products;
          tempSummary.num_of_orders_sent_on_day_set_by_system +=
            element.num_of_orders_sent_on_day_set_by_system;
          tempSummary.num_of_delayed_orders += element.num_of_delayed_orders;
        });
        summary.value.push(tempSummary);
      }
    };

    store.dispatch("getSalesGroups");
    getSalesStats();

    watch(
      () => salesGroupsData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data?.forEach((element) => {
          groups.value.push({
            name: element[1],
            value: element[0],
          });
        });
      }
    );

    watch(
      () => salesStatsData.value,
      function (newValue) {
        if (newValue.comp) {
          itemsAll1.value = newValue.data.count;
          items1.value = [];
        } else {
          itemsAll.value = newValue.data.count;
          items.value = [];
        }

        newValue.data.results?.forEach((element) => {
          if (newValue.comp) {
            items1.value.push(element);
          } else {
            items.value.push(element);
          }

          data.value.net_sum_without_delivery_costs.labels.push(
            element.date_to_display
          );
          data.value.net_sum_without_delivery_costs.datasets[0].data.push(
            element.net_sum_without_delivery_costs
          );

          data.value.num_of_orders.labels.push(element.date_to_display);
          data.value.num_of_orders.datasets[0].data.push(element.num_of_orders);

          data.value.num_of_on_cash_delivery_orders.labels.push(
            element.date_to_display
          );
          data.value.num_of_on_cash_delivery_orders.datasets[0].data.push(
            element.num_of_on_cash_delivery_orders
          );

          data.value.num_of_order_products.labels.push(element.date_to_display);
          data.value.num_of_order_products.datasets[0].data.push(
            element.num_of_order_products
          );

          data.value.num_of_orders_sent_on_day_set_by_system.labels.push(
            element.date_to_display
          );
          data.value.num_of_orders_sent_on_day_set_by_system.datasets[0].data.push(
            element.num_of_orders_sent_on_day_set_by_system
          );

          data.value.num_of_delayed_orders.labels.push(element.date_to_display);
          data.value.num_of_delayed_orders.datasets[0].data.push(
            element.num_of_delayed_orders
          );

          data.value.num_of_delayed_days_max.labels.push(
            element.date_to_display
          );
          data.value.num_of_delayed_days_max.datasets[0].data.push(
            element.num_of_delayed_days_max
          );

          data.value.num_of_delayed_days_min.labels.push(
            element.date_to_display
          );
          data.value.num_of_delayed_days_min.datasets[0].data.push(
            element.num_of_delayed_days_min
          );
        });
        tableSummary(newValue.comp);
        loading.value = false;
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        getSalesStats();
      }
    );
    watch(
      () => shop.value,
      function () {
        getSalesStats();
      }
    );
    watch(
      () => shop1.value,
      function () {
        getSalesStats(true);
      }
    );
    watch(
      () => statsRangeDate.value,
      function () {
        getSalesStats();
      }
    );
    watch(
      () => group.value,
      function () {
        getSalesStats();
      }
    );
    watch(
      () => group1.value,
      function () {
        getSalesStats(true);
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getSalesStats();
          }, 1000);
        }
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getSalesStats();
          isSearch.value = false;
        }
      }
    );

    return {
      headers,
      serverOptions,
      items,
      items1,
      itemsAll,
      itemsAll1,
      loading,
      format,
      searchValue,
      search,
      updateSort,
      isSearch,
      rowsPerPage,
      statsRangeDate,
      statsRangeDate1,
      shop,
      shop1,
      shopOptions,
      groups,
      group,
      group1,
      data,
      options,
      summary,
      summary1,
      comparative,
      comparativeFn,
    };
  },
  computed: {},
};
</script>
