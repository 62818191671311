<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Sprawy</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/messages/list">Sprawy</router-link>
        </li>
        <li class="active">
          <strong>Szczegóły</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis sprawy</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">
                  Osoba odpowiedzialna
                </label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="messageObj.responsible_person"
                    mode="single"
                    placeholder="Osoba odpowiedzialna"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :searchable="true"
                    :options="adminsOptions"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.responsible_person"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.responsible_person[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Klient</label>
                <div v-if="clientsOptions" class="col-sm-10">
                  <Multiselect
                    v-model="messageObj.client"
                    mode="single"
                    placeholder="Klient"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :searchable="true"
                    :options="clientsOptions"
                  >
                  </Multiselect>
                  <div v-if="errorsAdminData?.data?.client" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.client[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Rodzaj sprawy</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="messageObj.competency"
                    mode="single"
                    placeholder="Rodzaj sprawy"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :searchable="true"
                    :options="adminsReasons"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.competency"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.competency[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Powód reklamacji</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="messageObj.complaint_reason"
                    mode="single"
                    placeholder="Powód reklamacji"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :searchable="true"
                    :disabled="messageObj.competency !== 2"
                    :options="adminsComplaint"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.competency"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.competency[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Zamówienie</label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    v-model="messageObj.order"
                    class="form-control inputClass"
                    placeholder="Zamówienie"
                    disabled="disabled"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Pozycja zamówienia</label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    v-model="messageObj.order_product"
                    class="form-control inputClass"
                    placeholder="Pozycja zamówienia"
                    disabled="disabled"
                  />
                </div>
              </div>

              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <router-link class="btn btn-white" to="/messages/list">
                    Anuluj
                  </router-link>
                  <button @click="create" class="btn btn-primary">Dodaj</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Wiadomość</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-horizontal">
                <div class="form-group">
                  <label class="control-label col-sm-2">Treść zgłoszenia</label>
                  <div class="col-sm-10 QuillEditor-box">
                    <QuillEditor
                      theme="snow"
                      contentType="text"
                      v-model:content="messageObj.message_content"
                    />
                    <div
                      v-if="errorsAdminData?.data?.message_content"
                      class="has-error"
                    >
                      <span class="help-block">
                        {{ errorsAdminData.data.message_content[0] }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-sm-2">Pliki</label>
                  <div class="col-sm-12">
                    <label class="btn btn-info btn-file">
                      <span v-if="!files.length">Dodaj pliki</span>
                      <span v-if="files.length">Zmień pliki</span>
                      <input
                        type="file"
                        multiple="true"
                        @change="onFileChange"
                        style="display: none"
                      />
                    </label>
                  </div>
                </div>
                <ul class="list-group">
                  <li
                    v-for="(file, index) in files"
                    :key="index"
                    class="list-group-item"
                  >
                    <a>{{ file.name }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";
import { useRoute } from "vue-router";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "ClientsCreate",
  mixins: [],
  components: {
    Multiselect,
  },
  props: {},

  setup() {
    const route = useRoute();
    const countryList = ref([
      { name: "Polska", value: "PL" },
      { name: "Niemcy", value: "DE" },
    ]);

    const errorsAdminData = ref();
    const showErrors = ref(false);
    const store = useStore();
    const clientsContactData = computed(
      () => store.getters.getClientsContactData
    );
    const adminsContactData = computed(
      () => store.getters.getAdminsContactData
    );
    const adminsReasonsData = computed(
      () => store.getters.getAdminsReasonsData
    );
    const adminsComplaintData = computed(
      () => store.getters.getAdminsComplaintData
    );
    const createMessageData = computed(
      () => store.getters.getCreateMessageData
    );
    const countriesData = computed(() => store.getters.getCountriesData);
    const clientsOptions = ref([]);
    const adminsOptions = ref([]);
    const adminsReasons = ref([]);
    const adminsComplaint = ref([]);
    const countriesOptions = ref([]);
    const files = ref([]);
    const messageObj = ref({
      responsible_person: [],
      client: [],
      competence: [],
      message_content: "",
    });

    const onFileChange = (e) => {
      files.value[0] = e.target.files[0];
    };

    const create = () => {
      showErrors.value = true;
      if (messageObj.value) {
        store.dispatch("createMessage", messageObj.value);
      }
    };

    store.dispatch("getClientsContact", { pk: route.params?.pk });
    store.dispatch("getMessagesDetails", { pk: route.params?.pk });
    store.dispatch("getAdminsContact");
    store.dispatch("getAdminsReasons", { complaintReason: true });
    store.dispatch("getAdminsReasons", { complaintReason: false });
    store.dispatch("getCountriesList");

    watch(
      () => createMessageData.value,
      async function (newValue) {
        if (newValue.status === 201) {
          if (files.value[0]) {
            await store.dispatch("addMessageFile", {
              message: newValue.data.pk,
              file: files.value[0],
            });
          }
          router
            .push({ name: "MessagesList", path: "/messages/list" })
            .then(() => {
              store.dispatch("showToaster", {
                type: "success",
                content: "Nowa sprawa została dodana!",
              });
            });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );
    watch(
      () => clientsContactData.value,
      function (newValue) {
        newValue.data.results?.forEach((element) => {
          clientsOptions.value.push({
            name: "[" + element.pk + "] " + element.full_name,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => adminsContactData.value,
      function (newValue) {
        newValue.data?.forEach((element) => {
          adminsOptions.value.push({
            name: "[" + element.pk + "] " + element.full_name,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => adminsReasonsData.value,
      function (newValue) {
        newValue.data?.forEach((element) => {
          adminsReasons.value.push({
            name: "[" + element.pk + "] " + element.name,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => adminsComplaintData.value,
      function (newValue) {
        newValue.data?.forEach((element) => {
          adminsComplaint.value.push({
            name: "[" + element.pk + "] " + element.name,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => countriesData.value,
      function (newValue) {
        newValue.data.data?.forEach((element) => {
          countriesOptions.value.push({
            name: element.name_pl,
            value: element.code,
          });
        });
      }
    );

    return {
      countryList,
      errorsAdminData,
      showErrors,
      create,
      clientsOptions,
      adminsOptions,
      adminsReasons,
      adminsComplaint,
      countriesOptions,
      messageObj,
      files,
      onFileChange,
    };
  },
  computed: {},
};
</script>
