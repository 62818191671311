<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Zamówienia</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Zamówienia</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Filtry</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-md-2 nopadding">
                <div class="form-group">
                  <label class="control-label">Miesiąc</label>
                  <div class="col-sm-10">
                    <VueDatePicker
                      :format="format"
                      v-model="filtersDate"
                      month-picker
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-2 nopadding">
                <div class="form-group">
                  <label class="control-label">Płatność</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="filters.payment_status"
                      mode="tags"
                      placeholder="Płatność"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :searchable="true"
                      :options="filterPayment"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
              <div class="col-md-2 nopadding">
                <div class="form-group">
                  <label class="control-label">Faktura papierowa</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="filters.invoice_paper"
                      mode="tags"
                      placeholder="Faktura papierowa"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :searchable="true"
                      :options="filterInvoice"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
              <div class="col-md-2 nopadding">
                <div class="form-group">
                  <label class="control-label">Wysyłka</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="filters.delivery_status"
                      mode="tags"
                      placeholder="Wysyłka"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :searchable="true"
                      :options="filterDelivery"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
              <div class="col-md-2 nopadding">
                <div class="form-group">
                  <label class="control-label">Status zamówienia</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="filters.status"
                      mode="tags"
                      placeholder="Status zamówienia"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :searchable="true"
                      :options="filterStatus"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
              <div class="col-md-2 nopadding">
                <div class="form-group">
                  <label class="control-label">Sklep</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="filters.company_localization"
                      mode="tags"
                      placeholder="Sklep"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :searchable="true"
                      :options="filterLocalization"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
              <div class="col-md-2 nopadding">
                <div class="form-group">
                  <label class="control-label">Zlecenia zewnętrzne</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="filters.is_external"
                      mode="tags"
                      placeholder="Zlecenia zewnętrzne"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :searchable="true"
                      :options="filterInvoice"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
              <div class="col-md-2 nopadding">
                <div class="form-group">
                  <label class="control-label">Domena</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="filters.site_pk"
                      mode="tags"
                      placeholder="Domena"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :searchable="true"
                      :options="filterSites"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
              <div class="col-md-2 nopadding">
                <div class="form-group">
                  <label class="control-label">Czy dodano notatkę?</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="filters.has_notes"
                      mode="tags"
                      placeholder="Czy dodano notatkę?"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :searchable="true"
                      :options="filterInvoice"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
              <div class="col-md-2 nopadding">
                <div class="form-group">
                  <label class="control-label">Tagi</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="filters.tag_pks"
                      mode="tags"
                      placeholder="Tagi"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :searchable="true"
                      :options="filterTags"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
              <div class="col-md-2 nopadding">
                <div class="form-group">
                  <label class="control-label">Fraza notatki</label>
                  <div class="col-sm-10">
                    <input
                      v-model="filtersNote"
                      type="text"
                      class="form-control inputClass"
                      placeholder="Fraza notatki"
                      required="required"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Zamówienia</h5>
          </div>
          <div class="ibox-content">
            <router-link to="/order/create">
              <button class="btn btn-sm btn-primary">Dodaj</button>
            </router-link>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <button @click="resetFilters" class="btn btn-primary btn-xs">
                  <i class="fa fa-refresh"></i>
                </button>
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :loading="loading"
                  @click-row="orderDetails"
                  alternating
                  multi-sort
                  show-index
                  border-cell
                  @update-sort="updateSort"
                  class="messages table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                  <template #item-actions="item">
                    <button
                      @click.stop="getAdminCard(item.pk)"
                      class="btn btn-primary btn-xs"
                      title="Pobierz kartę"
                    >
                      <i class="fa fa-list-alt"></i>
                    </button>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "OrderList",
  mixins: [],
  components: {
    Multiselect,
  },
  props: {},

  setup() {
    const store = useStore();
    const deleteMessageData = computed(
      () => store.getters.getDeleteMessageData
    );
    const ordersData = computed(() => store.getters.getOrdersData);
    const sitesData = computed(() => store.getters.getSitesData);
    const tagsData = computed(() => store.getters.getTagsData);
    let headers = [
      { text: "Numer zamówienia", value: "pk", search: true },
      {
        text: "E-mail",
        value: "client_email",
        sortable: true,
        search: true,
      },
      {
        text: "Klient",
        value: "client_name",
        sortable: true,
        search: true,
      },
      {
        text: "Sklep",
        value: "company_localization_display",
        sortable: true,
        search: true,
      },
      { text: "Język", value: "lang_display", sortable: true, search: true },
      {
        text: "Waluta",
        value: "currency",
        sortable: true,
        search: true,
      },
      {
        text: "Netto",
        value: "info.final_net_price",
        sortable: true,
        search: true,
      },
      { text: "Brutto", value: "brutto", sortable: true, search: true },
      {
        text: "Netto (bez wysyłki)",
        value: "info.net_price_no_delivery",
        sortable: true,
        search: true,
      },
      {
        text: "Netto wysyłka",
        value: "info.delivery_price_pln",
        sortable: true,
        search: true,
      },
      {
        text: "Stawka VAT",
        value: "info.vat_percent",
        sortable: true,
        search: true,
      },
      {
        text: "Wartość VAT",
        value: "info.vat_price",
        sortable: true,
        search: true,
      },
      {
        text: "Liczba adresów",
        value: "adresses_count",
        sortable: true,
        search: true,
      },
      {
        text: "Liczba paczek",
        value: "info.delivery_packages",
        sortable: true,
        search: true,
      },
      {
        text: "Metoda płatności",
        value: "payment_method_display",
        sortable: true,
        search: true,
      },
      {
        text: "Status zamówienia",
        value: "status_display",
        sortable: true,
        search: true,
      },
      {
        text: "Termin",
        value: "realization_date",
        sortable: true,
        search: true,
      },
      { text: "Data dodania", value: "date_added", sortable: true },
      { text: "Data modyfikacji", value: "date_modified", sortable: true },
      { text: "Akcje", value: "actions" },
    ];

    let items = ref([]);
    let itemsAll = ref(0);
    const showModal = ref(false);
    const deleteName = ref("");
    const permissionsList = ref([]);
    const loading = ref(true);
    const searchValue = ref({});
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const filters = ref({
      payment_status: [],
      invoice_paper: [],
      delivery_status: [],
      status: [],
      company_localization: [],
      is_external: [],
      site_pk: [],
      has_notes: [],
      tag_pks: [],
      notes_text: "",
    });
    const filtersDate = ref([]);
    const filtersNote = ref([]);
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });

    const filterSites = ref([]);
    const filterTags = ref([]);
    const filterPayment = ref([
      { name: "Opłacone", value: "paid" },
      { name: "Nieopłacone", value: "not_paid" },
    ]);

    const filterInvoice = ref([
      { name: "Tak", value: "True" },
      { name: "Nie", value: "False" },
    ]);

    const filterDelivery = ref([
      { name: "Niewysłana", value: "not_send" },
      { name: "Zatrzymana", value: "stopped" },
      { name: "Wysłana", value: "send" },
      { name: "Doręczona", value: "delivered" },
      { name: "Zwrócona", value: "returned" },
    ]);

    const filterStatus = ref([
      { name: "Nowe", value: "draft" },
      { name: "Negocjowane", value: "negotiated" },
      { name: "Zapisane", value: "saved" },
      { name: "Oczekuje na płatność", value: "waiting_for_payment" },
      { name: "W realizacji", value: "in_realization" },
      { name: "W produkcji", value: "in_production" },
      { name: "Zrealizowane", value: "done" },
      { name: "Anulowane", value: "canceled" },
      { name: "Usunięte", value: "deleted" },
    ]);

    const filterLocalization = ref([
      { name: "Polska", value: "PL" },
      { name: "Niemcy", value: "DE" },
    ]);

    const getOrders = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      await store.dispatch("getOrders", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
        month: filtersDate.value?.month + 1,
        year: filtersDate.value?.year,
        filters: filters.value,
        notes_text: filtersNote.value,
      });
    };

    const deleteMessage = (pk) => {
      showModal.value = false;
      store.dispatch("deleteMessage", {
        pk: pk,
      });
    };
    const cancel = () => {
      document.querySelector(".expanding")?.click();
    };

    const editMessage = (item) => {
      cancel();
      store.dispatch("getMessage", { pk: item.pk });
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getOrders();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getOrders();
    };

    const updateSort = () => {
      search.value = true;
    };

    const bodyRowClassNameFunction = (item) => {
      if (item.is_last_message_from_client) {
        return "yellow-row";
      }

      return false;
    };

    const orderDetails = (item) => {
      router.push({
        name: "OrderDetails",
        path: "/order/details/",
        params: { pk: item.pk },
      });
    };

    const format = (date) => {
      let month, year;
      month = ("0" + (date.getMonth() + 1)).slice(-2);
      year = date.getFullYear();
      return `${month}.${year}`;
    };

    const getAdminCard = (pk) => {
      if (pk) {
        store.dispatch("getOrderAdminCard", pk);
      }
    };

    getOrders();
    store.dispatch("getSites");
    store.dispatch("getTags");

    watch(
      () => [filters.value, filtersDate, filtersNote],
      function () {
        getOrders();
      },
      { deep: true }
    );

    watch(
      () => sitesData.value,
      function (newValue) {
        if (newValue.data) {
          newValue.data?.results.forEach((element) => {
            filterSites.value.push({
              name: element.name,
              value: element.pk,
            });
          });
        }
      }
    );

    watch(
      () => tagsData.value,
      function (newValue) {
        if (newValue.data) {
          newValue.data?.forEach((element) => {
            filterTags.value.push({
              name: element.name,
              value: element.pk,
            });
          });
        }
      }
    );

    watch(
      () => ordersData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => deleteMessageData.value,
      function (newValue) {
        if (newValue.status === 204) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Grupa rabatów została usunięta",
          });
        }
        getOrders();
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getOrders();
          }, 1000);
        }
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getOrders();
          isSearch.value = false;
        }
      }
    );

    return {
      headers,
      items,
      itemsAll,
      loading,
      serverOptions,
      permissionsList,
      showModal,
      deleteMessage,
      deleteName,
      cancel,
      editMessage,
      searchValue,
      isSearch,
      search,
      rowsPerPage,
      resetFilters,
      updateSort,
      bodyRowClassNameFunction,
      orderDetails,
      filters,
      filtersDate,
      filtersNote,
      format,
      filterPayment,
      filterInvoice,
      filterDelivery,
      filterStatus,
      filterLocalization,
      filterSites,
      filterTags,
      getAdminCard,
    };
  },
  computed: {},
};
</script>
