<template>
  <div id="preflight-preview" :style="{ height: getContainerHeight() + 'px' }">
    <div id="preview-controls" ref="controlsHeightRef">
      <div class="preview-control-wrapper">
        <span>Powiększanie</span>
        <div class="control zoom">
          <input
            id="id_zoom"
            type="range"
            min="1"
            max="10"
            step="0.1"
            v-model="zoomValue"
            @change="zoom"
          />
        </div>
      </div>
      <div class="preview-control-wrapper">
        <span>Obracanie strony</span>
        <div class="control rotate">
          <input
            id="id_rotate"
            type="range"
            min="0"
            max="180"
            step="1"
            v-model="rotateValue"
            :disabled="isMultiPageFile"
            @change="rotate"
          />
        </div>
      </div>
    </div>
    <div id="preview-tech-lines" ref="techLinesBlockRef" v-if="hasTechLines()">
      <div class="tech-lines-info">
        <i class="tooltip-info-icon"></i>
        <span>Pokaż / Ukryj linie technologiczne</span>
      </div>
      <div class="tech-lines-btns">
        <div
          v-if="bleed > 0"
          class="btn-multi-state"
          :class="{ checked: bleedTechLine.is_active }"
          @click="
            bleedTechLine.is_active = !bleedTechLine.is_active;
            applyBleedArea(bleedTechLine.is_active, currentPage);
          "
        >
          <div class="check-mark"></div>
          <div class="msc-label">Obszar spadu</div>
        </div>
        <div
          v-if="textMargin > 0"
          class="btn-multi-state"
          :class="{ checked: textMarginTechLine.is_active }"
          @click="
            textMarginTechLine.is_active = !textMarginTechLine.is_active;
            applyTextMargin(textMarginTechLine.is_active, currentPage);
          "
        >
          <div class="check-mark"></div>
          <div class="msc-label">Margines tekstu</div>
        </div>
        <div
          v-if="bleed > 0"
          class="btn-multi-state"
          :class="{ checked: cutTechLine.is_active }"
          @click="
            cutTechLine.is_active = !cutTechLine.is_active;
            applyCutLine(cutTechLine.is_active, currentPage);
          "
        >
          <div class="check-mark"></div>
          <div class="msc-label">Linia cięcia</div>
        </div>
        <div
          v-if="hasUvMask(currentPage)"
          class="btn-multi-state"
          :class="{ checked: uvTechLine.is_active }"
          @click="
            uvTechLine.is_active = !uvTechLine.is_active;
            applyUvMask(uvTechLine.is_active, currentPage);
          "
        >
          <div class="check-mark"></div>
          <div class="msc-label">Lakier UV wybiórczy</div>
        </div>
      </div>
    </div>
    <div
      id="preview-wrapper"
      ref="previewWrapperRef"
      :style="{ height: height + 'px' }"
    >
      <canvas
        id="ruler_canvas"
        ref="rulerCanvasRef"
        :width="width"
        :height="height"
      ></canvas>
    </div>
    <div id="preview-accept-block" ref="acceptBlockRef"></div>
    <div id="preview-slider" ref="previewSliderRef" v-if="isMultiPageFile">
      <div class="carousel-controls prev-controls">
        <div class="control-wrapper">
          <button
            id="to_first_page"
            class="btn btn-first-page"
            type="button"
            @click="
              changePage(0);
              resetPageNum();
            "
            :disabled="isFirstPage()"
          />
        </div>
        <div class="control-wrapper">
          <button
            id="to_prev_page"
            class="btn btn-prev-page"
            type="button"
            @click="
              changePage(currentPage - 1);
              resetPageNum();
            "
            :disabled="isFirstPage()"
          />
        </div>
        <div class="control-wrapper with-input">
          <input
            type="number"
            class="form-control"
            id="go_to"
            v-model="goToPageNum.value"
            @keydown="changePageOnEnter"
            @change="onPageChange()"
          />
          <label for="go_to" @click="changePage(goToPageNum.value - 1)">
            idź do
          </label>
        </div>
      </div>
      <div id="carousel" style="width: 637px">
        <div
          class="slider-item"
          v-for="(page, index) in frontCover()"
          :key="index"
          @click="
            changePage(index / coverIndexFix);
            resetPageNum();
          "
        >
          <div
            class="slider-icon"
            :style="{ visibility: page.is_accepted ? 'visible' : 'hidden' }"
          >
            <i class="accept-icon"></i>
          </div>
          <div class="slider-item-wrapper">
            <img :src="page.preview_thumbnail" />
          </div>
          <div class="slider-caption">
            <span>{{ index / coverIndexFix + 1 }}</span>
          </div>
        </div>
        <div
          class="slider-item"
          v-for="(page, index) in orderProductFile.pages"
          :key="index"
          @click="
            changePage((index + coverFix) / indexFix);
            resetPageNum();
          "
        >
          <div
            class="slider-icon"
            :style="{ visibility: page.is_accepted ? 'visible' : 'hidden' }"
          >
            <i class="accept-icon"></i>
          </div>
          <div class="slider-item-wrapper">
            <img :src="page.preview_thumbnail" />
          </div>
          <div class="slider-caption">
            <span>{{ (index + coverFix) / indexFix + 1 }}</span>
          </div>
        </div>
        <div
          class="slider-item"
          v-for="(page, index) in backCover()"
          :key="index"
          @click="
            changePage(index + orderProductFile.pages.length + coverFix);
            resetPageNum();
          "
        >
          <div
            class="slider-icon"
            :style="{ visibility: page.is_accepted ? 'visible' : 'hidden' }"
          >
            <i class="accept-icon"></i>
          </div>
          <div class="slider-item-wrapper">
            <img :src="page.preview_thumbnail" />
          </div>
          <div class="slider-caption">
            <span>{{ index + 1 }}</span>
          </div>
        </div>
      </div>
      <div class="carousel-controls next-controls">
        <div class="control-wrapper">
          <button
            id="to_next_page"
            class="btn btn-next-page"
            type="button"
            @click="
              changePage(currentPage + 1);
              resetPageNum();
            "
            :disabled="isLastPage()"
          />
        </div>
        <div class="control-wrapper">
          <button
            id="to_last_page"
            class="btn btn-last-page"
            type="button"
            @click="
              changePage(pageCount - 1);
              resetPageNum();
            "
            :disabled="isLastPage()"
          />
        </div>
      </div>
    </div>
    <div ref="errorsBlockRef">
      <div
        class="exception-block errors-block"
        v-if="pageHasErrors(currentPage)"
      >
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-9">
            <h4 class="exception-header errors-header">
              Raport błędów dla str {{ currentPageDisplay() }}
            </h4>
            <ul class="dashed">
              <li
                v-for="error in currentPageObj.errors"
                :key="error.id"
                v-bind="error.message"
              />
              <li
                v-for="error in currentPageMaskObj.errors"
                :key="error.id"
                v-bind="error.message + ' (dotyczy maski UV)'"
              />
            </ul>
          </div>
        </div>
      </div>
      <div
        class="exception-block warnings-block"
        v-if="pageHasWarnings(currentPage)"
      >
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-9">
            <h4 class="exception-header warnings-header">
              Raport ostrzeżeń dla str {{ currentPageDisplay() }}
            </h4>
            <ul v-if="currentPageObj && currentPageMaskObj" class="dashed">
              <li
                v-for="warning in currentPageObj.warnings"
                :key="warning.id"
                v-bind="warning.message"
              />
              <li
                v-for="warning in currentPageMaskObj.warnings"
                :key="warning.id"
                v-bind="warning.message + ' (dotyczy maski UV)'"
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

export default {
  props: {
    orderProductFile: {
      type: Object,
      required: true,
    },
    grossSize: {
      type: Object,
      required: true,
    },
    bleed: {
      type: Number,
      required: true,
    },
    textMargin: {
      type: Number,
      required: true,
    },
    productKind: {
      type: String,
      required: true,
    },
    techLines: {
      type: Array,
      required: true,
    },
    pagesLoaded: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    bookletUv: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    console.log(props);
    var camera,
      controls,
      scene,
      renderer,
      materials,
      object,
      objectBBox,
      container,
      carousel,
      textureCanvas,
      techLineCanvas,
      pages;
    const store = useStore();
    const orderProductFileData = computed(
      () => store.getters.getOrderProductFileData
    );
    const orderProductFileData2 = computed(
      () => store.getters.getOrderProductFileData2
    );
    const previewWrapperRef = ref(null);
    const rulerCanvasRef = ref(null);
    const previewSliderRef = ref(null);
    const acceptBlockRef = ref(null);
    const errorsBlockRef = ref(null);
    const techLinesBlockRef = ref(null);
    const controlsHeightRef = ref(null);
    const isMultiPageFile = ref(false);
    const width = ref();
    const indicatorHeight = ref(40);
    const previewWidth = ref();
    const height = ref(600);
    const previewHeight = ref(560);
    const rotateValue = ref(0);
    const zoomValue = ref(1);
    const sizes = ref(null);
    const pagesLoadedC = ref(props.pagesLoaded);
    const techLinesC = ref(props.techLines);
    const shiftValue = {
      x: 0,
      y: 0,
    };
    const precisionFactor = ref(1000);
    const fileMMSize = ref(props.grossSize.width);
    const fileMMNetSize = ref(props.grossSize.width - props.bleed * 2);
    const fileGrossHeight = ref(props.grossSize.height);
    const bleedInPx = ref(0);
    const initialScale = ref();
    const offset = ref({});
    const pxValue = ref(4);
    const scale = ref(1);
    const scaledPxValue = ref(
      pxValue.value * precisionFactor.value * scale.value
    );
    const canvasWidth = ref(0);
    const zoomedPxValue = ref(pxValue.value);
    const spaceFix = ref(30);
    const rulerFix = ref(spaceFix.value * precisionFactor.value * scale.value);
    const fifthSep = ref(5);
    const tenthSep = ref(10);
    const ratio = ref(0);
    const firstPosition = ref();
    const positionFactor = ref(0);
    const targetX = ref(0);
    const dpi = ref(300);
    const inchInMm = ref(25.4);
    const scaleFactor = ref(1);
    const maxSize = ref(1280);
    const previewGrossSize = ref(null);
    const isHorizontal = ref(false);
    const currentPage = ref(0);
    const previousPage = ref(0);
    const currentPageObj = ref(
      props.bookletUv
        ? props.orderProductFile.cover_pages[0]
        : props.orderProductFile.pages[0]
    );

    const currentPageMaskObj = ref(
      props.bookletUv
        ? props.orderProductFile.cover_pages[1]
        : props.productKind === "uv"
        ? props.orderProductFile.pages[1]
        : null
    );

    const indexFix = ref(props.productKind === "uv" ? 2 : 1);
    const coverFix = ref(props.bookletUv ? 2 : 0);
    const coverIndexFix = ref(props.bookletUv ? 2 : 1);

    const pageCount = ref(
      props.bookletUv
        ? Math.ceil(props.orderProductFile.pages.length / indexFix.value) + 4
        : Math.ceil(props.orderProductFile.pages.length / indexFix.value)
    );

    const goToPageNum = ref({
      value: null,
    });

    const uvTechLine = ref({
      is_active: null,
    });
    const bleedTechLine = ref({
      is_active: false,
    });
    const textMarginTechLine = ref({
      is_active: false,
    });
    const cutTechLine = ref({
      is_active: false,
    });

    for (var i = 0; i < techLinesC.value.length; ++i) {
      if (techLinesC.value[i].is_active !== null) {
        techLinesC.value[i].is_active = null;
      }
    }

    const animationInProgress = ref(true);

    onMounted(() => {
      if (previewWrapperRef.value?.clientWidth) {
        width.value = previewWrapperRef.value.clientWidth;
        previewWidth.value = width.value - indicatorHeight.value;
        previewHeight.value = height.value - indicatorHeight.value;
        firstPosition.value =
          previewWidth.value / 2 - spaceFix.value - indicatorHeight.value + 1;
      }
      isMultiPageFile.value = checkFileType();
      window.addEventListener("resize", onWindowResize, false);
      init();
    });
    const init = () => {
      container = previewWrapperRef.value;
      var FOV = (2 * Math.atan(560 / (2 * 900)) * 180) / Math.PI;

      camera = new THREE.PerspectiveCamera(
        FOV,
        previewWidth.value / previewHeight.value,
        0.1,
        2000
      );

      camera.position.x = 0;
      camera.position.y = 0;
      camera.position.z = 1000;

      renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true,
      });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(previewWidth.value, previewHeight.value);
      container.appendChild(renderer.domElement);
      controls = new OrbitControls(camera, renderer.domElement);
      controls.addEventListener("change", render);
      controls.addEventListener("change", onMove);
      controls.enableZoom = false;
      controls.enableRotate = false;

      scene = new THREE.Scene();
      materials = [];

      var URL = window.URL || window.webkitURL,
        loadedTexturesCanvas = [],
        imageUrls = [],
        canvasTextures = [];

      store.dispatch("getOrderProductFilePages", {
        pk: props.bookletUv
          ? props.orderProductFile.cover_pages[0].pk
          : props.orderProductFile.pages[0].pk,
      });

      watch(
        () => orderProductFileData.value,
        function (response) {
          var binaryData = [];
          binaryData.push(response.data);
          imageUrls.push({
            page: props.bookletUv
              ? props.orderProductFile.cover_pages[0].pk
              : props.orderProductFile.pages[0].pk,
            url: URL.createObjectURL(
              new Blob(binaryData, { type: "image/png" })
            ),
          });
          createTextureCanvasObjs(loadedTexturesCanvas, imageUrls, URL);

          Promise.all(loadedTexturesCanvas).then((patternCanvasTexture) => {
            // $q.all(loadedTexturesCanvas).then(function (patternCanvasTexture) {
            var i = 1;
            canvasTextures.push(patternCanvasTexture[0]);

            if (props.bookletUv) {
              for (
                i = 1;
                i < props.orderProductFile.cover_pages.length / 2;
                i++
              ) {
                canvasTextures.push(
                  prepareTextureCanvasObjs(
                    patternCanvasTexture[0].canvas,
                    i,
                    true
                  )
                );
                pagesLoadedC.value++;
              }
              i = 0;
            }

            for (; i < props.orderProductFile.pages.length; i++) {
              canvasTextures.push(
                prepareTextureCanvasObjs(
                  patternCanvasTexture[0].canvas,
                  i,
                  false
                )
              );
              pagesLoadedC.value++;
            }

            if (props.bookletUv) {
              for (
                i = props.orderProductFile.cover_pages.length / 2;
                i < props.orderProductFile.cover_pages.length;
                i++
              ) {
                canvasTextures.push(
                  prepareTextureCanvasObjs(
                    patternCanvasTexture[0].canvas,
                    i,
                    true
                  )
                );
                pagesLoadedC.value++;
              }
            }
            prepare3dObjects(canvasTextures);
            loadOthersImages();
          });

          return imageUrls[0];
        }
      );
    };

    const render = () => {
      renderer.render(scene, camera);
    };

    const animate = () => {
      console.log("ANIMATE");
      controls.update();
      render();
      // props.loading = false;
      animationInProgress.value = false;
    };

    const rotate = () => {
      rotateObject(rotateValue.value);
    };

    const zoom = () => {
      zoomValue.value = parseFloat(zoomValue.value);
      if (renderer && scene) {
        var scaleFactorX = !bleedTechLinesActive() ? initialScale.value?.x : 1,
          scaleFactorY = !bleedTechLinesActive() ? initialScale.value?.y : 1;
        object.scale.x = zoomValue.value * scaleFactorX;
        object.scale.y = zoomValue.value * scaleFactorY;

        var width = objectBBox.max.x - objectBBox.min.x;
        var height = objectBBox.max.y - objectBBox.min.y;

        var x =
          -(previewWidth.value / 2) +
          (width * zoomValue.value * scaleFactorX) / 2 +
          spaceFix.value;
        var y =
          previewHeight.value / 2 -
          (height * zoomValue.value * scaleFactorY) / 2 -
          spaceFix.value;

        object.position.x = x;
        object.position.y = y;

        controls.reset();
        if (isMultiPageFile.value) {
          controls.pan(firstPosition.value, 0);
          if (currentPage.value !== 0 && currentPage.value % 2 !== 0) {
            controls.pan(sizes.value?.width / positionFactor.value, 0);
          }
        }
        updateMultiplicitySeparators();
        updateRuler();
        renderer.render(scene, camera);
      }
    };

    const changePage = (pageNum) => {
      console.log(currentPage.value, pageNum);
      if (currentPage.value === pageNum) {
        return;
      }
      if (pageNum >= 0 && pageNum <= pageCount.value - 1) {
        var pageObj = getPageByNum(pageNum);
        previousPage.value = currentPage.value;
        currentPage.value = pageNum;
        currentPageObj.value = pageObj.page;
        if ((isUv() || pageObj.isCover) && pageObj.pageMask) {
          currentPageMaskObj.value = pageObj.pageMask;
        } else {
          currentPageMaskObj.value = null;
        }

        setTimeout(() => {
          var previousPageObj = pageObj.page;
          if (uvTechLine.value.is_active !== null) {
            uvTechLine.value.is_active = null;
            applyUvMask(null, previousPage.value);
          }
          if (bleedTechLine.value.is_active !== null) {
            bleedTechLine.value.is_active = null;
            applyBleedArea(null, previousPage.value);
          }
          if (textMarginTechLine.value.is_active !== null) {
            textMarginTechLine.value.is_active = null;
            applyTextMargin(null, previousPage.value);
          }
          if (cutTechLine.value.is_active !== false) {
            cutTechLine.value.is_active = false;
            applyCutLine(null, previousPage.value);
          }
          for (var i = 0; i < techLinesC.value.length; ++i) {
            if (techLinesC.value[i].is_active !== null) {
              techLinesC.value[i].is_active = null;
              applyTechLine(techLinesC.value[i], previousPage.value, i);
            }
          }
          if (pageHasErrors(previousPage.value)) {
            if (previousPageObj.errors_active !== null) {
              previousPageObj.errors_active = null;
              applyErrors(null, previousPage.value);
            }
          }
          if (pageHasWarnings(previousPage.value)) {
            if (previousPageObj.warnings_active !== null) {
              previousPageObj.warnings_active = null;
              applyWarnings(null, previousPage.value);
            }
          }
        }, 0);
        if (isMultiPageFile.value) {
          updateScene();
          carousel.slick("slickGoTo", currentPage.value, true);
        }
      }
    };

    const isFirstPage = () => {
      return currentPage.value === 0;
    };

    const isLastPage = () => {
      return currentPage.value === pageCount.value - 1;
    };

    if (isMultiPageFile.value) {
      setTimeout(() => {
        carousel = document.getElementById("carousel").slick({
          dots: false,
          arrows: false,
          variableWidth: true,
          centerMode: true,
          centerPadding: "0",
          infinite: false,
          draggable: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        });
      }, 150);
    }

    const getPageByNum = (pageNum) => {
      var currPageNum, currPageMaskNum, pages, isCover;

      if (props.bookletUv && (pageNum < coverFix.value || pageNum >= props.orderProductFile.pages.length + coverFix.value)) {  // eslint-disable-line
        if (pageNum < coverFix.value) {
          currPageNum = pageNum * coverIndexFix.value;
          currPageMaskNum = pageNum * coverIndexFix.value + 1;
        } else {
          currPageNum = (pageNum - props.orderProductFile.pages.length - coverFix.value) * coverIndexFix.value + (props.orderProductFile.cover_pages.length / 2);  // eslint-disable-line
          currPageMaskNum = currPageNum + 1;
        }
        pages = props.orderProductFile.cover_pages;
        isCover = true;
      } else {
        currPageNum = pageNum * indexFix.value - coverFix.value;
        currPageMaskNum = pageNum * indexFix.value - coverFix.value + 1;
        pages = props.orderProductFile.pages;
        isCover = false;
      }

      return {
        page: pages[currPageNum],
        pageMask: pages[currPageMaskNum],
        isCover: isCover,
      };
    };

    const getPageTextureIndex = (pageNum) => {
      var pageIndex;
      if (props.bookletUv && (pageNum < coverFix.value || pageNum >= props.orderProductFile.pages.length + coverFix.value)) {  // eslint-disable-line
        if (pageNum < coverFix.value) {
          pageIndex = pageNum * coverIndexFix.value;
        } else {
          pageIndex = (pageNum - props.orderProductFile.pages.length - coverFix.value) * coverIndexFix.value + props.orderProductFile.pages.length + (props.orderProductFile.cover_pages.length / 2);  // eslint-disable-line
        }
      } else {
        pageIndex =
          pageNum * indexFix.value -
          coverFix.value +
          props.orderProductFile.cover_pages.length / 2;
      }

      return pageIndex;
    };

    const onWindowResize = () => {
      if (previewWrapperRef.value?.clientWidth) {
        width.value = previewWrapperRef.value?.clientWidth;
      }
      previewWidth.value = width.value - indicatorHeight.value;
      previewHeight.value = height.value - indicatorHeight.value;
      camera.aspect = previewWidth.value / previewHeight.value;
      camera.updateProjectionMatrix();
      renderer.setSize(previewWidth.value, previewHeight.value);
      if (props.bleed > 0) {
        resetGeometriesSizes();
      }

      controls.reset();
      if (isMultiPageFile.value) {
        controls.pan(firstPosition.value, 0);
        if (currentPage.value !== 0 && currentPage.value % 2 !== 0) {
          controls.pan(sizes.value.width / positionFactor.value, 0);
        }
      }

      animate();
    };

    const prepare3dObjects = (canvasTextures) => {
      textureCanvas = [];
      canvasTextures.forEach((canvasTexture) => {
        if (canvasTexture.texture) {
          canvasTexture.texture.anisotropy =
            renderer.capabilities.getMaxAnisotropy();
          canvasTexture.texture.magFilter = THREE.NearestFilter;
          canvasTexture.texture.minFilter = THREE.NearestFilter;
          canvasTexture.texture.needsUpdate = true;
          materials.push(
            new THREE.MeshBasicMaterial({
              map: canvasTexture.texture,
              side: THREE.FrontSide,
              transparent: true,
            })
          );
        }
        textureCanvas.push(canvasTexture);
      });

      bleedInPx.value = Math.ceil(props.bleed * Math.round(((dpi.value / inchInMm.value) * materials[0].map.image.width / previewGrossSize.value.width) * precisionFactor.value) / precisionFactor.value); // eslint-disable-line
      sizes.value = fixSize(materials[0].map);
      object = new THREE.Group();
      var materialsForPages = [],
        i;

      for (i = 0; i < materials.length; i += 2) {
        materialsForPages.push(materials.slice(i, i + 2));
      }

      materialsForPages = materialsForPages.reverse();

      pages = [];
      for (i = 0; i < materialsForPages.length; i++) {
        var page = new THREE.Object3D();
        console.log(sizes);
        var g1 = new THREE.PlaneGeometry(sizes.value.width, sizes.value.height),
          m1 = new THREE.Mesh(g1, materialsForPages[i][0]),
          eg1 = new THREE.EdgesGeometry(m1.geometry),
          em1 = new THREE.LineBasicMaterial({
            color: 0x000000,
            linewidth: 1,
          }),
          wf1 = new THREE.LineSegments(eg1, em1);
        m1.add(wf1);

        page.add(m1);
        if (materialsForPages[i][1]) {
          var g2 = new THREE.PlaneGeometry(
              sizes.value.width,
              sizes.value.height
            ),
            m2 = new THREE.Mesh(g2, materialsForPages[i][1]),
            eg2 = new THREE.EdgesGeometry(m2.geometry),
            em2 = new THREE.LineBasicMaterial({
              color: 0x000000,
              linewidth: 1,
            }),
            wf2 = new THREE.LineSegments(eg2, em2);
          m2.add(wf2);
          m2.rotation.y = Math.PI;
          page.add(m2);
        }
        page.renderOrder = object.children.length;
        pages.push(page);
      }

      for (var k = pages.length - 1; k >= 0; k--) {
        object.add(pages[k]);
      }

      objectBBox = new THREE.Box3().setFromObject(object);

      pxValue.value =
        Math.round(
          (dpi.value / inchInMm.value) *
            scaleFactor.value *
            precisionFactor.value
        ) / precisionFactor.value;
      scale.value = zoomValue.value * 10;
      scaledPxValue.value = pxValue.value * precisionFactor.value * scale.value;
      rulerFix.value = spaceFix.value * precisionFactor.value * scale.value;

      object.position.x =
        -(previewWidth.value / 2 - sizes.value.width / 2) + spaceFix.value;
      object.position.y =
        previewHeight.value / 2 - sizes.value.height / 2 - spaceFix.value;
      object.position.z = 100;

      scene.add(object);

      if (props.bleed > 0) {
        resetGeometriesSizes();
        fixBleedArea();
      }

      if (isMultiPageFile.value) {
        controls.pan(firstPosition.value, 0);
        targetX.value = controls.target.x;
        positionFactor.value = controls.target.x / firstPosition.value - 0.02;
      } else {
        // controls.pan(1, 0);
        // controls.pan(0, 0);
      }

      initialScale.value = {
        x: object.scale.x,
        y: object.scale.y,
      };

      updateRuler();
      onWindowResize();

      var loadedTechLinesCanvas = [];
      techLinesC.value.forEach((techLine) => {
        var canvas = document.createElement("canvas"),
          ctx = canvas.getContext("2d");
        loadedTechLinesCanvas.push(
          new Promise((resolve, reject) => {
            // $q(function (resolve, reject) {
            var img = new Image();
            img.crossOrigin = "Anonymous";
            img.onload = function () {
              canvas.width = textureCanvas[0].canvas.width;
              canvas.height = textureCanvas[0].canvas.height;
              var scaleX = canvas.width / img.width,
                scaleY = canvas.height / img.height;
              ctx.scale(-scaleX, scaleY);
              ctx.drawImage(img, -img.width, 0);
              var flippedData = ctx.getImageData(
                0,
                0,
                canvas.width,
                canvas.height
              );
              ctx.setTransform(1, 0, 0, 1, 0, 0);
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.scale(scaleX, scaleY);
              ctx.drawImage(img, 0, 0);
              var originData = ctx.getImageData(
                0,
                0,
                canvas.width,
                canvas.height
              );
              resolve({
                canvas: canvas,
                ctx: ctx,
                originData: originData,
                flippedData: flippedData,
              });
            };
            img.onerror = function (xhr) {
              reject(new Error(xhr + "An error occurred while loading"));
            };
            img.src = techLine.tech_line_object.image_url;
          })
        );
      });
      Promise.all(loadedTechLinesCanvas).then((canvasTechLines) => {
        techLineCanvas = canvasTechLines;
      });

      // $q.all(loadedTechLinesCanvas).then(
      //   function (canvasTechLines) {
      //     techLineCanvas = canvasTechLines;
      //   },
      //   function (err) {
      //     console.log("err: ", err);
      //   }
      // );

      animate();

      if (isMultiPageFile.value) {
        animationInProgress.value = true;

        var angle = 0,
          rotateInterval = setInterval(function () {
            if (angle < 360) {
              angle++;
              rotateObject(angle);
              // scope.$apply();
            } else {
              angle = 0;
              rotateObject(angle);
              clearInterval(rotateInterval);
              animationInProgress.value = false;
              // scope.$apply();
            }
          }, 6);
      }
    };

    const prepareTextureCanvasObjs = (patternCanvas, index, isCover) => {
      var canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      canvas.width = patternCanvas.width;
      canvas.height = patternCanvas.height;

      var originData = ctx.getImageData(0, 0, canvas.width, canvas.height),
        textString = "Ładowanie tekstur";
      // textWidth = ctx.measureText(textString).width;

      ctx.font = "50px Arial";
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "#9f826f";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillText(textString, canvas.width / 2, canvas.height / 2);

      var data = {
          canvas: canvas,
          ctx: ctx,
          originData: originData,
          imgLoaded: false,
        },
        indexFix1 = isCover ? 2 : indexFix.value;

      if (index % indexFix1 === 0) {
        data["texture"] = new THREE.Texture(canvas);
      }

      return data;
    };

    const loadOthersImages = () => {
      var i = 1,
        j = 1;
      pagesLoadedC.value = 1;

      if (props.bookletUv) {
        for (i = 1; i < props.orderProductFile.cover_pages.length / 2; i++) {
          loadImage(i, props.orderProductFile.cover_pages[i].pk, true);
        }
        j = 0;
      }

      for (j = 0; j < props.orderProductFile.pages.length; j++, i++) {
        loadImage(i, props.orderProductFile.pages[j].pk, false);
      }

      if (props.bookletUv) {
        for (
          j = props.orderProductFile.cover_pages.length / 2;
          j < props.orderProductFile.cover_pages.length;
          j++, i++
        ) {
          loadImage(i, props.orderProductFile.cover_pages[j].pk, true);
        }
      }
    };

    const loadImage = async (i, pk, isCover) => {
      var indexFix1 = isCover ? 2 : indexFix.value;
      await store.dispatch("getOrderProductFilePages", {
        pk: pk,
        second: true,
      });
      watch(
        () => orderProductFileData2.value,
        function (response) {
          var binaryData = [];
          binaryData.push(response.data);
          var img = new Image(),
            imgUrl = URL.createObjectURL(new Blob(binaryData));
          img.crossOrigin = "Anonymous";
          img.onload = function () {
            textureCanvas[i - 1].ctx.drawImage(
              img,
              0,
              0,
              textureCanvas[i - 1].canvas.width,
              textureCanvas[i - 1].canvas.height,
              0,
              0,
              textureCanvas[i - 1].canvas.width,
              textureCanvas[i - 1].canvas.height
            );
            textureCanvas[i - 1].originData = textureCanvas[
              i - 1
            ].ctx.getImageData(
              0,
              0,
              textureCanvas[i - 1].canvas.width,
              textureCanvas[i - 1].canvas.height
            );
            if ((i - 1) % indexFix1 === 0) {
              textureCanvas[i - 1].texture.needsUpdate = true;
            }
            URL.revokeObjectURL(imgUrl);
            pagesLoadedC.value++;
            render();
          };
          img.onerror = function (xhr) {
            console.log(xhr + "An error occurred while loading");
          };
          img.src = imgUrl;
        }
      );

      // orderProductFilesPagesDataSrvc.getPagePreview({
      //     pk: pk
      // }).$promise.then(
      //   (function (i) {
      //     return function (response) {
      //       var img = new Image(),
      //         imgUrl = URL.createObjectURL(response.data);
      //       img.crossOrigin = "Anonymous";
      //       img.onload = function () {
      //         textureCanvas[i].ctx.drawImage(img, 0, 0, textureCanvas[i].canvas.width, textureCanvas[i].canvas.height, 0, 0, textureCanvas[i].canvas.width, textureCanvas[i].canvas.height);
      //         textureCanvas[i].originData = textureCanvas[i].ctx.getImageData(0, 0, textureCanvas[i].canvas.width, textureCanvas[i].canvas.height);
      //         if (i % indexFix1 === 0) {
      //           textureCanvas[i].texture.needsUpdate = true;
      //         }
      //         URL.revokeObjectURL(imgUrl);
      //         pagesLoaded.value++;
      //         render();
      //       };
      //       img.onerror = function (xhr) {
      //         console.log(xhr + 'An error occurred while loading');
      //       };
      //         img.src = imgUrl;
      //     };
      //     } (i)),
      //     function (error) {
      //       return error;
      //     }
      //   );
    };

    const createTextureCanvasObjs = (loadedTexturesCanvas, imageUrls, URL) => {
      imageUrls.forEach((imageUrl, index) => {
        var canvas = document.createElement("canvas"),
          ctx = canvas.getContext("2d");
        // $q(function (resolve, reject) {
        var img = new Image();
        img.crossOrigin = "Anonymous";
        var height, width, size;
        if (img.height > img.width) {
          width =
            props.grossSize.preview_height > props.grossSize.preview_width
              ? props.grossSize.preview_width
              : props.grossSize.preview_height;
          height =
            props.grossSize.preview_height > props.grossSize.preview_width
              ? props.grossSize.preview_height
              : props.grossSize.preview_width;
        } else {
          width =
            props.grossSize.preview_width > props.grossSize.preview_height
              ? props.grossSize.preview_width
              : props.grossSize.preview_height;
          height =
            props.grossSize.preview_width > props.grossSize.preview_height
              ? props.grossSize.preview_height
              : props.grossSize.preview_width;
        }
        if (previewGrossSize.value === null) {
          previewGrossSize.value = {
            width: width,
            height: height,
            imgWidth: img.width,
            imgHeight: img.height,
          };
        }
        size = resize(width, height);

        canvas.width = size.width;
        canvas.height = size.height;
        ctx.drawImage(
          img,
          0,
          0,
          canvas.width,
          canvas.height,
          0,
          0,
          canvas.width,
          canvas.height
        );
        var originData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        var data = {
          canvas: canvas,
          ctx: ctx,
          originData: originData,
        };
        if (index % indexFix.value === 0) {
          data["texture"] = new THREE.Texture(canvas);
        }
        URL.revokeObjectURL(imageUrl.url);
        pagesLoadedC.value++;
        loadedTexturesCanvas.push(data);
        img.src = imageUrl.url;
      });
    };

    const resize = (width, height) => {
      var ratio = 0;
      if (width > maxSize.value) {
        ratio = maxSize.value / width;
        height = height * ratio;
        width = width * ratio;
      }

      if (height > maxSize.value) {
        ratio = maxSize.value / height;
        width = width * ratio;
        height = height * ratio;
      }

      return { width: width, height: height };
    };

    const fixSize = (texture) => {
      var width = texture.image.width,
        height = texture.image.height;
      var sizeFix = 0.99,
        divisor;

      if (height > width) {
        isHorizontal.value = false;
        if (height > (previewHeight.value - spaceFix.value) * sizeFix) {
          ratio.value =
            ((previewHeight.value - spaceFix.value) * sizeFix) / height;
          width = Math.round(width * ratio.value);
          height = Math.round(height * ratio.value);
        }
        divisor = isHorizontal.value && isMultiPageFile.value ? 0.495 : 1;
        if (width > (previewWidth.value - spaceFix.value) * sizeFix * divisor) {
          ratio.value =
            ((previewWidth.value - spaceFix.value) * sizeFix * divisor) / width;
          height = Math.round(height * ratio.value);
          width = Math.round(width * ratio.value);
        }
        fileMMSize.value =
          props.grossSize.height > props.grossSize.width
            ? props.grossSize.width
            : props.grossSize.height;
        fileGrossHeight.value =
          props.grossSize.height > props.grossSize.width
            ? props.grossSize.height
            : props.grossSize.width;
        scaleFactor.value =
          width /
          (props.grossSize.preview_height > props.grossSize.preview_width
            ? props.grossSize.preview_width
            : props.grossSize.preview_height);
      } else {
        isHorizontal.value = true;
        divisor = isHorizontal.value && isMultiPageFile.value ? 0.495 : 1;
        if (width > (previewWidth.value - spaceFix.value) * sizeFix * divisor) {
          ratio.value =
            ((previewWidth.value - spaceFix.value) * sizeFix * divisor) / width;
          height = Math.round(height * ratio.value);
          width = Math.round(width * ratio.value);
        }
        if (height > (previewHeight.value - spaceFix.value) * sizeFix) {
          ratio.value =
            ((previewHeight.value - spaceFix.value) * sizeFix) / height;
          width = Math.round(width * ratio.value);
          height = Math.round(height * ratio.value);
        }
        fileMMSize.value =
          props.grossSize.width > props.grossSize.height
            ? props.grossSize.width
            : props.grossSize.height;
        fileGrossHeight.value =
          props.grossSize.width > props.grossSize.height
            ? props.grossSize.height
            : props.grossSize.width;
        scaleFactor.value =
          width /
          (props.grossSize.preview_width > props.grossSize.preview_height
            ? props.grossSize.preview_width
            : props.grossSize.preview_height);
      }
      fileMMNetSize.value = fileMMSize.value - props.bleed * 2;

      updateMultiplicitySeparators();

      return {
        width: width,
        height: height,
      };
    };

    const updateRuler = () => {
      var rulerCanvas = rulerCanvasRef.value;
      var rulerCtx = rulerCanvas.getContext("2d");
      rulerCtx.clearRect(0, 0, rulerCanvas.width, rulerCanvas.height);
      rulerCtx.fillStyle = rulerCtx.strokeStyle = "#a1826e";

      rulerCtx.beginPath();
      scale.value = zoomValue.value * 10;
      scaledPxValue.value = pxValue.value * precisionFactor.value * scale.value;
      canvasWidth.value = rulerCanvas.width * 10 * precisionFactor.value;
      var sepFix = tenthSep.value / 10;

      zoomedPxValue.value = pxValue.value * zoomValue.value * sepFix;
      for (
        var i = 0;
        i < canvasWidth.value;
        i += scaledPxValue.value * sepFix
      ) {
        var scaledValue = (i + rulerFix.value) / 10 / precisionFactor.value;
        var tenthValue = scaledPxValue.value * tenthSep.value;
        var fifthValue = scaledPxValue.value * fifthSep.value;

        var shiftedPositionX =
          i +
          scaledPxValue.value *
            sepFix *
            Math.ceil(shiftValue.x / zoomedPxValue.value);
        var shiftedPositionY =
          i -
          scaledPxValue.value *
            sepFix *
            Math.floor(shiftValue.y / zoomedPxValue.value);

        var shiftX =
          shiftedPositionX % tenthValue === 0
            ? 10
            : shiftedPositionX % fifthValue === 0
            ? 15
            : 25;
        var shiftY =
          shiftedPositionY % tenthValue === 0
            ? 10
            : shiftedPositionY % fifthValue === 0
            ? 15
            : 25;

        if (shiftedPositionX % tenthValue === 0) {
          var labelX = String(shiftedPositionX / scaledPxValue.value);
          var fixX = 3 + (labelX.length - 1 * 2);
          rulerCtx.fillText(
            labelX,
            scaledValue + indicatorHeight.value - fixX,
            8
          );
        }

        if (shiftedPositionY % tenthValue === 0) {
          var labelY = String(shiftedPositionY / scaledPxValue.value);
          var fixY = 3 + (labelY.length - 1 * 2);

          rulerCtx.save();
          rulerCtx.translate(8, scaledValue + indicatorHeight.value + fixY);
          rulerCtx.rotate((-90 * Math.PI) / 180);
          rulerCtx.fillText(labelY, 0, 0);
          rulerCtx.restore();
        }

        rulerCtx.moveTo(scaledValue + indicatorHeight.value, shiftX);
        rulerCtx.lineTo(
          scaledValue + indicatorHeight.value,
          indicatorHeight.value
        );

        rulerCtx.moveTo(shiftY, scaledValue + indicatorHeight.value);
        rulerCtx.lineTo(
          indicatorHeight.value,
          scaledValue + indicatorHeight.value
        );
      }
      rulerCtx.stroke();
    };

    const onMove = () => {
      shiftValue.x = controls.target.x;
      shiftValue.y = controls.target.y;
      updateRuler();
    };

    const getContainerHeight = () => {
      setTimeout(() => {
        var previewSlider = previewSliderRef.value,
          acceptBlock = acceptBlockRef.value,
          errorsBlock = errorsBlockRef.value,
          techLinesBlock = techLinesBlockRef.value,
          controlsHeight = controlsHeightRef.value.offsetHeight,
          sliderHeight = previewSlider ? previewSlider.offsetHeight : 0,
          acceptBlockHeight = acceptBlock ? acceptBlock.offsetHeight : 0,
          errorsBlockHeight = errorsBlock ? errorsBlock.offsetHeight : 0,
          techLinesHeight = techLinesBlock ? techLinesBlock.offsetHeight : 0;
        return (
          height.value +
          controlsHeight +
          sliderHeight +
          acceptBlockHeight +
          errorsBlockHeight +
          techLinesHeight
        );
      }, 1);
    };

    const roundUpToTen = (n) => {
      if (Math.ceil(n) % 10 === 0) {
        return Math.ceil(n);
      } else {
        return Math.round((n + 10 / 2) / 10) * 10;
      }
    };

    const updateMultiplicitySeparators = () => {
      tenthSep.value = roundUpToTen(fileMMSize.value / 10);
      tenthSep.value -= zoomValue.value * tenthSep.value - tenthSep.value;
      tenthSep.value = roundUpToTen(tenthSep.value);
      if (tenthSep.value < 10) tenthSep.value = 10;
      fifthSep.value = tenthSep.value / 2;
    };

    const checkFileType = () => {
      if (props.productKind === "uv") {
        return props.orderProductFile.pages.length > 4;
      } else {
        return props.orderProductFile.pages.length > 2;
      }
    };

    const updateScene = () => {
      var i;
      if (currentPage.value > previousPage.value) {
        for (i = previousPage.value + 1; i <= currentPage.value; i++) {
          updatePage(i);
        }
      } else {
        for (i = previousPage.value - 1; i >= currentPage.value; i--) {
          updatePage(i);
        }
      }
    };

    const updatePage = (currentPage) => {
      var currentCardIndex = Math.floor(currentPage / 2);
      var currentPageIndex = currentPage % 2 === 0 ? 0 : 1;

      object.children[currentCardIndex].visible = true;
      object.children[currentCardIndex].children[
        currentPageIndex
      ].material.opacity = 1;
      if (currentPage % 2 !== 0) {
        object.children[currentCardIndex].rotation.y = Math.PI;
        object.children[currentCardIndex].position.set(0, 0, 0);
        if (object.children[currentCardIndex - 1]) {
          object.children[currentCardIndex - 1].rotation.set(0, 0, 0);
          object.children[currentCardIndex - 1].position.set(0, 0, 0);
          object.children[currentCardIndex - 1].visible = false;
        }
        if (object.children[currentCardIndex + 1]) {
          object.children[currentCardIndex + 1].position.set(
            sizes.value.width,
            0,
            0
          );
          object.children[currentCardIndex + 1].children[
            currentPageIndex - 1
          ].material.opacity = 0.5;
        }
        controls.pan(sizes.value.width / positionFactor.value, 0);
      } else {
        object.children[currentCardIndex].rotation.set(0, 0, 0);
        object.children[currentCardIndex].position.set(0, 0, 0);
        if (object.children[currentCardIndex - 1]) {
          object.children[currentCardIndex - 1].rotation.y = Math.PI;
          object.children[currentCardIndex - 1].position.set(
            -sizes.value.width,
            0,
            0
          );
          object.children[currentCardIndex - 1].visible = true;
          object.children[currentCardIndex - 1].children[
            currentPageIndex + 1
          ].material.opacity = 0.5;
        }
        if (object.children[currentCardIndex + 1]) {
          object.children[currentCardIndex + 1].position.set(0, 0, 0);
        }
        controls.pan(-(sizes.value.width / positionFactor.value), 0);
      }
    };

    // const resetScene = () => {
    //   for (var i = object.children.length - 1; i >= 0; i--) {
    //     object.children[i].position.set(0, 0, 0);
    //     object.children[i].rotation.set(0, 0, 0);
    //     object.children[i].scale.set(1, 1, 1);
    //     object.children[i].updateMatrix();
    //     object.children[i].applyMatrix(new THREE.Matrix4());
    //     if (i % 2 === 0) {
    //       object.children[i].rotation.y = Math.PI;
    //     }
    //   }
    //   currentPage.value = 0;
    // };

    // const checkAllPagesAccepted = () => {
    //   var accepted = true,
    //     i;

    //   for (
    //     i = 0;
    //     i < props.orderProductFile.cover_pages.length;
    //     i += coverIndexFix.value
    //   ) {
    //     if (!props.orderProductFile.cover_pages[i].is_accepted) {
    //       accepted = false;
    //       break;
    //     }
    //   }

    //   if (accepted) {
    //     for (
    //       i = 0;
    //       i < props.orderProductFile.pages.length;
    //       i += indexFix.value
    //     ) {
    //       if (!props.orderProductFile.pages[i].is_accepted) {
    //         accepted = false;
    //         break;
    //       }
    //     }
    //   }

    //   return accepted;
    // };

    const changePageOnEnter = ($event) => {
      if ($event.which === 13) {
        changePage(goToPageNum.value.value - 1);
      }
    };

    const onPageChange = () => {
      if (goToPageNum.value > pageCount.value) {
        goToPageNum.value.value = pageCount.value;
      } else if (goToPageNum.value.value < 1) {
        goToPageNum.value.value = 1;
      }
    };

    const resetPageNum = () => {
      goToPageNum.value.value = null;
    };

    const isUv = () => {
      return props.productKind === "uv";
    };

    const applyUvMask = (state, currentPage) => {
      var pageTextureIndex = getPageTextureIndex(currentPage),
        texture = textureCanvas[pageTextureIndex],
        mask = textureCanvas[pageTextureIndex + 1],
        maskData = mask.originData,
        textureUpdatedData;

      if (maskData) {
        if (state === null) {
          textureUpdatedData = texture.originData;
        } else {
          var alpha = 200,
            textureData = texture.ctx.getImageData(
              0,
              0,
              texture.canvas.width,
              texture.canvas.height
            ),
            texturePixels = textureData.data,
            textureOriginPixels = texture.originData.data,
            maskPixels = maskData.data,
            color = [255, 255, 0],
            i,
            r,
            g,
            b;

          if (state === true) {
            for (i = 0; i < maskPixels.length; i += 4) {
              r = maskPixels[i];
              g = maskPixels[i + 1];
              b = maskPixels[i + 2];
              if (r < 130 && g < 130 && b < 130) {
                updatePixelColor(
                  color,
                  texturePixels,
                  textureOriginPixels,
                  alpha,
                  i
                );
              }
            }
          } else {
            for (i = 0; i < maskPixels.length; i += 4) {
              r = maskPixels[i];
              g = maskPixels[i + 1];
              b = maskPixels[i + 2];
              if (r < 130 && g < 130 && b < 130) {
                updatePixelReverse(texturePixels, textureOriginPixels, i);
              }
            }
          }
          textureUpdatedData = textureData;
        }
        texture.ctx.putImageData(textureUpdatedData, 0, 0);
        texture.texture.needsUpdate = true;
      }

      if (state === null) {
        applyActiveTechLines(currentPage);
      }
      render();
    };

    const hasUvMask = (page) => {
      if (props.bookletUv) {
        return (
          (page < coverFix.value ||
            page >= props.orderProductFile.pages.length + coverFix.value) &&
          isBookletUvPageWithMask(page) &&
          !!(textureCanvas && textureCanvas[getPageTextureIndex(page) + 1])
        );
      } else if (isUv()) {
        return !!(
          textureCanvas && textureCanvas[getPageTextureIndex(page) + 1]
        );
      } else {
        return false;
      }
    };

    const isBookletUvPageWithMask = (page) => {
      if (
        props.orderProductFile.cover_file_num_pages !== 0 &&
        props.orderProductFile.cover_file_num_pages < 8
      ) {
        if (page !== 1 && page !== pageCount.value - 2) {
          return true;
        }
      }

      return false;
    };

    const applyTechLine = (techLine, currentPage, index) => {
      var texture = textureCanvas[getPageTextureIndex(currentPage)],
        currentTechLineCanvas = techLineCanvas[index],
        techLineData =
          currentPage % 2 === 0
            ? currentTechLineCanvas.originData
            : currentTechLineCanvas.flippedData;

      applyMask(texture, techLineData, techLine.is_active);
      if (techLine.is_active === null) {
        applyActiveTechLines(currentPage);
      }
      render();
    };

    const applyBleedArea = (state, currentPage) => {
      var pageTextureIndex = getPageTextureIndex(currentPage),
        texture = textureCanvas[pageTextureIndex],
        bleedInPx = Math.ceil(
          props.bleed * (texture.canvas.width / fileMMSize.value)
        ),
        textureUpdatedData;

      sizes.value = fixSize(materials[0].map);
      resetGeometriesSizes();
      fixBleedArea();
      updateRuler();

      if (state === false) {
        textureUpdatedData = texture.originData;
      } else {
        var alpha = 200,
          textureData = texture.ctx.getImageData(
            0,
            0,
            texture.canvas.width,
            texture.canvas.height
          ),
          texturePixels = textureData.data,
          textureOriginPixels = texture.originData.data,
          color = [255, 255, 0],
          i,
          mod,
          topLine = texture.canvas.width * bleedInPx * 4,
          bottomLine = texturePixels.length - topLine,
          pixelsRow = texture.canvas.width * 4,
          fourTimesBleed = bleedInPx * 4;

        for (i = 0; i < texturePixels.length; i += 4) {
          mod = i % pixelsRow;
          if (
            i <= topLine ||
            i >= bottomLine ||
            mod < fourTimesBleed ||
            mod >= pixelsRow - fourTimesBleed
          ) {
            if (state) {
              updatePixelColor(
                color,
                texturePixels,
                textureOriginPixels,
                alpha,
                i
              );
            } else {
              updatePixelReverse(texturePixels, textureOriginPixels, i);
            }
          }
        }
        textureUpdatedData = textureData;
      }
      texture.ctx.putImageData(textureUpdatedData, 0, 0);
      texture.texture.needsUpdate = true;
      if (state === null) {
        applyActiveTechLines(currentPage);
      }
      render();
    };

    const applyTextMargin = (state, currentPage) => {
      var texture = textureCanvas[getPageTextureIndex(currentPage)];

      applyLineMask(texture, props.textMargin + props.bleed, state);
      if (state === false) {
        applyActiveTechLines(currentPage);
      }
      render();
    };

    const applyCutLine = (state, currentPage) => {
      var texture = textureCanvas[getPageTextureIndex(currentPage)];
      sizes.value = fixSize(materials[0].map);
      resetGeometriesSizes();
      fixBleedArea();
      updateRuler();

      applyLineMask(texture, props.bleed + 0.25, state);
      if (state === null) {
        applyActiveTechLines(currentPage);
      }
      render();
    };

    const applyLineMask = (texture, line, state) => {
      var mmInPx = Math.round(texture.canvas.width / fileMMSize.value),
        quarterMmInPx = Math.ceil(mmInPx / 4),
        halfMmInPx = Math.ceil(mmInPx / 2),
        lineInPx = line * mmInPx,
        textureUpdatedData;

      if (state === null) {
        textureUpdatedData = texture.originData;
      } else {
        var alpha = 200,
          textureData = texture.ctx.getImageData(
            0,
            0,
            texture.canvas.width,
            texture.canvas.height
          ),
          texturePixels = textureData.data,
          textureOriginPixels = texture.originData.data,
          color = [255, 255, 0],
          i,
          mod,
          topCheck,
          bottomCheck,
          leftCheck,
          rightCheck,
          leftRightCornerCheck,
          topBottomCornerCheck,
          pixelsRow = texture.canvas.width * 4,
          topLine = pixelsRow * lineInPx - pixelsRow * quarterMmInPx,
          topLineWithBorder = topLine + pixelsRow * halfMmInPx,
          bottomLine = texturePixels.length - topLine,
          bottomLineWithBorder = texturePixels.length - topLineWithBorder,
          leftLine = (lineInPx - quarterMmInPx) * 4,
          leftLineWithBorder = (lineInPx + quarterMmInPx) * 4,
          rightLine = pixelsRow - leftLineWithBorder,
          rightLineWithBorder = pixelsRow - leftLine;

        for (i = 0; i < texturePixels.length; i += 4) {
          mod = i % pixelsRow;

          topCheck = i >= topLine && i < topLineWithBorder;
          bottomCheck = i >= bottomLineWithBorder && i < bottomLine;
          leftCheck = mod > leftLine && mod <= leftLineWithBorder;
          rightCheck = mod >= rightLine && mod < rightLineWithBorder;
          leftRightCornerCheck = mod > leftLine && mod < rightLineWithBorder;
          topBottomCornerCheck = i >= topLine && i < bottomLine;

          if (
            (topCheck && leftRightCornerCheck) ||
            (bottomCheck && leftRightCornerCheck) ||
            (topBottomCornerCheck && leftCheck) ||
            (topBottomCornerCheck && rightCheck)
          ) {
            if (state) {
              updatePixelColor(
                color,
                texturePixels,
                textureOriginPixels,
                alpha,
                i
              );
            } else {
              updatePixelReverse(texturePixels, textureOriginPixels, i);
            }
          }
        }
        textureUpdatedData = textureData;
      }
      texture.ctx.putImageData(textureUpdatedData, 0, 0);
      texture.texture.needsUpdate = true;
    };

    const applyMask = (texture, maskData, state) => {
      var textureUpdatedData;

      if (maskData) {
        if (state === null) {
          textureUpdatedData = texture.originData;
        } else {
          var alpha = 200,
            textureData = texture.ctx.getImageData(
              0,
              0,
              texture.canvas.width,
              texture.canvas.height
            ),
            texturePixels = textureData.data,
            textureOriginPixels = texture.originData.data,
            maskPixels = maskData.data,
            color = [255, 255, 0],
            i,
            a;

          if (state === true) {
            for (i = 0; i < maskPixels.length; i += 4) {
              a = maskPixels[i + 3];
              if (a > 0) {
                updatePixelColor(
                  color,
                  texturePixels,
                  textureOriginPixels,
                  alpha,
                  i
                );
              }
            }
          } else {
            for (i = 0; i < maskPixels.length; i += 4) {
              a = maskPixels[i + 3];
              if (a > 0) {
                updatePixelReverse(texturePixels, textureOriginPixels, i);
              }
            }
          }
          textureUpdatedData = textureData;
        }
        texture.ctx.putImageData(textureUpdatedData, 0, 0);
        texture.texture.needsUpdate = true;
      }
    };

    const updatePixelColor = (
      color,
      texturePixels,
      textureOriginPixels,
      alpha,
      i
    ) => {
      texturePixels[i] =
        (color[0] * alpha + textureOriginPixels[i] * (255 - alpha)) / 255;
      texturePixels[i + 1] =
        (color[1] * alpha + textureOriginPixels[i + 1] * (255 - alpha)) / 255;
      texturePixels[i + 2] =
        (color[2] * alpha + textureOriginPixels[i + 2] * (255 - alpha)) / 255;
    };

    const updatePixelReverse = (texturePixels, textureOriginPixels, i) => {
      var fix = 0;
      if (
        textureOriginPixels[i] < 64 &&
        textureOriginPixels[i + 1] < 64 &&
        textureOriginPixels[i + 2] < 64
      ) {
        fix = -30;
      }
      if (
        textureOriginPixels[i] > 240 &&
        textureOriginPixels[i + 1] > 240 &&
        textureOriginPixels[i + 2] > 240
      ) {
        fix = 30;
      }
      texturePixels[i] = 255 - textureOriginPixels[i] + fix;
      texturePixels[i + 1] = 255 - textureOriginPixels[i + 1] + fix;
      texturePixels[i + 2] = 255 - textureOriginPixels[i + 2] + fix;
    };

    const applyActiveTechLines = (currentPage1) => {
      if (uvTechLine.value.is_active !== null) {
        applyUvMask(uvTechLine.value.is_active, currentPage1);
      }
      if (bleedTechLine.value.is_active !== false) {
        applyBleedArea(bleedTechLine.value.is_active, currentPage1);
      }
      if (textMarginTechLine.value.is_active !== false) {
        applyTextMargin(textMarginTechLine.value.is_active, currentPage1);
      }
      if (cutTechLine.value.is_active !== false) {
        applyCutLine(cutTechLine.value.is_active, currentPage1);
      }
      for (var i = 0; i < techLinesC.value.length; ++i) {
        if (techLinesC.value[i].is_active !== null) {
          applyTechLine(techLinesC.value[i], currentPage1, i);
        }
      }
      if (pageHasErrors(currentPage.value)) {
        if (currentPageObj.value.errors_active !== null) {
          applyErrors(currentPageObj.value.errors_active, currentPage.value);
        }
      }
      if (pageHasWarnings(currentPage.value)) {
        if (currentPageObj.value.warnings_active !== null) {
          applyWarnings(
            currentPageObj.value.warnings_active,
            currentPage.value
          );
        }
      }
    };

    const hasTechLines = () => {
      return (
        techLinesC.value.length ||
        isUv() ||
        props.bleed > 0 ||
        props.textMargin > 0
      );
    };

    const currentPageDisplay = () => {
      console.log("ZZZZ", pageCount.value);
      return " " + (currentPage.value + 1) + "/" + pageCount.value;
    };

    const pageHasErrors = (currentPage) => {
      var currentPageObj = getPageByNum(currentPage);
      if (isUv() || currentPageObj.isCover) {
        return (
          currentPageObj?.page?.errors ||
          (currentPageObj.pageMask && currentPageObj.pageMask.errors)
        );
      } else {
        return currentPageObj?.page?.errors;
      }
    };

    const pageHasWarnings = (currentPage) => {
      var currentPageObj = getPageByNum(currentPage);
      if (isUv() || currentPageObj.isCover) {
        return (
          currentPageObj?.page?.warnings ||
          (currentPageObj.pageMask && currentPageObj.pageMask.warnings)
        );
      } else {
        return currentPageObj?.page?.warnings;
      }
    };

    const applyErrors = (state, currentPage) => {
      var texture = textureCanvas[getPageTextureIndex(currentPage)],
        currentPageObj = getPageByNum(currentPage);

      applyExceptions(
        state,
        currentPageObj.page.errors,
        texture,
        currentPageObj.page.width,
        currentPageObj.page.height
      );
      if (isUv() || currentPageObj.isCover) {
        var pageMaskObj = currentPageObj.pageMask,
          maskErrors = pageMaskObj ? pageMaskObj.errors : null;
        if (maskErrors) {
          applyExceptions(
            state,
            maskErrors,
            texture,
            pageMaskObj.width,
            pageMaskObj.height
          );
        }
      }
      if (state === null) {
        applyActiveTechLines(currentPage);
      }
      render();
    };

    const applyWarnings = (state, currentPage) => {
      var texture = textureCanvas[getPageTextureIndex(currentPage)],
        currentPageObj = getPageByNum(currentPage);

      applyExceptions(
        state,
        currentPageObj.page.warnings,
        texture,
        currentPageObj.page.width,
        currentPageObj.page.height
      );
      if (isUv() || currentPageObj.isCover) {
        var pageMaskObj = currentPageObj.pageMask,
          maskWarnings = pageMaskObj ? pageMaskObj.warnings : null;
        if (maskWarnings) {
          applyExceptions(
            state,
            maskWarnings,
            texture,
            pageMaskObj.width,
            pageMaskObj.height
          );
        }
      }
      if (state === null) {
        applyActiveTechLines(currentPage);
      }
      render();
    };

    const applyExceptions = (
      state,
      exceptions,
      texture,
      pageWidth,
      pageHeight
    ) => {
      var textureUpdatedData;

      if (exceptions) {
        if (state === null) {
          textureUpdatedData = texture.originData;
        } else {
          var alpha = 200,
            textureData = texture.ctx.getImageData(
              0,
              0,
              texture.canvas.width,
              texture.canvas.height
            ),
            texturePixels = textureData.data,
            textureOriginPixels = texture.originData.data,
            color = [255, 255, 0],
            i,
            pixelsRow = texture.canvas.width * 4,
            mod,
            topLine,
            bottomLine,
            leftLine,
            rightLine;

          exceptions.forEach((exception) => {
            exception.hits.forEach((hit) => {
              topLine =
                Math.round(
                  (hit.ury * previewGrossSize.value.imgHeight) / pageHeight
                ) * pixelsRow;
              bottomLine =
                Math.round(
                  (hit.lly * previewGrossSize.value.imgHeight) / pageHeight
                ) * pixelsRow;
              leftLine =
                Math.round(
                  (hit.llx * previewGrossSize.value.imgWidth) / pageWidth
                ) * 4;
              rightLine =
                Math.round(
                  (hit.urx * previewGrossSize.value.imgWidth) / pageWidth
                ) * 4;
              for (i = 0; i < texturePixels.length; i += 4) {
                mod = i % pixelsRow;
                if (
                  i >= topLine &&
                  i <= bottomLine &&
                  mod >= leftLine &&
                  mod <= rightLine
                ) {
                  if (state === true) {
                    updatePixelColor(
                      color,
                      texturePixels,
                      textureOriginPixels,
                      alpha,
                      i
                    );
                  } else {
                    updatePixelReverse(texturePixels, textureOriginPixels, i);
                  }
                }
              }
            });
          });
          textureUpdatedData = textureData;
        }
        texture.ctx.putImageData(textureUpdatedData, 0, 0);
        texture.texture.needsUpdate = true;
      }
    };

    const bleedTechLinesActive = () => {
      return (
        bleedTechLine.value.is_active !== false ||
        cutTechLine.value.is_active !== false
      );
    };

    const resetGeometriesSizes = () => {
      var zoomX = !bleedTechLinesActive()
          ? ((pxValue.value * (fileMMSize.value - 2 * props.bleed)) /
              sizes.value.width) *
            zoomValue.value
          : zoomValue.value,
        zoomY = !bleedTechLinesActive()
          ? ((pxValue.value * (fileGrossHeight.value - 2 * props.bleed)) /
              sizes.value.height) *
            zoomValue.value
          : zoomValue.value;

      object.scale.x = zoomX;
      object.scale.y = zoomY;
      var width = objectBBox.max.x - objectBBox.min.x;
      var height = objectBBox.max.y - objectBBox.min.y;

      var x = -(previewWidth.value / 2) + (width * zoomX) / 2 + spaceFix.value;
      var y = previewHeight.value / 2 - (height * zoomY) / 2 - spaceFix.value;
      object.position.x = x;
      object.position.y = y;
    };

    const fixBleedArea = () => {
      var bleedValue = !bleedTechLinesActive() ? bleedInPx.value : 0;
      materials.forEach((material, index) => {
        var imgWidth, imgHeight;
        if (isMultiPageFile.value) {
          imgWidth = !bleedTechLinesActive()
            ? material.map.image.width - bleedValue * 2
            : material.map.image.width - bleedInPx.value;
          imgHeight = material.map.image.height - bleedValue * 2;
          material.map.repeat.x = imgWidth / material.map.image.width;
          material.map.repeat.y = imgHeight / material.map.image.height;
          material.map.offset.x = !bleedTechLinesActive()
            ? (bleedValue / imgWidth) * material.map.repeat.x
            : index % 2 === 0
            ? (bleedInPx.value / imgWidth) * material.map.repeat.x
            : 0;
          material.map.offset.y =
            (bleedValue / imgHeight) * material.map.repeat.y;
        } else {
          imgWidth = material.map.image.width - bleedValue * 2;
          imgHeight = material.map.image.height - bleedValue * 2;
          material.map.repeat.x = imgWidth / material.map.image.width;
          material.map.repeat.y = imgHeight / material.map.image.height;
          material.map.offset.x =
            (bleedValue / imgWidth) * material.map.repeat.x;
          material.map.offset.y =
            (bleedValue / imgHeight) * material.map.repeat.y;
        }
        offset.value = {
          x: material.map.offset.x,
          y: material.map.offset.y,
          rx: material.map.repeat.x,
          ry: material.map.repeat.y,
        };
      });
    };

    const frontCover = () => {
      return props.bookletUv
        ? props.orderProductFile.cover_pages.slice(0, 4)
        : [];
    };

    const backCover = () => {
      return props.bookletUv
        ? props.orderProductFile.cover_pages.slice(4, 8)
        : [];
    };

    // const frontCoverForExceptions = () => {
    //   var idx = props.orderProductFile.cover_file_num_pages < 8 ? 3 : 4;
    //   return props.bookletUv
    //     ? props.orderProductFile.cover_pages.slice(0, idx)
    //     : [];
    // };

    // const backCoverForExceptions = () => {
    //   var idx = props.orderProductFile.cover_file_num_pages < 8 ? 1 : -1,
    //     cover = props.bookletUv
    //       ? props.orderProductFile.cover_pages.slice(4, 8)
    //       : [];

    //   if (idx > -1) {
    //     cover.splice(idx, 1);
    //   }

    //   return cover;
    // };

    const rotateObject = (angle) => {
      if (renderer && scene && !isMultiPageFile.value) {
        object.rotation.y = angle * (Math.PI / 180);
        if (!isLastPage() && angle > 90) {
          changePage(currentPage.value + 1);
        } else if (!isFirstPage() && angle <= 90) {
          changePage(currentPage.value - 1);
        }
        renderer.render(scene, camera);
      }
    };

    return {
      backCover,
      frontCover,
      currentPageDisplay,
      currentPageObj,
      currentPageMaskObj,
      hasTechLines,
      resetPageNum,
      onPageChange,
      changePageOnEnter,
      getContainerHeight,
      zoom,
      zoomValue,
      rotate,
      rotateValue,
      width,
      height,
      previewWrapperRef,
      previewSliderRef,
      acceptBlockRef,
      rulerCanvasRef,
      errorsBlockRef,
      techLinesBlockRef,
      controlsHeightRef,
      pageHasErrors,
      pageHasWarnings,
      isMultiPageFile,
      currentPage,
      bleedTechLine,
      applyBleedArea,
      applyTextMargin,
      textMarginTechLine,
      cutTechLine,
      applyCutLine,
      hasUvMask,
      uvTechLine,
      applyUvMask,
    };
  },
};
</script>
