<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Kostki produkcyjne</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/cubes/list">Kostki produkcyjne</router-link>
        </li>
        <li class="active">
          <strong>Szczegóły</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight logs">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Szczegóły</h5>
          </div>
          <div class="ibox-content">
            <div>
              <dl v-if="cData" class="dl-horizontal">
                <dt>Nazwa maszyny</dt>
                <dd>{{ cData.machine_name }}</dd>
                <dt>Czynność</dt>
                <dd>{{ cData.machine_action_name }}</dd>
                <dt>Liczba zleceń</dt>
                <dd>{{ cData.products_count }}</dd>
                <dt>Termin realizacji</dt>
                <dd>{{ cData.realization_date }}</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Filtry</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div
                v-for="(filter, index) in cubeProductFilterData.data"
                :key="index"
                class="col-md-3 nopadding"
              >
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    {{ filter.name }}
                  </label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="filters[filter.pk]"
                      mode="tags"
                      :placeholder="filter.name"
                      track-by="pk"
                      :close-on-select="false"
                      :searchable="true"
                      :options="filter.product_options"
                    >
                      <template #option="{ option }">
                        <div>
                          <small class="label label-default">
                            {{ option.group_pk }}
                          </small>
                          {{ option.group_name }}

                          <small class="label label-default">
                            {{ option.pk }}
                          </small>
                          {{ option.label }}
                        </div>
                      </template>
                    </Multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Zlecenia zatrzymane na kostce</h5>
          </div>
          <div v-if="items && itemsAll > 0" class="ibox-content">
            <EasyDataTable
              v-model:server-options="serverOptions"
              :server-items-length="itemsAll"
              v-model:items-selected="itemsSelected"
              :headers="headers"
              :items="items"
              :clickRowToExpand="false"
              :loading="loading"
              alternating
              multi-sort
              border-cell
              @update-sort="updateSort"
              class="table-search"
            >
              <template #header="header">
                <span v-if="header.text">{{ header.text }}</span>
                <input
                  class="table-search"
                  type="text"
                  v-if="header.search"
                  v-model="searchValue[header.value]"
                  @keyup="search"
                  @click="isSearch = true"
                />
              </template>
              <template #item-actions="item">
                <button
                  @click="realization(item.pk)"
                  class="btn btn-primary btn-xs"
                  title="Do realizacji"
                >
                  <i class="fa fa-list-alt"></i>
                </button>
                <button
                  @click="
                    getOrderProductFiles(
                      item?.product_file?.preflight_status?.file_pk,
                      '/get_client_file/'
                    )
                  "
                  class="btn btn-xs"
                  title="Plik Klienta"
                >
                  <i class="fa fa-user"></i>
                </button>
                <button
                  @click="
                    getOrderProductFiles(
                      item?.product_file?.preflight_status?.file_pk,
                      '/get_result_file/'
                    )
                  "
                  class="btn btn-xs"
                  title="Plik wyjściowy"
                >
                  <i class="fa fa-search"></i>
                </button>
                <button
                  @click="
                    getOrderProductFiles(
                      item?.product_file?.preflight_status?.file_pk,
                      '/get_client_cover_file/'
                    )
                  "
                  class="btn btn-xs"
                  title="Plik okładki klienta"
                >
                  <i class="fa fa-user"></i>
                </button>
                <button
                  @click="
                    getOrderProductFiles(
                      item?.product_file?.preflight_status?.file_pk,
                      '/get_cover_result_file/'
                    )
                  "
                  class="btn btn-xs"
                  title="Plik okładki wyjściowy"
                >
                  <i class="fa fa-search"></i>
                </button>
              </template>
              <template #item-image="item">
                <img
                  :src="item?.product_file?.client_file_thumbnail"
                  @click="showPreviewModal(item)"
                  width="40px"
                />
              </template>
            </EasyDataTable>
          </div>

          <div class="ibox-title">
            <h5>Podsumowanie</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div v-if="summary.weight !== 0" class="col-sm-12 m-b-xs">
                <EasyDataTable
                  :headers="sumHeaders"
                  :items="summary"
                  border-cell
                  hide-footer="true"
                >
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mActions" class="modal-dialog realization">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header">
          <h4 class="modal-title">Realizacja wybranych zleceń</h4>
        </div>
        <div v-if="statussesData" class="modal-body">
          <nav>
            <div
              v-if="itemsSelected.length > 0"
              class="nav nav-tabs"
              id="nav-tab"
              role="tablist"
            >
              <button
                v-for="(item, index) in itemsSelected"
                :key="index"
                @click="activeTab(item?.pk)"
                class="nav-link"
                :class="{ active: index === 0 }"
                :id="'nav-tab' + item?.pk"
                data-bs-toggle="tab"
                :data-bs-target="'#nav-' + item?.pk"
                type="button"
                role="tab"
                :aria-controls="'#nav-' + item?.pk"
                aria-selected="true"
              >
                {{ item?.pk }}
              </button>
            </div>
          </nav>
          <div
            v-if="itemsSelected.length > 0"
            class="tab-content"
            id="nav-tabContent"
          >
            <div
              v-for="(item, index) in itemsSelected"
              :key="index"
              class="tab-pane fade"
              :class="{ 'active show': index === 0 }"
              :id="'nav-' + item?.pk"
              :data-pk="item?.pk"
              role="tabpanel"
              :aria-labelledby="'nav-' + item?.pk"
            >
              <div class="form-horizontal">
                <div class="ibox-content">
                  <div class="panel-body">
                    <div class="row-eq-height row">
                      <div
                        v-if="realizationData?.prev_process_stage"
                        class="col-md-3"
                      >
                        <div
                          class="nomargin height-100-pc widget lazur-bg p-xl unactive-cube"
                        >
                          <b>
                            <h2>
                              {{
                                realizationData?.prev_process_stage
                                  ?.machine_name
                              }}
                            </h2>
                            <small>Poprzedni etap</small>
                            <span class="cube-action-name">
                              {{
                                realizationData?.prev_process_stage
                                  ?.machine_action?.name
                              }}
                            </span>
                          </b>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div
                          class="nomargin height-100-pc widget lazur-bg p-xl"
                        >
                          <b>
                            <h2>
                              {{ realizationData?.process_stage?.machine_name }}
                            </h2>
                            <small>Aktualny etap</small>
                            <span class="cube-action-name">
                              {{
                                realizationData?.process_stage?.machine_action
                                  ?.name
                              }}
                            </span>
                          </b>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div
                          class="nomargin height-100-pc widget lazur-bg p-xl unactive-cube"
                        >
                          <b>
                            <h2>
                              {{
                                realizationData?.next_process_stage
                                  ?.machine_name
                              }}
                            </h2>
                            <small>Następny etap</small>
                            <span class="cube-action-name">
                              {{
                                realizationData?.next_process_stage
                                  ?.machine_action?.name
                              }}
                            </span>
                          </b>
                        </div>
                      </div>

                      <div class="col-md-3 border-left">
                        <div class="btn-group-vertical">
                          <button
                            @click="startProcess()"
                            :disabled="isStartedDisabled()"
                            class="btn btn-primary"
                          >
                            <span class="fa fa-play"></span>
                            <span> Rozpoczęcie pracy</span>
                          </button>
                          <button
                            @click="endProcess()"
                            :disabled="isFinishedDisabled()"
                            class="btn btn-primary"
                          >
                            <span class="fa fa-stop"></span>
                            <span> Zakończenie pracy</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="m-t-md row-eq-height row">
                      <div class="col-md-4">
                        <div class="well">
                          <h3>Uwagi pracowników</h3>
                          <div
                            v-for="(status, index) in statusData"
                            :key="index"
                          >
                            <p>
                              <span class="text-muted">{{ status.time }}</span>
                              <br />
                              <span class="text-muted">
                                {{ status.admin_full_name }}
                              </span>
                            </p>
                            <p>
                              <span class="label">
                                {{ status.machine_name }}
                              </span>
                              <i class="fa fa-caret-right"></i>
                              <span class="label">
                                {{ status.machine_action_name }}
                              </span>
                            </p>
                            <hr class="hr-line-solid" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="well">
                          <h3>Uwagi klienta</h3>
                          <div>TODO</div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="well">
                          <h3>Uwagi techniczne</h3>
                          <div
                            v-html="
                              realizationData?.process_stage?.machine_action
                                .description
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="mActions = false" class="btn btn-white">
            Zamknij
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mActions" @click="mActions = false" class="modal-backdrop"></div>

  <div v-if="mJobs" class="modal-dialog jobs">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header">
          <h4 class="modal-title">Rozpoczęcie pracy</h4>
        </div>
        <div class="modal-body">
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="panel-body">
                <div v-if="jobType">
                  <div
                    v-for="(checkbox, index) in realizationData?.process_stage
                      ?.machine_action?.checklist_before_start"
                    :key="index"
                    class="form-group"
                  >
                    <div class="col-sm-offset-2 col-sm-10">
                      <div class="checkbox checkbox-primary">
                        <label>
                          <input
                            v-model="checklist[index]"
                            name="checklist"
                            type="checkbox"
                          />
                          <span>{{ checkbox }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div
                    v-for="(checkbox, index) in realizationData?.process_stage
                      ?.machine_action?.checklist_before_end"
                    :key="index"
                    class="form-group"
                  >
                    <div class="col-sm-offset-2 col-sm-10">
                      <div class="checkbox checkbox-primary">
                        <label>
                          <input
                            v-model="checklist[index]"
                            name="checklist"
                            type="checkbox"
                          />
                          <span>{{ checkbox }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-sm-2">Uwagi</label>
                  <div class="col-sm-10 QuillEditor-box">
                    <QuillEditor
                      theme="snow"
                      contentType="html"
                      v-model:content="info"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="mJobs = false" class="btn btn-white">Zamknij</button>
          <button @click="saveJob" class="btn btn-primary">Zapisz</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mJobs" @click="mJobs = false" class="modal-backdrop"></div>

  <div v-if="mPreview" class="modal-dialog preflight">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header">
          <h4 class="modal-title">Podgląd pliku</h4>
        </div>
        <div class="modal-body">
          <pdfViewer
            :order-product-file="orderData.data.order_products[0].product_file"
            :gross-size="orderData.data.order_products[0].product_gross_size"
            :bleed="orderData.data.order_products[0].product_bleed"
            :text-margin="orderData.data.order_products[0].product_text_margin"
            :product-kind="orderData.data.order_products[0].product_kind"
            :tech-lines="orderData.data.order_products[0].tech_lines"
            :booklet-uv="orderData.data.order_products[0].is_booklet_uv"
            :pages-loaded="0"
            :loading="true"
            loading-text="Ładowanie stron"
          />
        </div>
        <div class="modal-footer">
          <button @click="mPreview = false" class="btn btn-white">
            Zamknij
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mPreview" @click="mPreview = false" class="modal-backdrop"></div>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { useRoute } from "vue-router";
import pdfViewer from "../pdfViewer/pdfViewer.vue";

export default {
  name: "CubeDetails",
  mixins: [],
  components: {
    Multiselect,
    pdfViewer,
  },
  props: {},

  setup() {
    const store = useStore();
    const cubeData = computed(() => store.getters.getCubeData);
    const cubeProductFilterData = computed(
      () => store.getters.getCubeProductFilterData
    );
    const cubeProductsData = computed(() => store.getters.getCubeProductsData);
    const cubeProductData = computed(() => store.getters.getCubeProductData);
    const statussesData = computed(
      () => store.getters.getOrderProcessStatusData
    );
    const orderProcessStageData = computed(
      () => store.getters.getOrderProcessStageData
    );

    const orderData = computed(() => store.getters.getOrderData);

    const loading = ref(true);
    const mPreview = ref(false);
    const mActions = ref(false);
    const mJobs = ref(false);
    const jobType = ref(false);
    const checklist = ref([]);
    const itemsSelected = ref([]);
    const info = ref("");
    const filters = ref([]);
    const summary = ref([]);
    let cData = ref();
    let statusData = ref();
    const itemsAll = ref(0);
    let items = ref([]);
    const searchValue = ref({});
    const realizationData = ref();
    const realizationsData = ref();
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });
    const route = useRoute();
    let headers = [
      { text: "Podgląd", value: "image", sortable: true },
      {
        text: "ID Zlecenia",
        value: "pk",
        sortable: true,
        search: true,
      },
      {
        text: "Nr zamówienia",
        value: "order_pk",
        sortable: true,
        search: true,
      },
      {
        text: "Nr montażu",
        value: "montage_pk",
        sortable: true,
        search: true,
      },
      {
        text: "ID produktu",
        value: "product_pk",
        sortable: true,
        search: true,
      },
      {
        text: "Produkt",
        value: "product_name",
        sortable: true,
        search: true,
      },
      {
        text: "Nakład",
        value: "selected_quantity",
        sortable: true,
        search: true,
      },
      {
        text: "Format",
        value: "product_format",
        sortable: true,
        search: true,
      },
      {
        text: "Netto",
        value: "net_price",
        sortable: true,
        search: true,
      },
      {
        text: "Waluta",
        value: "currency",
        sortable: true,
        search: true,
      },
      {
        text: "Waga",
        value: "weight",
        sortable: true,
        search: true,
      },
      {
        text: "Termin realizacji",
        value: "order_realization_date",
        sortable: true,
        search: true,
      },
      // { text: "Data dodania", value: "date_added", sortable: true },
      // { text: "Data modyfikacji", value: "date_modified", sortable: true },
      // {
      //   text: "Zmodyfikowano przez",
      //   value: "last_modified_by",
      //   sortable: true,
      // },
      // {
      //   text: "Utworzono przez",
      //   value: "created_by",
      //   sortable: true,
      // },
      { text: "Akcje", value: "actions" },
    ];

    let sumHeaders = [
      { text: "Nakład", value: "selected_quantity" },
      { text: "Netto", value: "net_price" },
      { text: "Waga", value: "weight" },
    ];

    const getCubeProducts = () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      store.dispatch("getCubeProducts", {
        pk: route.params.pk,
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
        filters: filters.value,
      });
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getCubeProducts();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getCubeProducts();
    };

    const updateSort = () => {
      search.value = true;
    };

    const isStartedDisabled = () => {
      if (Array.isArray(statussesData.value?.data)) {
        let status = statussesData.value?.data?.findLast(
          (x) => x.order_product === realizationData.value?.pk
        );

        if (!status) {
          return false;
        }

        if (!realizationData.value?.process_stage || !status) {
          return true;
        }

        return status.process_stage === realizationData.value?.process_stage.pk;
      } else {
        return false;
      }
    };

    const isFinishedDisabled = () => {
      if (Array.isArray(statussesData.value?.data)) {
        let status = statussesData.value?.data?.findLast(
          (x) => x.order_product === realizationData.value?.pk
        );
        if (!realizationData.value?.process_stage || !status) {
          return true;
        }

        if (!status) {
          return true;
        }
        return status.process_stage !== realizationData.value?.process_stage.pk;
      } else {
        return true;
      }
    };

    const getOrderProductFiles = (pk, type) => {
      if (pk) {
        store.dispatch("getOrderProductFiles", {
          pk: pk,
          type: type,
        });
      }
    };

    const realization = (pk) => {
      store.dispatch("getCubeProduct", {
        pk: route.params.pk,
        pks: itemsSelected.value.length > 1 ? itemsSelected.value : pk,
      });
      mActions.value = true;
    };

    const startProcess = () => {
      jobType.value = true;
      mJobs.value = true;
    };
    const endProcess = () => {
      jobType.value = false;
      mJobs.value = true;
    };
    const activeTab = (pk) => {
      if (statussesData.value?.data && statussesData.value?.data?.length > 0) {
        let statusses = statussesData.value?.data?.filter(
          (x) => x.order_product === pk
        );
        statusData.value = statusses;
      }
      let rItem = realizationsData.value?.filter((x) => x.pk === pk);
      realizationData.value = rItem[0];
    };

    const saveJob = () => {
      let tempChecklist = [];
      let saveArray = [];
      if (jobType.value) {
        checklist.value.forEach((element, idx) => {
          if (element) {
            tempChecklist.push(
              realizationData.value.process_stage.machine_action
                .checklist_before_start[idx]
            );
          }
        });

        itemsSelected.value.forEach((element) => {
          saveArray.push({
            checklist: tempChecklist,
            info: info.value,
            order_product: element.pk,
            process_stage: parseInt(route.params.pk),
            status: "started",
          });
        });
        store.dispatch("saveOrderProcessStage", saveArray);
      } else {
        checklist.value?.forEach((element, idx) => {
          if (element) {
            tempChecklist.push(
              realizationData.value.process_stage.machine_action
                .checklist_before_end[idx]
            );
          }
        });
        itemsSelected.value.forEach((element) => {
          saveArray.push({
            checklist: tempChecklist,
            info: info.value,
            order_product: element.pk,
            process_stage: parseInt(route.params.pk),
            status: "finished",
          });
        });
        store.dispatch("saveOrderProcessStage", saveArray);
      }
      info.value = "";
      checklist.value = [];
    };

    const tableSummary = () => {
      let tempSummary = {
        net_price: 0,
        selected_quantity: 0,
        weight: 0,
      };
      summary.value = [];
      items.value?.forEach((element) => {
        tempSummary.weight += element.weight;
        tempSummary.net_price += element.net_price;
        tempSummary.selected_quantity += element.selected_quantity;
      });
      tempSummary.weight = tempSummary.weight.toFixed(2);
      tempSummary.net_price = tempSummary.net_price.toFixed(2);
      summary.value.push(tempSummary);
    };

    const showPreviewModal = (item) => {
      store.dispatch("getOrder", item.order_pk);
    };

    store.dispatch("getCube", { pk: route.params.pk });
    store.dispatch("getCubeProductFilterGO", { pk: route.params.pk });
    getCubeProducts();

    watch(
      () => cubeProductsData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        tableSummary();
        loading.value = false;
      }
    );

    watch(
      () => cubeData.value,
      function (newValue) {
        cData.value = newValue.data;
      }
    );

    watch(
      () => orderData.value,
      function () {
        mPreview.value = true;
      }
    );
    watch(
      () => filters.value,
      function () {
        getCubeProducts();
      },
      { deep: true }
    );
    watch(
      () => cubeProductFilterData.value,
      function (newValue) {
        newValue.data?.forEach((element, index) => {
          element.product_options?.forEach((element1, index1) => {
            if (
              cubeProductFilterData.value.data[index].product_options[index1]
            ) {
              cubeProductFilterData.value.data[index].product_options[
                index1
              ].value = element1.pk;
            }
          });
        });
      }
    );
    watch(
      () => realizationData.value,
      function () {
        store.dispatch("getOrderProcessStage", {
          pks: itemsSelected.value
            ? itemsSelected.value
            : realizationData.value.pk,
        });
      }
    );

    watch(
      () => cubeProductData.value,
      function (newValue) {
        realizationsData.value = newValue?.data;
        if (itemsSelected.value.length < 1) {
          itemsSelected.value.push(realizationsData.value[0]);
        } else if (itemsSelected.value.length === 1) {
          itemsSelected.value = [];
          itemsSelected.value.push(realizationsData.value[0]);
        }
        if (realizationsData?.value[0]) {
          activeTab(realizationsData.value[0].pk);
        }
      }
    );

    watch(
      () => orderProcessStageData.value,
      function (newValue) {
        if (newValue.status === 201) {
          store.dispatch("getOrderProcessStage", {
            pks: realizationData.value.pk,
            pk: newValue.data[0].order_product,
          });
          store.dispatch("getCubeProduct", {
            pk: route.params.pk,
            pks: realizationData.value.pk,
          });
          if (jobType.value) {
            store.dispatch("showToaster", {
              type: "success",
              content: "Etap procesu został pomyślnie rozpoczęty!",
            });
            mJobs.value = false;
          } else {
            store.dispatch("showToaster", {
              type: "success",
              content: "Etap procesu został pomyślnie zakończony!",
            });
            mJobs.value = false;
            mActions.value = false;
            getCubeProducts();
          }
        }
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getCubeProducts();
          isSearch.value = false;
        }
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getCubeProducts();
          }, 1000);
        }
      }
    );

    return {
      cData,
      itemsAll,
      items,
      loading,
      mActions,
      mJobs,
      cubeProductFilterData,
      headers,
      searchValue,
      isSearch,
      updateSort,
      resetFilters,
      rowsPerPage,
      serverOptions,
      getOrderProductFiles,
      realization,
      realizationData,
      startProcess,
      saveJob,
      checklist,
      info,
      jobType,
      endProcess,
      isStartedDisabled,
      isFinishedDisabled,
      statussesData,
      itemsSelected,
      filters,
      activeTab,
      statusData,
      summary,
      sumHeaders,
      showPreviewModal,
      mPreview,
      orderData,
    };
  },
  computed: {},
};
</script>
