<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Sprawy</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/messages/list">Sprawy</router-link>
        </li>
        <li class="active">
          <strong>Szczegóły</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <button
      v-if="messageObj.status !== 'close'"
      @click="closeMessage"
      class="btn btn-primary"
    >
      Zamknij sprawę
    </button>
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Szczegóły</h5>
          </div>

          <div class="form-horizontal">
            <div v-if="messageObj" class="ibox-content">
              <dl class="dl-horizontal">
                <dt>Reklamacja</dt>
                <dd v-if="messageObj.complaint_reason">
                  <span class="fa fa-check text-navy"></span>
                </dd>
                <dd v-else><span class="fa fa-times text-danger"></span></dd>
                <dt>Status</dt>
                <dd>{{ messageObj.status_display }}</dd>
                <dt v-if="messageObj.competency_negotiations">
                  Negocjowane zamówienie
                </dt>
                <dd v-if="messageObj.competency_negotiations">
                  {{ messageObj.order }}
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis sprawy</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div v-if="adminsOptions" class="form-group">
                <label class="control-label col-sm-2">
                  Osoba odpowiedzialna
                </label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="messageObj.responsible_person"
                    mode="single"
                    placeholder="Osoba odpowiedzialna"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :searchable="true"
                    :options="adminsOptions"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.responsible_person"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.responsible_person[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Klient</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="messageObj.client"
                    mode="single"
                    placeholder="Klient"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :searchable="true"
                    :options="clientsOptions"
                  >
                  </Multiselect>
                  <div v-if="errorsAdminData?.data?.client" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.client[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Rodzaj sprawy</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="messageObj.competency"
                    mode="single"
                    placeholder="Rodzaj sprawy"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :searchable="true"
                    :options="adminsReasons"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.competency"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.competency[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Powód reklamacji</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="messageObj.complaint_reason"
                    mode="single"
                    placeholder="Powód reklamacji"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :searchable="true"
                    :disabled="messageObj.competency !== 2"
                    :options="adminsComplaint"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.competency"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.competency[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Zamówienie</label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    v-model="messageObj.order"
                    class="form-control inputClass"
                    placeholder="Zamówienie"
                    disabled="disabled"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Pozycja zamówienia</label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    v-model="messageObj.order_product"
                    class="form-control inputClass"
                    placeholder="Pozycja zamówienia"
                    disabled="disabled"
                  />
                </div>
              </div>

              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <router-link class="btn btn-white" to="/messages/list">
                    Anuluj
                  </router-link>
                  <button @click="saveEdit" class="btn btn-primary">
                    Zapisz
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Konwersacja</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <ol v-if="messageObj?.conversation_messages" class="chat">
                <div class="day">21.11.2023 08:21:47</div>
                <li
                  v-for="(message, index) in messageObj.conversation_messages"
                  :key="index"
                  :class="{
                    self: message.user_is_admin,
                    other: !message.user_is_admin,
                  }"
                >
                  <div class="avatar"></div>
                  <div class="msg">
                    <p>{{ message.content }}</p>
                    <div
                      v-for="(file, index) in message.message_files"
                      :key="index"
                    >
                      <a :href="file.file">{{ file.name }}</a>
                    </div>
                    <time>{{ message.date_added }}</time>
                    <span class="author">{{ message.user_username }}</span>
                  </div>
                </li>
              </ol>
              <div v-if="messageObj.status !== 'close'" class="form-horizontal">
                <textarea
                  v-model="chatMsg"
                  class="textarea"
                  placeholder="Kliknij aby zacząć pisać"
                  required
                ></textarea>
                <div v-if="errorsAdminData?.data?.content" class="has-error">
                  <span class="help-block">
                    {{ errorsAdminData.data.content[0] }}
                  </span>
                </div>
                <button @click="sendChat" class="btn-send">Odpowiedz</button>
                <div class="form-group col-sm-12">
                  <label class="btn btn-info btn-file">
                    <span v-if="!files.length">Dodaj pliki</span>
                    <span v-if="files.length">Zmień pliki</span>
                    <input
                      type="file"
                      multiple="true"
                      @change="onFileChange"
                      style="display: none"
                    />
                  </label>
                  <button
                    v-if="files.length"
                    @click="files = []"
                    class="btn btn-default"
                  >
                    Wyczyść
                  </button>
                </div>
                <ul class="list-group">
                  <li
                    v-for="(file, index) in files"
                    :key="index"
                    class="list-group-item"
                  >
                    <a>{{ file.name }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";
import { useRoute } from "vue-router";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { useCookies } from "vue3-cookies";

export default {
  name: "ClientsCreate",
  mixins: [],
  components: {
    Multiselect,
  },
  props: {},

  setup() {
    const { cookies } = useCookies();
    const route = useRoute();
    const countryList = ref([
      { name: "Polska", value: "PL" },
      { name: "Niemcy", value: "DE" },
    ]);

    const errorsAdminData = ref();
    const showErrors = ref(false);
    const store = useStore();
    const clientsContactData = computed(
      () => store.getters.getClientsContactData
    );
    const adminsContactData = computed(
      () => store.getters.getAdminsContactData
    );
    const adminsReasonsData = computed(
      () => store.getters.getAdminsReasonsData
    );
    const adminsComplaintData = computed(
      () => store.getters.getAdminsComplaintData
    );
    const updateMessageData = computed(
      () => store.getters.getUpdateMessageData
    );
    const conversationData = computed(() => store.getters.getConversationData);
    const countriesData = computed(() => store.getters.getCountriesData);
    const messageData = computed(() => store.getters.getMessageData);
    const clientsOptions = ref([]);
    const adminsOptions = ref([]);
    const adminsReasons = ref([]);
    const adminsComplaint = ref([]);
    const countriesOptions = ref([]);
    const files = ref([]);
    const chatMsg = ref();
    const messageObj = ref({
      responsible_person: [],
      client: [],
      competence: [],
    });

    const create = () => {
      showErrors.value = true;
      // store.dispatch("createClient", clientObj.value);
    };

    const onFileChange = (e) => {
      files.value[0] = e.target.files[0];
    };

    const closeMessage = () => {
      store.dispatch("updateMessage", {
        pk: route.params?.pk,
        status: "close",
        closeMessage: true,
      });
    };

    const sendChat = () => {
      if (chatMsg.value) {
        store.dispatch("addConversation", {
          conversation: route.params?.pk,
          content: chatMsg.value,
          user: cookies.get("CPid"),
        });
      }
    };

    const saveEdit = () => {
      if (messageObj.value) {
        store.dispatch("updateMessage", messageObj.value);
      }
    };

    store.dispatch("getClientsContact", { pk: route.params?.pk });
    store.dispatch("getMessagesDetails", { pk: route.params?.pk });
    store.dispatch("getAdminsContact");
    store.dispatch("getAdminsReasons", { complaintReason: true });
    store.dispatch("getAdminsReasons", { complaintReason: false });
    store.dispatch("getCountriesList");

    watch(
      () => updateMessageData.value,
      function (newValue) {
        if (newValue.status === 200) {
          let content = "Dane sprawy zostały zaktualizowane!";
          if (newValue.closeMessage) {
            content = "Sprawa została zamknięta!";
          }
          router
            .push({ name: "MessagesList", path: "/messages/list" })
            .then(() => {
              store.dispatch("showToaster", {
                type: "success",
                content: content,
              });
            });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );
    watch(
      () => conversationData.value,
      function (newValue) {
        if (newValue.status === 201) {
          if (files.value[0]) {
            store.dispatch("addMessageFile", {
              message: newValue.data.pk,
              file: files.value[0],
            });
          }
          store.dispatch("showToaster", {
            type: "success",
            content: "Wiadomość została wysłana!",
          });
          chatMsg.value = "";
          store.dispatch("getMessagesDetails", { pk: route.params?.pk });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    watch(
      () => clientsContactData.value,
      function (newValue) {
        newValue.data.results?.forEach((element) => {
          clientsOptions.value.push({
            name: "[" + element.pk + "] " + element.full_name,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => adminsContactData.value,
      function (newValue) {
        newValue.data?.forEach((element) => {
          adminsOptions.value.push({
            name: "[" + element.pk + "] " + element.full_name,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => adminsReasonsData.value,
      function (newValue) {
        newValue.data?.forEach((element) => {
          adminsReasons.value.push({
            name: "[" + element.pk + "] " + element.name,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => adminsComplaintData.value,
      function (newValue) {
        newValue.data?.forEach((element) => {
          adminsComplaint.value.push({
            name: "[" + element.pk + "] " + element.name,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => countriesData.value,
      function (newValue) {
        newValue.data.data?.forEach((element) => {
          countriesOptions.value.push({
            name: element.name_pl,
            value: element.code,
          });
        });
      }
    );

    watch(
      () => messageData.value,
      function (newValue) {
        messageObj.value = newValue.data;
      }
    );

    return {
      countryList,
      errorsAdminData,
      showErrors,
      create,
      clientsOptions,
      adminsOptions,
      adminsReasons,
      adminsComplaint,
      countriesOptions,
      messageObj,
      files,
      onFileChange,
      closeMessage,
      chatMsg,
      sendChat,
      saveEdit,
    };
  },
  computed: {},
};
</script>
