import { createStore } from "vuex";
import axios from "axios";
import { useCookies } from "vue3-cookies";
import "vue3-toastify/dist/index.css";
import { toast } from "vue3-toastify";

const API_HOST = process.env.VUE_APP_APIHOST;
const VERSION = "api/v1";
const WEBSOCKET_URL = `${process.env.VUE_APP_WSHOST}/online-users/`;
const LOGIN = `${API_HOST}/api/token-auth/`;
const ADMINS_LIST = `${API_HOST}/${VERSION}/admins`;
const ADMINS_LIST_GROUP = `${API_HOST}/${VERSION}/admins/group_action/`;
const ADMIN_BY_USERNAME = `${API_HOST}/${VERSION}/admins/get_admin_by_username/`;
const MACHINES = `${API_HOST}/${VERSION}/machines/`;
const MACHINE_ACTIONS = `${API_HOST}/${VERSION}/machine_actions/`;
const COMPETENCIES_LIST = `${API_HOST}/${VERSION}/competencies`;
const PERMISSIONS_LIST = `${API_HOST}/${VERSION}/model-permissions/admin_model_permissions/`;
const PERMISSIONS_GROUP_LIST = `${API_HOST}/${VERSION}/model-permissions-groups`;
const PRODUCTION_LOGS = `${API_HOST}/${VERSION}/production-logs/`;
const ALLOWED_IPS = `${API_HOST}/${VERSION}/allowed-ips`;
const SETTINGS = `${API_HOST}/${VERSION}/settings`;
const HOLIDAYS = `${API_HOST}/${VERSION}/holidays`;
const PACKAGES = `${API_HOST}/${VERSION}/packages/`;
const PROCESSES = `${API_HOST}/${VERSION}/processes/`;
const PLATES = `${API_HOST}/${VERSION}/plates/`;
const VARIABLES = `${API_HOST}/${VERSION}/variables/`;
const PRODLOGS = `${API_HOST}/${VERSION}/production-logs/`;
const NEWS = `${API_HOST}/${VERSION}/news/`;
const BANNERS = `${API_HOST}/${VERSION}/banners/`;
const PAGES_BANNERS = `${API_HOST}/${VERSION}/pages/admin_banner_pages/`;
const PAGES = `${API_HOST}/${VERSION}/pages/`;
const FAQS = `${API_HOST}/${VERSION}/faqs/`;
const TRANSLATIONS = `${API_HOST}/${VERSION}/translation/`;
const TRANSLATIONS_MODELS = `${API_HOST}/${VERSION}/translation-models/`;
const FILES = `${API_HOST}/${VERSION}/files/`;
const CATEGORIES = `${API_HOST}/${VERSION}/categories/`;
const PRODUCTS = `${API_HOST}/${VERSION}/products/`;
const FORMAT = `${API_HOST}/${VERSION}/format/`;
const APPLIANCES = `${API_HOST}/${VERSION}/appliances/`;
const PARAMETERS = `${API_HOST}/${VERSION}/product-parameters/`;
const ADMIN_PARAMETERS = `${API_HOST}/${VERSION}/product-parameters/admin_product_parameters/`;
const FORMAT_GROUPS = `${API_HOST}/${VERSION}/format_groups/`;
const PGROUP_OPTIONS = `${API_HOST}/${VERSION}/product-group-options/`;
const PAGROUP_OPTIONS = `${API_HOST}/${VERSION}/product-group-options/admin_product_group_options/`;
const PRODUCT_OPTIONS = `${API_HOST}/${VERSION}/product-options/`;
const PEX_OPTIONS = `${API_HOST}/${VERSION}/product-options-exclusion/`;
const MONTAGES = `${API_HOST}/${VERSION}/montages/`;
const ORDERS = `${API_HOST}/${VERSION}/orders/`;
const ORDER_DELIVERIES = `${API_HOST}/${VERSION}/order-deliveries/`;
const ORDER_PRODUCTSBD = `${API_HOST}/${VERSION}/order-products-by-deliveries/`;
const ORDER_PRODUCT_FILES = `${API_HOST}/${VERSION}/order-product-files/`;
const CUBES = `${API_HOST}/${VERSION}/production-cubes/`;
const CUBE_PRODUCTS = `${API_HOST}/${VERSION}/production-cube-products/`;
const ORDER_PROCESS_STAGE = `${API_HOST}/${VERSION}/order-product-process-stage-statuses/`;
const ORDER_PRODUCT_FILE_PAGES = `${API_HOST}/${VERSION}/order-product-files-pages/`;
const MONTAGE_PRODUCTS = `${API_HOST}/${VERSION}/montage-products/`;
const SITES = `${API_HOST}/${VERSION}/sites/`;
const QUANTITIES = `${API_HOST}/${VERSION}/quantities/`;
const CLIENTS = `${API_HOST}/${VERSION}/clients/`;
const COUNTRIES = `${API_HOST}/${VERSION}/country-list/`;
const DISCOUNTS_GROUPS = `${API_HOST}/${VERSION}/discount-groups/`;
const DISCOUNTS = `${API_HOST}/${VERSION}/discounts/`;
const ORDER_PRODUCTS = `${API_HOST}/${VERSION}/order-products/`;
const CONTACT = `${API_HOST}/${VERSION}/contact/`;
const MESSAGES = `${API_HOST}/${VERSION}/messages/`;
const MESSAGE_FILES = `${API_HOST}/${VERSION}/message-files/`;
const INVOICES = `${API_HOST}/${VERSION}/invoices/`;
const LEGACY_INVOICES = `${API_HOST}/${VERSION}/legacy-invoices/`;
const TAGS = `${API_HOST}/${VERSION}/tags/`;
const APM = `${API_HOST}/${VERSION}/admin-payment-methods/`;
const ORDER_LOGS = `${API_HOST}/${VERSION}/order-logs/`;
const PROCESS_STAGES = `${API_HOST}/${VERSION}/process-stages/`;
const MONTAGE_STATS = `${API_HOST}/${VERSION}/montage-stats/`;
const PRODUCTION_STATS = `${API_HOST}/${VERSION}/production-stats/`;
const SYSTEM_LOGS = `${API_HOST}/${VERSION}/system_logs/`;
const CLIENT_STATS = `${API_HOST}/${VERSION}/client-stats/`;
const PRODUCTS_STATS = `${API_HOST}/${VERSION}/products-stats/`;
const PACKAGE_STATS = `${API_HOST}/${VERSION}/packages-stats/`;
const SALES_STATS = `${API_HOST}/${VERSION}/sales-stats/`;
const { cookies } = useCookies();
const websocket = new WebSocket(WEBSOCKET_URL + cookies.get("CPsession") + "/");

export default createStore({
  state: {
    loginData: [],
    adminsData: [],
    adminsPGsData: [],
    onlineAdminsData: [],
    adminsBarcideData: [],
    adminData: [],
    adminEditData: [],
    adminGroupEditData: [],
    adminEditionData: [],
    machinesData: [],
    machineActionsData: [],
    machineData: [],
    createMachinesData: [],
    deleteMachinesData: [],
    updateMachinesData: [],
    competenciesData: [],
    permissionsGroupData: [],
    permissionsGroupsData: [],
    permissionsData: [],
    createAdminData: [],
    deleteAdminData: [],
    adminLogsData: [],
    deletePermissionsGroupData: [],
    createPermissionsGroupData: [],
    updatePermissionsGroupData: [],
    deleteCompetenceData: [],
    createCompetenceData: [],
    competenceData: [],
    updateCompetenceData: [],
    ipData: [],
    ipsData: [],
    updateIpData: [],
    deleteIpData: [],
    createIpData: [],
    settingData: [],
    settingsData: [],
    updateSettingData: [],
    deleteSettingData: [],
    createSettingData: [],
    holidaysData: [],
    deleteHolidaysData: [],
    createHolidaysData: [],
    packagesData: [],
    packageData: [],
    deletePackagesData: [],
    createPackagesData: [],
    updatePackagesData: [],
    processesData: [],
    processData: [],
    createProcessesData: [],
    updateProcessesData: [],
    deleteProcessesData: [],
    platesData: [],
    plateDate: [],
    updatePlatesData: [],
    createPlatesData: [],
    deletePlatesData: [],
    variablesData: [],
    variableData: [],
    updateVariablesData: [],
    createVariablesData: [],
    deleteVariablesData: [],
    prodlogsData: [],
    newsListData: [],
    newsData: [],
    createNewsData: [],
    updateNewsData: [],
    deleteNewsData: [],
    bannersData: [],
    bannerData: [],
    createBannersData: [],
    updateBannersData: [],
    deleteBannersData: [],
    pageBannersData: [],
    statusesData: [],
    prodLogsSummaryData: [],
    pagesData: [],
    pageData: [],
    createPagesData: [],
    updatePagesData: [],
    pageTypesData: [],
    pageLayoutsData: [],
    pageMenuData: [],
    faqsData: [],
    faqData: [],
    deleteFaqData: [],
    createFaqData: [],
    updateFaqData: [],
    translationsData: [],
    translationsModelsData: [],
    updateTranslationsModelsData: [],
    updateTranslationsData: [],
    filesData: [],
    createFilesData: [],
    deleteFilesData: [],
    updateFilesData: [],
    importTMData: [],
    categoriesData: [],
    categoriesAdminProductData: [],
    categoryData: [],
    deleteCategoriesData: [],
    updateCategoriesData: [],
    createCategoriesData: [],
    categoryParentsData: [],
    deletePagesData: [],
    productsData: [],
    createProductsData: [],
    productMaterialsData: [],
    productOptionsData: [],
    productFormatsData: [],
    appliancesData: [],
    applianceData: [],
    createAppliancesData: [],
    updateAppliancesData: [],
    deleteAppliancesData: [],
    parametersData: [],
    adminParametersData: [],
    parameterData: [],
    createParametersData: [],
    updateParametersData: [],
    deleteParametersData: [],
    formatGroupsdata: [],
    formatGroupsAdmindata: [],
    formatGroupdata: [],
    createFormatGroupsdata: [],
    updateFormatGroupsdata: [],
    deleteFormatGroupsdata: [],
    formatsData: [],
    formatData: [],
    createFormatsData: [],
    updateFormatsData: [],
    deleteFormatsData: [],
    pOptionsGroupData: [],
    paOptionsGroupData: [],
    pOptionsExData: [],
    pOptionGroupData: [],
    pOptionExData: [],
    createPoptionsGroupData: [],
    createPoptionsExData: [],
    updatePoptionsGroupData: [],
    updatePoptionsExData: [],
    deletePoptionsGroupData: [],
    deletePoptionsExData: [],
    POGChoicesData: [],
    adminProductOptionsData: [],
    publishProductsData: [],
    adminProductsOptionsData: [],
    montagesData: [],
    sendOrderData: [],
    adminSendUpsData: [],
    labelData: [],
    updateTrackingData: [],
    realWeightData: [],
    orderProductFilesData: [],
    montageData: [],
    montageProcessData: [],
    cubesData: [],
    cubeData: [],
    cubeProductFilterData: [],
    cubeProductsData: [],
    cubeProductData: [],
    orderProcessStageData: [],
    orderProcessStatusData: [],
    orderProductFileData: [],
    orderProductFileData2: [],
    orderData: [],
    clientOrdersData: [],
    clientMessagesData: [],
    ordersData: [],
    montageProductsData: [],
    montageFilterGroupData: [],
    sitesData: [],
    montageFormatData: [],
    montageQuantityData: [],
    montagePaperOptionsData: [],
    montagePapersData: [],
    montageFormatsData: [],
    montagePlatesData: [],
    createMontageData: [],
    updateMontageProductData: [],
    clientsData: [],
    clientData: [],
    createClientData: [],
    deleteClientData: [],
    updateClientData: [],
    adminClientsData: [],
    clientsContactData: [],
    countriesData: [],
    discountGroupsData: [],
    discountGroupData: [],
    createDiscountGroupsData: [],
    updateDiscountGroupsData: [],
    deleteDiscountGroupsData: [],
    orderProductsFileData: [],
    messagesData: [],
    messageData: [],
    adminsContactData: [],
    adminsReasonsData: [],
    adminsComplaintData: [],
    updateMessageData: [],
    createMessageData: [],
    conversationData: [],
    discountsData: [],
    discountData: [],
    updateDiscountsData: [],
    createDiscountsForQData: [],
    invoicesData: [],
    tagsData: [],
    apmData: [],
    createOrderData: [],
    paymentsImportData: [],
    orderLogsData: [],
    processStagesData: [],
    montageStatsData: [],
    productionStatsData: [],
    systemLogsData: [],
    clientStatsData: [],
    productsStatsData: [],
    packageStatsData: [],
    salesStatsData: [],
    salesGroupsData: [],
  },
  mutations: {
    addLoginData(state, data) {
      state.loginData = data;
    },
    addAdminsData(state, data) {
      state.adminsData = data;
    },
    addAdminsPGsData(state, data) {
      state.adminsPGsData = data;
    },
    addOnlineAdminsData(state, data) {
      state.onlineAdminsData = data;
    },
    addAdminBarcodeData(state, data) {
      state.adminsBarcodeData = data;
    },
    addAdminData(state, data) {
      state.adminData = data;
    },
    addAdminEditData(state, data) {
      state.adminEditData = data;
    },
    addAdminGroupEditData(state, data) {
      state.adminGroupEditData = data;
    },
    addAdminEditionData(state, data) {
      state.adminEditionData = data;
    },
    addMachinesData(state, data) {
      state.machinesData = data;
    },
    addMachineActionsData(state, data) {
      state.machineActionsData = data;
    },
    addCompetenciesData(state, data) {
      state.competenciesData = data;
    },
    addPermissionsGroupsData(state, data) {
      state.permissionsGroupsData = data;
    },
    addPermissionsGroupData(state, data) {
      state.permissionsGroupData = data;
    },
    addPermissionsData(state, data) {
      state.permissionsData = data;
    },
    addCreateAdminData(state, data) {
      state.createAdminData = data;
    },
    addAdminDeleteData(state, data) {
      state.deleteAdminData = data;
    },
    addAdminLogsData(state, data) {
      state.adminLogsData = data;
    },
    addDeletePermissionsGroupData(state, data) {
      state.deletePermissionsGroupData = data;
    },
    addCreatePermissionsGroupData(state, data) {
      state.createPermissionsGroupData = data;
    },
    addUpdatePermissionsGroupData(state, data) {
      state.updatePermissionsGroupData = data;
    },
    addDeleteCompetenceData(state, data) {
      state.deleteCompetenceData = data;
    },
    addCreateCompetenceData(state, data) {
      state.createCompetenceData = data;
    },
    addCompetenceData(state, data) {
      state.competenceData = data;
    },
    addUpdateCompetenceData(state, data) {
      state.updateCompetenceData = data;
    },
    addIpsData(state, data) {
      state.ipsData = data;
    },
    addIpData(state, data) {
      state.ipData = data;
    },
    addCreateIpData(state, data) {
      state.createIpData = data;
    },
    addDeleteIpData(state, data) {
      state.deleteIpData = data;
    },
    addUpdateIpData(state, data) {
      state.updateIpData = data;
    },
    addSettingsData(state, data) {
      state.settingsData = data;
    },
    addSettingData(state, data) {
      state.settingData = data;
    },
    addCreateSettingData(state, data) {
      state.createSettingData = data;
    },
    addDeleteSettingData(state, data) {
      state.deleteSettingData = data;
    },
    addUpdateSettingData(state, data) {
      state.updateSettingData = data;
    },
    addHolidaysData(state, data) {
      state.holidaysData = data;
    },
    addCreateHolidaysData(state, data) {
      state.createHolidaysData = data;
    },
    addDeleteHolidaysData(state, data) {
      state.deleteHolidaysData = data;
    },
    addPackagesData(state, data) {
      state.packagesData = data;
    },
    addPackageData(state, data) {
      state.packageData = data;
    },
    addCreatePackagesData(state, data) {
      state.createPackagesData = data;
    },
    addDeletePackagesData(state, data) {
      state.deletePackagesData = data;
    },
    addUpdatePackagesData(state, data) {
      state.updatePackagesData = data;
    },
    addProcessesData(state, data) {
      state.processesData = data;
    },
    addProcessData(state, data) {
      state.processData = data;
    },
    addCreateProcessesData(state, data) {
      state.createProcessesData = data;
    },
    addDeleteProcessesData(state, data) {
      state.deleteProcessesData = data;
    },
    addUpdateProcessesData(state, data) {
      state.updateProcessesData = data;
    },
    addPlatesData(state, data) {
      state.platesData = data;
    },
    addPlateData(state, data) {
      state.plateData = data;
    },
    addUpdatePlatesData(state, data) {
      state.updatePlatesData = data;
    },
    addCreatePlatesData(state, data) {
      state.createPlatesData = data;
    },
    addDeletePlatesData(state, data) {
      state.deletePlatesData = data;
    },
    addVariablesData(state, data) {
      state.variablesData = data;
    },
    addVariableData(state, data) {
      state.variableData = data;
    },
    addCreateVariablesData(state, data) {
      state.createVariablesData = data;
    },
    addUpdateVariablesData(state, data) {
      state.updateVariablesData = data;
    },
    addDeleteVariablesData(state, data) {
      state.deleteVariablesData = data;
    },
    addProdlogsData(state, data) {
      state.prodlogsData = data;
    },
    addNewsListData(state, data) {
      state.newsListData = data;
    },
    addNewsData(state, data) {
      state.newsData = data;
    },
    addCreateNewsData(state, data) {
      state.createNewsData = data;
    },
    addUpdateNewsData(state, data) {
      state.updateNewsData = data;
    },
    addDeleteNewsData(state, data) {
      state.deleteNewsData = data;
    },
    addBannersData(state, data) {
      state.bannersData = data;
    },
    addBannerData(state, data) {
      state.bannerData = data;
    },
    addCreateBannersData(state, data) {
      state.createBannersData = data;
    },
    addUpdateBannersData(state, data) {
      state.updateBannersData = data;
    },
    addDeleteBannersData(state, data) {
      state.deleteBannersData = data;
    },
    addPageBannersData(state, data) {
      state.pageBannersData = data;
    },
    addCreateMachinesData(state, data) {
      state.createMachinesData = data;
    },
    addDeleteMachinesData(state, data) {
      state.deleteMachinesData = data;
    },
    addUpdateMachinesData(state, data) {
      state.updateMachinesData = data;
    },
    addMachineData(state, data) {
      state.machineData = data;
    },
    addLogStatusesData(state, data) {
      state.statusesData = data;
    },
    addProdLogsSummaryData(state, data) {
      state.prodLogsSummaryData = data;
    },
    addPagesData(state, data) {
      state.pagesData = data;
    },
    addUpdatePagesData(state, data) {
      state.updatePagesData = data;
    },
    addPageTypesData(state, data) {
      state.pageTypesData = data;
    },
    addPageMenuData(state, data) {
      state.pageMenuData = data;
    },
    addFaqsData(state, data) {
      state.faqsData = data;
    },
    addDeleteFaqData(state, data) {
      state.deleteFaqData = data;
    },
    addCreateFaqData(state, data) {
      state.createFaqData = data;
    },
    addFaqData(state, data) {
      state.faqData = data;
    },
    addUpdateFaqData(state, data) {
      state.updateFaqData = data;
    },
    addTranslationsData(state, data) {
      state.translationsData = data;
    },
    addUpdateTranslationsData(state, data) {
      state.updateTranslationsData = data;
    },
    addTranslationsModelsData(state, data) {
      state.translationsModelsData = data;
    },
    addUpdateTranslationsModelsData(state, data) {
      state.updateTranslationsModelsData = data;
    },
    addFilesData(state, data) {
      state.filesData = data;
    },
    addFileData(state, data) {
      state.fileData = data;
    },
    addCreateFilesData(state, data) {
      state.createFilesData = data;
    },
    addDeleteFilesData(state, data) {
      state.deleteFilesData = data;
    },
    addUpdateFilesData(state, data) {
      state.updateFilesData = data;
    },
    addImportTMData(state, data) {
      state.importTMData = data;
    },
    addCategoriesData(state, data) {
      state.categoriesData = data;
    },
    addCategoriesAdminProductData(state, data) {
      state.categoriesAdminProductData = data;
    },
    addCategoryData(state, data) {
      state.categoryData = data;
    },
    addUpdateCategoriesData(state, data) {
      state.updateCategoriesData = data;
    },
    addCreateCategoriesData(state, data) {
      state.createCategoriesData = data;
    },
    addDeleteCategoriesData(state, data) {
      state.deleteCategoriesData = data;
    },
    addCategoryParentsData(state, data) {
      state.categoryParentsData = data;
    },
    addPageLayoutsData(state, data) {
      state.pageLayoutsData = data;
    },
    addCreatePagesData(state, data) {
      state.createPagesData = data;
    },
    addDeletePagesData(state, data) {
      state.deletePagesData = data;
    },
    addProductsData(state, data) {
      state.productsData = data;
    },
    addCreateProductsData(state, data) {
      state.createProductsData = data;
    },
    addPageData(state, data) {
      state.pageData = data;
    },
    addProductMaterialsData(state, data) {
      state.productMaterialsData = data;
    },
    addProductOptionsData(state, data) {
      state.productOptionsData = data;
    },
    addProductFormatsData(state, data) {
      state.productFormatsData = data;
    },
    addAppliancesData(state, data) {
      state.appliancesData = data;
    },
    addApplianceData(state, data) {
      state.applianceData = data;
    },
    addCreateAppliancesData(state, data) {
      state.createAppliancesData = data;
    },
    addUpdateAppliancesData(state, data) {
      state.updateAppliancesData = data;
    },
    addDeleteAppliancesData(state, data) {
      state.deleteAppliancesData = data;
    },
    addParametersData(state, data) {
      state.parametersData = data;
    },
    addAdminParametersData(state, data) {
      state.adminParametersData = data;
    },
    addParameterData(state, data) {
      state.parameterData = data;
    },
    addUpdateParametersData(state, data) {
      state.updateParametersData = data;
    },
    addCreateParametersData(state, data) {
      state.createParametersData = data;
    },
    addDeleteParametersData(state, data) {
      state.deleteParametersData = data;
    },
    addFormatGroupsData(state, data) {
      state.formatGroupsData = data;
    },
    addFormatGroupData(state, data) {
      state.formatGroupData = data;
    },
    addCreateFormatGroupsData(state, data) {
      state.createFormatGroupsData = data;
    },
    addUpdateFormatGroupsData(state, data) {
      state.updateFormatGroupsData = data;
    },
    addDeleteFormatGroupsData(state, data) {
      state.deleteFormatGroupsData = data;
    },
    addFormatsData(state, data) {
      state.formatsData = data;
    },
    addFormatData(state, data) {
      state.formatData = data;
    },
    addCreateFormatsData(state, data) {
      state.createFormatsData = data;
    },
    addUpdateFormatsData(state, data) {
      state.updateFormatsData = data;
    },
    addDeleteFormatsData(state, data) {
      state.deleteFormatsData = data;
    },
    addFormatGroupsAdminData(state, data) {
      state.formatGroupsAdminData = data;
    },
    addPoptionsGroupData(state, data) {
      state.pOptionsGroupData = data;
    },
    addPAoptionsGroupData(state, data) {
      state.paOptionsGroupData = data;
    },
    addPoptionsExData(state, data) {
      state.pOptionsExData = data;
    },
    addCreatePoptionsGroupData(state, data) {
      state.createPoptionsGroupData = data;
    },
    addCreatePoptionsExData(state, data) {
      state.createPoptionsExData = data;
    },
    addPOGData(state, data) {
      state.pOptionGroupData = data;
    },
    addPOEXData(state, data) {
      state.pOptionExData = data;
    },
    addPOGChoicesData(state, data) {
      state.POGChoicesData = data;
    },
    addDeletePoptionsGroupData(state, data) {
      state.deletePoptionsGroupData = data;
    },
    addDeletePoptionsExData(state, data) {
      state.deletePoptionsExData = data;
    },
    addUpdatePoptionsGroupData(state, data) {
      state.updatePoptionsGroupData = data;
    },
    addUpdatePoptionsExData(state, data) {
      state.updatePoptionsExData = data;
    },
    addAdminProductOptionsData(state, data) {
      state.adminProductOptionsData = data;
    },
    addAdminProductSearchData(state, data) {
      state.adminProductSearchData = data;
    },
    addPublishProductsData(state, data) {
      state.publishProductsData = data;
    },
    addAdminProductsOptionsData(state, data) {
      state.adminProductsOptionsData = data;
    },
    addMontagesData(state, data) {
      state.montagesData = data;
    },
    addSendOrderData(state, data) {
      state.sendOrderData = data;
    },
    addAdminSendUpsData(state, data) {
      state.adminSendUpsData = data;
    },
    addLabelData(state, data) {
      state.labelData = data;
    },
    addUpdateTrackingData(state, data) {
      state.updateTrackingData = data;
    },
    addRealWeightData(state, data) {
      state.realWeightData = data;
    },
    addOrderProductFilesData(state, data) {
      state.orderProductFilesData = data;
    },
    addMontageData(state, data) {
      state.montageData = data;
    },
    addMontageProcessData(state, data) {
      state.montageProcessData = data;
    },
    addCubesData(state, data) {
      state.cubesData = data;
    },
    addCubeData(state, data) {
      state.cubeData = data;
    },
    addCubeProductFilterData(state, data) {
      state.cubeProductFilterData = data;
    },
    addCubeProductsData(state, data) {
      state.cubeProductsData = data;
    },
    addCubeProductData(state, data) {
      state.cubeProductData = data;
    },
    addOrderProcessStageData(state, data) {
      state.orderProcessStageData = data;
    },
    addOrderProcessStatusData(state, data) {
      state.orderProcessStatusData = data;
    },
    addOrderProductFileData(state, data) {
      state.orderProductFileData = data;
    },
    addOrderProductFileData2(state, data) {
      state.orderProductFileData2 = data;
    },
    addOrderData(state, data) {
      state.orderData = data;
    },
    addOrdersData(state, data) {
      state.ordersData = data;
    },
    addClientOrdersData(state, data) {
      state.clientOrdersData = data;
    },
    addClientMessagesData(state, data) {
      state.clientMessagesData = data;
    },
    addMontageProductsData(state, data) {
      state.montageProductsData = data;
    },
    addMontageFilterGroupData(state, data) {
      state.montageFilterGroupData = data;
    },
    addSitesData(state, data) {
      state.sitesData = data;
    },
    addMontageFormatData(state, data) {
      state.montageFormatData = data;
    },
    addMontageQuantityData(state, data) {
      state.montageQuantityData = data;
    },
    addMontagePaperOptionsData(state, data) {
      state.montagePaperOptionsData = data;
    },
    addMontagePapersData(state, data) {
      state.montagePapersData = data;
    },
    addMontageFormatsData(state, data) {
      state.montageFormatsData = data;
    },
    addMontagePlatesData(state, data) {
      state.montagePlatesData = data;
    },
    addCreateMontageData(state, data) {
      state.createMontageData = data;
    },
    addUpdateMontageProductData(state, data) {
      state.updateMontageProductData = data;
    },
    addClientsData(state, data) {
      state.clientsData = data;
    },
    addClientData(state, data) {
      state.clientData = data;
    },
    addAdminClientsData(state, data) {
      state.adminClientsData = data;
    },
    addCountriesData(state, data) {
      state.countriesData = data;
    },
    addCreateClientData(state, data) {
      state.createClientData = data;
    },
    addDeleteClientData(state, data) {
      state.deleteClientData = data;
    },
    addUpdateClientData(state, data) {
      state.updateClientData = data;
    },
    addDiscountGroupsData(state, data) {
      state.discountGroupsData = data;
    },
    addDiscountGroupData(state, data) {
      state.discountGroupData = data;
    },
    addCreateDiscountGroupsData(state, data) {
      state.createDiscountGroupsData = data;
    },
    addUpdateDiscountGroupsData(state, data) {
      state.updateDiscountGroupsData = data;
    },
    addDeleteDiscountGroupsData(state, data) {
      state.deleteDiscountGroupsData = data;
    },
    addOrderProductsFileData(state, data) {
      state.orderProductsFileData = data;
    },
    addMessagesData(state, data) {
      state.messagesData = data;
    },
    addMessageData(state, data) {
      state.messageData = data;
    },
    addClientsContactData(state, data) {
      state.clientsContactData = data;
    },
    addAdminContactData(state, data) {
      state.adminsContactData = data;
    },
    addAdminReasonsData(state, data) {
      state.adminsReasonsData = data;
    },
    addAdminComplaintData(state, data) {
      state.adminsComplaintData = data;
    },
    addUpdateMessageData(state, data) {
      state.updateMessageData = data;
    },
    addCreateMessageData(state, data) {
      state.createMessageData = data;
    },
    addConversationData(state, data) {
      state.conversationData = data;
    },
    addDiscountsData(state, data) {
      state.discountsData = data;
    },
    addDiscountData(state, data) {
      state.discountData = data;
    },
    addUpdateDiscountsData(state, data) {
      state.updateDiscountsData = data;
    },
    addCreateDiscountsForQData(state, data) {
      state.createDiscountsForQData = data;
    },
    addInvoicesData(state, data) {
      state.invoicesData = data;
    },
    addTagsData(state, data) {
      state.tagsData = data;
    },
    addApmData(state, data) {
      state.apmData = data;
    },
    addCreateOrderData(state, data) {
      state.createOrderData = data;
    },
    addPaymentsImportData(state, data) {
      state.paymentsImportData = data;
    },
    addOrderLogsData(state, data) {
      state.orderLogsData = data;
    },
    addProcessStagesData(state, data) {
      state.processStagesData = data;
    },
    addMontageStatsData(state, data) {
      state.montageStatsData = data;
    },
    addProductionStatsData(state, data) {
      state.productionStatsData = data;
    },
    addSystemLogsData(state, data) {
      state.systemLogsData = data;
    },
    addClientStatsData(state, data) {
      state.clientStatsData = data;
    },
    addProductsStatsData(state, data) {
      state.productsStatsData = data;
    },
    addPackageStatsData(state, data) {
      state.packageStatsData = data;
    },
    addSaleStatsData(state, data) {
      state.salesStatsData = data;
    },
    addSalesGroupsData(state, data) {
      state.salesGroupsData = data;
    },
  },
  actions: {
    showToaster(context, payload) {
      if (websocket.readyState === 1) {
        websocket.send(JSON.stringify({ is_online: true }));
      }
      toast(payload.content, {
        theme: "colored",
        autoClose: "",
        type: payload.type,
        dangerouslyHTMLString: true,
      });
    },
    login(context, payload) {
      var data = new FormData();
      data.append("username", payload.username);
      data.append("password", payload.password);

      axios
        .post(LOGIN, data)
        .then((response) => {
          context.commit("addLoginData", response);
        })
        .catch((error) => {
          context.commit("addLoginData", error.response);
        });
    },
    getAdmins(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var pgurl;
      if (payload?.page) {
        pgurl =
          ADMINS_LIST +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = ADMINS_LIST;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === ADMINS_LIST) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      if (payload.userPk) {
        if (pgurl === ADMINS_LIST) {
          pgurl += "?user_pk_on_first_row=" + payload.userPk;
        } else {
          pgurl += "&user_pk_on_first_row=" + payload.userPk;
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addAdminsData", response);
        })
        .catch((error) => {
          context.commit("addAdminsData", error.response);
        });
    },
    getAdminsPGs(context, pk) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(ADMINS_LIST + "/?permission_group_pks=" + pk)
        .then((response) => {
          context.commit("addAdminsPGsData", response);
        })
        .catch((error) => {
          context.commit("addAdminsPGsData", error.response);
        });
    },
    getOnlineAdmins(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(ADMINS_LIST + "?user_pks=" + payload)
        .then((response) => {
          context.commit("addOnlineAdminsData", response);
        })
        .catch((error) => {
          context.commit("addOnlineAdminsData", error.response);
        });
    },
    getAdminProductionLogs(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      if (!payload.params) {
        payload.params = "";
      }
      axios
        .get(PRODUCTION_LOGS + "?user_pk=" + payload.pk + payload.params)
        .then((response) => {
          context.commit("addAdminLogsData", response);
        })
        .catch((error) => {
          context.commit("addAdminLogsData", error.response);
        });
    },
    getAdmin(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(ADMINS_LIST + "/" + payload.pk)
        .then((response) => {
          context.commit("addAdminEditData", response);
        })
        .catch((error) => {
          context.commit("addAdminEditData", error.response);
        });
    },
    updateAdmin(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      const serializerOptions = {
        indexes: null,
        dots: true,
      };

      if (typeof payload.image != "string" && payload.image) {
        axios.patchForm(ADMINS_LIST + "/" + payload.pk, {
          image: payload.image,
        });
        delete payload.image;
      }

      axios
        .patch(ADMINS_LIST + "/" + payload.pk, payload, {
          formSerializer: serializerOptions,
        })
        .then((response) => {
          context.commit("addAdminEditionData", response);
        })
        .catch((error) => {
          context.commit("addAdminEditionData", error.response);
        });
    },
    updateAdminsGroup(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .patch(ADMINS_LIST_GROUP, payload)
        .then((response) => {
          context.commit("addAdminGroupEditData", response);
        })
        .catch((error) => {
          context.commit("addAdminGroupEditData", error.response);
        });
    },
    deleteAdmin(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .patch(ADMINS_LIST + "/" + payload.pk, payload.userObj)
        .then((response) => {
          context.commit("addAdminDeleteData", response);
        })
        .catch((error) => {
          context.commit("addAdminDeleteData", error.response);
        });
    },
    getAdminBarcode(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(ADMINS_LIST + "/" + payload.pk + "/barcode")
        .then((response) => {
          context.commit("addAdminBarcodeData", response);
        })
        .catch((error) => {
          context.commit("addAdminBarcodeData", error.response);
        });
    },
    getAdminByUsername(context, payload) {
      var data = new FormData();
      data.append("username", payload.username);
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .post(ADMIN_BY_USERNAME, data)
        .then((response) => {
          context.commit("addAdminData", response);
        })
        .catch((error) => {
          context.commit("addAdminData", error.response);
        });
    },
    getMachines(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var pgurl;
      if (payload?.page) {
        pgurl =
          MACHINES +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = MACHINES;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === MACHINES) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }
      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addMachinesData", response);
        })
        .catch((error) => {
          context.commit("addMachinesData", error.response);
        });
    },
    getMachineActions(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(MACHINE_ACTIONS + "admin_list/")
        .then((response) => {
          context.commit("addMachineActionsData", response);
        })
        .catch((error) => {
          context.commit("addMachineActionsData", error.response);
        });
    },
    updateMachines(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
        "Content-Type": "multipart/form-data",
      };
      let actions = [];
      var data = new FormData();
      data.append("name", payload.name);
      data.append("model", payload.model);
      data.append("type", payload.type);
      Object.keys(payload.actions).forEach((e) => {
        actions.push(payload.actions[e]);
      });
      data.append(`actions`, JSON.stringify(actions));
      if (typeof payload.image != "string" && payload.image) {
        data.append("image", payload.image);
      }
      axios
        .patch(MACHINES + payload.pk, data)
        .then((response) => {
          context.commit("addUpdateMachinesData", response);
        })
        .catch((error) => {
          context.commit("addUpdateMachinesData", error.response);
        });
    },
    getMachine(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(MACHINES + payload.pk)
        .then((response) => {
          context.commit("addMachineData", response);
        })
        .catch((error) => {
          context.commit("addMachineData", error.response);
        });
    },
    createMachines(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
        "Content-Type": "multipart/form-data",
      };
      let actions = [];
      var data = new FormData();
      data.append("name", payload.name);
      data.append("model", payload.model);
      data.append("type", payload.type);
      data.append("image", payload.image);
      Object.keys(payload.actions).forEach((e) => {
        actions.push(payload.actions[e]);
      });
      data.append(`actions`, JSON.stringify(actions));

      axios
        .post(MACHINES, data)
        .then((response) => {
          context.commit("addCreateMachinesData", response);
        })
        .catch((error) => {
          context.commit("addCreateMachinesData", error.response);
        });
    },
    deleteMachines(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .delete(MACHINES + payload.pk)
        .then((response) => {
          context.commit("addDeleteMachinesData", response);
        })
        .catch((error) => {
          context.commit("addDeleteMachinesData", error.response);
        });
    },
    getCompetencies(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var pgurl;
      if (payload && payload?.page) {
        pgurl =
          COMPETENCIES_LIST +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = COMPETENCIES_LIST;
      }
      if (payload && payload?.search) {
        for (const [key, value] of Object.entries(payload?.search)) {
          if (pgurl === ADMINS_LIST) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }
      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addCompetenciesData", response);
        })
        .catch((error) => {
          context.commit("addCompetenciesData", error.response);
        });
    },
    createCompetence(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .post(COMPETENCIES_LIST + "/", payload)
        .then((response) => {
          context.commit("addCreateCompetenceData", response);
        })
        .catch((error) => {
          context.commit("addCreateCompetenceData", error.response);
        });
    },
    getPermissions(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(PERMISSIONS_LIST)
        .then((response) => {
          context.commit("addPermissionsData", response);
        })
        .catch((error) => {
          context.commit("addPermissionsData", error.response);
        });
    },
    getPermissionsGroups(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var pgurl;
      if (payload?.page) {
        pgurl =
          PERMISSIONS_GROUP_LIST +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = PERMISSIONS_GROUP_LIST;
      }
      if (payload && payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === PERMISSIONS_GROUP_LIST) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addPermissionsGroupsData", response);
        })
        .catch((error) => {
          context.commit("addPermissionsGroupsData", error.response);
        });
    },
    getPermissionsGroup(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.pk) {
        pgurl = PERMISSIONS_GROUP_LIST + "/" + payload.pk;
      } else {
        pgurl = PERMISSIONS_GROUP_LIST;
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addPermissionsGroupData", response);
        })
        .catch((error) => {
          context.commit("addPermissionsGroupData", error.response);
        });
    },
    deletePermissionsGroup(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .delete(PERMISSIONS_GROUP_LIST + "/" + payload.pk)
        .then((response) => {
          context.commit("addDeletePermissionsGroupData", response);
        })
        .catch((error) => {
          context.commit("addDeletePermissionsGroupData", error.response);
        });
    },
    deleteCompetence(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .delete(COMPETENCIES_LIST + "/" + payload.pk)
        .then((response) => {
          context.commit("addDeleteCompetenceData", response);
        })
        .catch((error) => {
          context.commit("addDeleteCompetenceData", error.response);
        });
    },
    getCompetence(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(COMPETENCIES_LIST + "/" + payload.pk)
        .then((response) => {
          context.commit("addCompetenceData", response);
        })
        .catch((error) => {
          context.commit("addCompetenceData", error.response);
        });
    },
    updateCompetence(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .patch(COMPETENCIES_LIST + "/" + payload.pk, payload)
        .then((response) => {
          context.commit("addUpdateCompetenceData", response);
        })
        .catch((error) => {
          context.commit("addUpdateCompetenceData", error.response);
        });
    },
    createAdmin(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      let tempImg;
      if (payload.value.image) {
        tempImg = payload.value.image;
        delete payload.value.image;
      }

      const serializerOptions = {
        indexes: null,
        dots: true,
      };

      axios
        .post(ADMINS_LIST + "/", payload.value, {
          formSerializer: serializerOptions,
        })
        .then((response) => {
          if (response?.data?.pk) {
            axios.patchForm(ADMINS_LIST + "/" + response?.data?.pk, {
              image: tempImg,
            });
          }
          context.commit("addCreateAdminData", response);
        })
        .catch((error) => {
          context.commit("addCreateAdminData", error.response);
        });
    },
    createPermissionsGroup(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .post(PERMISSIONS_GROUP_LIST + "/", payload)
        .then((response) => {
          context.commit("addCreatePermissionsGroupData", response);
        })
        .catch((error) => {
          context.commit("addCreatePermissionsGroupData", error.response);
        });
    },
    updatePermissionsGroup(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .patch(PERMISSIONS_GROUP_LIST + "/" + payload.pk, payload)
        .then((response) => {
          context.commit("addUpdatePermissionsGroupData", response);
        })
        .catch((error) => {
          context.commit("addUpdatePermissionsGroupData", error.response);
        });
    },
    getIps(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          ALLOWED_IPS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = ALLOWED_IPS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === ALLOWED_IPS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addIpsData", response);
        })
        .catch((error) => {
          context.commit("addIpsData", error.response);
        });
    },
    getIp(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(ALLOWED_IPS + "/" + payload.pk + "/")
        .then((response) => {
          context.commit("addIpData", response);
        })
        .catch((error) => {
          context.commit("addIpData", error.response);
        });
    },
    deleteIp(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .delete(ALLOWED_IPS + "/" + payload.pk + "/")
        .then((response) => {
          context.commit("addDeleteIpData", response);
        })
        .catch((error) => {
          context.commit("addDeleteIpData", error.response);
        });
    },
    updateIp(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .patch(ALLOWED_IPS + "/" + payload.pk + "/", payload)
        .then((response) => {
          context.commit("addUpdateIpData", response);
        })
        .catch((error) => {
          context.commit("addUpdateIpData", error.response);
        });
    },
    createIp(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .post(ALLOWED_IPS + "/", payload)
        .then((response) => {
          context.commit("addCreateIpData", response);
        })
        .catch((error) => {
          context.commit("addCreateIpData", error.response);
        });
    },
    getSettings(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          SETTINGS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = SETTINGS;
      }
      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === SETTINGS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addSettingsData", response);
        })
        .catch((error) => {
          context.commit("addSettingsData", error.response);
        });
    },
    getSetting(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(SETTINGS + "/" + payload.pk + "/")
        .then((response) => {
          context.commit("addSettingData", response);
        })
        .catch((error) => {
          context.commit("addSettingData", error.response);
        });
    },
    deleteSetting(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .delete(SETTINGS + "/" + payload.pk + "/")
        .then((response) => {
          context.commit("addDeleteSettingData", response);
        })
        .catch((error) => {
          context.commit("addDeleteSettingData", error.response);
        });
    },
    updateSetting(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .patch(SETTINGS + "/" + payload.pk + "/", payload)
        .then((response) => {
          context.commit("addUpdateSettingData", response);
        })
        .catch((error) => {
          context.commit("addUpdateSettingData", error.response);
        });
    },
    createSetting(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .post(SETTINGS + "/", payload)
        .then((response) => {
          context.commit("addCreateSettingData", response);
        })
        .catch((error) => {
          context.commit("addCreateSettingData", error.response);
        });
    },
    getHolidays(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(HOLIDAYS)
        .then((response) => {
          context.commit("addHolidaysData", response);
        })
        .catch((error) => {
          context.commit("addHolidaysData", error.response);
        });
    },
    createHolidays(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .post(HOLIDAYS + "/", payload)
        .then((response) => {
          context.commit("addCreateHolidaysData", response);
        })
        .catch((error) => {
          context.commit("addCreateHolidaysData", error.response);
        });
    },
    deleteHolidays(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .delete(HOLIDAYS + "/" + payload.pk)
        .then((response) => {
          context.commit("addDeleteHolidaysData", response);
        })
        .catch((error) => {
          context.commit("addDeleteHolidaysData", error.response);
        });
    },
    getPackages(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var pgurl;
      if (payload?.page) {
        pgurl =
          PACKAGES +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = PACKAGES;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === PACKAGES) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }
      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addPackagesData", response);
        })
        .catch((error) => {
          context.commit("addPackagesData", error.response);
        });
    },
    getPackage(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(PACKAGES + "/" + payload.pk)
        .then((response) => {
          context.commit("addPackageData", response);
        })
        .catch((error) => {
          context.commit("addPackageData", error.response);
        });
    },
    createPackages(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .post(PACKAGES, payload)
        .then((response) => {
          context.commit("addCreatePackagesData", response);
        })
        .catch((error) => {
          context.commit("addCreatePackagesData", error.response);
        });
    },
    updatePackages(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .patch(PACKAGES + payload.pk, payload)
        .then((response) => {
          context.commit("addUpdatePackagesData", response);
        })
        .catch((error) => {
          context.commit("addUpdatePackagesData", error.response);
        });
    },
    deletePackages(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .delete(PACKAGES + payload.pk)
        .then((response) => {
          context.commit("addDeletePackagesData", response);
        })
        .catch((error) => {
          context.commit("addDeletePackagesData", error.response);
        });
    },
    getProcesses(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var pgurl;
      if (payload?.page) {
        pgurl =
          PROCESSES +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = PROCESSES;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === PROCESSES) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }
      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addProcessesData", response);
        })
        .catch((error) => {
          context.commit("addProcessesData", error.response);
        });
    },
    getProcess(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(PROCESSES + payload.pk)
        .then((response) => {
          context.commit("addProcessData", response);
        })
        .catch((error) => {
          context.commit("addProcessData", error.response);
        });
    },
    updateProcesses(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .patch(PROCESSES + payload.pk, payload)
        .then((response) => {
          context.commit("addUpdateProcessesData", response);
        })
        .catch((error) => {
          context.commit("addUpdateProcessesData", error.response);
        });
    },
    createProcesses(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .post(PROCESSES, payload)
        .then((response) => {
          context.commit("addCreateProcessesData", response);
        })
        .catch((error) => {
          context.commit("addCreateProcessesData", error.response);
        });
    },
    deleteProcesses(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .delete(PROCESSES + payload.pk)
        .then((response) => {
          context.commit("addDeleteProcessesData", response);
        })
        .catch((error) => {
          context.commit("addDeleteProcessesData", error.response);
        });
    },
    getPlates(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var pgurl;
      if (payload?.page) {
        pgurl =
          PLATES +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = PLATES;
      }
      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === PLATES) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }
      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addPlatesData", response);
        })
        .catch((error) => {
          context.commit("addPlatesData", error.response);
        });
    },
    getPlate(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(PLATES + payload.pk)
        .then((response) => {
          context.commit("addPlateData", response);
        })
        .catch((error) => {
          context.commit("addPlateData", error.response);
        });
    },
    updatePlates(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .patch(PLATES + payload.pk, payload)
        .then((response) => {
          context.commit("addUpdatePlatesData", response);
        })
        .catch((error) => {
          context.commit("addUpdatePlatesData", error.response);
        });
    },
    createPlates(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .post(PLATES, payload)
        .then((response) => {
          context.commit("addCreatePlatesData", response);
        })
        .catch((error) => {
          context.commit("addCreatePlatesData", error.response);
        });
    },
    deletePlates(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .delete(PLATES + payload.pk)
        .then((response) => {
          context.commit("addDeletePlatesData", response);
        })
        .catch((error) => {
          context.commit("addDeletePlatesData", error.response);
        });
    },
    getVariables(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var pgurl;
      if (payload?.page) {
        pgurl =
          VARIABLES +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = VARIABLES;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === VARIABLES) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }
      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addVariablesData", response);
        })
        .catch((error) => {
          context.commit("addVariablesData", error.response);
        });
    },
    getVariable(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(VARIABLES + payload.pk)
        .then((response) => {
          context.commit("addVariableData", response);
        })
        .catch((error) => {
          context.commit("addVariableData", error.response);
        });
    },
    createVariables(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .post(VARIABLES, payload)
        .then((response) => {
          context.commit("addCreateVariablesData", response);
        })
        .catch((error) => {
          context.commit("addCreateVariablesData", error.response);
        });
    },
    updateVariables(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .patch(VARIABLES + payload.pk, payload)
        .then((response) => {
          context.commit("addUpdateVariablesData", response);
        })
        .catch((error) => {
          context.commit("addUpdateVariablesData", error.response);
        });
    },
    deleteVariables(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .delete(VARIABLES + payload.pk)
        .then((response) => {
          context.commit("addDeleteVariablesData", response);
        })
        .catch((error) => {
          context.commit("addDeleteVariablesData", error.response);
        });
    },
    getMontages(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var pgurl;
      if (payload?.page) {
        pgurl =
          MONTAGES +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = MONTAGES;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === MONTAGES) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      if (payload.isArchive) {
        if (pgurl === MONTAGES) {
          pgurl += "?is_archive=" + payload.isArchive;
        } else {
          pgurl += "&is_archive=" + payload.isArchive;
        }
      }

      if (payload.filters) {
        Object.keys(payload.filters).forEach((e) => {
          if (payload.filters[e].length) {
            Object.keys(payload.filters[e]).forEach((e1) => {
              pgurl += "&" + e + "=" + payload.filters[e][e1];
            });
          }
        });
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addMontagesData", response);
        })
        .catch((error) => {
          context.commit("addMontagesData", error.response);
        });
    },
    getMontage(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(MONTAGES + payload.pk)
        .then((response) => {
          context.commit("addMontageData", response);
        })
        .catch((error) => {
          context.commit("addMontageData", error.response);
        });
    },
    getProdLogsSummary(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(PRODLOGS + "summary")
        .then((response) => {
          context.commit("addProdLogsSummaryData", response);
        })
        .catch((error) => {
          context.commit("addProdLogsSummaryData", error.response);
        });
    },
    getProdlogs(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var pgurl;
      let day, month, year, dayEnd, monthEnd, yearEnd, dateMax, dateMin;
      if (payload?.date) {
        if (payload.date[0]) {
          day = payload.date[0].getDate();
          month = payload.date[0].getMonth() + 1;
          year = payload.date[0].getFullYear();

          dateMin = `${day}.${month}.${year}`;
        }

        if (payload.date[1]) {
          dayEnd = payload.date[1].getDate();
          monthEnd = payload.date[1].getMonth() + 1;
          yearEnd = payload.date[1].getFullYear();
          dateMax = `${dayEnd}.${monthEnd}.${yearEnd}`;
        }
      }
      if (payload?.page) {
        pgurl =
          PRODLOGS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;

        if (payload.status) {
          pgurl += "&production_process=" + payload.status;
        }

        if (payload.date) {
          pgurl += "&max_date=" + dateMax;
          pgurl += "&min_date=" + dateMin;
        }
      } else {
        pgurl = PRODLOGS;
        if (payload.status) {
          pgurl += "?production_process=" + payload.status;
          if (payload.date) {
            pgurl += "&max_date=" + dateMax;
            pgurl += "&min_date=" + dateMin;
          }
        }
      }
      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === PRODLOGS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }
      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addProdlogsData", response);
        })
        .catch((error) => {
          context.commit("addProdlogsData", error.response);
        });
    },
    getNewsList(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var pgurl;
      if (payload?.page) {
        pgurl =
          NEWS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = NEWS;
      }
      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === NEWS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }
      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addNewsListData", response);
        })
        .catch((error) => {
          context.commit("addNewsListData", error.response);
        });
    },
    getNews(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(NEWS + payload.pk)
        .then((response) => {
          context.commit("addNewsData", response);
        })
        .catch((error) => {
          context.commit("addNewsData", error.response);
        });
    },
    createNews(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var data = new FormData();
      data.append("title_pl", payload.title_pl);
      data.append("content_pl", payload.content_pl);
      data.append("title_en", payload.title_en);
      data.append("content_en", payload.content_en);
      data.append("title_de", payload.title_de);
      data.append("content_de", payload.content_de);
      data.append("title_cpde", payload.title_cpde);
      data.append("content_cpde", payload.content_cpde);
      data.append("title_cpen", payload.title_cpen);
      data.append("content_cpen", payload.content_cpen);
      data.append("publication_date", payload.publication_date);
      axios
        .post(NEWS, data)
        .then((response) => {
          context.commit("addCreateNewsData", response);
        })
        .catch((error) => {
          context.commit("addCreateNewsData", error.response);
        });
    },
    updateNews(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var data = new FormData();
      data.append("title_pl", payload.title_pl);
      data.append("content_pl", payload.content_pl);
      data.append("title_en", payload.title_en);
      data.append("content_en", payload.content_en);
      data.append("title_de", payload.title_de);
      data.append("content_de", payload.content_de);
      data.append("title_cpde", payload.title_cpde);
      data.append("content_cpde", payload.content_cpde);
      data.append("title_cpen", payload.title_cpen);
      data.append("content_cpen", payload.content_cpen);
      data.append("publication_date", payload.publication_date);
      axios
        .patch(NEWS + payload.pk, data)
        .then((response) => {
          context.commit("addUpdateNewsData", response);
        })
        .catch((error) => {
          context.commit("addUpdateNewsData", error.response);
        });
    },
    deleteNews(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .delete(NEWS + payload.pk)
        .then((response) => {
          context.commit("addDeleteNewsData", response);
        })
        .catch((error) => {
          context.commit("addDeleteNewsData", error.response);
        });
    },
    getBanners(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var pgurl;
      if (payload?.page) {
        pgurl =
          BANNERS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = BANNERS;
      }
      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === BANNERS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }
      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addBannersData", response);
        })
        .catch((error) => {
          context.commit("addBannersData", error.response);
        });
    },
    getBanner(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(BANNERS + payload.pk)
        .then((response) => {
          context.commit("addBannerData", response);
        })
        .catch((error) => {
          context.commit("addBannerData", error.response);
        });
    },
    getPageBanners(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(PAGES_BANNERS)
        .then((response) => {
          context.commit("addPageBannersData", response);
        })
        .catch((error) => {
          context.commit("addPageBannersData", error.response);
        });
    },
    getLogStatuses(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(PRODUCTION_LOGS + "log_statuses/")
        .then((response) => {
          context.commit("addLogStatusesData", response);
        })
        .catch((error) => {
          context.commit("addLogStatusesData", error.response);
        });
    },
    createBanners(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var data = new FormData();
      data.append("name", payload.name);
      data.append("url", payload.url);
      data.append("is_published", payload.is_published);
      data.append("datetime_from", payload.datetime_from);
      data.append("datetime_to", payload.datetime_to);
      data.append("display_time", payload.display_time);
      data.append("image", payload.image);
      data.append("available_in_countries", payload.available_in_countries);
      data.append("pages", payload.pages);
      data.append("description_pl", payload.description_pl);
      data.append("description_de", payload.description_de);
      data.append("description_en", payload.description_en);
      data.append("description_cpde", payload.description_cpde);
      data.append("description_cpen", payload.description_cpen);
      axios
        .post(BANNERS, data)
        .then((response) => {
          context.commit("addCreateBannersData", response);
        })
        .catch((error) => {
          context.commit("addCreateBannersData", error.response);
        });
    },
    updateBanners(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var data = new FormData();
      data.append("name", payload.name);
      data.append("url", payload.url);
      data.append("is_published", payload.is_published);
      data.append("datetime_from", payload.datetime_from);
      data.append("datetime_to", payload.datetime_to);
      data.append("display_time", payload.display_time);
      data.append("image", payload.image);
      data.append("available_in_countries", payload.available_in_countries);
      data.append("pages", payload.pages);
      data.append("description_pl", payload.description_pl);
      data.append("description_de", payload.description_de);
      data.append("description_en", payload.description_en);
      data.append("description_cpde", payload.description_cpde);
      data.append("description_cpen", payload.description_cpen);
      axios
        .patch(BANNERS + payload.pk, data)
        .then((response) => {
          context.commit("addUpdateBannersData", response);
        })
        .catch((error) => {
          context.commit("addUpdateBannersData", error.response);
        });
    },
    createPages(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .post(PAGES, payload)
        .then((response) => {
          context.commit("addCreatePagesData", response);
        })
        .catch((error) => {
          context.commit("addCreatePagesData", error.response);
        });
    },
    deletePages(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .delete(PAGES + payload.pk)
        .then((response) => {
          context.commit("addDeletePagesData", response);
        })
        .catch((error) => {
          context.commit("addDeletePagesData", error.response);
        });
    },
    getPages(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var pgurl;
      if (payload?.page) {
        pgurl =
          PAGES +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = PAGES;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === PAGES) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addPagesData", response);
        })
        .catch((error) => {
          context.commit("addPagesData", error.response);
        });
    },
    getPage(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(PAGES + payload.pk)
        .then((response) => {
          context.commit("addPageData", response);
        })
        .catch((error) => {
          context.commit("addPageData", error.response);
        });
    },
    updatePages(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .patch(PAGES + payload.pk, payload)
        .then((response) => {
          context.commit("addUpdatePagesData", response);
        })
        .catch((error) => {
          context.commit("addUpdatePagesData", error.response);
        });
    },
    getPageTypes(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(PAGES + "admin_type_options/")
        .then((response) => {
          context.commit("addPageTypesData", response);
        })
        .catch((error) => {
          context.commit("addPageTypesData", error.response);
        });
    },
    getPageLayouts(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(PAGES + "admin_layout_options/")
        .then((response) => {
          context.commit("addPageLayoutsData", response);
        })
        .catch((error) => {
          context.commit("addPageLayoutsData", error.response);
        });
    },
    getPageMenu(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(PAGES + "admin_menu_options/")
        .then((response) => {
          context.commit("addPageMenuData", response);
        })
        .catch((error) => {
          context.commit("addPageMenuData", error.response);
        });
    },
    getFaqs(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var pgurl;
      if (payload?.page) {
        pgurl =
          FAQS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = FAQS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === FAQS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addFaqsData", response);
        })
        .catch((error) => {
          context.commit("addFaqsData", error.response);
        });
    },
    getFaq(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(FAQS + payload.pk)
        .then((response) => {
          context.commit("addFaqData", response);
        })
        .catch((error) => {
          context.commit("addFaqData", error.response);
        });
    },
    deleteFaq(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .delete(FAQS + "/" + payload.pk)
        .then((response) => {
          context.commit("addDeleteFaqData", response);
        })
        .catch((error) => {
          context.commit("addDeleteFaqData", error.response);
        });
    },
    createFaq(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .post(FAQS, payload)
        .then((response) => {
          context.commit("addCreateFaqData", response);
        })
        .catch((error) => {
          context.commit("addCreateFaqData", error.response);
        });
    },
    updateFaq(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .patch(FAQS + payload.pk, payload)
        .then((response) => {
          context.commit("addUpdateFaqData", response);
        })
        .catch((error) => {
          context.commit("addUpdateFaqData", error.response);
        });
    },
    updateTranslations(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .patch(TRANSLATIONS, payload)
        .then((response) => {
          context.commit("addUpdateTranslationsData", response);
        })
        .catch((error) => {
          context.commit("addUpdateTranslationsData", error.response);
        });
    },
    getTranslations(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var pgurl;
      if (payload?.page) {
        pgurl =
          TRANSLATIONS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = TRANSLATIONS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === TRANSLATIONS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addTranslationsData", response);
        })
        .catch((error) => {
          context.commit("addTranslationsData", error.response);
        });
    },
    getFiles(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var pgurl;
      if (payload?.page) {
        pgurl =
          FILES +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = FILES;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === FILES) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addFilesData", response);
        })
        .catch((error) => {
          context.commit("addFilesData", error.response);
        });
    },
    createFile(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
        "Content-Type": "multipart/form-data",
      };
      axios
        .post(FILES, payload)
        .then((response) => {
          context.commit("addCreateFilesData", response);
        })
        .catch((error) => {
          context.commit("addCreateFilesData", error.response);
        });
    },
    deleteFile(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .delete(FILES + payload.pk)
        .then((response) => {
          context.commit("addDeleteFilesData", response);
        })
        .catch((error) => {
          context.commit("addDeleteFilesData", error.response);
        });
    },
    updateFiles(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
        "Content-Type": "multipart/form-data",
      };
      axios
        .patch(FILES + payload.pk, payload)
        .then((response) => {
          context.commit("addUpdateFilesData", response);
        })
        .catch((error) => {
          context.commit("addUpdateFilesData", error.response);
        });
    },
    getFile(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(FILES + payload.pk)
        .then((response) => {
          context.commit("addFileData", response);
        })
        .catch((error) => {
          context.commit("addFileData", error.response);
        });
    },
    getTranslationsModels(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var pgurl;
      if (payload?.page) {
        pgurl =
          TRANSLATIONS_MODELS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = TRANSLATIONS_MODELS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === TRANSLATIONS_MODELS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addTranslationsModelsData", response);
        })
        .catch((error) => {
          context.commit("addTranslationsModelsData", error.response);
        });
    },
    updateTranslationsModels(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .patch(TRANSLATIONS_MODELS, payload)
        .then((response) => {
          context.commit("addUpdateTranslationsModelsData", response);
        })
        .catch((error) => {
          context.commit("addUpdateTranslationsModelsData", error.response);
        });
    },
    exportTranslationsModels() {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(TRANSLATIONS_MODELS + "export/")
        .then((response) => {
          let blob = new Blob([response.data], {
              type: "application/vnd.ms-excel",
            }),
            url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch();
    },
    importTranslationsModels(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
        "Content-Type": "multipart/form-data",
      };
      axios
        .post(TRANSLATIONS_MODELS + "import_from_file/", payload)
        .then((response) => {
          context.commit("addImportTMData", response);
        })
        .catch((error) => {
          context.commit("addImportTMData", error.response);
        });
    },
    getCategories(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var pgurl;
      if (payload?.page) {
        pgurl =
          CATEGORIES +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = CATEGORIES;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === CATEGORIES) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addCategoriesData", response);
        })
        .catch((error) => {
          context.commit("addCategoriesData", error.response);
        });
    },
    getCategoriesAdminProductFilter(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(CATEGORIES + "admin_product_filter")
        .then((response) => {
          context.commit("addCategoriesAdminProductData", response);
        })
        .catch((error) => {
          context.commit("addCategoriesAdminProductData", error.response);
        });
    },
    getCategoryParents(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(CATEGORIES + "admin_category_parents/")
        .then((response) => {
          context.commit("addCategoryParentsData", response);
        })
        .catch((error) => {
          context.commit("addCategoryParentsData", error.response);
        });
    },
    getCategory(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(CATEGORIES + payload.pk)
        .then((response) => {
          context.commit("addCategoryData", response);
        })
        .catch((error) => {
          context.commit("addCategoryData", error.response);
        });
    },
    createCategories(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
        "Content-Type": "multipart/form-data",
      };

      axios
        .post(CATEGORIES, payload)
        .then((response) => {
          context.commit("addCreateCategoriesData", response);
        })
        .catch((error) => {
          context.commit("addCreateCategoriesData", error.response);
        });
    },
    updateCategories(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
        "Content-Type": "multipart/form-data",
      };
      axios
        .patch(CATEGORIES + payload.pk, payload)
        .then((response) => {
          context.commit("addUpdateCategoriesData", response);
        })
        .catch((error) => {
          context.commit("addUpdateCategoriesData", error.response);
        });
    },
    getProducts(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
        "Content-Type": "multipart/form-data",
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          PRODUCTS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
        if (payload.category) {
          pgurl += "&category=" + payload.category;
        }
      } else {
        if (payload.category) {
          pgurl = PRODUCTS + "?category=" + payload.category;
        } else {
          pgurl = PRODUCTS;
        }
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === PRODUCTS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addProductsData", response);
        })
        .catch((error) => {
          context.commit("addProductsData", error.response);
        });
    },
    createProducts(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
        "Content-Type": "multipart/form-data",
      };

      axios
        .post(PRODUCTS, payload)
        .then((response) => {
          context.commit("addCreateProductsData", response);
        })
        .catch((error) => {
          context.commit("addCreateProductsData", error.response);
        });
    },
    publishProducts(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .post(PRODUCTS + "admin_publish_many/", payload)
        .then((response) => {
          context.commit("addPublishProductsData", response);
        })
        .catch((error) => {
          context.commit("addPublishProductsData", error.response);
        });
    },
    getAdminProductsOptions(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(PRODUCTS + "admin_options_list/")
        .then((response) => {
          context.commit("addAdminProductsOptionsData", response);
        })
        .catch((error) => {
          context.commit("addAdminProductsOptionsData", error.response);
        });
    },
    getProductMaterials(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(FILES + "admin_product_materials_files")
        .then((response) => {
          context.commit("addProductMaterialsData", response);
        })
        .catch((error) => {
          context.commit("addProductMaterialsData", error.response);
        });
    },
    getProductOptions(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(PRODUCTS + "admin_kind_options")
        .then((response) => {
          context.commit("addProductOptionsData", response);
        })
        .catch((error) => {
          context.commit("addProductOptionsData", error.response);
        });
    },
    getProductFormats(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(FORMAT + "admin_order_products_formats")
        .then((response) => {
          context.commit("addProductFormatsData", response);
        })
        .catch((error) => {
          context.commit("addProductFormatsData", error.response);
        });
    },
    getFormats(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          FORMAT +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = PRODUCTS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === FORMAT) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addFormatsData", response);
        })
        .catch((error) => {
          context.commit("addFormatsData", error.response);
        });
    },
    getFormat(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(FORMAT + payload.pk)
        .then((response) => {
          context.commit("addFormatData", response);
        })
        .catch((error) => {
          context.commit("addFormatData", error.response);
        });
    },
    createFormats(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .post(FORMAT, payload)
        .then((response) => {
          context.commit("addCreateFormatsData", response);
        })
        .catch((error) => {
          context.commit("addCreateFormatsData", error.response);
        });
    },
    updateFormats(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .patch(FORMAT + payload.pk, payload)
        .then((response) => {
          context.commit("addUpdateFormatsData", response);
        })
        .catch((error) => {
          context.commit("addUpdateFormatsData", error.response);
        });
    },
    deleteFormats(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .delete(FORMAT + payload.pk)
        .then((response) => {
          context.commit("addDeleteFormatsData", response);
        })
        .catch((error) => {
          context.commit("addDeleteFormatsData", error.response);
        });
    },
    getAppliances(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
        "Content-Type": "multipart/form-data",
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          APPLIANCES +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = APPLIANCES;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === APPLIANCES) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addAppliancesData", response);
        })
        .catch((error) => {
          context.commit("addAppliancesData", error.response);
        });
    },
    getAppliance(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
        "Content-Type": "multipart/form-data",
      };

      axios
        .get(APPLIANCES + payload.pk)
        .then((response) => {
          context.commit("addApplianceData", response);
        })
        .catch((error) => {
          context.commit("addApplianceData", error.response);
        });
    },
    createAppliances(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
        "Content-Type": "multipart/form-data",
      };

      axios
        .post(APPLIANCES, payload)
        .then((response) => {
          context.commit("addCreateAppliancesData", response);
        })
        .catch((error) => {
          context.commit("addCreateAppliancesData", error.response);
        });
    },
    updateAppliances(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
        "Content-Type": "multipart/form-data",
      };

      axios
        .patch(APPLIANCES + payload.pk, payload)
        .then((response) => {
          context.commit("addUpdateAppliancesData", response);
        })
        .catch((error) => {
          context.commit("addUpdateAppliancesData", error.response);
        });
    },
    deleteAppliances(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .delete(APPLIANCES + payload.pk)
        .then((response) => {
          context.commit("addDeleteAppliancesData", response);
        })
        .catch((error) => {
          context.commit("addDeleteAppliancesData", error.response);
        });
    },
    getParameters(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          PARAMETERS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = PARAMETERS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === PARAMETERS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addParametersData", response);
        })
        .catch((error) => {
          context.commit("addParametersData", error.response);
        });
    },
    getAdminParameters(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          ADMIN_PARAMETERS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = ADMIN_PARAMETERS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === ADMIN_PARAMETERS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addAdminParametersData", response);
        })
        .catch((error) => {
          context.commit("addAdminParametersData", error.response);
        });
    },
    getParameter(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(PARAMETERS + payload.pk, payload)
        .then((response) => {
          context.commit("addParameterData", response);
        })
        .catch((error) => {
          context.commit("addParameterData", error.response);
        });
    },
    createParameters(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .post(PARAMETERS, payload)
        .then((response) => {
          context.commit("addCreateParametersData", response);
        })
        .catch((error) => {
          context.commit("addCreateParametersData", error.response);
        });
    },
    updateParameters(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .patch(PARAMETERS + payload.pk, payload)
        .then((response) => {
          context.commit("addUpdateParametersData", response);
        })
        .catch((error) => {
          context.commit("addUpdateParametersData", error.response);
        });
    },
    deleteParameters(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .delete(PARAMETERS + payload.pk)
        .then((response) => {
          context.commit("addDeleteParametersData", response);
        })
        .catch((error) => {
          context.commit("addDeleteParametersData", error.response);
        });
    },
    getFormatGroups(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          FORMAT_GROUPS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = FORMAT_GROUPS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === FORMAT_GROUPS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addFormatGroupsData", response);
        })
        .catch((error) => {
          context.commit("addFormatGroupsData", error.response);
        });
    },
    getFormatGroupsAdmin(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(FORMAT_GROUPS + "admin_for_formats_list")
        .then((response) => {
          context.commit("addFormatGroupsAdminData", response);
        })
        .catch((error) => {
          context.commit("addFormatGroupsAdminData", error.response);
        });
    },
    getFormatGroup(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(FORMAT_GROUPS + payload.pk)
        .then((response) => {
          context.commit("addFormatGroupData", response);
        })
        .catch((error) => {
          context.commit("addFormatGroupData", error.response);
        });
    },
    createFormatGroups(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .post(FORMAT_GROUPS, payload)
        .then((response) => {
          context.commit("addCreateFormatGroupsData", response);
        })
        .catch((error) => {
          context.commit("addCreateFormatGroupsData", error.response);
        });
    },
    updateFormatGroups(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .patch(FORMAT_GROUPS + payload.pk, payload)
        .then((response) => {
          context.commit("addUpdateFormatGroupsData", response);
        })
        .catch((error) => {
          context.commit("addUpdateFormatGroupsData", error.response);
        });
    },
    deleteFormatGroups(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .delete(FORMAT_GROUPS + payload.pk)
        .then((response) => {
          context.commit("addDeleteFormatGroupsData", response);
        })
        .catch((error) => {
          context.commit("addDeleteFormatGroupsData", error.response);
        });
    },
    getpOptionsGroup(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          PGROUP_OPTIONS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = PGROUP_OPTIONS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === PGROUP_OPTIONS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addPoptionsGroupData", response);
        })
        .catch((error) => {
          context.commit("addPoptionsGroupData", error.response);
        });
    },
    getpOptionsAdminGroup(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          PAGROUP_OPTIONS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = PAGROUP_OPTIONS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === PAGROUP_OPTIONS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addPAoptionsGroupData", response);
        })
        .catch((error) => {
          context.commit("addPAoptionsGroupData", error.response);
        });
    },
    getpOptionsEx(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          PEX_OPTIONS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = PEX_OPTIONS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === PEX_OPTIONS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addPoptionsExData", response);
        })
        .catch((error) => {
          context.commit("addPoptionsExData", error.response);
        });
    },
    getPOG(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(PGROUP_OPTIONS + payload.pk)
        .then((response) => {
          context.commit("addPOGData", response);
        })
        .catch((error) => {
          context.commit("addPOGData", error.response);
        });
    },
    getPOEX(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(PEX_OPTIONS + payload.pk)
        .then((response) => {
          context.commit("addPOEXData", response);
        })
        .catch((error) => {
          context.commit("addPOEXData", error.response);
        });
    },
    createpOptionsGroup(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .post(PGROUP_OPTIONS, payload)
        .then((response) => {
          context.commit("addCreatePoptionsGroupData", response);
        })
        .catch((error) => {
          context.commit("addCreatePoptionsGroupData", error.response);
        });
    },
    createpOptionsEx(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .post(PEX_OPTIONS, payload)
        .then((response) => {
          context.commit("addCreatePoptionsExData", response);
        })
        .catch((error) => {
          context.commit("addCreatePoptionsExData", error.response);
        });
    },
    deletepOptionsGroup(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .delete(PGROUP_OPTIONS + payload.pk)
        .then((response) => {
          context.commit("addDeletePoptionsGroupData", response);
        })
        .catch((error) => {
          context.commit("addDeletePoptionsGroupData", error.response);
        });
    },
    deletepOptionsEx(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .delete(PEX_OPTIONS + payload.pk)
        .then((response) => {
          context.commit("addDeletePoptionsExData", response);
        })
        .catch((error) => {
          context.commit("addDeletePoptionsExData", error.response);
        });
    },
    updatepOptionsGroup(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .patch(PGROUP_OPTIONS + payload.pk + "/", JSON.stringify(payload))
        .then((response) => {
          context.commit("addUpdatePoptionsGroupData", response);
        })
        .catch((error) => {
          context.commit("addUpdatePoptionsGroupData", error.response);
        });
    },
    updatepOptionsEx(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .patch(PEX_OPTIONS + payload.pk + "/", payload)
        .then((response) => {
          context.commit("addUpdatePoptionsExData", response);
        })
        .catch((error) => {
          context.commit("addUpdatePoptionsExData", error.response);
        });
    },
    getPOGChoices(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(PGROUP_OPTIONS + "group_choices")
        .then((response) => {
          context.commit("addPOGChoicesData", response);
        })
        .catch((error) => {
          context.commit("addPOGChoicesData", error.response);
        });
    },
    getAdminProductOptions(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(PRODUCT_OPTIONS + "admin_product_options")
        .then((response) => {
          context.commit("addAdminProductOptionsData", response);
        })
        .catch((error) => {
          context.commit("addAdminProductOptionsData", error.response);
        });
    },
    getAdminProductSearchOptions(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(PRODUCT_OPTIONS + "admin_search_list")
        .then((response) => {
          context.commit("addAdminProductSearchData", response);
        })
        .catch((error) => {
          context.commit("addAdminProductSearchData", error.response);
        });
    },
    sendOrder(context, pk) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(ORDERS + pk + "/admin_send_check/")
        .then((response) => {
          context.commit("addSendOrderData", response);
        })
        .catch((error) => {
          context.commit("addSendOrderData", error.response);
        });
    },
    getOrder(context, pk) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(ORDERS + pk)
        .then((response) => {
          context.commit("addOrderData", response);
        })
        .catch((error) => {
          context.commit("addOrderData", error.response);
        });
    },
    getOrders(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          ORDERS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = ORDERS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === ORDERS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      if (payload?.filters) {
        for (const [key, value] of Object.entries(payload.filters)) {
          if (pgurl === ORDERS) {
            if (value.length > 0) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value.length > 0) {
              value.forEach((element) => {
                pgurl += "&" + key + "=" + element;
              });
            }
          }
        }
      }

      if (payload?.notes_text.length > 0) {
        pgurl += "&notes_text=" + payload.notes_text;
      }

      if (payload.month) {
        pgurl += "&date_month=" + payload.month + "&date_year=" + payload.year;
      }
      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addOrdersData", response);
        })
        .catch((error) => {
          context.commit("addOrdersData", error.response);
        });
    },
    getClientOrders(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          ORDERS +
          "?client_pk=" +
          payload.client_pk +
          "&page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = ORDERS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === ORDERS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addClientOrdersData", response);
        })
        .catch((error) => {
          context.commit("addClientOrdersData", error.response);
        });
    },

    getClientMessages(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          CONTACT +
          "?client_pk=" +
          payload.client_pk +
          "&page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = CONTACT;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === CONTACT) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addClientMessagesData", response);
        })
        .catch((error) => {
          context.commit("addClientMessagesData", error.response);
        });
    },
    getOrderAdminCard(context, pk) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(ORDERS + pk + "/admin_card/", { responseType: "arraybuffer" })
        .then((response) => {
          let blob = new Blob([response.data], {
              type: "application/pdf",
            }),
            url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch();
    },
    adminSendUpsRetrieve(context, pk) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(ORDERS + pk + "/admin_send_ups_retrieve/")
        .then((response) => {
          context.commit("addAdminSendUpsData", response);
        })
        .catch((error) => {
          context.commit("addAdminSendUpsData", error.response);
        });
    },
    generateLabel(context, pk) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .patch(ORDER_DELIVERIES + pk + "/admin_generate_label/")
        .then((response) => {
          context.commit("addLabelData", response);
        })
        .catch((error) => {
          context.commit("addLabelData", error.response);
        });
    },
    updateTracking(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .patch(ORDER_DELIVERIES + payload.pk + "/", {
          ups_tracking_number: payload.ups_tracking_number,
        })
        .then((response) => {
          context.commit("addUpdateTrackingData", response);
        })
        .catch((error) => {
          context.commit("addUpdateTrackingData", error.response);
        });
    },
    upsUpdateRealWeight(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      var data = new FormData();
      data.append("real_weight", payload.real_weight);

      axios
        .patch(
          ORDER_PRODUCTSBD + payload.pk + "/admin_send_ups_update_real_weight/",
          data
        )
        .then((response) => {
          context.commit("addRealWeightData", response);
        })
        .catch((error) => {
          context.commit("addRealWeightData", error.response);
        });
    },
    getOrderProductFiles(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(ORDER_PRODUCT_FILES + payload.pk + payload.type, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          let blob = new Blob([response.data], {
              type: "application/pdf",
            }),
            url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch((error) => {
          context.commit("addOrderProductFilesData", error);
        });
    },
    getMontageCard(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(MONTAGES + payload.pk + "/admin_montage_card/", {
          responseType: "arraybuffer",
        })
        .then((response) => {
          let blob = new Blob([response.data], {
              type: "application/pdf",
            }),
            url = window.URL.createObjectURL(blob);
          window.open(url);
        });
    },
    startMontageProcess(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .patch(MONTAGES + payload.pk + payload.type, payload)
        .then((response) => {
          context.commit("addMontageProcessData", response);
        })
        .catch((error) => {
          context.commit("addMontageProcessData", error.response);
        });
    },
    getCubes(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(CUBES)
        .then((response) => {
          context.commit("addCubesData", response);
        })
        .catch((error) => {
          context.commit("addCubesData", error.response);
        });
    },
    getCube(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(CUBES + payload.pk)
        .then((response) => {
          context.commit("addCubeData", response);
        })
        .catch((error) => {
          context.commit("addCubeData", error.response);
        });
    },
    getCubeProductFilterGO(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(
          PGROUP_OPTIONS +
            "admin_production_cube_product_filter_group_options/?production_cube=" +
            payload.pk
        )
        .then((response) => {
          context.commit("addCubeProductFilterData", response);
        })
        .catch((error) => {
          context.commit("addCubeProductFilterData", error.response);
        });
    },
    getCubeProducts(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          CUBE_PRODUCTS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = CUBE_PRODUCTS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === CUBE_PRODUCTS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      for (const value of Object.entries(payload.filters)) {
        if (value[1].length) {
          pgurl += "&options_pks=" + value[1];
        }
      }

      axios
        .get(pgurl + "&production_cube_pk=" + payload.pk)
        .then((response) => {
          context.commit("addCubeProductsData", response);
        })
        .catch((error) => {
          context.commit("addCubeProductsData", error.response);
        });
    },
    getCubeProduct(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      let products_pks = "";
      for (const [key, value] of Object.entries(payload.pks)) {
        console.log(key, value.pk);
        if (value.pk) {
          products_pks += "&products_pks=" + value.pk;
        }
      }

      if (!products_pks) {
        products_pks = "&products_pks=" + payload.pks;
      }

      axios
        .get(
          CUBE_PRODUCTS +
            "admin_to_realization_list/?production_cube_pk=" +
            payload.pk +
            products_pks
        )
        .then((response) => {
          context.commit("addCubeProductData", response);
        })
        .catch((error) => {
          context.commit("addCubeProductData", error.response);
        });
    },
    saveOrderProcessStage(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .post(ORDER_PROCESS_STAGE, payload)
        .then((response) => {
          context.commit("addOrderProcessStageData", response);
        })
        .catch((error) => {
          context.commit("addOrderProcessStageData", error.response);
        });
    },
    getOrderProcessStage(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      let products_pks = "";
      for (const [key, value] of Object.entries(payload.pks)) {
        console.log(key);
        if (value.pk) {
          if (products_pks) {
            products_pks += "&order_products_pks=" + value.pk;
          } else {
            products_pks += "?order_products_pks=" + value.pk;
          }
        }
      }
      if (!products_pks) {
        products_pks = "?order_products_pks=" + payload.pk;
      }

      axios
        .get(ORDER_PROCESS_STAGE + products_pks)
        .then((response) => {
          context.commit("addOrderProcessStatusData", response);
        })
        .catch((error) => {
          context.commit("addOrderProcessStatusData", error.response);
        });
    },
    getOrderProductFilePages(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(ORDER_PRODUCT_FILE_PAGES + payload.pk + "/get_page_preview", {
          responseType: "arraybuffer",
        })
        .then((response) => {
          if (payload.second) {
            context.commit("addOrderProductFileData2", response);
          } else {
            context.commit("addOrderProductFileData", response);
          }
        })
        .catch((error) => {
          if (payload.second) {
            context.commit("addOrderProductFileData2", error.response);
          } else {
            context.commit("addOrderProductFileData", error.response);
          }
        });
    },
    getMontagePapers(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(PRODUCT_OPTIONS + "/admin_montage_paper_options")
        .then((response) => {
          context.commit("addMontagePapersData", response);
        })
        .catch((error) => {
          context.commit("addMontagePapersData", error.response);
        });
    },
    getMontageFormats(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(FORMAT + "/admin_montage_format")
        .then((response) => {
          context.commit("addMontageFormatsData", response);
        })
        .catch((error) => {
          context.commit("addMontageFormatsData", error.response);
        });
    },
    getMontagePlates(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(PLATES + "/admin_montage_plates")
        .then((response) => {
          context.commit("addMontagePlatesData", response);
        })
        .catch((error) => {
          context.commit("addMontagePlatesData", error.response);
        });
    },
    getMontageProducts(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var url = MONTAGE_PRODUCTS + "/admin_list_left_table";
      var pgurl;
      if (payload?.page) {
        pgurl =
          url +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = url;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === url) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      if (payload.site_pk) {
        if (pgurl === url) {
          pgurl += "?site_pk=" + payload.site_pk;
        } else {
          pgurl += "&site_pk=" + payload.site_pk;
        }
      }

      for (const value of Object.entries(payload.filters)) {
        if (value[1].length) {
          pgurl += "&options_pks=" + value[1];
        }
      }
      for (const value of Object.entries(payload.exclude)) {
        if (value[1]) {
          pgurl += "&exclude_pks=" + value[1];
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addMontageProductsData", response);
        })
        .catch((error) => {
          context.commit("addMontageProductsData", error.response);
        });
    },
    updateMontageProduct(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .patch(MONTAGE_PRODUCTS + payload.pk, payload)
        .then((response) => {
          context.commit("addUpdateMontageProductData", response);
        })
        .catch((error) => {
          context.commit("addUpdateMontageProductData", error.response);
        });
    },
    getSites(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(SITES + "field_opts")
        .then((response) => {
          context.commit("addSitesData", response);
        })
        .catch((error) => {
          context.commit("addSitesData", error.response);
        });
    },
    getMontageFilterGroup(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(
          PGROUP_OPTIONS +
            "admin_montage_product_filter_group_options/?" +
            payload.type +
            "=true"
        )
        .then((response) => {
          response.type = payload.type;
          context.commit("addMontageFilterGroupData", response);
        })
        .catch((error) => {
          context.commit("addMontageFilterGroupData", error.response);
        });
    },
    getMontageFormatsOptions(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(
          FORMAT +
            "admin_montage_product_filter_format?" +
            payload.type +
            "=true"
        )
        .then((response) => {
          response.type = payload.type;
          context.commit("addMontageFormatData", response);
        })
        .catch((error) => {
          context.commit("addMontageFormatData", error.response);
        });
    },
    getMontageQuantities(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(
          QUANTITIES +
            "admin_montage_product_filter_quantity?" +
            payload.type +
            "=true"
        )
        .then((response) => {
          response.type = payload.type;
          context.commit("addMontageQuantityData", response);
        })
        .catch((error) => {
          context.commit("addMontageQuantityData", error.response);
        });
    },
    getMontagePaperOptions(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(
          PRODUCT_OPTIONS +
            "admin_montage_product_filter_paper_options/?" +
            payload.type +
            "=true"
        )
        .then((response) => {
          response.type = payload.type;
          context.commit("addMontagePaperOptionsData", response);
        })
        .catch((error) => {
          context.commit("addMontagePaperOptionsData", error.response);
        });
    },
    createMontage(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .post(MONTAGES, payload)
        .then((response) => {
          context.commit("addCreateMontageData", response);
        })
        .catch((error) => {
          context.commit("addCreateMontageData", error.response);
        });
    },
    getClients(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(CLIENTS, payload)
        .then((response) => {
          context.commit("addClientsData", response);
        })
        .catch((error) => {
          context.commit("addClientsData", error.response);
        });
    },
    getClient(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(CLIENTS + payload.pk + "/admin_get_flattened")
        .then((response) => {
          context.commit("addClientData", response);
        })
        .catch((error) => {
          context.commit("addClientData", error.response);
        });
    },
    deleteClient(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .patch(CLIENTS + payload.pk, payload)
        .then((response) => {
          context.commit("addDeleteClientData", response);
        })
        .catch((error) => {
          context.commit("addDeleteClientData", error.response);
        });
    },
    updateClient(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .patch(CLIENTS + payload.pk, payload)
        .then((response) => {
          context.commit("addUpdateClientData", response);
        })
        .catch((error) => {
          context.commit("addUpdateClientData", error.response);
        });
    },
    createClient(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .post(CLIENTS, payload)
        .then((response) => {
          context.commit("addCreateClientData", response);
        })
        .catch((error) => {
          context.commit("addCreateClientData", error.response);
        });
    },
    getAdminClients(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(CLIENTS + "admin_client_options")
        .then((response) => {
          context.commit("addAdminClientsData", response);
        })
        .catch((error) => {
          context.commit("addAdminClientsData", error.response);
        });
    },
    getCountriesList(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(COUNTRIES)
        .then((response) => {
          context.commit("addCountriesData", response);
        })
        .catch((error) => {
          context.commit("addCountriesData", error.response);
        });
    },
    getDiscountGroups(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          DISCOUNTS_GROUPS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = DISCOUNTS_GROUPS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === DISCOUNTS_GROUPS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addDiscountGroupsData", response);
        })
        .catch((error) => {
          context.commit("addDiscountGroupsData", error.response);
        });
    },
    getDiscountGroup(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(DISCOUNTS_GROUPS + payload.pk)
        .then((response) => {
          context.commit("addDiscountGroupData", response);
        })
        .catch((error) => {
          context.commit("addDiscountGroupData", error.response);
        });
    },
    updateDiscountGroup(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .patch(DISCOUNTS_GROUPS + payload.pk, payload)
        .then((response) => {
          context.commit("addUpdateDiscountGroupsData", response);
        })
        .catch((error) => {
          context.commit("addUpdateDiscountGroupsData", error.response);
        });
    },
    deleteDiscountGroup(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .delete(DISCOUNTS_GROUPS + payload.pk)
        .then((response) => {
          context.commit("addDeleteDiscountGroupsData", response);
        })
        .catch((error) => {
          context.commit("addDeleteDiscountGroupsData", error.response);
        });
    },
    createDiscountGroup(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .post(DISCOUNTS_GROUPS, payload)
        .then((response) => {
          context.commit("addCreateDiscountGroupsData", response);
        })
        .catch((error) => {
          context.commit("addCreateDiscountGroupsData", error.response);
        });
    },
    getOrderProductsFile(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(ORDER_PRODUCTS + payload.pk + "/admin_get_file_data")
        .then((response) => {
          context.commit("addOrderProductsFileData", response);
        })
        .catch((error) => {
          context.commit("addOrderProductsFileData", error.response);
        });
    },
    getMessages(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          CONTACT +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = CONTACT;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === CONTACT) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addMessagesData", response);
        })
        .catch((error) => {
          context.commit("addMessagesData", error.response);
        });
    },
    getMessagesDetails(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(CONTACT + payload.pk)
        .then((response) => {
          context.commit("addMessageData", response);
        })
        .catch((error) => {
          context.commit("addMessageData", error.response);
        });
    },
    getClientsContact(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      let url = CLIENTS + "/admin_client_contact_clients";
      if (payload?.pk) {
        url += "?client_contact_pk=" + payload.pk;
      }

      axios
        .get(url)
        .then((response) => {
          context.commit("addClientsContactData", response);
        })
        .catch((error) => {
          context.commit("addClientsContactData", error.response);
        });
    },
    getAdminsContact(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(ADMINS_LIST + "/client_contact_list")
        .then((response) => {
          context.commit("addAdminContactData", response);
        })
        .catch((error) => {
          context.commit("addAdminContactData", error.response);
        });
    },
    getAdminsReasons(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      let url = COMPETENCIES_LIST + "/client_contact_list/";

      if (payload.complaintReason) {
        url += "?complaint_reason=True";
      }
      axios
        .get(url)
        .then((response) => {
          if (payload.complaintReason) {
            context.commit("addAdminComplaintData", response);
          } else {
            context.commit("addAdminReasonsData", response);
          }
        })
        .catch((error) => {
          if (payload.complaintReason) {
            context.commit("addAdminComplaintData", error);
          } else {
            context.commit("addAdminReasonsData", error);
          }
        });
    },
    updateMessage(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .patch(CONTACT + payload.pk, payload)
        .then((response) => {
          if (payload.closeMessage) {
            response.closeMessage = true;
          }
          context.commit("addUpdateMessageData", response);
        })
        .catch((error) => {
          context.commit("addUpdateMessageData", error.response);
        });
    },
    createMessage(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .post(CONTACT, payload)
        .then((response) => {
          if (payload.closeMessage) {
            response.closeMessage = true;
          }
          context.commit("addCreateMessageData", response);
        })
        .catch((error) => {
          context.commit("addCreateMessageData", error.response);
        });
    },
    addMessageFile(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
        "Content-Type": "multipart/form-data",
      };
      axios.post(MESSAGE_FILES, payload);
    },
    addConversation(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .post(MESSAGES, payload)
        .then((response) => {
          context.commit("addConversationData", response);
        })
        .catch((error) => {
          context.commit("addConversationData", error.response);
        });
    },
    getDiscounts(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(DISCOUNTS)
        .then((response) => {
          context.commit("addDiscountsData", response);
        })
        .catch((error) => {
          context.commit("addDiscountsData", error.response);
        });
    },
    getDiscount(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(DISCOUNTS + payload.id)
        .then((response) => {
          context.commit("addDiscountData", response);
        })
        .catch((error) => {
          context.commit("addDiscountData", error.response);
        });
    },
    updateDiscount(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .patch(DISCOUNTS + payload.pk, payload)
        .then((response) => {
          context.commit("addUpdateDiscountsData", response);
        })
        .catch((error) => {
          context.commit("addUpdateDiscountsData", error.response);
        });
    },
    createDiscountForQuantitity(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .post(DISCOUNTS + "create_discount_for_quantity/", payload)
        .then((response) => {
          context.commit("addCreateDiscountsForQData", response);
        })
        .catch((error) => {
          context.commit("addCreateDiscountsForQData", error.response);
        });
    },
    deleteDiscountForQuantitity(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .delete(DISCOUNTS + "delete_discount_for_quantities/?id=" + payload.id)
        .then((response) => {
          context.commit("addCreateDiscountsForQData", response);
        })
        .catch((error) => {
          context.commit("addCreateDiscountsForQData", error.response);
        });
    },
    updateDiscountForQ(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .post(DISCOUNTS + "update_discounts_for_quantity/", payload)
        .then((response) => {
          response.closeModal = true;
          context.commit("addCreateDiscountsForQData", response);
        })
        .catch((error) => {
          context.commit("addCreateDiscountsForQData", error.response);
        });
    },
    importDiscountForQ(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
        "Content-Type": "multipart/form-data",
      };

      axios
        .post(DISCOUNTS + "import_discounts_for_quantity/", payload)
        .then((response) => {
          context.commit("addDiscountData", response);
        })
        .catch((error) => {
          context.commit("addDiscountData", error.response);
        });
    },
    getInvoices(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          INVOICES +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = INVOICES;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === INVOICES) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      if (payload.month && payload.year) {
        pgurl += "&date_month=" + payload.month + "&date_year=" + payload.year;
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addInvoicesData", response);
        })
        .catch((error) => {
          context.commit("addInvoicesData", error.response);
        });
    },
    getLegacyInvoices(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          LEGACY_INVOICES +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = LEGACY_INVOICES;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === LEGACY_INVOICES) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      if (payload.month && payload.year) {
        pgurl += "&date_month=" + payload.month + "&date_year=" + payload.year;
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addInvoicesData", response);
        })
        .catch((error) => {
          context.commit("addInvoicesData", error.response);
        });
    },
    getAdvanceInvoice(context, pk) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(ORDERS + pk + "/advance_invoice/", { responseType: "arraybuffer" })
        .then((response) => {
          let blob = new Blob([response.data], {
              type: "application/pdf",
            }),
            url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch();
    },
    invoiceExportCSV(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };
      axios
        .get(
          INVOICES +
            "/" +
            payload.type +
            "/?date_month=" +
            payload.month +
            "&date_year=" +
            payload.year,
          { responseType: "arraybuffer" }
        )
        .then((response) => {
          let blob = new Blob([response.data], {
              type: "application/csv",
            }),
            url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch();
    },
    getTags(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(TAGS + "form_field_opts/")
        .then((response) => {
          context.commit("addTagsData", response);
        })
        .catch((error) => {
          context.commit("addTagsData", error.response);
        });
    },
    getApm(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(APM)
        .then((response) => {
          context.commit("addApmData", response);
        })
        .catch((error) => {
          context.commit("addApmData", error.response);
        });
    },
    createOrder(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
        "Content-Type": "multipart/form-data",
      };

      axios
        .post(ORDERS + "admin_create/", payload)
        .then((response) => {
          context.commit("addCreateOrderData", response);
        })
        .catch((error) => {
          context.commit("addCreateOrderData", error.response);
        });
    },
    paymentsImport(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
        "Content-Type": "multipart/form-data",
      };

      axios
        .post(ORDERS + "admin_payments_import/", payload)
        .then((response) => {
          context.commit("addPaymentsImportData", response);
        })
        .catch((error) => {
          context.commit("addPaymentsImportData", error.response);
        });
    },
    getOrderLogs(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          ORDER_LOGS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage;
      } else {
        pgurl = ORDER_LOGS;
      }

      if (payload.order_pk) {
        pgurl += "&order_pk=" + payload.order_pk;
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addOrderLogsData", response);
        })
        .catch((error) => {
          context.commit("addOrderLogsData", error.response);
        });
    },
    getProcessStages(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(PROCESS_STAGES + "admin_for_process/?process_pk=" + payload.pk)
        .then((response) => {
          context.commit("addProcessStagesData", response);
        })
        .catch((error) => {
          context.commit("addProcessStagesData", error.response);
        });
    },
    getMontageStats(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          MONTAGE_STATS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = MONTAGE_STATS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === MONTAGE_STATS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      if (payload.month) {
        pgurl += "&date_month=" + payload.month + "&date_year=" + payload.year;
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addMontageStatsData", response);
        })
        .catch((error) => {
          context.commit("addMontageStatsData", error.response);
        });
    },
    getProductionStats(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          PRODUCTION_STATS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = PRODUCTION_STATS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === PRODUCTION_STATS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      if (payload.month) {
        pgurl += "&date_month=" + payload.month + "&date_year=" + payload.year;
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addProductionStatsData", response);
        })
        .catch((error) => {
          context.commit("addProductionStatsData", error.response);
        });
    },
    getSystemLogs(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      var pgurl;
      if (payload?.page) {
        pgurl =
          SYSTEM_LOGS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = SYSTEM_LOGS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === SYSTEM_LOGS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      if (payload.month) {
        pgurl += "&date_month=" + payload.month + "&date_year=" + payload.year;
      }

      axios
        .get(pgurl)
        .then((response) => {
          context.commit("addSystemLogsData", response);
        })
        .catch((error) => {
          context.commit("addSystemLogsData", error.response);
        });
    },
    getProductsStats(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      let day, month, year, dayEnd, monthEnd, yearEnd, dateMax, dateMin;
      if (payload?.date) {
        if (payload.date[0]) {
          day = payload.date[0].getDate();
          month = payload.date[0].getMonth() + 1;
          year = payload.date[0].getFullYear();

          dateMin = `${day}.${month}.${year}`;
        }

        if (payload.date[1]) {
          dayEnd = payload.date[1].getDate();
          monthEnd = payload.date[1].getMonth() + 1;
          yearEnd = payload.date[1].getFullYear();
          dateMax = `${dayEnd}.${monthEnd}.${yearEnd}`;
        }
      }

      var pgurl;
      if (payload?.page) {
        pgurl =
          PRODUCTS_STATS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = PRODUCTS_STATS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === PRODUCTS_STATS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      if (payload.date) {
        pgurl += "&max_date=" + dateMax;
        pgurl += "&min_date=" + dateMin;
      }
      if (payload.shop_version) {
        pgurl += "&shop_version=" + payload.shop_version;
      }

      axios
        .get(pgurl)
        .then((response) => {
          response.comp = payload.comp;
          context.commit("addProductsStatsData", response);
        })
        .catch((error) => {
          context.commit("addProductsStatsData", error.response);
        });
    },
    getClientStats(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      let day, month, year, dayEnd, monthEnd, yearEnd, dateMax, dateMin;
      if (payload?.date) {
        if (payload.date[0]) {
          day = payload.date[0].getDate();
          month = payload.date[0].getMonth() + 1;
          year = payload.date[0].getFullYear();

          dateMin = `${day}.${month}.${year}`;
        }

        if (payload.date[1]) {
          dayEnd = payload.date[1].getDate();
          monthEnd = payload.date[1].getMonth() + 1;
          yearEnd = payload.date[1].getFullYear();
          dateMax = `${dayEnd}.${monthEnd}.${yearEnd}`;
        }
      }

      var pgurl;
      if (payload?.page) {
        pgurl =
          CLIENT_STATS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = CLIENT_STATS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === CLIENT_STATS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      if (payload.date) {
        pgurl += "&max_date=" + dateMax;
        pgurl += "&min_date=" + dateMin;
      }
      if (payload.shop_version) {
        pgurl += "&shop_version=" + payload.shop_version;
      }

      axios
        .get(pgurl)
        .then((response) => {
          response.comp = payload.comp;
          context.commit("addClientStatsData", response);
        })
        .catch((error) => {
          context.commit("addClientStatsData", error.response);
        });
    },
    getSalesStats(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      let day, month, year, dayEnd, monthEnd, yearEnd, dateMax, dateMin;
      if (payload?.date) {
        if (payload.date[0]) {
          day = payload.date[0].getDate();
          month = payload.date[0].getMonth() + 1;
          year = payload.date[0].getFullYear();

          dateMin = `${day}.${month}.${year}`;
        }

        if (payload.date[1]) {
          dayEnd = payload.date[1].getDate();
          monthEnd = payload.date[1].getMonth() + 1;
          yearEnd = payload.date[1].getFullYear();
          dateMax = `${dayEnd}.${monthEnd}.${yearEnd}`;
        }
      }

      var pgurl;
      if (payload?.page) {
        pgurl =
          SALES_STATS +
          "?page=" +
          payload.page +
          "&page_size=" +
          payload.itemsPerPage +
          "&ordering=" +
          payload.ordering;
      } else {
        pgurl = SALES_STATS;
      }

      if (payload?.search) {
        for (const [key, value] of Object.entries(payload.search)) {
          if (pgurl === SALES_STATS) {
            if (value) {
              pgurl += "?" + key + "=" + value;
            }
          } else {
            if (value) {
              pgurl += "&" + key + "=" + value;
            }
          }
        }
      }

      if (payload.date) {
        pgurl += "&max_date=" + dateMax;
        pgurl += "&min_date=" + dateMin;
      }
      if (payload.shop_version) {
        pgurl += "&shop_version=" + payload.shop_version;
      }
      if (payload.group_by_date) {
        pgurl += "&group_by_date=" + payload.group_by_date;
      }

      axios
        .get(pgurl)
        .then((response) => {
          response.comp = payload.comp;
          context.commit("addSaleStatsData", response);
        })
        .catch((error) => {
          context.commit("addSaleStatsData", error.response);
        });
    },
    getPackageStats(context, payload) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      let day, month, year, dayEnd, monthEnd, yearEnd, dateMax, dateMin;
      if (payload?.date) {
        if (payload.date[0]) {
          day = payload.date[0].getDate();
          month = payload.date[0].getMonth() + 1;
          year = payload.date[0].getFullYear();

          dateMin = `${day}.${month}.${year}`;
        }

        if (payload.date[1]) {
          dayEnd = payload.date[1].getDate();
          monthEnd = payload.date[1].getMonth() + 1;
          yearEnd = payload.date[1].getFullYear();
          dateMax = `${dayEnd}.${monthEnd}.${yearEnd}`;
        }
      }

      var pgurl = PACKAGE_STATS;
      if (payload.date) {
        pgurl += "?max_date=" + dateMax;
        pgurl += "&min_date=" + dateMin;
      }
      if (payload.shop_version) {
        pgurl += "&shop_version=" + payload.shop_version;
      }

      axios
        .get(pgurl)
        .then((response) => {
          response.comp = payload.comp;
          context.commit("addPackageStatsData", response);
        })
        .catch((error) => {
          context.commit("addPackageStatsData", error.response);
        });
    },
    getSalesGroups(context) {
      axios.defaults.headers = {
        Authorization: "Token " + cookies.get("CPsession"),
      };

      axios
        .get(SALES_STATS + "group_by_dates_options/")
        .then((response) => {
          context.commit("addSalesGroupsData", response);
        })
        .catch((error) => {
          context.commit("addSalesGroupsData", error.response);
        });
    },
  },
  getters: {
    getLoginData(state) {
      return state.loginData;
    },
    getAdminsData(state) {
      return state.adminsData;
    },
    getOnlineAdminsData(state) {
      return state.onlineAdminsData;
    },
    getAdminBarcodeData(state) {
      return state.adminsBarcodeData;
    },
    getAdminData(state) {
      return state.adminData;
    },
    getMachinesData(state) {
      return state.machinesData;
    },
    getCompetenciesData(state) {
      return state.competenciesData;
    },
    getPermissionsGroupData(state) {
      return state.permissionsGroupData;
    },
    getPermissionsGroupsData(state) {
      return state.permissionsGroupsData;
    },
    getPermissionsData(state) {
      return state.permissionsData;
    },
    getCreateAdminData(state) {
      return state.createAdminData;
    },
    getAdminEditData(state) {
      return state.adminEditData;
    },
    getAdminGroupEditData(state) {
      return state.adminGroupEditData;
    },
    getAdminEditionData(state) {
      return state.adminEditionData;
    },
    getAdminDeleteData(state) {
      return state.deleteAdminData;
    },
    getAdminLogsData(state) {
      return state.adminLogsData;
    },
    getDeletePermissionsGroupData(state) {
      return state.deletePermissionsGroupData;
    },
    getCreatePermissionsGroupData(state) {
      return state.createPermissionsGroupData;
    },
    getUpdatePermissionsGroupData(state) {
      return state.updatePermissionsGroupData;
    },
    getDeleteCompetenceData(state) {
      return state.deleteCompetenceData;
    },
    getCreateCompetenceData(state) {
      return state.createCompetenceData;
    },
    getCompetenceData(state) {
      return state.competenceData;
    },
    getUpdateCompetenceData(state) {
      return state.updateCompetenceData;
    },
    getIpsData(state) {
      return state.ipsData;
    },
    getIpData(state) {
      return state.ipData;
    },
    getCreateIpData(state) {
      return state.createIpData;
    },
    getDeleteIpData(state) {
      return state.deleteIpData;
    },
    getUpdateIpData(state) {
      return state.updateIpData;
    },
    getSettingsData(state) {
      return state.settingsData;
    },
    getSettingData(state) {
      return state.settingData;
    },
    getCreateSettingData(state) {
      return state.createSettingData;
    },
    getDeleteSettingData(state) {
      return state.deleteSettingData;
    },
    getUpdateSettingData(state) {
      return state.updateSettingData;
    },
    getHolidaysData(state) {
      return state.holidaysData;
    },
    getCreateHolidaysData(state) {
      return state.createHolidaysData;
    },
    getDeleteHolidaysData(state) {
      return state.deleteHolidaysData;
    },
    getPackagesData(state) {
      return state.packagesData;
    },
    getPackageData(state) {
      return state.packageData;
    },
    getUpdatePackagesData(state) {
      return state.updatePackagesData;
    },
    getCreatePackagesData(state) {
      return state.createPackagesData;
    },
    getDeletePackagesData(state) {
      return state.deletePackagesData;
    },
    getProcessesData(state) {
      return state.processesData;
    },
    getCreateProcessesData(state) {
      return state.createProcessesData;
    },
    getDeleteProcessesData(state) {
      return state.deleteProcessesData;
    },
    getUpdateProcessesData(state) {
      return state.updateProcessesData;
    },
    getProcessData(state) {
      return state.processData;
    },
    getPlatesData(state) {
      return state.platesData;
    },
    getPlateData(state) {
      return state.plateData;
    },
    getUpdatePlatesData(state) {
      return state.updatePlatesData;
    },
    getCreatePlatesData(state) {
      return state.createPlatesData;
    },
    getDeletePlatesData(state) {
      return state.deletePlatesData;
    },
    getVariablesData(state) {
      return state.variablesData;
    },
    getVariableData(state) {
      return state.variableData;
    },
    getCreateVariablesData(state) {
      return state.createVariablesData;
    },
    getUpdateVariablesData(state) {
      return state.updateVariablesData;
    },
    getDeleteVariablesData(state) {
      return state.deleteVariablesData;
    },
    getProdlogsData(state) {
      return state.prodlogsData;
    },
    getNewsListData(state) {
      return state.newsListData;
    },
    getNewsData(state) {
      return state.newsData;
    },
    getCreateNewsData(state) {
      return state.createNewsData;
    },
    getUpdateNewsData(state) {
      return state.updateNewsData;
    },
    getDeleteNewsData(state) {
      return state.deleteNewsData;
    },
    getBannersData(state) {
      return state.bannersData;
    },
    getBannerData(state) {
      return state.bannerData;
    },
    getCreateBannersData(state) {
      return state.createBannersData;
    },
    getUpdateBannersData(state) {
      return state.updateBannersData;
    },
    getDeleteBannersData(state) {
      return state.deleteBannersData;
    },
    getPageBannersData(state) {
      return state.pageBannersData;
    },
    getAdminsPGsData(state) {
      return state.adminsPGsData;
    },
    getCreateMachinesData(state) {
      return state.createMachinesData;
    },
    getDeleteMachinesData(state) {
      return state.deleteMachinesData;
    },
    getUpdateMachinesData(state) {
      return state.updateMachinesData;
    },
    getMachineData(state) {
      return state.machineData;
    },
    getLogStatusesData(state) {
      return state.statusesData;
    },
    getProdLogsSummaryData(state) {
      return state.prodLogsSummaryData;
    },
    getPagesData(state) {
      return state.pagesData;
    },
    getPageData(state) {
      return state.pageData;
    },
    getPageTypesData(state) {
      return state.pageTypesData;
    },
    getFaqsData(state) {
      return state.faqsData;
    },
    getDeleteFaqData(state) {
      return state.deleteFaqData;
    },
    getCreateFaqData(state) {
      return state.createFaqData;
    },
    getFaqData(state) {
      return state.faqData;
    },
    getUpdateFaqData(state) {
      return state.updateFaqData;
    },
    getTranslationsData(state) {
      return state.translationsData;
    },
    getUpdateTranslationsData(state) {
      return state.updateTranslationsData;
    },
    getTranslationsModelsData(state) {
      return state.translationsModelsData;
    },
    getUpdateTranslationsModelsData(state) {
      return state.updateTranslationsModelsData;
    },
    getFilesData(state) {
      return state.filesData;
    },
    getFileData(state) {
      return state.fileData;
    },
    getCreateFilesData(state) {
      return state.createFilesData;
    },
    getDeleteFilesData(state) {
      return state.deleteFilesData;
    },
    getUpdateFilesData(state) {
      return state.updateFilesData;
    },
    getImportTMData(state) {
      return state.importTMData;
    },
    getCategoriesData(state) {
      return state.categoriesData;
    },
    getCategoriesAdminProductData(state) {
      return state.categoriesAdminProductData;
    },
    getCategoryData(state) {
      return state.categoryData;
    },
    getUpdateCategoriesData(state) {
      return state.updateCategoriesData;
    },
    getCreateCategoriesData(state) {
      return state.createCategoriesData;
    },
    getDeleteCategoriesData(state) {
      return state.deleteCategoriesData;
    },
    getCategoryParentsData(state) {
      return state.categoryParentsData;
    },
    getPageLayoutsData(state) {
      return state.pageLayoutsData;
    },
    getPageMenuData(state) {
      return state.pageMenuData;
    },
    getCreatePagesData(state) {
      return state.createPagesData;
    },
    getDeletePagesData(state) {
      return state.deletePagesData;
    },
    getUpdatePagesData(state) {
      return state.updatePagesData;
    },
    getProductsData(state) {
      return state.productsData;
    },
    getCreateProductsData(state) {
      return state.createProductsData;
    },
    getProductMaterialsData(state) {
      return state.productMaterialsData;
    },
    getProductOptionsData(state) {
      return state.productOptionsData;
    },
    getProductFormatsData(state) {
      return state.productFormatsData;
    },
    getAppliancesData(state) {
      return state.appliancesData;
    },
    getParametersData(state) {
      return state.parametersData;
    },
    getAdminParametersData(state) {
      return state.adminParametersData;
    },
    getParameterData(state) {
      return state.parameterData;
    },
    getCreateParametersData(state) {
      return state.createParametersData;
    },
    getDeleteParametersData(state) {
      return state.deleteParametersData;
    },
    getUpdateParametersData(state) {
      return state.updateParametersData;
    },
    getFormatGroupsData(state) {
      return state.formatGroupsData;
    },
    getFormatGroupData(state) {
      return state.formatGroupData;
    },
    getCreateFormatGroupsData(state) {
      return state.createFormatGroupsData;
    },
    getUpdateFormatGroupsData(state) {
      return state.updateFormatGroupsData;
    },
    getDeleteFormatGroupsData(state) {
      return state.deleteFormatGroupsData;
    },
    getFormatsData(state) {
      return state.formatsData;
    },
    getFormatData(state) {
      return state.formatData;
    },
    getCreateFormatsData(state) {
      return state.createFormatsData;
    },
    getDeleteFormatsData(state) {
      return state.deleteFormatsData;
    },
    getUpdateFormatsData(state) {
      return state.updateFormatsData;
    },
    getFormatGroupsAdminData(state) {
      return state.formatGroupsAdminData;
    },
    getpOptionsGroupData(state) {
      return state.pOptionsGroupData;
    },
    getpaOptionsGroupData(state) {
      return state.paOptionsGroupData;
    },
    getpOptionsExData(state) {
      return state.pOptionsExData;
    },
    getCreatepOptionsGroupData(state) {
      return state.createPoptionsGroupData;
    },
    getPOGChoicesData(state) {
      return state.POGChoicesData;
    },
    getPOGData(state) {
      return state.pOptionGroupData;
    },
    getPOEXData(state) {
      return state.pOptionExData;
    },
    getDeletepOptionsGroupData(state) {
      return state.deletePoptionsGroupData;
    },
    getDeletepOptionsExData(state) {
      return state.deletePoptionsExData;
    },
    getUpdatepOptionsGroupData(state) {
      return state.updatePoptionsGroupData;
    },
    getUpdatepOptionsExData(state) {
      return state.updatePoptionsExData;
    },
    getAdminProductSearchData(state) {
      return state.adminProductSearchData;
    },
    getAdminProductOptionsData(state) {
      return state.adminProductSearchData;
    },
    getCreatepOptionsExData(state) {
      return state.createPoptionsExData;
    },
    getCreateAppliancesData(state) {
      return state.createAppliancesData;
    },
    getApplianceData(state) {
      return state.applianceData;
    },
    getUpdateAppliancesData(state) {
      return state.updateAppliancesData;
    },
    getDeleteAppliancesData(state) {
      return state.deleteAppliancesData;
    },
    getPublishProductsData(state) {
      return state.publishProductsData;
    },
    getAdminProductsOptionsData(state) {
      return state.adminProductsOptionsData;
    },
    getMontagesData(state) {
      return state.montagesData;
    },
    getMachineActionsData(state) {
      return state.machineActionsData;
    },
    getSendOrderData(state) {
      return state.sendOrderData;
    },
    getAdminSendUpsData(state) {
      return state.adminSendUpsData;
    },
    getLabelData(state) {
      return state.labelData;
    },
    getUpdateTrackingData(state) {
      return state.updateTrackingData;
    },
    getRealWeightData(state) {
      return state.realWeightData;
    },
    getOrderProductFilesData(state) {
      return state.orderProductFilesData;
    },
    getMontageData(state) {
      return state.montageData;
    },
    getMontageProcessData(state) {
      return state.montageProcessData;
    },
    getCubesData(state) {
      return state.cubesData;
    },
    getCubeData(state) {
      return state.cubeData;
    },
    getCubeProductFilterData(state) {
      return state.cubeProductFilterData;
    },
    getCubeProductsData(state) {
      return state.cubeProductsData;
    },
    getCubeProductData(state) {
      return state.cubeProductData;
    },
    getOrderProcessStageData(state) {
      return state.orderProcessStageData;
    },
    getOrderProcessStatusData(state) {
      return state.orderProcessStatusData;
    },
    getOrderProductFileData(state) {
      return state.orderProductFileData;
    },
    getOrderProductFileData2(state) {
      return state.orderProductFileData2;
    },
    getOrderData(state) {
      return state.orderData;
    },
    getOrdersData(state) {
      return state.ordersData;
    },
    getClientOrdersData(state) {
      return state.clientOrdersData;
    },
    getClientMessagesData(state) {
      return state.clientMessagesData;
    },
    getMontageProductsData(state) {
      return state.montageProductsData;
    },
    getMontageFilterGroupData(state) {
      return state.montageFilterGroupData;
    },
    getSitesData(state) {
      return state.sitesData;
    },
    getMontageFormatData(state) {
      return state.montageFormatData;
    },
    getMontageQuantitiesData(state) {
      return state.montageQuantityData;
    },
    getMontagePaperOptionsData(state) {
      return state.montagePaperOptionsData;
    },
    getMontagePapersData(state) {
      return state.montagePapersData;
    },
    getMontageFormatsData(state) {
      return state.montageFormatsData;
    },
    getMontagePlatesData(state) {
      return state.montagePlatesData;
    },
    getCreateMontageData(state) {
      return state.createMontageData;
    },
    getUpdateMontageProductData(state) {
      return state.updateMontageProductData;
    },
    getClientsData(state) {
      return state.clientsData;
    },
    getClientData(state) {
      return state.clientData;
    },
    getAdminClientsData(state) {
      return state.adminClientsData;
    },
    getCountriesData(state) {
      return state.countriesData;
    },
    getCreateClientData(state) {
      return state.createClientData;
    },
    getDeleteClientData(state) {
      return state.deleteClientData;
    },
    getUpdateClientData(state) {
      return state.updateClientData;
    },
    getDiscountGroupsData(state) {
      return state.discountGroupsData;
    },
    getDiscountGroupData(state) {
      return state.discountGroupData;
    },
    getCreateDiscountGroupsData(state) {
      return state.createDiscountGroupsData;
    },
    getUpdateDiscountGroupData(state) {
      return state.updateDiscountGroupsData;
    },
    getDeleteDiscountGroupData(state) {
      return state.deleteDiscountGroupsData;
    },
    getOrderProductsFileData(state) {
      return state.orderProductsFileData;
    },
    getMessagesData(state) {
      return state.messagesData;
    },
    getMessageData(state) {
      return state.messageData;
    },
    getClientsContactData(state) {
      return state.clientsContactData;
    },
    getAdminsContactData(state) {
      return state.adminsContactData;
    },
    getAdminsReasonsData(state) {
      return state.adminsReasonsData;
    },
    getAdminsComplaintData(state) {
      return state.adminsComplaintData;
    },
    getUpdateMessageData(state) {
      return state.updateMessageData;
    },
    getCreateMessageData(state) {
      return state.createMessageData;
    },
    getConversationData(state) {
      return state.conversationData;
    },
    getDiscountsData(state) {
      return state.discountsData;
    },
    getDiscountData(state) {
      return state.discountData;
    },
    getUpdateDiscountsData(state) {
      return state.updateDiscountsData;
    },
    getCreateDiscountsForQData(state) {
      return state.createDiscountsForQData;
    },
    getInvoicesData(state) {
      return state.invoicesData;
    },
    getTagsData(state) {
      return state.tagsData;
    },
    getApmData(state) {
      return state.apmData;
    },
    getCreateOrderData(state) {
      return state.createOrderData;
    },
    getPaymentsImportData(state) {
      return state.paymentsImportData;
    },
    getOrderLogsData(state) {
      return state.orderLogsData;
    },
    getProcessStagesData(state) {
      return state.processStagesData;
    },
    getMontageStatsData(state) {
      return state.montageStatsData;
    },
    getProductionStatsData(state) {
      return state.productionStatsData;
    },
    getSystemLogsData(state) {
      return state.systemLogsData;
    },
    getClientStatsData(state) {
      return state.clientStatsData;
    },
    getProductsStatsData(state) {
      return state.productsStatsData;
    },
    getPackageStatsData(state) {
      return state.packageStatsData;
    },
    getSalesStatsData(state) {
      return state.salesStatsData;
    },
    getSalesGroupsData(state) {
      return state.salesGroupsData;
    },
  },
});
