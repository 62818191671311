<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Zamówienia</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/order/list">Zamówienia</router-link>
        </li>
        <li class="active">
          <strong>Szczegóły</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <button class="btn btn-primary">
            <span class="fa fa-money"></span>
            <span>Zapłacono</span>
          </button>
          <button class="btn btn-primary">
            <span class="fa fa-check"></span>
            <span>Zakończ zamówienie</span>
          </button>
          <button class="btn btn-primary">
            <span class="fa fa-times"></span>
            <span>Anuluj zamówienie</span>
          </button>
          <button class="btn btn-primary">
            <span class="fa fa-stop"></span>
            <span>Zablokuj wysyłkę</span>
          </button>
          <button class="btn btn-primary">
            <span class="fa fa-play"></span>
            <span>Odblokuj wysyłkę</span>
          </button>
          <button class="btn btn-primary">
            <span class="fa fa-file"></span>
            <span>Pobierz kartę zamówienia</span>
          </button>
          <button class="btn btn-primary">
            <span class="fa fa-sticky-note"></span>
            <span>Dodaj notatkę</span>
          </button>
          <div class="ibox-title">
            <h5>Szczegóły</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content panel">
              <div class="panel-body">
                <dl class="dl-horizontal dl-order-detail">
                  <dt>Numer zamówienia:</dt>
                  <dd>255098</dd>
                  <dt>Status:</dt>
                  <dd>Anulowane</dd>
                  <dt>Termin realizacji:</dt>
                  <dd></dd>
                  <dt>Data zamówienia / ostatniej modyfikacji:</dt>
                  <dd>04.10.2024 17:50:02 / 04.10.2024 17:55:08</dd>
                  <dt>Sklep / Język / Waluta:</dt>
                  <dd>Polska / Polski / PLN</dd>
                  <dt>Waga zamówienia:</dt>
                  <dd>175.884 kg</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Dane klienta</h5>
          </div>
          <div class="ibox-content panel">
            <div class="panel-body">
              <span class="dblock">Achjoj</span>
              <span class="dblock">6252455329</span>
              <span class="dblock">Sielecka 61</span>
              <span class="dblock">42-500 Będzin</span>
              <span class="dblock">Polska</span>
              <br />
              <span class="dblock">Wojciech Wicher</span>
              <span class="dblock">509914576</span>
              <span class="dblock">zamowienia@achjoj.pl</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Faktury</h5>
          </div>
          <div class="ibox-content panel">
            <div class="panel-body">
              <dl class="dl-horizontal dl-order-detail">
                <dt>Faktura papierowa wysyłana pocztą:</dt>
                <dd>Nie</dd>
                <hr />
                <dt>Dane do faktury:</dt>
                <dd>
                  <span>Achjoj</span>
                  <br />
                  <span>6252455329</span>
                  <br />
                  <span>Sielecka 61</span>
                  <br />
                  <span>42-500 Będzin</span>
                  <br />
                  <span>PL</span>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Płatność</h5>
          </div>
          <div class="ibox-content panel">
            <div class="panel-body">
              <dl class="dl-horizontal dl-order-detail">
                <dt>Metoda:</dt>
                <dd>Przedpłata na konto</dd>
                <dt>Status:</dt>
                <dd>Nieopłacone</dd>
                <dt>Grupa rabatowa:</dt>
                <dd>START (30.00%)</dd>
                <dt>Negocjacje przyjęte:</dt>
                <dd>Tak</dd>
                <dt>Wynegocjowany rabat:</dt>
                <dd>24.3%</dd>
                <hr />
                <dt>Razem netto (dotyczy wartości produktów w koszyku):</dt>
                <dd>719,56 PLN</dd>
                <dt>Faktura papierowa wysłana pocztą:</dt>
                <dd>0,00 PLN</dd>
                <dt>Koszt wysyłki:</dt>
                <dd>0,00 PLN</dd>
                <dt>Koszt dodatkowych adresów:</dt>
                <dd>0,00 PLN</dd>
                <dt>Razem netto:</dt>
                <dd>719,56 PLN</dd>
                <dt>Opłata dystrybucyjna 9%:</dt>
                <dd>0,00 PLN</dd>
                <dt>Podatek VAT 23%:</dt>
                <dd>165,50 PLN</dd>
                <dt>Razem</dt>
                <dd>885,06 PLN</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Wysyłka</h5>
          </div>
          <div class="ibox-content panel">
            <div class="panel-body">
              <dl class="dl-horizontal dl-order-detail">
                <dt>Opcja wysyłki:</dt>
                <dd>GLS Standard</dd>
                <dt>Status:</dt>
                <dd>Niewysłana</dd>
                <dt>Powód blokady wysyłki:</dt>
                <dd>-</dd>
                <dt>Wysyłka z moim adresem jako nadawcy:</dt>
                <dd>Nie</dd>
                <hr />
                <dt>Liczba pozycji zamówienia:</dt>
                <dd>1</dd>
                <hr />
                <dt>Liczba adresów dostawy:</dt>
                <dd>1</dd>
                <dt>Liczba paczek:</dt>
                <dd>7</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Pozycje zamówienia</h5>
          </div>
          <div v-if="items.length > 0" class="ibox-content">
            <EasyDataTable
              v-model:server-options="serverOptions"
              :server-items-length="itemsAll"
              :headers="headers"
              :items="items"
              :clickRowToExpand="false"
              :loading="loading"
              alternating
              multi-sort
              border-cell
              show-index
            >
              <template #item-options="item">
                <span
                  v-for="(option, index) in item.chosen_options"
                  :key="index"
                  class="show m-b-xs dblock"
                >
                  <span class="m-r-xs label label-default">
                    {{ option.chosen_option.group_pk }}
                  </span>
                  <span class="m-r-xs">
                    {{ option.chosen_option.group_name }}
                  </span>
                  <span class="m-r-xs label label-default">
                    {{ option.chosen_option.pk }}
                  </span>

                  <span class="m-r-xs">
                    {{ option.chosen_option.label }}
                  </span>
                </span>
              </template>
              <template #item-actions="item">
                <button
                  @click="
                    getOrderProductFiles(
                      item?.product_file?.preflight_status?.file_pk,
                      '/get_client_file/'
                    )
                  "
                  class="btn btn-xs"
                  title="Plik Klienta"
                >
                  <i class="fa fa-user"></i>
                </button>
                <button
                  @click="
                    getOrderProductFiles(
                      item?.product_file?.preflight_status?.file_pk,
                      '/get_result_file/'
                    )
                  "
                  class="btn btn-xs"
                  title="Plik wyjściowy"
                >
                  <i class="fa fa-search"></i>
                </button>
                <button
                  @click="
                    getOrderProductFiles(
                      item?.product_file?.preflight_status?.file_pk,
                      '/get_client_cover_file/'
                    )
                  "
                  class="btn btn-xs"
                  title="Plik okładki klienta"
                >
                  <i class="fa fa-user"></i>
                </button>
                <button
                  @click="
                    getOrderProductFiles(
                      item?.product_file?.preflight_status?.file_pk,
                      '/get_cover_result_file/'
                    )
                  "
                  class="btn btn-xs"
                  title="Plik okładki wyjściowy"
                >
                  <i class="fa fa-search"></i>
                </button>
              </template>
              <template #item-preview="item">
                <img
                  :src="item?.product_file?.client_file_thumbnail"
                  @click="preview(item)"
                  width="40px"
                />
              </template>
            </EasyDataTable>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Adresy dostawy</h5>
          </div>
          <div class="ibox-content panel">
            <div v-if="orderData.data" class="panel-body">
              <div
                v-for="(order, index) in orderData.data.delivery_addresses"
                :key="index"
                class="row"
              >
                <div class="col-md-4">
                  <div class="form-inline">
                    <div class="form-group">
                      <div class="none">
                        <input
                          v-model="order.ups_tracking_number"
                          :modelValue="order.ups_tracking_number"
                          type="text"
                          class="form-control"
                          placeholder="Numer trackingowy"
                        />
                      </div>
                    </div>
                    <button
                      @click="
                        updateTrackingModal(order.pk, order.ups_tracking_number)
                      "
                      class="btn btn-primary"
                    >
                      Zapisz
                    </button>
                  </div>
                  <hr class="hr-line-dashed" />
                  <div class="row">
                    <div
                      v-for="(pack, index1) in order.delivery_packages"
                      :key="index1"
                      class="col-md-12"
                    >
                      <h3>
                        <span>{{ pack.package.name }}</span>
                      </h3>
                      <p class="small">
                        <i class="fa fa-truck"></i>
                        <span>{{ pack.package.height }}</span>
                        <i class="fa fa-times"></i>
                        <span>{{ pack.package.width }}</span>
                        <i class="fa fa-times"></i>
                        <span>{{ pack.package.length }}</span>
                        <span>[cm]</span>
                      </p>
                    </div>
                  </div>
                  <hr class="hr-line-dashed" />
                  <div>
                    <span>{{ order.name }}</span>
                    <br />
                    <span>{{ order.street }}</span>
                    <span>{{ order.building_number }}</span>
                    <br />
                    <span>{{ order.zip_code }}</span>
                    <span>{{ order.city }}</span>
                    <br />
                    <span>{{ order.country }}</span>
                    <br />
                    <span>{{ order.telephone }}</span>
                    <br />
                    <button
                      @click="changeAddress(order)"
                      class="btn btn-warning"
                    >
                      Zmień adres
                    </button>
                  </div>
                </div>
                <div class="col-md-8">
                  <EasyDataTable
                    v-model:server-options="serverOptions"
                    :server-items-length="order.products.length"
                    :headers="addressHeaders"
                    :items="order.products"
                    :clickRowToExpand="false"
                    :loading="loading"
                    alternating
                    multi-sort
                    show-index
                    border-cell
                  >
                  </EasyDataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Historia zmian</h5>
          </div>
          <div v-if="orderLogsData?.data?.results?.length" class="ibox-content">
            <EasyDataTable
              v-model:server-options="serverLogsOptions"
              :server-items-length="orderLogsData.data.results.length"
              :headers="logsHeaders"
              :items="orderLogsData.data.results"
              :clickRowToExpand="false"
              :loading="loading"
              alternating
              show-index
              multi-sort
              border-cell
            >
            </EasyDataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mPreview" class="modal-dialog preflight">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header">
          <h4 class="modal-title">Podgląd pliku</h4>
        </div>
        <div v-if="previewData.preview.product_file" class="modal-body">
          <pdfViewer
            :order-product-file="previewData.preview.product_file"
            :gross-size="previewData.preview.product_gross_size"
            :bleed="previewData.product_bleed"
            :text-margin="previewData.product_text_margin"
            :product-kind="previewData.product_kind"
            :tech-lines="previewData.preview.tech_lines"
            :booklet-uv="previewData.preview.is_booklet_uv"
            :pages-loaded="0"
            :loading="true"
            loading-text="Ładowanie stron"
          />
        </div>
        <div class="modal-footer">
          <button @click="mPreview = false" class="btn btn-white">
            Zamknij
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mPreview" @click="mPreview = false" class="modal-backdrop"></div>

  <div v-if="mTracking" class="modal-dialog abs">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header">
          <h4 class="modal-title">Potwierdzenie działania</h4>
        </div>
        <div class="modal-body">
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="panel-body">
                Czy na pewno chcesz zapisać numer trackingowy?
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="mTracking = false" class="btn btn-white">Nie</button>
          <button @click="updateTracking" class="btn btn-danger">Tak</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mTracking" @click="mTracking = false" class="modal-backdrop"></div>

  <div v-if="mAddress" class="modal-dialog abs">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header">
          <h4 class="modal-title">Edycja adresu dostawy</h4>
        </div>
        <div class="modal-body">
          <div class="form-horizontal">
            <div class="form-group">
              <label class="control-label col-sm-2">Nazwa</label>
              <div class="col-sm-10">
                <input
                  v-model="addressObj.name"
                  type="text"
                  class="form-control inputClass"
                  placeholder="Nazwa"
                  required="required"
                />
                <div v-if="!addressObj.name" class="has-error">
                  <span class="help-block">To pole nie może być puste.</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-2">Numer telefonu</label>
              <div class="col-sm-10">
                <input
                  v-model="addressObj.telephone"
                  type="text"
                  class="form-control inputClass"
                  placeholder="Numer telefonu"
                  required="required"
                />
                <div v-if="!addressObj.telephone" class="has-error">
                  <span class="help-block">To pole nie może być puste.</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-2">Ulica</label>
              <div class="col-sm-10">
                <input
                  v-model="addressObj.street"
                  type="text"
                  class="form-control inputClass"
                  placeholder="Ulica"
                  required="required"
                />
                <div v-if="!addressObj.street" class="has-error">
                  <span class="help-block">To pole nie może być puste.</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-2">Numer budynku</label>
              <div class="col-sm-10">
                <input
                  v-model="addressObj.building_number"
                  type="text"
                  class="form-control inputClass"
                  placeholder="Numer budynku"
                  required="required"
                />
                <div v-if="!addressObj.building_number" class="has-error">
                  <span class="help-block">To pole nie może być puste.</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-2">Numer mieszkania</label>
              <div class="col-sm-10">
                <input
                  v-model="addressObj.suite_number"
                  type="text"
                  class="form-control inputClass"
                  placeholder="Numer mieszkania"
                  required="required"
                />
                <div v-if="!addressObj.suite_number" class="has-error">
                  <span class="help-block">To pole nie może być puste.</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-2">Miasto</label>
              <div class="col-sm-10">
                <input
                  v-model="addressObj.city"
                  type="text"
                  class="form-control inputClass"
                  placeholder="Miasto"
                  required="required"
                />
                <div v-if="!addressObj.city" class="has-error">
                  <span class="help-block">To pole nie może być puste.</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-2">Kod pocztowy</label>
              <div class="col-sm-10">
                <input
                  v-model="addressObj.zip_code"
                  type="text"
                  class="form-control inputClass"
                  placeholder="Kod pocztowy"
                  required="required"
                />
                <div v-if="!addressObj.zip_code" class="has-error">
                  <span class="help-block">To pole nie może być puste.</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-2">Kraj</label>
              <div class="col-sm-10">
                <Multiselect
                  v-model="addressObj.country"
                  mode="single"
                  placeholder="Kraj"
                  track-by="name"
                  label="name"
                  :disabled="true"
                >
                </Multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="mAddress = false" class="btn btn-white">
            Anuluj
          </button>
          <button @click="updateAddress" class="btn btn-primary">Zapisz</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mAddress" @click="mAddress = false" class="modal-backdrop"></div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import pdfViewer from "../pdfViewer/pdfViewer.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "OrderDetails",
  mixins: [],
  components: {
    pdfViewer,
    Multiselect,
  },
  props: {},

  setup() {
    const errorsAdminData = ref();
    const showErrors = ref(false);
    const store = useStore();
    const orderData = computed(() => store.getters.getOrderData);
    const orderLogsData = computed(() => store.getters.getOrderLogsData);
    const updateTrackingData = computed(
      () => store.getters.getUpdateTrackingData
    );
    const orderProductsFileData = computed(
      () => store.getters.getOrderProductsFileData
    );
    const clientsOptions = ref([]);
    const adminsComplaint = ref([]);
    const countriesOptions = ref([]);
    let items = ref([]);
    const loading = ref(true);
    const mPreview = ref(false);
    const mTracking = ref(false);
    const mAddress = ref(false);
    const previewData = ref();
    const trackingPk = ref();
    const trackingNumber = ref();
    const addressObj = ref({});
    let itemsAll = ref(0);
    const route = useRoute();
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });
    const serverLogsOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });
    let headers = [
      { text: "Podgląd", value: "preview", sortable: true, search: true },
      { text: "ID Zlecenia", value: "pk", sortable: true, search: true },
      { text: "Nr montażu", value: "montage_pk", sortable: true, search: true },
      {
        text: "ID produktu",
        value: "product_pk",
        sortable: true,
        search: true,
      },
      { text: "Produkt", value: "product_name", sortable: true, search: true },
      { text: "Nakład", value: "selected_quantity", sortable: true },
      { text: "Format", value: "format", sortable: true, search: true },
      { text: "Netto", value: "net_price", sortable: true, search: true },
      { text: "Waluta", value: "currency", sortable: true, search: true },
      { text: "Waga", value: "weight", sortable: true, search: true },
      { text: "Opcje", value: "options", sortable: true, search: true },
      { text: "Akcje", value: "actions" },
    ];
    let addressHeaders = [
      { text: "ID Zlecenia", value: "pk" },
      { text: "Nr montażu", value: "montage_pk" },
      { text: "ID produktu", value: "order_product_pk" },
      { text: "Produkt", value: "product_name" },
      { text: "Nakład", value: "amount" },
      { text: "Waga", value: "weight" },
      { text: "Waga wysłana", value: "real_weight" },
    ];
    let logsHeaders = [
      { text: "Autor", value: "author_display" },
      { text: "Status", value: "status_display" },
      { text: "Status płatności", value: "payment_status_display" },
      { text: "Status dostawy", value: "delivery_status_display" },
      { text: "Data", value: "created" },
    ];

    const getOrderData = () => {
      loading.value = true;
      store.dispatch("getOrderLogs", {
        page: serverLogsOptions.value.page,
        itemsPerPage: serverLogsOptions.value.rowsPerPage,
        order_pk: route.params?.pk,
      });
    };

    const getOrderProductFiles = (pk, type) => {
      if (pk) {
        store.dispatch("getOrderProductFiles", {
          pk: pk,
          type: type,
        });
      }
    };

    const preview = (item) => {
      previewData.value = item;
      store.dispatch("getOrderProductsFile", { pk: item.pk });
    };

    const updateTracking = () => {
      store.dispatch("updateTracking", {
        pk: trackingPk.value,
        ups_tracking_number: trackingNumber.value,
      });
    };

    const updateTrackingModal = (pk, tracking) => {
      trackingPk.value = pk;
      mTracking.value = true;
      trackingNumber.value = tracking;
    };

    const changeAddress = (address) => {
      mAddress.value = true;
      console.log("address", address);
    };

    store.dispatch("getOrder", route.params?.pk);
    getOrderData();
    watch(
      () => orderData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.order_products?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => serverLogsOptions.value,
      function () {
        getOrderData();
      }
    );

    watch(
      () => orderProductsFileData.value,
      function (newValue) {
        if (newValue.data.product_file) {
          previewData.value.preview = newValue.data;
          mPreview.value = true;
        }
      }
    );

    watch(
      () => updateTrackingData.value,
      function (newValue) {
        if (newValue.status === 200) {
          mTracking.value = false;
          store.dispatch("showToaster", {
            type: "success",
            content: "Numer trackingowy został zaktualizowany!",
          });
        }
      }
    );

    return {
      errorsAdminData,
      showErrors,
      clientsOptions,
      adminsComplaint,
      countriesOptions,
      headers,
      addressHeaders,
      orderData,
      itemsAll,
      items,
      loading,
      serverOptions,
      serverLogsOptions,
      orderLogsData,
      logsHeaders,
      getOrderProductFiles,
      mPreview,
      previewData,
      preview,
      trackingNumber,
      updateTracking,
      mTracking,
      mAddress,
      updateTrackingModal,
      changeAddress,
      addressObj,
    };
  },
  computed: {},
};
</script>
