<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Banery</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Banery</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Banery</h5>
          </div>
          <div class="ibox-content">
            <router-link to="/banners/create">
              <button class="btn btn-sm btn-primary">Dodaj</button>
            </router-link>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <button @click="resetFilters" class="btn btn-primary btn-xs">
                  <i class="fa fa-refresh"></i>
                </button>
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :clickRowToExpand="true"
                  :loading="loading"
                  @click-row="editBanner"
                  alternating
                  multi-sort
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                  <template #expand>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Dane banera</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Nazwa
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="bannerObj.name"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.name"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.name[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  URL
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="bannerObj.url"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="URL"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.url"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.url[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Opis [PL]
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="bannerObj.description_pl"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Opis [PL]"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.description_pl"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.description_pl[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Opis [DE]
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="bannerObj.description_de"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Opis [DE]"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.description_de"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.description_de[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Opis [EN]
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="bannerObj.description_en"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Opis [EN]"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.description_en"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.description_en[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <div class="col-sm-offset-2 col-sm-10">
                                  <div class="checkbox checkbox-primary">
                                    <label>
                                      <input
                                        v-model="bannerObj.is_published"
                                        name="is_published"
                                        type="checkbox"
                                      />
                                      <span>Aktywny</span>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Czas emisji od
                                </label>
                                <div class="col-sm-10">
                                  {{ datetime_from }}
                                  <VueDatePicker
                                    :format="format"
                                    v-model="datetime_from"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.datetime_from"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.datetime_from[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Czas emisji do
                                </label>
                                <div class="col-sm-10">
                                  <VueDatePicker
                                    :format="format"
                                    v-model="datetime_to"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.datetime_to"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.datetime_to[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Czas wyświetlania [ms]
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="bannerObj.display_time"
                                    name="name"
                                    type="number"
                                    class="form-control inputClass"
                                    placeholder="Czas wyświetlania [ms]"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.display_time"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.display_time[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Dostępność w sklepach
                                </label>
                                <div class="col-sm-10">
                                  <Multiselect
                                    v-model="bannerObj.available_in_countries"
                                    mode="tags"
                                    placeholder="Dostępność w sklepach"
                                    track-by="name"
                                    label="name"
                                    :close-on-select="false"
                                    :searchable="true"
                                    :options="countries"
                                  >
                                  </Multiselect>
                                  <div
                                    v-if="
                                      errorsAdminData?.data
                                        ?.available_in_countries
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .available_in_countries[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Strony
                                </label>
                                <div class="col-sm-10">
                                  <Multiselect
                                    v-model="bannerObj.pages"
                                    mode="tags"
                                    placeholder="Strony"
                                    track-by="name"
                                    label="name"
                                    :close-on-select="false"
                                    :searchable="true"
                                    :options="pageBanners"
                                  >
                                  </Multiselect>
                                  <div
                                    v-if="errorsAdminData?.data?.pages"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.pages[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Obrazek [skalowany do 1170x295 px]
                                </label>
                                <div class="col-sm-3">
                                  <picture-input
                                    ref="pictureInput"
                                    width="300"
                                    height="300"
                                    margin="16"
                                    accept="image/jpeg,image/png"
                                    size="10"
                                    button-class="btn"
                                    :prefill="bannerObj?.image"
                                    :custom-strings="{
                                      upload: '<h1>Uploading!</h1>',
                                      drag: 'Drag & Drop',
                                    }"
                                    @change="changePhoto"
                                  >
                                  </picture-input>
                                </div>
                              </div>
                            </div>

                            <div class="ibox-content">
                              <div class="form-group">
                                <div class="col-sm-4 col-sm-offset-2">
                                  <button class="btn btn-white" @click="cancel">
                                    Anuluj
                                  </button>
                                  <button
                                    @click="saveEdit"
                                    class="btn btn-primary"
                                  >
                                    Zapisz
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #item-is_published="item">
                    <i v-if="item.is_published" class="fa fa-check"></i>
                    <i v-else class="fa fa-times"></i>
                  </template>
                  <template #item-actions="item">
                    <button
                      @click.stop="
                        showModal = item.pk;
                        deleteName = item.title_pl;
                      "
                      class="btn btn-danger btn-xs"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </template>
                  <template #item-image="item">
                    <img :src="item.image" width="40px" />
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showModal"
    :title="'Potwierdzenie usunięcia'"
    :content="'Czy na pewno chcesz usunąć <b>' + deleteName + '</b> ?'"
    @callbackOk="deleteNews(showModal)"
    @callbackClose="showModal = false"
  />
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../caffeprint/Modal.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import PictureInput from "vue-picture-input";

export default {
  name: "BannersList",
  mixins: [],
  components: {
    Modal,
    Multiselect,
    PictureInput,
  },
  props: {},

  setup() {
    const store = useStore();
    const deleteBannersData = computed(
      () => store.getters.getDeleteBannersData
    );
    const bannerData = computed(() => store.getters.getBannerData);
    const updateBannersData = computed(
      () => store.getters.getUpdateBannersData
    );
    const bannersData = computed(() => store.getters.getBannersData);
    const pageBannersData = computed(() => store.getters.getPageBannersData);
    const pageBanners = ref([]);
    const countries = ref(["PL", "DE"]);
    const bannerObj = ref({
      name: "",
      url: "",
      is_published: "",
      datetime_from: "",
      datetime_to: "",
      display_time: "",
      image: "",
      available_in_countries: [],
      pages: [],
      description_pl: "",
      description_de: "",
      description_en: "",
      description_cpde: "",
      description_cpen: "",
    });
    let headers = [
      { text: "Nr", value: "pk" },
      { text: "Obrazek", value: "image", sortable: true },
      { text: "Nazwa", value: "name", sortable: true, search: true },
      { text: "Strony", value: "pages", sortable: true },
      { text: "Url", value: "url", sortable: true, search: true },
      { text: "Opublikowany", value: "is_published", sortable: true },
      {
        text: "Czas emisji od",
        value: "datetime_from",
        sortable: true,
        search: true,
      },
      {
        text: "Czas emisji do",
        value: "datetime_to",
        sortable: true,
        search: true,
      },
      {
        text: "Dostępne w sklepie",
        value: "available_in_countries",
        sortable: true,
        search: true,
      },
      { text: "Akcje", value: "actions" },
    ];

    let items = ref([]);
    let itemsAll = ref(0);
    const showModal = ref(false);
    const deleteName = ref("");
    const publication_date = ref();
    const loading = ref(true);
    const searchValue = ref({});
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const datetime_from = ref();
    const datetime_to = ref();
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });

    const getBanners = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      await store.dispatch("getBanners", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
      });
    };

    const format = (date) => {
      let day, month, year, hours, minutes, seconds;
      day = date.getDate();
      month = ("0" + (date.getMonth() + 1)).slice(-2);
      year = date.getFullYear();
      minutes = ("0" + date.getMinutes()).slice(-2);
      seconds = ("0" + date.getSeconds()).slice(-2);
      hours = date.getHours();

      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    };

    const deleteBanner = (pk) => {
      showModal.value = false;
      store.dispatch("deleteBanners", {
        pk: pk,
      });
    };
    const saveEdit = () => {
      if (typeof bannerObj.value.image == "string") {
        delete bannerObj.value.image;
      }
      store.dispatch("updateBanners", bannerObj.value);
    };

    const cancel = () => {
      document.querySelector(".expanding")?.click();
    };

    const editBanner = (item) => {
      cancel();
      store.dispatch("getBanner", { pk: item.pk });
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getBanners();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getBanners();
    };

    const updateSort = () => {
      search.value = true;
    };

    const changePhoto = (image) => {
      if (image.srcElement?.files[0]) {
        bannerObj.value.image = image.srcElement?.files[0];
      }
    };

    store.dispatch("getPageBanners");
    getBanners();
    watch(
      () => bannersData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => bannerData.value,
      function (newValue) {
        bannerObj.value = newValue.data;
        if (newValue.data.datetime_from) {
          datetime_from.value = format(newValue.data.datetime_from);
        }
        datetime_to.value = newValue.data.datetime_to;
      }
    );

    watch(
      () => deleteBannersData.value,
      function (newValue) {
        if (newValue.status === 204) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Baner został usunięty",
          });
        }
        getBanners();
      }
    );

    watch(
      () => updateBannersData.value,
      function (newValue) {
        if (newValue.status === 200) {
          getBanners();
          store.dispatch("showToaster", {
            type: "success",
            content: "Dane zostały zaktualizowane",
          });
        }

        cancel();
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getBanners();
          }, 1000);
        }
      }
    );

    watch(
      () => pageBannersData.value,
      function (newValue) {
        newValue.data?.forEach((element) => {
          pageBanners.value.push({
            name: "[" + element.pk + "] " + element.title_seo,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getBanners();
          isSearch.value = false;
        }
      }
    );

    return {
      headers,
      items,
      itemsAll,
      loading,
      serverOptions,
      showModal,
      deleteBanner,
      deleteName,
      bannerObj,
      cancel,
      editBanner,
      saveEdit,
      publication_date,
      format,
      searchValue,
      search,
      resetFilters,
      updateSort,
      isSearch,
      rowsPerPage,
      countries,
      pageBanners,
      datetime_from,
      datetime_to,
      changePhoto,
    };
  },
  computed: {},
};
</script>
