<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Faktury</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Faktury</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>Filtry</h5>
          </div>
          <div class="ibox-content flex">
            <div class="col-md-2 nopadding">
              <div class="form-group">
                <label for="date" class="control-label col-sm-2">Miesiąc</label>
                <div class="col-sm-10">
                  <VueDatePicker
                    :format="format"
                    v-model="filtersMonth"
                    month-picker
                  />
                </div>
              </div>
            </div>
            <div class="col-md-2 nopadding">
              <div
                class="form-group"
                title="Faktury zaliczkowe bez końcowych w tym samym miesiącu"
              >
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        id="without_final"
                        name="without_final"
                        type="checkbox"
                      />
                      <span>
                        Faktury zaliczkowe bez końcowych w tym samym miesiącu
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Faktury</h5>
          </div>
          <div class="ibox-content">
            <box-actions>
              <button @click="download('CSV')" class="btn btn-sm btn-primary">
                <span>
                  <span>Pobierz CSV</span>
                </span>
              </button>
              <button @click="download('JPK')" class="btn btn-sm btn-primary">
                <span>
                  <span>Pobierz JPK_FA</span>
                </span>
              </button>
            </box-actions>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <button @click="resetFilters" class="btn btn-primary btn-xs">
                  <i class="fa fa-refresh"></i>
                </button>
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :loading="loading"
                  alternating
                  multi-sort
                  show-index
                  border-cell
                  @update-sort="updateSort"
                  :body-row-class-name="bodyRowClassNameFunction"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                  <template #item-client_email="item">
                    <router-link :to="'/clients/' + item.client_pk">
                      {{ item.client_email }}
                    </router-link>
                  </template>
                  <template #item-actions="item">
                    <button
                      @click="getAdvance(item.order)"
                      class="btn btn-primary btn-xs"
                      title="Pobierz PDF"
                    >
                      <i class="fa fa-file"></i>
                    </button>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
export default {
  name: "InvoicesList",
  mixins: [],
  components: {},
  props: {},

  setup() {
    const store = useStore();
    const invoicesData = computed(() => store.getters.getInvoicesData);

    let headers = [
      { text: "Nr faktury", value: "number", sortable: true, search: true },
      { text: "Nr zamówienia", value: "order", sortable: true, search: true },
      { text: "Email", value: "client_email", sortable: true, search: true },
      {
        text: "Nazwa firmy",
        value: "company_name",
        sortable: true,
        search: true,
      },
      {
        text: "Sklep",
        value: "company_localization_display",
        sortable: true,
        search: true,
      },
      { text: "Język", value: "lang_display", sortable: true, search: true },
      { text: "Waluta", value: "currency", sortable: true, search: true },
      { text: "Netto", value: "net_price", sortable: true, search: true },
      { text: "VAT", value: "vat", sortable: true, search: true },
      { text: "Brutto", value: "gross_price", sortable: true, search: true },
      {
        text: "Rodzaj faktury",
        value: "invoice_type_display",
        sortable: true,
        search: true,
      },
      {
        text: "Data wystawienia",
        value: "issue_date",
        sortable: true,
        search: true,
      },
      { text: "Akcje", value: "actions" },
    ];

    let items = ref([]);
    let itemsAll = ref(0);
    const showModal = ref(false);
    const loading = ref(true);
    const searchValue = ref({});
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const filtersMonth = ref();
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });

    const getInvoices = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      await store.dispatch("getInvoices", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
        month: filtersMonth.value?.month + 1,
        year: filtersMonth.value?.year,
      });
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getInvoices();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getInvoices();
    };

    const updateSort = () => {
      search.value = true;
    };

    const bodyRowClassNameFunction = (item) => {
      if (item.exposing_value === "done") {
        return "done-row";
      }

      return false;
    };

    const getAdvance = (pk) => {
      store.dispatch("getAdvanceInvoice", pk);
    };

    const format = (date) => {
      let month, year;
      month = ("0" + (date.getMonth() + 1)).slice(-2);
      year = date.getFullYear();
      return `${month}.${year}`;
    };

    const download = (type) => {
      if (filtersMonth.value) {
        if (type === "CSV") {
          store.dispatch("invoiceExportCSV", {
            month: filtersMonth.value.month + 1,
            year: filtersMonth.value.year,
            type: "admin_export_csv",
          });
        } else if (type === "JPK") {
          store.dispatch("invoiceExportCSV", {
            month: filtersMonth.value.month + 1,
            year: filtersMonth.value.year,
            type: "admin_export_jpkfa",
          });
        }
      } else {
        store.dispatch("showToaster", {
          type: "error",
          content: "Wybierz miesiąc aby pobrać plik!",
        });
      }
    };

    getInvoices();

    watch(
      () => filtersMonth.value,
      function () {
        getInvoices();
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getInvoices();
          }, 1000);
        }
      }
    );

    watch(
      () => invoicesData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getInvoices();
          isSearch.value = false;
        }
      }
    );

    return {
      headers,
      items,
      itemsAll,
      loading,
      serverOptions,
      showModal,
      searchValue,
      search,
      resetFilters,
      updateSort,
      isSearch,
      rowsPerPage,
      bodyRowClassNameFunction,
      getAdvance,
      format,
      filtersMonth,
      download,
    };
  },
  computed: {},
};
</script>
