<template>
  <nav class="navbar-default navbar-static-side" role="navigation">
    <div class="sidebar-collapse">
      <ul class="nav metismenu" id="side-menu">
        <li class="nav-header">
          <div class="profile-element">
            <a>
              <span class="clear">
                <span class="block m-t-xs">
                  <strong class="font-bold">
                    <a class="user-name">{{ userData }}</a>
                  </strong>
                </span>
              </span>
            </a>
          </div>
          <div class="logo-element">
            <span>CP</span>
          </div>
        </li>

        <li :class="{ active: selectedMenu.includes('dashboard') }">
          <a class="navlink" @click="selectMenu('dashboard')">
            <i class="fa fa-laptop"></i>
            <router-link to="/" class="menu-item">Dashboard</router-link>
          </a>
        </li>

        <li :class="{ active: selectedMenu.includes('sale') }">
          <a class="navlink" @click="selectMenu('sale')">
            <i class="fa fa-shopping-cart"></i>
            <span class="nav-label">Sprzedaż</span>
            <i class="arrow fas fa-angle-left"></i>
          </a>
          <ul v-if="selectedMenu.includes('sale')" class="nav nav-second-level">
            <li>
              <router-link to="/order/list" class="submenu__item">
                Zamówienia
              </router-link>
            </li>
            <li>
              <router-link to="/invoices/list" class="submenu__item">
                Faktury
              </router-link>
            </li>
            <li>
              <router-link to="/invoices/legacy_list" class="submenu__item">
                Faktury - legacy
              </router-link>
            </li>
            <li>
              <router-link to="/payment/import" class="submenu__item">
                Import wpłat
              </router-link>
            </li>
          </ul>
        </li>

        <li :class="{ active: selectedMenu.includes('crm') }">
          <a class="navlink" @click="selectMenu('crm')">
            <i class="fa fa-th-large"></i>
            <span class="nav-label">CRM</span>
            <i class="arrow fas fa-angle-left"></i>
          </a>
          <ul v-if="selectedMenu.includes('crm')" class="nav nav-second-level">
            <li>
              <router-link to="/clients/list" class="submenu__item">
                Klienci
              </router-link>
            </li>
            <li>
              <router-link to="/messages/list" class="submenu__item">
                Sprawy
              </router-link>
            </li>
            <li>
              <router-link to="/discount-groups/list" class="submenu__item">
                Grupy rabatowe
              </router-link>
            </li>
          </ul>
        </li>

        <li :class="{ active: selectedMenu.includes('production') }">
          <a class="navlink" @click="selectMenu('production')">
            <i class="fa fa-refresh"></i>
            <span class="nav-label">Produkcja</span>
            <i class="arrow fas fa-angle-left"></i>
          </a>
          <ul
            v-if="selectedMenu.includes('production')"
            class="nav nav-second-level"
          >
            <li>
              <router-link to="/montages/list" class="submenu__item">
                Montaże
              </router-link>
            </li>
            <li>
              <router-link to="/montages/list-archive" class="submenu__item">
                Montaże - archiwum
              </router-link>
            </li>
            <li>
              <router-link to="/cubes/list" class="submenu__item">
                Kostki produkcyjne
              </router-link>
            </li>
            <li>
              <router-link to="/order/send" class="submenu__item">
                Wysyłka zamówień
              </router-link>
            </li>
          </ul>
        </li>

        <li :class="{ active: selectedMenu.includes('products') }">
          <a class="navlink" @click="selectMenu('products')">
            <i class="fa fa-th-large"></i>
            <span class="nav-label">Produkty</span>
            <i class="arrow fas fa-angle-left"></i>
          </a>
          <ul
            v-if="selectedMenu.includes('products')"
            class="nav nav-second-level"
          >
            <li>
              <router-link to="/categories/list" class="submenu__item">
                Kategorie
              </router-link>
            </li>
            <li>
              <router-link to="/products" class="submenu__item">
                Produkty
              </router-link>
            </li>
            <li>
              <router-link to="/parameters" class="submenu__item">
                Parametry produktów
              </router-link>
            </li>
            <li>
              <router-link to="/format-groups" class="submenu__item">
                Grupy formatów
              </router-link>
            </li>
            <li>
              <router-link to="/format/list" class="submenu__item">
                Formaty
              </router-link>
            </li>
            <li>
              <router-link
                to="/product-option-group/list"
                class="submenu__item"
              >
                Grupy opcji
              </router-link>
            </li>
            <li>
              <router-link
                to="/product-option-exclusion/list"
                class="submenu__item"
              >
                Wykluczenia opcji
              </router-link>
            </li>
            <li>
              <router-link to="/appliances" class="submenu__item">
                Zastosowania
              </router-link>
            </li>
            <li>
              <router-link to="/discount/list" class="submenu__item">
                Rabaty
              </router-link>
            </li>
          </ul>
        </li>

        <li :class="{ active: selectedMenu.includes('contents') }">
          <a class="navlink" @click="selectMenu('contents')">
            <i class="fa fa-th-large"></i>
            <span class="nav-label">Treści w serwisie</span>
            <i class="arrow fas fa-angle-left"></i>
          </a>
          <ul
            v-if="selectedMenu.includes('contents')"
            class="nav nav-second-level"
          >
            <li>
              <router-link to="/news/list" class="submenu__item">
                Aktualności
              </router-link>
            </li>
            <li>
              <router-link to="/banners/list" class="submenu__item">
                Banery
              </router-link>
            </li>
            <li>
              <router-link to="/pages" class="submenu__item">
                Strony
              </router-link>
            </li>
            <li>
              <router-link to="/faq" class="submenu__item">Faq</router-link>
            </li>
            <li>
              <router-link to="/translations" class="submenu__item">
                Tłumaczenia
              </router-link>
            </li>
            <li>
              <router-link to="/translations/models-list" class="submenu__item">
                Tłumaczenia Modeli
              </router-link>
            </li>
            <li>
              <router-link to="/file/list" class="submenu__item">
                Pliki
              </router-link>
            </li>
          </ul>
        </li>

        <li :class="{ active: selectedMenu.includes('logs') }">
          <a class="navlink" @click="selectMenu('logs')">
            <i class="fa fa-area-chart"></i>
            <span class="nav-label">Logi i statystyki</span>
            <i class="arrow fas fa-angle-left"></i>
          </a>
          <ul v-if="selectedMenu.includes('logs')" class="nav nav-second-level">
            <li>
              <router-link to="/montagelogs" class="submenu__item">
                <span>Statystki montaży</span>
              </router-link>
            </li>
            <li>
              <router-link to="/cubelogs" class="submenu__item">
                <span>Statystyki kostek i wysyłki</span>
              </router-link>
            </li>
            <li>
              <router-link to="/systemlogs" class="submenu__item">
                <span>Logi systemowe</span>
              </router-link>
            </li>
            <li>
              <router-link to="/productionlogs" class="submenu__item">
                <span>Logi produkcyjne</span>
              </router-link>
            </li>
            <li>
              <a class="navlink" @click="selectMenu('stats')">
                <span class="nav-label">Statystyki</span>
                <i class="arrow fas fa-angle-left"></i>
              </a>
              <ul
                v-if="selectedMenu.includes('stats')"
                class="nav nav-three-level"
              >
                <li>
                  <router-link to="/overall" class="submenu__item">
                    <span>Klienci</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/productlogs" class="submenu__item">
                    <span>Zamawiane produkty</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/packagelogs" class="submenu__item">
                    <span>Przesyłki/paczki</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/salelogs" class="submenu__item">
                    <span>Sprzedaży</span>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li :class="{ active: selectedMenu.includes('configuration') }">
          <a class="navlink" @click="selectMenu('configuration')">
            <i class="fa fa-gears"></i>
            <span class="nav-label">Konfiguracja</span>
            <i class="arrow fas fa-angle-left"></i>
          </a>
          <ul
            v-if="selectedMenu.includes('configuration')"
            class="nav nav-second-level"
          >
            <li>
              <router-link to="/admins" class="submenu__item">
                Użytkownicy
              </router-link>
            </li>
            <li>
              <router-link to="/groups" class="submenu__item">
                Grupy uprawnień
              </router-link>
            </li>
            <li>
              <router-link to="/competencies" class="submenu__item">
                Kompetencje
              </router-link>
            </li>
            <li>
              <router-link to="/ips" class="submenu__item">
                Dozwolone adresy IP
              </router-link>
            </li>
            <li>
              <router-link to="/settings" class="submenu__item">
                Ustawienia
              </router-link>
            </li>
            <li>
              <router-link to="/holidays" class="submenu__item">
                Dni wolne
              </router-link>
            </li>
            <li>
              <router-link to="/packages" class="submenu__item">
                Grupy pakowania
              </router-link>
            </li>
            <li>
              <router-link to="/machines" class="submenu__item">
                Maszyny
              </router-link>
            </li>
            <li>
              <router-link to="/processes" class="submenu__item">
                Procesy
              </router-link>
            </li>
            <li>
              <router-link to="/plates" class="submenu__item">
                Maszyny drukujące
              </router-link>
            </li>
            <li>
              <router-link to="/variables" class="submenu__item">
                <span>Zmienne pdfToolbox</span>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { ref } from "vue";
import { useCookies } from "vue3-cookies";
export default {
  name: "NavigationPage",
  mixins: [],
  components: {},

  props: {},

  setup() {
    const { cookies } = useCookies();
    const selectedMenu = ref([]);
    const userData = ref(cookies.get("CPadmin"));
    const selectMenu = (item) => {
      if (selectedMenu.value.includes(item)) {
        let index = selectedMenu.value.indexOf(item);
        if (index > -1) {
          selectedMenu.value.splice(index, 1);
        }
      } else {
        selectedMenu.value.push(item);
      }
    };
    return {
      selectMenu,
      selectedMenu,
      userData,
    };
  },
  computed: {},
};
</script>
