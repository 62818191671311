<template>
  <div class="middle-box text-center loginscreen animated fadeInDown">
    <div>
      <div>
        <h1 class="logo-name">CP</h1>
      </div>

      <h3>Witam w Caffeprint</h3>

      <div class="form">
        <p>
          <span>Zaloguj się przy pomocy</span>
          <a class="login-with-barcode"> kodu kreskowego</a>
        </p>

        <div v-if="loginData?.data?.non_field_errors" class="has-error">
          <span class="help-block">
            {{ loginData.data.non_field_errors[0] }}
          </span>
        </div>

        <div class="form-group">
          <input
            v-model="username"
            id="username"
            name="username"
            type="text"
            class="form-control"
            placeholder="Nazwa użytkownika"
          />

          <div v-if="sentForm && username.length === 0" class="has-error">
            <span class="help-block">To pole nie może być puste.</span>
          </div>
        </div>

        <div class="form-group">
          <input
            v-model="password"
            id="password"
            name="password"
            type="password"
            class="form-control"
            placeholder="Hasło"
          />

          <div v-if="sentForm && password.length === 0" class="has-error">
            <span class="help-block">To pole nie może być puste.</span>
          </div>
        </div>

        <button @click="login()" class="btn btn-primary block full-width m-b">
          Zaloguj się
        </button>
      </div>

      <p class="m-t">
        <small>Caffeprint &copy; 2024</small>
      </p>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useCookies } from "vue3-cookies";
import router from "../../router/router";

export default {
  name: "LoginPage",
  mixins: [],
  components: {},
  props: {},

  setup() {
    const store = useStore();
    const loginData = computed(() => store.getters.getLoginData);
    const adminData = computed(() => store.getters.getAdminData);
    const { cookies } = useCookies();
    let username = ref("");
    let password = ref("");
    let sentForm = ref(false);

    const login = async () => {
      await store.dispatch("login", {
        username: username.value,
        password: password.value,
      });
      sentForm.value = true;
    };

    watch(
      () => loginData.value,
      function (newValue) {
        if (newValue.data.token) {
          cookies.set("CPsession", newValue.data.token);
          store.dispatch("getAdminByUsername", {
            username: username.value,
          });
        }
      }
    );

    watch(
      () => adminData.value,
      function (newValue) {
        if (newValue.data.user) {
          cookies.set(
            "CPadmin",
            newValue.data.user.first_name + " " + newValue.data.user.last_name
          );
          cookies.set("CPid", parseInt(newValue.data.user.pk));
          router.push({
            name: "Home",
            path: "/",
          });
        }
      }
    );

    return {
      username,
      password,
      login,
      loginData,
      sentForm,
    };
  },
  computed: {},
};
</script>
