<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Bannery</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/banners/list">Bannery</router-link>
        </li>
        <li class="active">
          <strong>Nowa</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Dane banera</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa</label>
                <div class="col-sm-10">
                  <input
                    v-model="bannerObj.name"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">URL</label>
                <div class="col-sm-10">
                  <input
                    v-model="bannerObj.url"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="URL"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.url" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.url[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Opis [PL]</label>
                <div class="col-sm-10">
                  <input
                    v-model="bannerObj.description_pl"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Opis [PL]"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_pl"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Opis [DE]</label>
                <div class="col-sm-10">
                  <input
                    v-model="bannerObj.description_de"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Opis [DE]"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_de"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_de[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Opis [EN]</label>
                <div class="col-sm-10">
                  <input
                    v-model="bannerObj.description_en"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Opis [EN]"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_en"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="bannerObj.is_published"
                        name="is_published"
                        type="checkbox"
                      />
                      <span>Aktywny</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Czas emisji od</label>
                <div class="col-sm-10">
                  <VueDatePicker :format="format" v-model="datetime_from" />
                  <div
                    v-if="errorsAdminData?.data?.datetime_from"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.datetime_from[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Czas emisji do</label>
                <div class="col-sm-10">
                  <VueDatePicker :format="format" v-model="datetime_to" />
                  <div
                    v-if="errorsAdminData?.data?.datetime_to"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.datetime_to[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">
                  Czas wyświetlania [ms]
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="bannerObj.display_time"
                    name="name"
                    type="number"
                    class="form-control inputClass"
                    placeholder="Czas wyświetlania [ms]"
                  />
                  <div
                    v-if="errorsAdminData?.data?.display_time"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.display_time[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">
                  Dostępność w sklepach
                </label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="bannerObj.available_in_countries"
                    mode="tags"
                    placeholder="Dostępność w sklepach"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="countries"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.available_in_countries"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.available_in_countries[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Strony</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="bannerObj.pages"
                    mode="tags"
                    placeholder="Strony"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :searchable="true"
                    :options="pageBanners"
                  >
                  </Multiselect>
                  <div v-if="errorsAdminData?.data?.pages" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.pages[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">
                  Obrazek [skalowany do 1170x295 px]
                </label>
                <div class="col-sm-3">
                  <picture-input
                    ref="pictureInput"
                    width="300"
                    height="300"
                    margin="16"
                    accept="image/jpeg,image/png"
                    size="10"
                    button-class="btn"
                    :custom-strings="{
                      upload: '<h1>Uploading!</h1>',
                      drag: 'Drag & Drop',
                    }"
                    @change="changePhoto"
                  >
                  </picture-input>
                </div>
              </div>
            </div>

            <div class="ibox-content">
              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <router-link class="btn btn-white" to="/news/list">
                    Anuluj
                  </router-link>
                  <button @click="create" class="btn btn-primary">Dodaj</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import PictureInput from "vue-picture-input";

export default {
  name: "NewsCreate",
  mixins: [],
  components: {
    Multiselect,
    PictureInput,
  },
  props: {},

  setup() {
    const bannerObj = ref({
      name: "",
      url: "",
      is_published: false,
      datetime_from: "",
      datetime_to: "",
      display_time: "",
      image: "",
      available_in_countries: [],
      pages: [],
      description_pl: "",
      description_de: "",
      description_en: "",
      description_cpde: "",
      description_cpen: "",
    });
    const countries = ref(["PL", "DE"]);
    const pageBanners = ref([]);
    const datetime_from = ref();
    const datetime_to = ref();
    const errorsAdminData = ref();
    const showErrors = ref(false);
    const store = useStore();
    const createBannersData = computed(
      () => store.getters.getCreateBannersData
    );
    const pageBannersData = computed(() => store.getters.getPageBannersData);
    const create = () => {
      if (datetime_from.value && datetime_to.value) {
        bannerObj.value.datetime_from = format(datetime_from.value);
        bannerObj.value.datetime_to = format(datetime_to.value);
      }
      showErrors.value = true;
      store.dispatch("createBanners", bannerObj.value);
    };

    const format = (date) => {
      let day, month, year, hours, minutes, seconds;
      day = date.getDate();
      month = ("0" + (date.getMonth() + 1)).slice(-2);
      year = date.getFullYear();
      minutes = ("0" + date.getMinutes()).slice(-2);
      seconds = ("0" + date.getSeconds()).slice(-2);
      hours = date.getHours();

      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    };
    store.dispatch("getPageBanners");

    watch(
      () => createBannersData.value,
      function (newValue) {
        if (newValue.status === 201) {
          router
            .push({
              name: "BannersList",
              path: "/banners/list",
            })
            .then(() => {
              store.dispatch("showToaster", {
                type: "success",
                content: "Nowy banner został dodany",
              });
            });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    const changePhoto = (image) => {
      if (image.srcElement?.files[0]) {
        bannerObj.value.image = image.srcElement?.files[0];
      }
    };

    watch(
      () => pageBannersData.value,
      function (newValue) {
        newValue.data?.forEach((element) => {
          pageBanners.value.push({
            name: "[" + element.pk + "] " + element.title_seo,
            value: element.pk,
          });
        });
      }
    );

    return {
      bannerObj,
      errorsAdminData,
      showErrors,
      create,
      format,
      datetime_from,
      datetime_to,
      countries,
      pageBanners,
      changePhoto,
    };
  },
  computed: {},
};
</script>
