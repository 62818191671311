<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Statystyki</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Statystyki</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight logs">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Filtry</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-md-3 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Wybierz daty</label>
                  <div class="col-sm-8">
                    <VueDatePicker
                      class="stats-daterangepicker"
                      v-model="statsRangeDate"
                      multi-calendars
                      :format="format"
                      range
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Sklep</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="shop"
                      mode="single"
                      placeholder="Sklep"
                      track-by="name"
                      label="name"
                      :close-on-select="true"
                      :searchable="true"
                      :options="shopOptions"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Statystyki</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :loading="loading"
                  alternating
                  multi-sort
                  show-index
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Podsumowanie</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div v-if="summary !== 0" class="col-sm-12 m-b-xs">
                <EasyDataTable
                  :headers="headers"
                  :items="summary"
                  border-cell
                  hide-footer="true"
                >
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="statsRangeDate1[0]" class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Filtry</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-md-3 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Wybierz daty</label>
                  <div class="col-sm-8">
                    <VueDatePicker
                      class="stats-daterangepicker"
                      v-model="statsRangeDate1"
                      multi-calendars
                      :format="format"
                      range
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Sklep</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="shop1"
                      mode="single"
                      placeholder="Sklep"
                      track-by="name"
                      label="name"
                      :close-on-select="true"
                      :searchable="true"
                      :options="shopOptions"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Statystyki</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items1">
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll1"
                  :headers="headers"
                  :items="items1"
                  :loading="loading"
                  alternating
                  multi-sort
                  show-index
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Podsumowanie</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div v-if="summary1 !== 0" class="col-sm-12 m-b-xs">
                <EasyDataTable
                  :headers="headers"
                  :items="summary1"
                  border-cell
                  hide-footer="true"
                >
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="statsRangeDate" class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title"><h5>Porównaj zakres</h5></div>
          <div class="ibox-content">
            <div class="form-inline">
              <div class="form-group">
                <label for="no-comparative" class="sr-only">Liczba</label>
                <input
                  v-model="comparative"
                  type="number"
                  placeholder="Podaj liczbę"
                  min="1"
                  id="no-comparative"
                  class="form-control"
                />
              </div>
              <button @click="comparativeFn('week')" class="btn btn-white">
                Tygodni wcześniej
              </button>
              <button @click="comparativeFn('month')" class="btn btn-white">
                Miesięcy wcześniej
              </button>
              <button @click="comparativeFn('year')" class="btn btn-white">
                Lat wcześniej
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "ProductlogsList",
  mixins: [],
  components: {
    Multiselect,
  },
  props: {},

  setup() {
    const store = useStore();
    const productsStatsData = computed(
      () => store.getters.getProductsStatsData
    );
    let headers = [
      { text: "Product", value: "name", sortable: true, search: true },
      {
        text: "ID produktu",
        value: "id",
        sortable: true,
      },
      { text: "Ilość zamówień produktu", value: "no_orders", sortable: false },
      { text: "Wartość netto", value: "net_value", sortable: false },
      { text: "Waga", value: "weight", sortable: false },
      {
        text: "Ilość loginów, które zamówiły produkt",
        value: "no_logins",
        sortable: false,
      },
    ];
    const shopOptions = ref([
      { name: "Polska", value: "PL" },
      { name: "Niemcy", value: "DE" },
      { name: "Zamówienia zewnętrzne", value: "ZZ" },
    ]);
    const summary = ref([]);
    const summary1 = ref([]);
    const loading = ref(true);
    const searchValue = ref({});
    const isSearch = ref(false);
    const shop = ref();
    const shop1 = ref();
    const rowsPerPage = ref();
    const statsRangeDate = ref();
    const statsRangeDate1 = ref([]);
    const comparative = ref(0);
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 20,
    });
    let items = ref([]);
    let items1 = ref([]);
    let itemsAll = ref(0);
    let itemsAll1 = ref(0);
    const getProductsStats = (comp = false) => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      let shopVal = shop.value;
      let date = statsRangeDate.value;
      if (comp) {
        date = statsRangeDate1.value;
        shopVal = shop1.value;
      }
      store.dispatch("getProductsStats", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        date: date,
        search: searchValue.value,
        shop_version: shopVal,
        comp: comp,
      });
    };

    const comparativeFn = (type) => {
      let dateStart = new Date(statsRangeDate.value[0]);
      let dateEnd = new Date(statsRangeDate.value[1]);
      dateStart.toLocaleDateString();
      dateEnd.toLocaleDateString();
      if (type === "week") {
        dateStart.setDate(dateStart.getDay() - comparative.value * 7);
        dateEnd.setDate(dateEnd.getDay() - comparative.value * 7);
      } else if (type === "month") {
        dateStart.setMonth(dateStart.getMonth() - comparative.value);
        dateEnd.setMonth(dateEnd.getMonth() - comparative.value);
      } else if (type === "year") {
        dateStart.setYear(dateStart.getFullYear() - comparative.value);
        dateEnd.setYear(dateEnd.getFullYear() - comparative.value);
      }
      statsRangeDate1.value[0] = dateStart;
      statsRangeDate1.value[1] = dateEnd;
      getProductsStats(true);
    };

    const format = (date) => {
      let day, month, year, dayEnd, monthEnd, yearEnd;
      if (date[0]) {
        day = date[0].getDate();
        month = date[0].getMonth() + 1;
        year = date[0].getFullYear();
      }

      if (date[1]) {
        dayEnd = date[1].getDate();
        monthEnd = date[1].getMonth() + 1;
        yearEnd = date[1].getFullYear();
      }

      if (date.length > 1) {
        return `${day}/${month}/${year} - ${dayEnd}/${monthEnd}/${yearEnd}`;
      }
    };

    const tableSummary = (comp = false) => {
      let tempSummary = {
        no_orders: 0,
        net_value: 0,
        weight: 0,
        no_logins: 0,
      };
      if (comp) {
        summary1.value = [];
        items1.value?.forEach((element) => {
          tempSummary.no_orders += element.no_orders;
          tempSummary.net_value += element.net_value;
          tempSummary.weight += element.weight;
          tempSummary.no_logins += element.no_logins;
        });
        tempSummary.net_value = tempSummary.net_value.toFixed(2);
        summary1.value.push(tempSummary);
      } else {
        summary.value = [];
        items.value?.forEach((element) => {
          tempSummary.no_orders += element.no_orders;
          tempSummary.net_value += element.net_value;
          tempSummary.weight += element.weight;
          tempSummary.no_logins += element.no_logins;
        });
        tempSummary.net_value = tempSummary.net_value.toFixed(2);
        summary.value.push(tempSummary);
      }
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getProductsStats();
        }, 1000);
      }
    };

    const updateSort = () => {
      search.value = true;
    };

    getProductsStats();

    watch(
      () => productsStatsData.value,
      function (newValue) {
        if (newValue.comp) {
          itemsAll1.value = newValue.data.count;
          items1.value = [];
          newValue.data.results?.forEach((element) => {
            items1.value.push(element);
          });
        } else {
          itemsAll.value = newValue.data.count;
          items.value = [];
          newValue.data.results?.forEach((element) => {
            items.value.push(element);
          });
        }
        tableSummary(newValue.comp);
        loading.value = false;
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        getProductsStats();
      }
    );
    watch(
      () => shop.value,
      function () {
        getProductsStats();
      }
    );
    watch(
      () => shop1.value,
      function () {
        getProductsStats(true);
      }
    );
    watch(
      () => statsRangeDate.value,
      function () {
        getProductsStats();
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getProductsStats();
          }, 1000);
        }
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getProductsStats();
          isSearch.value = false;
        }
      }
    );

    return {
      headers,
      serverOptions,
      items,
      items1,
      itemsAll,
      itemsAll1,
      loading,
      format,
      searchValue,
      search,
      updateSort,
      isSearch,
      rowsPerPage,
      statsRangeDate,
      statsRangeDate1,
      shop,
      shop1,
      shopOptions,
      comparativeFn,
      comparative,
      summary,
      summary1,
    };
  },
  computed: {},
};
</script>
