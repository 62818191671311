<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Grupy opcji</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/product-option-group/list">Grupy opcji</router-link>
        </li>
        <li class="active">
          <strong>Nowa</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Dane grupy opcji</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa pl</label>
                <div class="col-sm-10">
                  <input
                    v-model="pOptionsGroupObj.name_pl"
                    name="name_pl"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa pl"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name_pl" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa en</label>
                <div class="col-sm-10">
                  <input
                    v-model="pOptionsGroupObj.name_en"
                    name="name_en"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa en"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name_en" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name_en[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa de</label>
                <div class="col-sm-10">
                  <input
                    v-model="pOptionsGroupObj.name_de"
                    name="name_de"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa de"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name_de" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name_de[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa de_de</label>
                <div class="col-sm-10">
                  <input
                    v-model="pOptionsGroupObj.name_cpde"
                    name="name_cpde"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa de_de"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.name_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.name_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa de_en</label>
                <div class="col-sm-10">
                  <input
                    v-model="pOptionsGroupObj.name_cpen"
                    name="name_cpen"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa de_en"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.name_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.name_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Opis</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="pOptionsGroupObj.description"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Wybór</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="pOptionsGroupObj.group_choice"
                    mode="single"
                    placeholder="Wybór"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :searchable="true"
                    :options="POGChoices"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.group_choice"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.group_choice[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="pOptionsGroupObj.determines_paper_type"
                        name="determines_paper_type"
                        type="checkbox"
                      />
                      <span>Określa rodzaj papieru</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="pOptionsGroupObj.select_from_list"
                        name="select_from_list"
                        type="checkbox"
                      />
                      <span>Wybór z listy</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="pOptionsGroupObj.hide_in_order"
                        name="hide_in_order"
                        type="checkbox"
                      />
                      <span>Ukryj na karcie zamówienia i karcie montażu</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <router-link
                    class="btn btn-white"
                    to="/product-option-group/list"
                  >
                    Anuluj
                  </router-link>
                  <button @click="create" class="btn btn-primary">Dodaj</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "ProductOptionCreate",
  mixins: [],
  components: {
    Multiselect,
  },
  props: {},

  setup() {
    const pOptionsGroupObj = ref({
      select_from_list: false,
      determines_paper_type: false,
      hide_in_order: false,
    });
    const errorsAdminData = ref();
    const POGChoices = ref([]);
    const showErrors = ref(false);
    const store = useStore();
    const createpOptionsGroupData = computed(
      () => store.getters.getCreatepOptionsGroupData
    );
    const POGChoicesData = computed(() => store.getters.getPOGChoicesData);

    const create = () => {
      showErrors.value = true;
      store.dispatch("createpOptionsGroup", pOptionsGroupObj.value);
    };
    store.dispatch("getPOGChoices");

    watch(
      () => createpOptionsGroupData.value,
      function (newValue) {
        if (newValue.status === 201) {
          router
            .push({
              name: "ProductOptionList",
              path: "/product-option-group/list",
            })
            .then(() => {
              store.dispatch("showToaster", {
                type: "success",
                content: "Nowa grupa została dodana",
              });
            });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    watch(
      () => POGChoicesData.value,
      function (newValue) {
        newValue.data?.forEach((element) => {
          POGChoices.value.push({
            name: element[1],
            value: element[0],
          });
        });
      }
    );

    return {
      pOptionsGroupObj,
      errorsAdminData,
      showErrors,
      create,
      POGChoices,
    };
  },
  computed: {},
};
</script>
