<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Grupy uprawnień</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Grupy uprawnień</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Grupy uprawnień</h5>
          </div>
          <div class="ibox-content">
            <router-link to="/groups/create">
              <button class="btn btn-sm btn-primary">Dodaj</button>
            </router-link>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <button @click="resetFilters" class="btn btn-primary btn-xs">
                  <i class="fa fa-refresh"></i>
                </button>
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :clickRowToExpand="true"
                  :loading="loading"
                  @click-row="editGroup"
                  alternating
                  multi-sort
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                  <template #expand="item">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Edycja grupy uprawnień {{ item.name }}</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label
                                  for="groupname"
                                  class="control-label col-sm-2"
                                >
                                  Nazwa
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    id="groupname"
                                    v-model="groupObj.name"
                                    name="username"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.name"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.name[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Uprawnienia
                                </label>
                                <div class="col-sm-10">
                                  <Multiselect
                                    v-model="groupObj.permissions"
                                    mode="tags"
                                    placeholder="Uprawnienia"
                                    track-by="name"
                                    label="name"
                                    :close-on-select="false"
                                    :searchable="true"
                                    :options="permissionsList"
                                  >
                                  </Multiselect>
                                  <div
                                    v-if="
                                      !groupObj.permissions?.length &&
                                      showErrors
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      To pole nie może być puste
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="hr-line-dashed"></div>

                              <div class="form-group">
                                <div class="col-sm-4 col-sm-offset-2">
                                  <button @click="cancel" class="btn btn-white">
                                    Anuluj
                                  </button>
                                  <button
                                    @click="saveEdit"
                                    class="btn btn-primary"
                                  >
                                    Zapisz
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #item-user_count="item">
                    <span
                      class="group-users-count"
                      @mouseover="groupUsersList(item.pk)"
                    >
                      {{ item.user_count }}

                      <div
                        v-if="usersPk === item.pk && item.user_count > 0"
                        class="group-users-list"
                      >
                        <router-link
                          v-for="(user, key) in users"
                          :to="'/admins/' + user.user.pk"
                          :key="key"
                        >
                          {{ user.user.first_name }}
                          {{ user.user.last_name }}
                        </router-link>
                      </div>
                    </span>
                  </template>
                  <template #item-actions="item">
                    <button
                      @click.stop="
                        showModal = item.pk;
                        deleteName = item.name;
                      "
                      class="btn btn-danger btn-xs"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showModal"
    :title="'Potwierdzenie usunięcia'"
    :content="'Czy na pewno chcesz usunąć <b>' + deleteName + '</b> ?'"
    @callbackOk="deleteGroup(showModal)"
    @callbackClose="showModal = false"
  />
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../caffeprint/Modal.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "GroupsList",
  mixins: [],
  components: {
    Modal,
    Multiselect,
  },
  props: {},

  setup() {
    const store = useStore();
    store.dispatch("getPermissions");
    const permissionsGroupsData = computed(
      () => store.getters.getPermissionsGroupsData
    );
    const permissionsGroupData = computed(
      () => store.getters.getPermissionsGroupData
    );
    const deletePermissionsGroupData = computed(
      () => store.getters.getDeletePermissionsGroupData
    );
    const updatePermissionsGroupData = computed(
      () => store.getters.getUpdatePermissionsGroupData
    );
    const permissionsData = computed(() => store.getters.getPermissionsData);
    const adminsPGsData = computed(() => store.getters.getAdminsPGsData);
    const groupObj = ref({
      name: "",
      permissions: [],
    });
    let headers = [
      { text: "Nr", value: "pk" },
      {
        text: "Nazwa",
        value: "name",
        sortable: true,
        search: true,
      },
      {
        text: "Ilość użytkowników",
        value: "user_count",
        sortable: true,
      },
      {
        text: "Data dodania",
        value: "date_added",
        sortable: true,
        search: true,
      },
      {
        text: "Data modyfikacji",
        value: "date_modified",
        sortable: true,
        search: true,
      },
      { text: "Akcje", value: "actions" },
    ];

    let items = ref([]);
    let users = ref([]);
    let usersPk = ref();
    let itemsAll = ref(0);
    const showModal = ref(false);
    const deleteName = ref("");
    const permissionsList = ref([]);
    const loading = ref(true);
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const searchValue = ref({});
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });

    const getPermissionsGroups = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      await store.dispatch("getPermissionsGroups", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
      });
    };

    const deleteGroup = (pk) => {
      showModal.value = false;
      store.dispatch("deletePermissionsGroup", {
        pk: pk,
      });
    };

    const groupUsersList = (pk) => {
      if (pk !== usersPk.value) {
        users.value = [];
        usersPk.value = pk;
        store.dispatch("getAdminsPGs", pk);
      }
    };
    const saveEdit = () => {
      store.dispatch("updatePermissionsGroup", groupObj.value);
    };

    const cancel = () => {
      document.querySelector(".expanding")?.click();
    };

    const editGroup = (item) => {
      cancel();
      store.dispatch("getPermissionsGroup", { pk: item.pk });
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getPermissionsGroups();
        }, 1000);
      }
    };

    const updateSort = () => {
      search.value = true;
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getPermissionsGroups();
    };

    getPermissionsGroups();

    watch(
      () => permissionsGroupsData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => permissionsGroupData.value,
      function (newValue) {
        groupObj.value = newValue.data;
      }
    );

    watch(
      () => deletePermissionsGroupData.value,
      function (newValue) {
        if (newValue.status === 204) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Grupa uprawnień została usunięta",
          });
        }
        getPermissionsGroups();
      }
    );

    watch(
      () => permissionsData.value,
      function (newValue) {
        newValue.data?.forEach((element) => {
          permissionsList.value.push({
            name: "[" + element.pk + "] " + element.name,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => adminsPGsData.value,
      function (newValue) {
        newValue.data.results.forEach((element) => {
          users.value.push(element);
        });
      }
    );

    watch(
      () => updatePermissionsGroupData.value,
      function (newValue) {
        if (newValue.status === 200) {
          getPermissionsGroups();
          store.dispatch("showToaster", {
            type: "success",
            content: "Dane zostały zaktualizowane",
          });
        }

        cancel();
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getPermissionsGroups();
          }, 1000);
        }
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getPermissionsGroups();
          isSearch.value = false;
        }
      }
    );

    return {
      headers,
      items,
      itemsAll,
      loading,
      serverOptions,
      permissionsList,
      showModal,
      deleteGroup,
      deleteName,
      groupObj,
      cancel,
      editGroup,
      saveEdit,
      groupUsersList,
      users,
      usersPk,
      search,
      searchValue,
      isSearch,
      resetFilters,
      updateSort,
      rowsPerPage,
    };
  },
  computed: {},
};
</script>
