<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Produkty</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Produkty</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Filtry</h5>
          </div>
          <div class="ibox-content">
            <div class="form-group">
              <div class="col-sm-5">
                <label class="control-label col-sm-2">Kategoria</label>
                <Multiselect
                  v-model="filterCategory"
                  mode="single"
                  placeholder="Kategoria"
                  track-by="name"
                  label="name"
                  :close-on-select="true"
                  :searchable="true"
                  :options="categories"
                >
                </Multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Produkty</h5>
          </div>
          <div class="ibox-content">
            <router-link to="/products/create">
              <button class="btn btn-sm btn-primary">Dodaj</button>
            </router-link>
            <button @click="publishMany(true)" class="btn btn-sm btn-primary">
              Publikuj
            </button>
            <button @click="publishMany(false)" class="btn btn-sm btn-primary">
              Wyłącz
            </button>
            <button @click="importFn()" class="btn btn-sm btn-primary">
              Importuj logi
            </button>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <button @click="resetFilters" class="btn btn-primary btn-xs">
                  <i class="fa fa-refresh"></i>
                </button>
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  v-model:items-selected="itemsSelected"
                  :headers="headers"
                  :items="items"
                  :clickRowToExpand="false"
                  :loading="loading"
                  alternating
                  multi-sort
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                  <template #item-actions="item">
                    <router-link :to="'/Products/edit/' + item.pk">
                      <button class="btn btn-warning btn-xs">
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                    </router-link>
                    <button
                      @click.stop="
                        showModal = item.pk;
                        deleteName = item.name;
                      "
                      class="btn btn-danger btn-xs"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </template>
                  <template #item-image="item">
                    <img :src="item.image" />
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showModal"
    :title="'Potwierdzenie usunięcia'"
    :content="'Czy na pewno chcesz usunąć <b>' + deleteName + '</b> ?'"
    @callbackOk="deleteProducts(showModal)"
    @callbackClose="showModal = false"
  />
  <div v-if="mImport" class="modal-dialog">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header">
          <h4 class="modal-title">Import operacji grup/opcji produktu</h4>
        </div>
        <div class="modal-body">
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <div class="col-sm-12">
                  <Multiselect
                    v-model="pOption"
                    mode="single"
                    placeholder="Produkt"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :searchable="true"
                    :options="pOptions"
                    :hideSelected="true"
                  >
                  </Multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="mImport = false" class="btn btn-white">Anuluj</button>
          <button @click="addGroup()" class="btn btn-primary">Dodaj</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mImport" @click="mImport = false" class="modal-backdrop"></div>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../caffeprint/Modal.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "ProductsList",
  mixins: [],
  components: {
    Modal,
    Multiselect,
  },
  props: {},

  setup() {
    const store = useStore();
    const deleteProductsData = computed(
      () => store.getters.getDeleteProductsData
    );
    const machineData = computed(() => store.getters.getMachineData);
    const updateProductsData = computed(
      () => store.getters.getUpdateProductsData
    );
    const productsData = computed(() => store.getters.getProductsData);
    const categoriesData = computed(
      () => store.getters.getCategoriesAdminProductData
    );
    const publishProductsData = computed(
      () => store.getters.getPublishProductsData
    );
    const adminProductsOptionsData = computed(
      () => store.getters.getAdminProductsOptionsData
    );
    const productObj = ref({
      name: "",
      model: "",
      type: "",
      image: "",
    });
    let headers = [
      { text: "ID", value: "pk" },
      {
        text: "Nazwa",
        value: "name",
        sortable: true,
        search: true,
      },
      {
        text: "Opublikowanyy",
        value: "is_published",
        sortable: true,
        search: true,
      },
      {
        text: "Data od",
        value: "date_from",
        sortable: true,
        search: true,
      },
      { text: "Data do", value: "date_to", sortable: true },
      { text: "Akcje", value: "actions" },
    ];

    let items = ref([]);
    let itemsAll = ref(0);
    const showModal = ref(false);
    const deleteName = ref("");
    const loading = ref(true);
    const searchValue = ref({});
    const isSearch = ref(false);
    const mImport = ref(false);
    const rowsPerPage = ref();
    const itemsSelected = ref([]);
    const pOptions = ref([]);
    const pOption = ref();
    const categories = ref([]);
    const filterCategory = ref("");
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });

    const getProducts = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      await store.dispatch("getProducts", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
        category: filterCategory.value,
      });
    };

    const deleteProducts = (pk) => {
      showModal.value = false;
      store.dispatch("deleteProducts", {
        pk: pk,
      });
    };
    const saveEdit = () => {
      store.dispatch("updateProducts", productObj.value);
    };

    const cancel = () => {
      document.querySelector(".expanding")?.click();
    };

    const editProduct = (item) => {
      cancel();
      store.dispatch("getProduct", { pk: item.pk });
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getProducts();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getProducts();
    };

    const updateSort = () => {
      search.value = true;
    };

    const importFn = () => {
      if (itemsSelected.value.length > 0) {
        mImport.value = true;
      } else {
        store.dispatch("showToaster", {
          type: "error",
          content: "Nie wybrano żadnych produktów",
        });
      }
    };

    const publishMany = (type) => {
      let publishData = [];
      itemsSelected.value.forEach((element) => {
        publishData.push({
          pk: element.pk,
          is_published: type,
        });
      });
      if (publishData.length > 0) {
        store.dispatch("publishProducts", publishData);
      } else {
        store.dispatch("showToaster", {
          type: "error",
          content: "Nie wybrano żadnych produktów",
        });
      }
    };

    store.dispatch("getCategoriesAdminProductFilter");
    store.dispatch("getAdminProductsOptions");
    getProducts();

    watch(
      () => productsData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => machineData.value,
      function (newValue) {
        productObj.value = newValue.data;
      }
    );

    watch(
      () => deleteProductsData.value,
      function (newValue) {
        if (newValue.status === 204) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Maszyna została usunieta",
          });
        }
        getProducts();
      }
    );

    watch(
      () => publishProductsData.value,
      function (newValue) {
        if (newValue.status === 200) {
          let strPublish = "opublikowane";
          if (newValue.data[0].is_published === false) {
            strPublish = "wyłączone";
          }
          store.dispatch("showToaster", {
            type: "success",
            content: "Wybrane produkty zostały " + strPublish,
          });
        }
        getProducts();
      }
    );

    watch(
      () => updateProductsData.value,
      function (newValue) {
        if (newValue.status === 200) {
          getProducts();
          store.dispatch("showToaster", {
            type: "success",
            content: "Dane zostały zaktualizowane",
          });
        }

        cancel();
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getProducts();
          }, 1000);
        }
      }
    );

    watch(
      () => categoriesData.value,
      function (newValue) {
        if (newValue.status === 200) {
          newValue.data?.forEach((element) => {
            categories.value.push({
              name: "[" + element.pk + "] " + element.title,
              value: element.pk,
            });
          });
        }
      }
    );

    watch(
      () => adminProductsOptionsData.value,
      function (newValue) {
        if (newValue.status === 200) {
          newValue.data?.results.forEach((element) => {
            pOptions.value.push({
              name: "[" + element.pk + "] " + element.name,
              value: element.pk,
            });
          });
        }
      }
    );

    watch(
      () => filterCategory.value,
      function () {
        getProducts();
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getProducts();
          isSearch.value = false;
        }
      }
    );

    return {
      headers,
      items,
      itemsAll,
      loading,
      serverOptions,
      showModal,
      deleteProducts,
      deleteName,
      productObj,
      cancel,
      editProduct,
      saveEdit,
      searchValue,
      isSearch,
      rowsPerPage,
      resetFilters,
      updateSort,
      search,
      itemsSelected,
      filterCategory,
      categories,
      publishMany,
      importFn,
      mImport,
      pOptions,
      pOption,
    };
  },
  computed: {},
};
</script>
